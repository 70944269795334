<template>
  <div class="mb-markeanlegen" v-if="app.currentBrand != null">
    <div class="mb-cont-header text-center">
      <h2 v-if="app.currentContact != null" v-html="$t('page.newbrand.success.title', { firstname: app.currentContact.Firstname, lastname: app.currentContact.Lastname })"></h2>
      <div v-html="$t('page.newbrand.success.partofnetwork', { brandname: app.currentBrand.Name })"></div>
    </div>
    <div class="mb-markeanlegen-top" v-if="isMaintenance">
      <h2 v-t="'page.login.maintenance'"></h2>
      <p v-html="$t('page.login.maintenancetext')"></p>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class="mb-progress">
            <h3 v-t="'page.membershipsuccess.howitcontinues'"></h3>
            <ul class="mb-info-lst">
              <li v-if="app.shortlyRegistered"><i class="fa fa-check"></i><span v-html="$t('page.newbrand.success.usercreated', { user: app.login.user.Email })"></span></li>
              <li><i class="fa fa-check"></i><span v-html="$t('page.newbrand.success.brandcreated', { brandname: app.currentBrand.Name })"></span></li>
              <li><i class="fa fa-clock-o"></i><span v-t="'page.newbrand.success.creatingtext'"></span></li>
              <li><i class="fa fa-clock-o"></i><span v-t="'page.newbrand.success.notificationmail'"></span></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-5">
            <div class="form-container">
              <h3 v-t="'page.newbrand.success.newpossibilities'"></h3>
              <div class="text-center">
              <img
                :src="app.currentBrandLogo"
                class="mb-logo img-fluid"
              >
              </div>
              <h3 class="mb-brandname">{{app.currentBrand.Name}} <i class="fa fa-check"></i></h3>
              <ul class="mb-info-lst">
                <li><i class="fa fa-unlock-alt"></i><span v-t="'page.newbrand.success.completeprofile'"></span></li>
                <li><i class="fa fa-unlock-alt"></i><span v-t="'page.newbrand.success.startmembership'"></span></li>
              </ul>
              <div class="text-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="goToDashboard()"
                  v-t="'page.newbrand.success.todashboard'"
                ></button>
              </div>
            </div>
        </div>
      </div>
      <div class="mb-kurz-info">
        <h2>
          <i class="fa fa-clock-o"></i><span v-t="'page.newbrand.success.shortprofile'"></span>
        </h2>
        <div class="mb-kurz-cont">
          <ul class="mb-info-lst">
            <li><i class="fa fa-check"></i><span v-t="'page.newbrand.success.brandpage'"></span></li>
            <li><i class="fa fa-check"></i><span v-t="'page.newbrand.success.gersites'"></span></li>
            <li><i class="fa fa-check"></i><span v-t="'page.newbrand.success.newsletter'"></span></li>
            <li><i class="fa fa-check"></i><span v-t="'page.newbrand.success.visibility'"></span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Config from '../config'
import VueConfetti from 'vue-confetti'

Vue.use(VueConfetti)

export default {
  name: 'MarkeAnlegen',
  components: {
  },
  props: [],
  data: function () {
    return {
      isMaintenance: false
    }
  },
  computed: {
    currentAppVersion: function () {
      return Config.CLIENT_VERSION
    },
    isLoggedIn: function () {
      return this.$parent.isLoggedIn
    },
    app: function () {
      return this.$parent || {}
    }
  },
  watch: {
    $route (to) {
      if (to.path === '/markeanlegendetails') {
        this.setDefaultValues()
      } else {
        this.resetValues()
      }
    }
  },
  mounted: function () {
    this.$confetti.start({
      particles: [
        {
          type: 'rect'
        }
      ]
    })
    setTimeout(() => {
      this.$confetti.stop()
    }, 5000)
  },
  methods: {
    goToDashboard () {
      this.resetValues()
      this.$router.push('/dashboard')
    },
    resetValues () {
      this.app.showSaveSuccessPopup = true
      this.app.showSaveBar = true
      this.app.showPageLoadingOnSave = true
      this.app.loadBrandDataAfterSaveBrand = true
      this.app.shortlyRegistered = false
    },
    setDefaultValues () {
      this.app.showSaveSuccessPopup = false
      this.app.showSaveBar = false
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  color: white;
}
.mb-markeanlegen {
  > form {
    > .form-group {
      margin-bottom: 30px;
    }

    .btn {
      width: 100%;
      margin-bottom: 10px;
    }

    a {
      color: white;
      text-align: center;
    }
  }
}
.clientVersionHint {
  font-size: 10px;
  color: #fff;
}
</style>
