<template>
  <section>
    <div class="d-flex gap-2 w-100">
      <b-form-checkbox v-if="showSelect" v-model="isSelected" />
      <a href="javascript:" class="header" @click="toggleContent">
        <div class="headline">{{ this.title }}</div>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" v-if="isOpen">
          <path d="M24 20L16 12L8 20" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" v-else>
          <path d="M8 12L16 20L24 12" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </a>
    </div>

    <div v-if="isOpen">
      <slot />
    </div>
  </section>
</template>

<script>

export default {
  name: 'FaqQuestionContainer',
  data: function () {
    return {
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => []
    },
    showSelect: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 1
    },
    faqId: {
      type: Number || null,
      required: true
    }
  },
  computed: {
    computedIsDisabled () {
      return this.isDisabled || this.computedIsLoading || (this.selected.length >= 6 && !this.isSelected)
    },
    isSelected: {
      get () {
        return this.selected.includes(this.faqId)
      },
      set (val) {
        if (val) {
          this.selectFaqId()
        } else {
          this.deselectFaqId()
        }
      }
    }
  },
  methods: {
    toggleContent () {
      this.$emit('toggle', this.index)
    },
    selectFaqId () {
      this.$emit('select', this.faqId)
    },
    deselectFaqId () {
      this.$emit('deselect', this.faqId)
    }
  },
  watch: {
    isSelected (val) {
      if (val) {
        this.selectFaqId()
      } else {
        this.deselectFaqId()
      }
    }
  }
}

</script>

<style lang="scss" scoped>
section {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background-color: #EFF1F4;

  a {
    color: #3F4957;
    text-decoration: none;
  }

}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  flex-grow: 1;

  .headline {
    flex: 1 0 0;
    color: #3F4957;
    font-family: "Nunito Sans";
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}
</style>
