<template>
  <a href="javascript:" class="locale-switch" @click="switchLanguage">
        <inline-svg
          height="32"
          viewBox="0 0 640 480"
          :src="computedLocaleIcon"
        />
      </a>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
export default {
  name: 'LocaleSwitchComponent',
  components: {
    InlineSvg
  },
  computed: {
    computedLocaleIcon () {
      switch (this.$i18n.locale) {
        case 'de':
          return require('@/assets/images/flags/de.svg')
        case 'en':
          return require('@/assets/images/flags/gb.svg')
      }
      return null
    }
  },
  methods: {
    switchLanguage () {
      switch (this.$i18n.locale) {
        case 'de':
          this.$i18n.locale = 'en'
          break
        case 'en':
          this.$i18n.locale = 'de'
          break
      }
    }
  }
}
</script>
