<template>
    <div class="row slim" id="licencefee">
        <div class="col-12">
        <h6 class="mb-card-title" v-t="'page.newbrand.basicdata.licencefee'"></h6>
        </div>
        <div class="col form-group-options">
        <label
            class="checkbox form-group"
            :class="currentBrand.CondRegularLicenceType==-1 ? 'selected' : ''"
        >
            <input
            v-model="currentBrand.CondRegularLicenceType"
            type="radio"
            name="CondRegularLicenceType"
            :value="-1"
            @change="registerChange"
            > {{ $t('choice.notspecifiedseeremark') }}
        </label>
        <label
            class="checkbox form-group"
            :class="currentBrand.CondRegularLicenceType==0 ? 'selected' : ''"
        >
            <input
            v-model="currentBrand.CondRegularLicenceType"
            type="radio"
            name="CondRegularLicenceType"
            :value="0"
            @change="registerChange"
            > {{ $t('choice.nolicencefee') }}
        </label>
        <label
            class="checkbox form-group"
            :class="currentBrand.CondRegularLicenceType==1 ? 'selected' : ''"
        >
            <input
            v-model="currentBrand.CondRegularLicenceType"
            type="radio"
            name="CondRegularLicenceType"
            :value="1"
            @change="registerChange"
            >
            <div
            v-if="currentBrand.CondRegularLicenceType!=1"
            class="cover-parent"
            />
            <percentage-input
            v-model="currentBrand.CondRegularLicencePercentage"
            class="d-inline"
            :disabled="currentBrand.CondRegularLicenceType!=1"
            @change="registerChange"
            />{{ $t('choice.nettopermonth') }}
        </label>
        <label
            class="checkbox form-group"
            :class="currentBrand.CondRegularLicenceType==2 ? 'selected' : ''"
        >
            <input
            v-model="currentBrand.CondRegularLicenceType"
            type="radio"
            name="CondRegularLicenceType"
            :value="2"
            @change="registerChange"
            >
            <div
            v-if="currentBrand.CondRegularLicenceType!=2"
            class="cover-parent"
            />
            <currency-input
            v-model="currentBrand.CondRegularLicenceAmount"
            style="width:120px"
            :disabled="currentBrand.CondRegularLicenceType!=2"
            :currency="currentBrand.CondCurrency"
            locale="de"
            class="percentage-input"
            @change="registerChange"
            />{{ $t('choice.fixedpermonth') }}
        </label>
        <label
            class="checkbox form-group"
            :class="currentBrand.CondRegularLicenceType==3 ? 'selected' : ''"
        >
            <input
            v-model="currentBrand.CondRegularLicenceType"
            type="radio"
            name="CondRegularLicenceType"
            :value="3"
            @change="registerChange"
            >
            <div
            v-if="currentBrand.CondRegularLicenceType!=3"
            class="cover-parent"
            />
            <percentage-input
            v-model="currentBrand.CondRegularLicencePercentage"
            class="d-inline"
            :disabled="currentBrand.CondRegularLicenceType!=3"
            @change="registerChange"
            />{{ $t('choice.nettoplus') }}
            <currency-input
            v-model="currentBrand.CondRegularLicenceAmount"
            style="width:120px"
            :disabled="currentBrand.CondRegularLicenceType!=3"
            :currency="currentBrand.CondCurrency"
            locale="de"
            class="percentage-input ms-15"
            @change="registerChange"
            />{{ $t('choice.fixedpermonth') }}
        </label>
        <label
            class="checkbox form-group"
            :class="currentBrand.CondRegularLicenceType==5 ? 'selected' : ''"
        >
            <input
            v-model="currentBrand.CondRegularLicenceType"
            type="radio"
            name="CondRegularLicenceType"
            :value="5"
            @change="registerChange"
            >
            <div
            v-if="currentBrand.CondRegularLicenceType!=5"
            class="cover-parent"
            />
            <percentage-input
            v-model="currentBrand.CondRegularLicencePercentage"
            class="d-inline"
            :disabled="currentBrand.CondRegularLicenceType!=5"
            @change="registerChange"
            />{{ $t('choice.nettobut') }}
            <currency-input
            v-model="currentBrand.CondRegularLicenceAmount"
            style="width:120px"
            :disabled="currentBrand.CondRegularLicenceType!=5"
            :currency="currentBrand.CondCurrency"
            locale="de"
            class="percentage-input ms-15"
            @change="registerChange"
            />{{ $t('choice.minpermonth') }}
        </label>
        <label
            class="checkbox form-group"
            :class="currentBrand.CondRegularLicenceType==6 ? 'selected' : ''"
        >
            <input
            v-model="currentBrand.CondRegularLicenceType"
            type="radio"
            name="CondRegularLicenceType"
            :value="6"
            @change="registerChange"
            >
            <div
            v-if="currentBrand.CondRegularLicenceType!=6"
            class="cover-parent"
            />
            {{ $t('choice.variablepercentfrom') }}
            <percentage-input
            v-model="currentBrand.CondRegularLicencePercentage"
            class="d-inline ms-2"
            :disabled="currentBrand.CondRegularLicenceType!=6"
            @change="registerChange"
            />
            {{ $t('choice.to') }}
            <percentage-input
            v-model="currentBrand.CondRegularLicenceAmount"
            class="d-inline ms-2"
            :disabled="currentBrand.CondRegularLicenceType!=6"
            @change="registerChange"
            />
        </label>
        <label
            class="checkbox form-group"
            :class="currentBrand.CondRegularLicenceType==7 ? 'selected' : ''"
        >
            <input
            v-model="currentBrand.CondRegularLicenceType"
            type="radio"
            name="CondRegularLicenceType"
            :value="7"
            @change="registerChange"
            >
            <div
            v-if="currentBrand.CondRegularLicenceType!=7"
            class="cover-parent"
            />
            {{ $t('choice.variableamountfrom') }}
            <currency-input
            v-model="currentBrand.CondRegularLicenceAmount"
            style="width:120px"
            :disabled="currentBrand.CondRegularLicenceType!=7"
            :currency="currentBrand.CondCurrency"
            locale="de"
            class="percentage-input ms-15"
            @change="registerChange"
            />
            {{ $t('choice.to') }}
            <currency-input
            v-model="currentBrand.CondRegularLicencePercentage"
            style="width:120px"
            :disabled="currentBrand.CondRegularLicenceType!=7"
            :currency="currentBrand.CondCurrency"
            locale="de"
            class="percentage-input ms-15"
            @change="registerChange"
            />
        </label>
        </div>
    </div>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input'
import PercentageInput from '@/components/PercentageInput'

export default {
  name: 'Investments',
  components: {
    CurrencyInput,
    PercentageInput
  },
  props: ['name', 'value'],
  data: function () {
    return {
    }
  },
  computed: {
    app: function () {
      return this.$parent || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    }
  },
  methods: {
    registerChange: function () {
      this.$emit('change')
    },
    getCurrencyLabel (currencyId) {
      return currencyId
    }
  },
  mounted: function () {
  }
}
</script>

<style lang="scss" scoped>

</style>
