<template>
  <div>
    <div class="card">
      <div class="card-title" :class="computedBackgroundClass">
        <span>Verfügbar</span>
        <span>Match: {{ computedMatchScore }}%</span>
      </div>

      <div class="card-body">
        <div class="lead-details">
          <div
            class="person-details d-flex justify-content-start align-items-center mb-2"
          >
            <div class="me-3 lead-portrait">
              <img :src="computedPortrait" />
            </div>
            <div class="d-flex flex-column">
              <span class="mb-1 person-name">
                {{ computedName }}
              </span>
              <div class="contact-details d-flex">
                <span class="me-3" v-if="computedIsMailValidated">
                  <inline-svg
                    width="16"
                    height="16"
                    :src="require('@/assets/images/icons/3.0/verified.svg')"
                  />
                  Email
                </span>
                <span v-if="computedIsPhoneValidated">
                  <inline-svg
                    width="16"
                    height="16"
                    :src="require('@/assets/images/icons/3.0/verified.svg')"
                  />
                  Telefon
                </span>
              </div>
            </div>
          </div>

          <div class="location-details d-flex align-items-center">
            <span class="me-1">
              <inline-svg
                width="16"
                height="16"
                :src="require('@/assets/images/icons/3.0/map-marker.svg')"
              />
            </span>
            <span v-if="currentContact.LocationCity">
              {{ currentContact.LocationCity }}
              <span v-if="currentContact.LocationRadius"
                >+ {{ currentContact.LocationRadius }}km</span
              >
            </span>
          </div>

          <hr />

          <div class="business-details">
            <div class="mb-2">
              <strong>Kapital:</strong>
              <span v-if="computedHasEquitySet">
                {{ computedEquityFrom }} € - {{ computedEquityTo }} €
              </span>
              <span v-else> unbekannt </span>
            </div>
            <div class="mb-2">
              <strong>Gruppe:</strong>
              <span v-if="currentContact.ProfessionalPosition !== null">
                {{ currentContact.ProfessionalPosition }}
              </span>
              <span v-else> unbekannt </span>
            </div>
            <div class="mb-2">
              <strong>Führung:</strong>
              <span v-if="currentContact.IndependentProfession !== null">
                {{ currentContact.IndependentProfession }}
              </span>
              <span v-else> unbekannt </span>
            </div>
          </div>

        </div>
        <div class="lead-actions">
          <div class="d-flex justify-content-between">
            <a
              class="d-flex align-items-center"
              href="javascript:"
              @click="markContact"
              v-if="!computedIsMarked"
            >
              <inline-svg
                class="me-1"
                width="16"
                height="16"
                :src="require('@/assets/images/icons/3.0/star.svg')"
              />
              Merken
            </a>
            <a
              class="d-flex align-items-center"
              href="javascript:"
              @click="unmarkContact"
              v-else
            >
              <inline-svg
                class="me-1"
                width="16"
                height="16"
                :src="require('@/assets/images/icons/3.0/remove.svg')"
              />
              Nicht merken
            </a>

            <a
              class="d-flex align-items-center"
              href="javascript:"
              @click="openModal"
            >
              <inline-svg
                class="me-1"
                width="16"
                height="16"
                :src="require('@/assets/images/icons/3.0/profile-eye.svg')"
              />
              Profil
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="source-details">
      <span>Quelle: Talentpool</span>
    </div>

  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import moment from 'moment'
import { useChatStore } from '@/stores/chatStore'
import { useBrandStore } from '@/stores/brandStore'

export default {
  components: {
    InlineSvg
  },
  name: 'AnonymousContactCard',
  props: ['currentContact'],
  inject: ['GoogleMap'],
  data: function () {
    return {
      chatStore: useChatStore(),
      brandStore: useBrandStore(),
      aiFeatures: {
        isLoading: true,
        generatedDescription: undefined
      }
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    computedAiDescription: function () {
      if (this.aiFeatures.generatedDescription === undefined) {
        return ''
      }
      return this.aiFeatures.generatedDescription
    },
    computedLeadData: function () {
      if (!this.currentContact) return {}
      return {
        ContactID: this.currentContact.ContactID,
        Contact: this.currentContact,
        ContactProperties: this.currentContact.ContactProperties,
        Upgrade: null
      }
    },
    computedContactProperties: function () {
      return this.currentContact.ContactProperties || []
    },
    computedIsMailValidated: function () {
      return this.currentContact.EmailValidated
    },
    computedIsPhoneValidated: function () {
      return this.currentContact.PhoneValidated
    },
    computedMatchScore () {
      return this.currentContact.Score
    },
    computedCreatedDate () {
      return moment(this.currentContact.Created).format('DD.MM.YYYY')
    },
    computedPortrait () {
      return require('@/assets/images/talent.png')
    },
    computedSourceModule () {
      return 'Unbekannt'
    },
    computedName () {
      return `${this.currentContact.Firstname} ${this.currentContact.Lastname}`
    },
    computedModalId () {
      return `contact-modal-${this.currentContact.ContactID}`
    },
    computedAddress () {
      return ''
    },
    computedToastId () {
      return `toast-${this.currentContact.ContactID}`
    },
    computedHasEquitySet () {
      return (
        this.currentContact.EquityFrom !== null &&
        this.currentContact.EquityTo !== null
      )
    },
    computedEquityFrom () {
      return this.currentContact.EquityFrom.toLocaleString('de-DE')
    },
    computedEquityTo () {
      return this.currentContact.EquityTo.toLocaleString('de-DE')
    },
    computedIsMarked () {
      return this.brandStore.getMarkedContacts.includes(
        this.currentContact.ContactID
      )
    },
    computedBackgroundClass () {
      return {
        'is-marked': this.computedIsMarked
      }
    }
  },
  created: function () {},
  methods: {
    loadAiFeatures: async function () {
      try {
        this.aiFeatures.generatedDescription = null
        const result = await this.app.APIService._post('talentpool/leads/ai/detail', {
          contactId: this.currentContact.ContactID,
          brandId: this.brandStore.getCurrentBrandID
        })
        this.aiFeatures.generatedDescription = result.body.text
      } catch (error) {
        console.error(error)
      } finally {
        this.aiFeatures.isLoading = false
      }
    },
    openModal: function () {
      this.$bvModal.show(this.computedModalId)
      this.initMap(window.google)

      const newPath = `/talentpool/detail/${this.currentContact.ContactID}`
      if (this.$route.path.startsWith(newPath)) {
        return
      }

      this.$router.push(newPath)

      this.loadAiFeatures()
    },
    handleModalHide () {
      if (this.$route.path.startsWith('/talentpool/detail/')) {
        this.$router.push('/talentpool')
      }
    },
    getZoomForRadius (radius) {
      const equatorLength = 40075004 // in meters
      const widthInPixels = document.getElementById('googleMap').offsetWidth
      let metersPerPixel = equatorLength / 256
      let zoomLevel = 1
      while (metersPerPixel * widthInPixels > radius * 2) {
        metersPerPixel /= 2
        ++zoomLevel
      }
      return zoomLevel
    },
    initMap (google) {
      this.GoogleMap.geocoder = new google.maps.Geocoder()
      const searchAddress = `${this.currentContact.LocationCity}, ${this.currentContact.LocationState}`
      this.geocodeAddress(searchAddress)
    },
    geocodeAddress (address) {
      this.GoogleMap.geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          const latLng = results[0].geometry.location

          const center = { lat: latLng.lat(), lng: latLng.lng() } // These are just placeholder values

          this.GoogleMap.map = new window.google.maps.Map(
            document.getElementById('googleMap'),
            {
              zoom: 9,
              center
            }
          )

          if (this.currentContact.LocationRadius) {
            const calculatedRadius = this.currentContact.LocationRadius * 1000
            this.GoogleMap.circle = new window.google.maps.Circle({
              strokeColor: '#005578',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#005578',
              fillOpacity: 0.35,
              map: this.GoogleMap.map,
              center,
              radius: calculatedRadius // 10 km
            })

            if (calculatedRadius <= 20000) {
              this.GoogleMap.map.setZoom(10)
            }

            if (calculatedRadius >= 50000) {
              this.GoogleMap.map.setZoom(8)
            }

            if (calculatedRadius >= 75000) {
              this.GoogleMap.map.setZoom(7)
            }
            // this.googleMap.circle.setCenter(latLng)
            // this.googleMap.map.setZoom(this.getZoomForRadius(this.currentLead.Upgrade.Radius * 1000))
          }

          // this.googleMap.map.setCenter(latLng)
        } else {
          console.error(
            `Geocode was not successful for the following reason: ${status}`
          )
        }
      })
    },
    markContact () {
      this.brandStore.markContact(this.currentContact.ContactID)
    },
    unmarkContact () {
      this.brandStore.unmarkContact(this.currentContact.ContactID)
    }
  },
  mounted () {
    if (typeof this.$route.params.id !== 'undefined') {
      const contactId = parseInt(this.$route.params.id)
      if (contactId === this.currentContact.ContactID) {
        this.openModal()
      }
    }
  },
  watch: {
    $route (to, from) {
      if (typeof to.params.id !== 'undefined') {
        const contactId = parseInt(to.params.id)
        if (contactId === this.currentContact.ContactID) {
          this.openModal()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.card {
  width: 296px;
  font-size: 14px;
  font-family: DM Sans;

  .card-title {
    display: flex;
    padding: 4px 16px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    background: linear-gradient(90deg, #91C124 0%, #A4D82E 100%);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    &.is-marked {
      background: linear-gradient(90deg, #D97706 0%, #F59E0B 100%);
    }

    margin-bottom: 0;

    span {
      color: white;
      text-transform: none;

      font-style: normal;
      font-weight: 700;
      line-height: 20px;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .card-body {
    a {
      color: #00719f;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration: none;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 16px;
    height: 372px;

    .person-details {
      .lead-portrait {
        width: 44px;
        height: 44px;
        border-radius: 22px;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .person-name {
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }

    .contact-details {
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    hr {
      color: #d0d5dd;
      opacity: 1;

    }

    .business-details {
      > div {
        strong {
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }

        span {
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
}

.source-details {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #64748b;

  margin-top: 8px;

  padding: 0 16px;
}

.lead-modal {
  .modal-header {
    padding: 0;
  }

  #googleMap {
    width: 100%;
    height: 360px;
  }
}
</style>
