<template>
  <div class="mb-pie-chart">
      <div class="mb-pie-chart p-4 mb-4 card">
        <div v-if="isLoading" class="mb-loading"><span v-t="'page.statistics.loading'"></span></div>
        <div v-if="!isLoading && showNoDataHint" class="mb-no-chart-data"><span v-t="'page.statistics.nodata'"></span></div>
        <div class="row">
          <div class="col-4">
            <h6 v-t="'page.statistics.leadpies.title1'"></h6>
            <pie v-if="!isLoading && leadsConcretisationChartData.datasets != null && leadsConcretisationChartData.datasets.length > 0" :chart-data="leadsConcretisationChartData" :options="leadsConcretisationChartData.options"></pie>
          </div>
          <div class="col-4">
            <h6 v-t="'page.statistics.leadpies.title2'"></h6>
            <pie v-if="!isLoading && leadsProfessionChartData.datasets != null && leadsProfessionChartData.datasets.length > 0" :chart-data="leadsProfessionChartData" :options="leadsProfessionChartData.options"></pie>
          </div>
          <div class="col-4 mb-cv-cont">
            <h6 v-t="'page.statistics.leadpies.title3'"></h6>
            <div v-if="!showNoDataHint" class="mb-cv-legend mb-4" v-t="'page.statistics.leadpies.totalinperiod'"></div>
            <div v-if="!showNoDataHint" class="mb-leads-cv">{{leadsCVCount}}</div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Pie from './Pie.vue'
import moment from 'moment'

export default {
  components: {
    Pie
  },
  name: 'PieChart',
  props: ['startDate', 'endDate'],
  data: function () {
    return {
      isLoading: false,
      showNoDataHint: false,
      leadsConcretisationData: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      leadsConcretisationChartData: { labels: [], datasets: [] },
      leadsProfessionData: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
      leadsProfessionChartData: { labels: [], datasets: [] },
      leadsCVCount: 0
    }
  },
  computed: {
    currentBrand () {
      const current = this.app.currentBrand || {}
      return current
    },
    app: function () {
      return this.$root.getApp() || {}
    }
  },
  created: function () {
  },
  methods: {
    getChartData () {
      if (this.isLoading || this.currentBrand.BrandID == null) return
      this.isLoading = true
      const momentStarDate = moment(this.startDate, 'DD.MM.YYYY')
      const momentEndDate = moment(this.endDate, 'DD.MM.YYYY')
      const postData = {
        ResFrom: 1,
        ResTo: 1000,
        FullSubEntities: false,
        Filter: [
          {
            Field: 'Created',
            Operator: 'GE',
            Value: momentStarDate.format('YYYY-MM-DD HH:mm:ss')
          },
          {
            Field: 'Created',
            Operator: 'LT',
            Value: momentEndDate.format('YYYY-MM-DD HH:mm:ss')
          },
          {
            Field: 'BrandID',
            Operator: 'EQ',
            Value: this.currentBrand.BrandID
          },
          {
            Field: 'LeadUpgrade',
            Operator: 'EQ',
            Value: 1
          }
        ]
      }
      this.app.API._post('leads/', postData)
        .then(response => {
          this.leadsCVCount = 0
          this.leadsConcretisationData = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 }
          this.leadsConcretisationChartData = { labels: [], datasets: [] }
          this.leadsProfessionData = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 }
          this.leadsProfessionChartData = { labels: [], datasets: [] }
          if (response.body.Result.Data == null || response.body.Result.Data.length === 0) {
            this.showNoDataHint = true
            this.isLoading = false
          } else this.showNoDataHint = false
          response.body.Result.Data.forEach(lead => {
            const interest = lead.Upgrade.Concretisation
            if (interest != null) this.leadsConcretisationData[interest]++
            const profession = lead.Upgrade.Profession
            if (profession != null) this.leadsProfessionData[profession]++
            if (lead.Upgrade.CVID != null) this.leadsCVCount++
          })
          this.mapLeadsData()
        }).finally(() => {
          this.isLoading = false
        })
    },
    mapLeadsData () {
      const interestChartData = this.getDefaultPieChartData()
      const professionChartData = this.getDefaultPieChartData()
      let leadsConcretisationCount = 0
      for (const countkey1 in this.leadsConcretisationData) {
        leadsConcretisationCount += this.leadsConcretisationData[countkey1]
      }
      let leadsProfessionCount = 0
      for (const countkey2 in this.leadsProfessionData) {
        leadsProfessionCount += this.leadsProfessionData[countkey2]
      }
      for (const key in this.leadsConcretisationData) {
        if (this.leadsConcretisationData[key] === 0) continue
        interestChartData.labels.push(this.mapLeadConcretisation(key))
        interestChartData.datasets[0].data.push((this.leadsConcretisationData[key] / leadsConcretisationCount * 100).toFixed(0))
      }
      for (const key2 in this.leadsProfessionData) {
        if (this.leadsProfessionData[key2] === 0) continue
        professionChartData.labels.push(this.mapLeadProfession(key2))
        professionChartData.datasets[0].data.push((this.leadsProfessionData[key2] / leadsProfessionCount * 100).toFixed(0))
      }
      this.leadsConcretisationChartData = interestChartData
      this.leadsProfessionChartData = professionChartData
    },
    mapLeadConcretisation (key) {
      return this.$t('lead.concretisation.' + key)
    },
    mapLeadProfession (key) {
      return this.$t('lead.profession.' + key)
    },
    getDefaultPieChartData () {
      return {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              '#00441B',
              '#1B7837',
              '#5AAE61',
              '#A6DBA0',
              '#D9F0D3'
            ]
          }],
        options: {
          tooltips: {
            callbacks: {
              label: this.tooltipCallbackLabel
            }
          }
        }
      }
    },
    tooltipCallbackLabel (tooltipItem, data) {
      const label = data.labels[tooltipItem.index]
      const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
      return label + ': ' + value + '%'
    }
  },
  watch: {
    startDate: function () {
      this.getChartData()
    },
    endDate: function () {
      this.getChartData()
    },
    '$i18n.locale': function (newLocale, oldLocale) {
      this.mapLeadsData()
    }
  },
  mounted: function () {
    this.getChartData()
  }
}
</script>

<style lang="less" scoped>
.mb-pie-chart {
  min-height: 400px;
  position: relative;

  .col-4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.mb-no-chart-data, .mb-loading {
  background-color: rgba(255,255,255,0.5);
  position: absolute;
  display: grid;
  place-items: center;
  height: calc(100% - (1.5rem * 2));
  width: calc(100% - (1.5rem * 2));
  font-weight: 700;
  font-size: 20px;
  z-index: 1;
}
.mb-cv-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mb-leads-cv {
  width: 280px;
  height: 280px;
  border: 25px solid #58BC29;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 75px;
  font-weight: 800;
}
.mb-cv-legend {
  font-size: 14px;
  text-align: center;
}
</style>
