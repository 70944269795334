<template>
  <div class="mb-markeanlegen">
    <div
      v-if="$parent.shortlyRegisteredBrand"
      class="mb-markeanlegen-top">
      <i class="fa fa-check"></i><span v-html="$t('page.newbrand.basicdata.accountregistered', { brandname: currentBrand.Name })"></span>
    </div>

    <div class="mb-markeanlegen-top" v-if="isMaintenance">
      <h2 v-t="'page.login.maintenance'"></h2>
      <p v-html="$t('page.login.maintenancetext')"></p>
    </div>
    <div class="col-lg-9 col-7" v-if="currentBrand.BrandID != null">
      <div class="row">
        <div class="col-lg-9 mb-member-info" v-if="this.showMemberInfo()">
          <div>
            <img :src="require('../assets/images/icons/question.svg')">
          </div>
          <label>
            <span v-html="$t('page.newbrand.basicdata.info')"></span>
            <div class="text-right mt-3"><small><router-link to="/markenauswahl" class="font-weight-bold text-right"><i class="fa fa-close"></i> {{ $t('page.newbrand.basicdata.skip') }}</router-link></small></div>
          </label>
        </div>
        <div class="col-9 d-none d-lg-block mb-5" v-if="!this.showMemberInfo()">
          <div class="mb-progress">
            <h3 v-t="'page.membershipsuccess.howitcontinues'"></h3>
            <ul class="mb-info-lst">
              <li><i class="fa fa-check"></i><span v-t="'page.newbrand.createnewbrand'"></span></li>
              <li class="mb-progress-active"><i class="fa fa-long-arrow-right"></i><span v-t="'page.newbrand.fillimportantinformation'"></span></li>
              <li><i class="fa fa-clock-o"></i><span v-t="'page.newbrand.wecreateyourtext'"></span></li>
              <li><i class="fa fa-clock-o"></i><span v-t="'page.newbrand.bookmembership'"></span></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-9">
          <form v-if="!isMaintenance" @submit.prevent="checkAllAndContinue">
            <div class="form-container">
              <h3 v-if="!this.showMemberInfo()">{{ $t('page.newbrand.basicdata.title', { brandname: currentBrand.Name }) }}</h3>
              <h3 v-else>{{ $t('page.newbrand.basicdata.edittitle', { brandname: currentBrand.Name }) }}</h3>
              <div
                class="row"
                v-if="hiddenElements.ComParamOriginCountryID !== true || hiddenElements.ComParamIntRootCountryID !== true"
              >
                <div class="col-sm-6">
                  <div class="form-group mb-frm-grp-height">
                    <label for="brandCountry" class="mb-card-title" v-t="'page.newbrand.basicdata.country'"></label>
                    <select
                      key="CountryCodeSelect"
                      v-model="currentBrand.ComParamOriginCountryID"
                      class="form-control"
                      name="CountryCode"
                      id="brandCountry"
                      required
                      @change="registerChange"
                    >
                      <option
                        v-for="(country) in app.getCountryListStartWithDeAtCh()"
                        :key="country.CountryID"
                        :value="country.CountryID"
                      >{{ country.Caption }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="brandCountry" class="mb-card-title" v-t="'page.newbrand.basicdata.origincountry'"></label>
                    <select
                      key="CountryCodeSelect"
                      v-model="currentBrand.ComParamIntRootCountryID"
                      class="form-control"
                      name="CountryCode"
                      id="brandCountry"
                      required
                      @change="registerChange"
                    >
                      <option
                        v-for="(country) in app.getCountryListStartWithDeAtCh()"
                        :key="country.CountryID"
                        :value="country.CountryID"
                      >{{ country.Caption }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                class="row"
                v-if="hiddenElements.ComParamIntRootYear !== true || hiddenElements.ComParamIntRootFirstPartnerYear !== true"
              >
                <div class="col-sm-6 form-group">
                  <label for="ComParamIntRootYear" class="mb-card-title">
                    {{ $t('page.newbrand.basicdata.introotyear') }}
                    <a
                      id="tt_yearOfFounding"
                      href="javascript:"
                      class="badge badge-warning"
                    >?</a>
                  </label>
                  <b-tooltip
                    target="tt_yearOfFounding"
                    placement="top"
                  >
                    <p>{{ $t('page.newbrand.basicdata.introotyearhint', { brandname: currentBrand.Name }) }}</p>
                  </b-tooltip>
                  <input
                    v-model="currentBrand.ComParamIntRootYear"
                    type="number"
                    class="form-control"
                    placeholder="YYYY"
                    @change="registerChange"
                  >
                </div>
                <div class="col-sm-6 form-group">
                  <label for="ComParamIntRootFirstPartnerYear" class="mb-card-title">
                    {{ $t('page.newbrand.basicdata.firstpartneryear') }}
                    <a
                      id="tt_yearOfFounding"
                      href="javascript:"
                      class="badge badge-warning"
                    >?</a>
                  </label>
                  <b-tooltip
                    target="tt_yearOfFounding"
                    placement="top"
                  >
                    <p>{{ $t('page.newbrand.basicdata.introotyearhint', { brandname: currentBrand.Name }) }}</p>
                  </b-tooltip>
                  <input
                    v-model="currentBrand.ComParamIntRootFirstPartnerYear"
                    type="number"
                    class="form-control"
                    placeholder="YYYY"
                    @change="registerChange"
                  >
                </div>
              </div>
              <div
                class="row"
                v-if="hiddenElements.SpecialSectorID !== true"
              >
                <div class="col-12 form-group">
                  <label class="mb-card-title" v-t="'page.newbrand.basicdata.selectsector'"></label>
                  <multiselect
                      v-model="app.computedSpecialSector"
                      :options="app.getSpecialSectorDropdownData()"
                      track-by="SpecialSectorID"
                      group-values="sectors"
                      group-label="group"
                      label="Caption"
                      :multiple="false"
                      :placeholder="$t('component.typetosearch')"
                      :selectLabel="$t('multiselect.select')"
                      :selectedLabel="$t('multiselect.selected')"
                      :deselectLabel="$t('multiselect.remove')"
                      class="group-select"
                      @change="registerChange"
                  >
                      <template slot="singleLabel" slot-scope="props">{{ props.option.groupcaption }} > <strong>{{ props.option.Caption.split('|')[1] }}</strong></template>
                      <template slot="option" slot-scope="props">{{ props.option.Caption ? props.option.Caption.split('|')[1] : props.option.$groupLabel }}</template>
                  </multiselect>
                </div>
              </div>
              <div
                class="row"
                v-if="hiddenElements.ConceptServices !== true"
              >
                <div class="form-group col-12">
                  <label for="txtGeschModel" class="mb-card-title" v-t="'page.newbrand.basicdata.conceptservices'"></label>
                  <custom-editor
                    v-model="currentBrand.ConceptServices"
                    id="txtGeschModel"
                    :small="true"
                    :maxlength="4000"
                    @change="registerChange"
                    minHeight="350"
                    :showProgressBar="true"
                    :progressCharCountMin="400"
                    :progressCharCountOK="1000"
                    :toolbarStickyOffset="0"
                    :placeholderText="$t('page.newbrand.basicdata.conceptservicesplaceholder')"
                   />
                </div>
              </div>
              <div
                class="row"
                v-if="hiddenElements.ConceptUSP !== true"
              >
                <div class="form-group col-12">
                  <label for="txtUSP" class="mb-card-title" v-t="'page.newbrand.basicdata.conceptusp'"></label>
                  <custom-editor
                    v-model="currentBrand.ConceptUSP"
                    id="txtUSP"
                    :small="true"
                    :maxlength="4000"
                    @change="registerChange"
                    minHeight="350"
                    :showProgressBar="true"
                    :progressCharCountMin="180"
                    :progressCharCountOK="500"
                    :toolbarStickyOffset="0"
                    :placeholderText="$t('page.newbrand.basicdata.conceptuspplaceholder')"
                  />
                </div>
              </div>
              <div
                class="row"
                v-if="hiddenElements.CandidateRemark !== true"
              >
                <div class="form-group col-12">
                  <label for="txtPartner" class="mb-card-title" v-t="'page.newbrand.basicdata.candidateremark'"></label>
                  <custom-editor
                    v-model="currentBrand.CandidateRemark"
                    id="txtPartner"
                    :small="true"
                    :maxlength="1100"
                    @change="registerChange"
                    minHeight="350"
                    :showProgressBar="true"
                    :progressCharCountMin="180"
                    :progressCharCountOK="500"
                    :toolbarStickyOffset="0"
                    :placeholderText="$t('page.newbrand.basicdata.candidateremarkplaceholder')"
                  />
                </div>
              </div>
              <div
                class="row"
                v-if="hiddenElements.PartnerActivityShortDescription !== true"
              >
                <div class="form-group col-12">
                  <label for="txtTaetigkeit" class="mb-card-title" v-t="'page.newbrand.basicdata.partnerdescription'"></label>
                  <custom-editor
                    v-model="currentBrand.PartnerActivityShortDescription"
                    id="txtTaetigkeit"
                    :small="true"
                    :maxlength="1100"
                    @change="registerChange"
                    minHeight="350"
                    :showProgressBar="true"
                    :progressCharCountMin="180"
                    :progressCharCountOK="500"
                    :toolbarStickyOffset="0"
                    :placeholderText="$t('page.newbrand.basicdata.partnerdescriptionplaceholder')"
                  />
                </div>
              </div>
              <div class="row slim">
                <div
                  class="form-group col"
                  v-if="hiddenElements.PartnerTargetGroupIDs !== true"
                >
                  <label for="PartnerTargetGroupIDs" v-t="'page.yourdata.applicant.targetgroupofpartner'"></label>

                  <div>
                    <b-form-checkbox-group
                      id="PartnerTargetGroupIDs"
                      v-model="currentBrand.PartnerTargetGroupIDs"
                      class="stacked"
                      name="PartnerTargetGroupIDs"
                      :options="app.preloads.partnertargetgroups"
                      value-field="PartnerTargetGroupID"
                      text-field="Caption"
                      @change="registerChange"
                    />
                  </div>
                </div>
                <div
                  class="form-group col"
                  v-if="hiddenElements.PartnerFullTimeJob !== true"
                >
                  <label for="fld_Title" v-t="'page.yourdata.applicant.fulltimeparttime'"></label>

                  <div>
                    <b-form-checkbox
                      v-model="currentBrand.PartnerFullTimeJob"
                      value="1"
                      unchecked-value="0"
                      class="stacked"
                      @change="registerChange"
                    >
                      {{ $t('choice.fulltimepossible') }}
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="currentBrand.PartnerPartTimeJob"
                      value="1"
                      unchecked-value="0"
                      class="stacked"
                      @change="registerChange"
                    >
                      {{ $t('choice.parttimepossible') }}
                    </b-form-checkbox>
                  </div>
                </div>
            </div>
            <div class="row slim">
                <div
                  class="form-group col"
                  v-if="hiddenElements.PartnerLateralEntrance !== true"
                >
                <label for="PartnerLateralEntrance" v-t="'page.yourdata.applicant.startascareerchanger'"></label>

                <b-form-radio-group
                  id="PartnerLateralEntrance"
                  v-model="currentBrand.PartnerLateralEntrance"
                  name="PartnerLateralEntrance"
                  @change="registerChange"
                  class="radios-stacked"
                  stacked
                >
                  <b-form-radio value="1">
                    {{ $t('page.yourdata.applicant.startascareerchangeryes') }}
                  </b-form-radio>
                  <b-form-radio value="0">
                    {{ $t('page.yourdata.applicant.startascareerchangerno') }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
                <div
                  class="form-group col"
                  v-if="hiddenElements.PartnerHomeOffice !== true"
                >
                <label for="PartnerHomeOffice" v-t="'page.yourdata.applicant.startinhomeoffice'"></label>
                <b-form-radio-group
                  id="PartnerHomeOffice"
                  v-model="currentBrand.PartnerHomeOffice"
                  name="PartnerHomeOffice"
                  @change="registerChange"
                  class="radios-stacked"
                  stacked
                >
                  <b-form-radio value="1">
                    {{ $t('page.yourdata.applicant.startinhomeofficeyes') }}
                  </b-form-radio>
                  <b-form-radio value="0">
                    {{ $t('page.yourdata.applicant.startinhomeofficeno') }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
            <div class="row slim">
                <div
                  class="form-group col"
                  v-if="hiddenElements.PartnerSmallBusiness !== true"
                >
                <label for="PartnerSmallBusiness" v-t="'page.yourdata.applicant.startassmallbusiness'"></label>
                <b-form-radio-group
                  id="PartnerSmallBusiness"
                  v-model="currentBrand.PartnerSmallBusiness"
                  name="PartnerSmallBusiness"
                  @change="registerChange"
                  class="radio-stacked"
                  stacked
                >
                  <b-form-radio value="1">
                    {{ $t('page.yourdata.applicant.startassmallbusinessyes') }}
                  </b-form-radio>
                  <b-form-radio value="0">
                    {{ $t('page.yourdata.applicant.startassmallbusinessno') }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
                <div
                  class="form-group col"
                  v-if="hiddenElements.PartnerMultipleLocations !== true"
                >
                <label for="PartnerMultipleLocations" v-t="'page.yourdata.applicant.openingofseveralbusiness'"></label>
                <b-form-radio-group
                  id="PartnerMultipleLocations"
                  v-model="currentBrand.PartnerMultipleLocations"
                  name="PartnerMultipleLocations"
                  @change="registerChange"
                  class="radios-stacked"
                  stacked
                >
                  <b-form-radio value="1">
                    {{ $t('page.yourdata.applicant.openingofseveralbusinessyes') }}
                  </b-form-radio>
                  <b-form-radio value="0">
                    {{ $t('page.yourdata.applicant.openingofseveralbusinessno') }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
            <div class="row" v-if="hiddenElements.ProvidedSupportIDs !== true">
                  <div class="form-group col">
                      <label for="ProvidedSupportIDs">{{ $t('page.newbrand.basicdata.providedsupport') }}
                      <a
                        id="tt_ProvidedSupportIDs"
                        href="javascript:"
                        class="badge badge-warning"
                      >?</a>
                    </label>
                    <b-tooltip
                      target="tt_ProvidedSupportIDs"
                      placement="top"
                    >
                    <div class="divider"><strong v-t="'page.newbrand.basicdata.typeofsupport'"></strong></div>
                    <p v-t="'page.newbrand.basicdata.typeofsupporthint'"></p>
                  </b-tooltip>

                      <div>
                          <multiselect-list
                            v-model="currentBrand.ProvidedSupportIDs"
                            :options="app.preloads.providedsupports"
                            label="Caption"
                            identifier="ProvidedSupportID"
                            @change="registerChange"
                            ref="ProvidedSupportIDs"
                            show-first-item-separately="true"
                          />
                      </div>
                  </div>
              </div>
              <div class="row" v-if="hiddenElements.ProvidedFinancialAidIDs !== true">
                  <div class="form-group col">
                      <label for="ProvidedFinancialAidIDs">{{ $t('page.newbrand.basicdata.providedfinancialaids') }}
                      <a
                        id="tt_ProvidedFinancialAidIDs"
                        href="javascript:"
                        class="badge badge-warning"
                      >?</a>
                    </label>
                    <b-tooltip
                      target="tt_ProvidedFinancialAidIDs"
                      placement="top"
                    >
                    <div class="divider"><strong v-t="'page.newbrand.basicdata.financialaids'"></strong></div>
                    <p v-t="'page.newbrand.basicdata.financialaidshint'"></p>
                  </b-tooltip>

                      <div>
                          <multiselect-list
                            v-model="currentBrand.ProvidedFinancialAidIDs"
                            :options="app.preloads.providedfinancialaids"
                            label="Caption" identifier="ProvidedFinancialAidID"
                            show-first-item-separately="true"
                            @change="registerChange"
                          />
                      </div>
                  </div>
              </div>
              <div class="row" v-if="hiddenElements.ProvidedTrainingIDs !== true">
                  <div class="form-group col">
                      <label for="ProvidedTrainingIDs">{{ $t('page.newbrand.basicdata.providedtrainings') }}
                      <a
                        id="tt_ProvidedTrainingIDs"
                        href="javascript:"
                        class="badge badge-warning"
                      >?</a>
                    </label>
                    <b-tooltip
                      target="tt_ProvidedTrainingIDs"
                      placement="top"
                    >
                    <div class="divider"><strong v-t="'page.newbrand.basicdata.trainings'"></strong></div>
                    <p v-t="'page.newbrand.basicdata.trainingshint'"></p>
                  </b-tooltip>

                      <div>
                          <multiselect-list
                            v-model="currentBrand.ProvidedTrainingIDs"
                            :options="app.preloads.providedtrainings"
                            label="Caption"
                            identifier="ProvidedTrainingID"
                            show-first-item-separately="true"
                            @change="registerChange"
                          />
                      </div>
                  </div>
              </div>
              <div class="row" v-if="hiddenElements.ProvidedManagementAssistanceIDs !== true">
                  <div class="form-group col">
                      <label for="ProvidedManagementAssistanceIDs">{{ $t('page.newbrand.basicdata.providedmanagementassistance') }}
                      <a
                        id="tt_ProvidedManagementAssistanceIDs"
                        href="javascript:"
                        class="badge badge-warning"
                      >?</a>
                    </label>
                    <b-tooltip
                      target="tt_ProvidedManagementAssistanceIDs"
                      placement="top"
                    >
                    <p><strong v-t="'page.newbrand.basicdata.managementassistancehint'"></strong></p>
                  </b-tooltip>

                      <div>
                          <multiselect-list
                            v-model="currentBrand.ProvidedManagementAssistanceIDs"
                            :options="app.preloads.providedmanagementassistances"
                            label="Caption"
                            identifier="ProvidedManagementAssistanceID"
                            show-first-item-separately="true"
                            @change="registerChange"
                          />
                      </div>
                  </div>
              </div>
              <system-type v-if="hiddenElements.DetailedSystemHandbook !== true" @detailedmanualchange="registerChange" @systemtypechange="registerChange"></system-type>
              <div id="investmentCosts">
                <div class="row" v-if="hiddenElements.CondCurrency !== true">
                  <div class="form-group col-12">
                  <label class="mb-card-title">
                    {{ $t('page.newbrand.basicdata.currency') }}
                    <a
                      id="tt_currency"
                      href="javascript:"
                      class="badge badge-warning"
                    >?</a>
                  </label>

                  <b-tooltip
                    target="tt_currency"
                    placement="top"
                  >
                    <p v-html="$t('page.newbrand.basicdata.currencyhint')"></p>
                  </b-tooltip>
                  <div>
                    <select
                      v-model="currentBrand.CondCurrency"
                      class="form-control"
                      @change="registerChange"
                    >
                      <option value="CHF" v-t="'currency.chf'"></option>
                      <option value="EUR" v-t="'currency.eur'"></option>
                    </select>
                  </div>
                </div>
              </div>
              <investment v-if="hiddenElements.CondInvestSumType !== true ||
                                hiddenElements.CondRequiredCapitalType !== true ||
                                hiddenElements.CondEntryFeeType !== true ||
                                hiddenElements.CondInitialCapitalType !== true"
                                @change="registerChange"
              ></investment>
              <licence-fee v-if="hiddenElements.CondRegularLicenceType !== true" @change="registerChange"></licence-fee>
              <div
                class="row slim"
                v-if="hiddenElements.CondLicenceRemark !== true"
              >
                <div class="form-group col">
                  <label for="fld_CondLicenseRemark">
                    {{ $t('page.newbrand.basicdata.remarklicencefee') }}<span v-if="this.currentBrand.CondRegularLicenceType === -1">*</span>&nbsp;
                    <a
                      id="tt_licensefeeinfo"
                      href="javascript:"
                      class="badge badge-warning"
                    >?</a>
                  </label>
                  <b-tooltip
                    target="tt_licensefeeinfo"
                    placement="top"
                  >
                    <p v-html="$t('page.newbrand.basicdata.remarklicencefeehint')"></p>
                  </b-tooltip>
                  <div>
                    <custom-editor
                      v-model="currentBrand.CondLicenceRemark"
                      maxlength="500"
                      :small="true"
                      @change="registerChange"
                    />
                  </div>
                </div>
              </div>
              <div
                class="row"
                v-if="hiddenElements.Homepage !== true"
              >
                <div class="col-12 form-group">
                  <label class="mb-card-title" v-t="'page.newbrand.basicdata.website'"></label>
                  <input
                    v-model="currentBrand.Homepage"
                    type="url"
                    name="website"
                    class="form-control"
                    required
                    @change="registerChange"
                  >
                </div>
              </div>
              <div class="row">
                <div
                  class="col-sm-5"
                  v-if="hiddenElements.LogoID !== true"
                >
                  <label class="mb-card-title" v-t="'page.newbrand.basicdata.brandlogo'"></label>
                  <image-connected-selector
                    v-model="currentBrand.LogoID"
                    class="mb-edit-img"
                    :default="require(`@/assets/images/brand-logo-edit.jpg`)"
                    :edit-caption="$t('page.newbrand.basicdata.uploadbrandlogo')"
                    :ratio="29/20"
                    :resolution-hint="'480 x 332 ' + $t('image.withouttransparency')"
                    img-type="logo"
                    @change="registerChange"
                  />
                </div>
                <div
                  class="col-sm-7"
                  v-if="hiddenElements.MainPictureID !== true"
                >
                  <label class="mb-card-title" v-t="'page.newbrand.basicdata.mainpicture'"></label>
                  <image-connected-selector
                    ref="mainImageSelector"
                    v-model="messestand.MainPictureID"
                    class="mb-edit-img"
                    :ratio="509/218"
                    :resolution-hint="$t('image.recomainpicture')"
                    img-type="mainpicture"
                    @change="saveMessestand"
                  />
                </div>
              </div>
              <small v-t="'page.newbrand.mandantoryfields'"></small>
            </div>
            <div class="mb-footer-bar">
              <div class="mb-wrap container">
                <div class="mb-save-state"><i ref="saveBrandState" data-style="zoom-in" class="fa fa-check-circle" :class="saveStateClass"></i>{{saveStateText}}</div>
                <div class="mb-state-counter" v-html="$t('page.newbrand.basicdata.onlyanswers', { count: app.pflichtfeldCounter })"></div>
                <button
                  type="submit"
                  class="btn btn-primary"
                  data-style="zoom-in"
                  :disabled="app.pflichtfeldCounter > 0"
                  @click="checkAllAndContinue"
                  v-t="'page.newbrand.basicdata.submitbtn'"
                ></button>
              </div>
            </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Ladda from 'ladda'
import Config from '../config'
import CustomEditor from '@/components/CustomEditor'
import ImageConnectedSelector from '@/components/ImageConnectedSelector'
import Multiselect from 'vue-multiselect'
import MultiselectList from '../components/MultiselectList.vue'
import SystemType from '../components/mydata/SystemType'
import Investment from '../components/mydata/Investment.vue'
import LicenceFee from '../components/mydata/LicenceFee.vue'

export default {
  name: 'MarkeAnlegenDetails',
  components: {
    CustomEditor,
    ImageConnectedSelector,
    Multiselect,
    MultiselectList,
    SystemType,
    Investment,
    LicenceFee
  },
  props: [],
  data: function () {
    return {
      userType: 0,
      userCompany: {},
      brandCompany: {},
      contactCompany: {},
      loader: null,
      formDataErrors: {},
      isMaintenance: false,
      errorClass: 'mb-field-error',
      saveInterval: null,
      hiddenElements: {},
      showAllFields: false
    }
  },
  computed: {
    currentAppVersion: function () {
      return Config.CLIENT_VERSION
    },
    isLoggedIn: function () {
      return this.app.isLoggedIn
    },
    app: function () {
      return this.$parent || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    },
    saveStateText: function () {
      if (!this.app.saveBrandInProgress) {
        return this.$t('page.newbrand.basicdata.saved')
      } else {
        return this.$t('page.newbrand.basicdata.saving')
      }
    },
    saveStateClass: function () {
      if (!this.app.saveBrandInProgress) {
        this.stopSaveStateLoader()
        return ''
      } else {
        this.startSaveStateLoader()
        return 'mb-loading'
      }
    }
  },
  watch: {
    'currentBrand.ProvidedSupportIDs': function (newVal, oldVal) {
      if (newVal != null && newVal[newVal.length - 1] === -1) {
        if (newVal[newVal.length - 1] !== oldVal[newVal.length - 1]) document.activeElement.blur()
      }
    },
    'currentBrand.ProvidedFinancialAidIDs': function (newVal, oldVal) {
      if (newVal != null && newVal[newVal.length - 1] === -1) {
        if (newVal[newVal.length - 1] !== oldVal[newVal.length - 1]) document.activeElement.blur()
      }
    },
    'currentBrand.ProvidedTrainingIDs': function (newVal, oldVal) {
      if (newVal != null && newVal[newVal.length - 1] === -1) {
        if (newVal[newVal.length - 1] !== oldVal[newVal.length - 1]) document.activeElement.blur()
      }
    },
    'currentBrand.ProvidedManagementAssistanceIDs': function (newVal, oldVal) {
      if (newVal != null && newVal[newVal.length - 1] === -1) {
        if (newVal[newVal.length - 1] !== oldVal[newVal.length - 1]) document.activeElement.blur()
      }
    },
    'currentBrand.BrandID': function () {
      this.afterBrandIsLoaded()
    },
    'app.login' () {
      this.setDefaultValues()
    },
    $route (to) {
      if (to.path.startsWith('/markeanlegendetails')) {
        this.setDefaultValues()
      } else {
        this.resetValues()
      }
    },
    '$refs.saveBrandState': function () {
      if (this.$refs.saveBrandState != null) {
        this.$refs.saveBrandState.loader = Ladda.create(this.$refs.saveBrandState)
      }
    }
  },
  mounted: function () {
    this.setDefaultValues()
    if (this.app.shortlyRegisteredBrand) {
      this.$toast(this.$t('page.newbrand.basicdata.accountregistered', { brandname: this.currentBrand.Name }),
        {
          position: 'top-center',
          showCloseButtonOnHover: false,
          timeout: 5000,
          icon: true
        })
    }
  },
  methods: {
    setDefaultValues () {
      this.app.showSaveSuccessPopup = false
      this.app.showSaveBar = false
      this.app.showPageLoadingOnSave = false
      this.app.loadBrandDataAfterSaveBrand = false
      this.app.rewriteDefaultValuesOnLoadBrand = false
      this.app.navIsOpen = false
      this.app.showUnloadPrompt = false
      this.saveInterval = setInterval(function () {
        if (this.$route.path.startsWith('/markeanlegendetails')) {
          this.app.saveChanges(false)
        }
      }.bind(this), 5000)
      this.loadBrandData()
    },
    loadBrandData () {
      const param = this.$route.params.all
      const hash = window.location.hash.replace('#', '')
      this.showAllFields = (param === 'all')
      if (this.app.currentBrand == null) {
        const brandID = hash
        if (this.app.availableBrands == null || this.app.availableBrands.length === 0) {
          this.app.loadAvailableBrands()
            .then(() => {
              this.loadBrandDataFromAvailableBrands(brandID)
            })
        } else {
          this.loadBrandDataFromAvailableBrands(brandID)
        }
      } else {
        this.afterBrandIsLoaded()
      }
      this.afterBrandIsLoaded()
    },
    loadBrandDataFromAvailableBrands (brandID) {
      if (
        this.currentBrand != null && this.currentBrand.Brandid !== parseInt(brandID) &&
        this.app.availableBrands != null && this.app.availableBrands.length > 0
      ) {
        this.app.availableBrands.forEach(brand => {
          if (brand.BrandID === parseInt(brandID)) {
            this.app.loadBrand(brandID)
              .then(() => {
                this.afterBrandIsLoaded()
              })
          }
        })
      }
      this.setDefaultCurrency()
    },
    afterBrandIsLoaded () {
      this.setDefaultCurrency()
      this.setDefaultCondType()
      this.hiddenElements = this.app.checkPflichtfeldStatus(!this.showAllFields && this.hasValidPflichtfeldstatus(), false, true).hiddenElements
      this.app.checkPflichtfeldStatus()
    },
    setDefaultCurrency () {
      if (this.currentBrand.CondCurrency == null) this.currentBrand.CondCurrency = 'EUR'
    },
    setDefaultCondType () {
      if (this.currentBrand.CondInvestSumType == null || this.currentBrand.CondInvestSumType === 0) this.currentBrand.CondInvestSumType = 3
      if (this.currentBrand.CondRequiredCapitalType == null || this.currentBrand.CondRequiredCapitalType === 0) this.currentBrand.CondRequiredCapitalType = 3
      if (this.currentBrand.CondEntryFeeType == null || this.currentBrand.CondEntryFeeType === 0) this.currentBrand.CondEntryFeeType = 3
      if (this.currentBrand.CondInitialCapitalType == null || this.currentBrand.CondInitialCapitalType === 0) this.currentBrand.CondInitialCapitalType = 3
    },
    startSaveStateLoader () {
      if (
        typeof this.$refs.saveBrandState !== 'undefined' &&
        typeof this.$refs.saveBrandState.loader !== 'undefined'
      ) {
        this.$refs.saveBrandState.loader.start()
      }
    },
    stopSaveStateLoader () {
      if (
        typeof this.$refs.saveBrandState !== 'undefined' &&
        typeof this.$refs.saveBrandState.loader !== 'undefined'
      ) {
        this.$refs.saveBrandState.loader.stop()
      }
    },
    registerChange () {
      if (!this.$route.path.startsWith('/markeanlegendetails') || this.currentBrand.BrandID == null) return
      this.app.registerChangedContent(this.app.saveBrand)
      this.app.checkPflichtfeldStatus()
    },
    saveMessestand () {
      this.startSaveStateLoader()
      this.app.checkPflichtfeldStatus()
      const dataToSave = {
        FairID: this.messestand.FairID,
        MainPictureID: this.messestand.MainPictureID
      }

      return new Promise((resolve, reject) => {
        this.app.API._put('fair/', dataToSave)
          .then(() => {
            this.stopSaveStateLoader()
            resolve()
          })
          .finally(this.stopSaveStateLoader)
      })
    },
    startLoader () {
      this.loader = this.app.startLoader()
    },
    stopLoader () {
      this.app.stopLoader(this.loader)
    },
    checkAllAndContinue () {
      if (this.app.checkPflichtfeldStatus().counter === 0) {
        clearInterval(this.saveInterval)
        const isNoMember = !this.currentBrand.Membership !== 3
        const hasValidPflichtfeldStatus = this.hasValidPflichtfeldstatus()
        this.app.loadBrandDataAfterSaveBrand = true
        this.app.rewriteDefaultValuesOnLoadBrand = true
        this.app.showSaveSuccessPopup = false
        const origPflichtfeldStatus = this.currentBrand.PflichfeldStatusID
        if (!hasValidPflichtfeldStatus) {
          if (isNoMember) {
            this.currentBrand.PflichfeldStatusID = 2 // In Prüfung
          } else {
            this.currentBrand.PflichfeldStatusID = 1 // Vollständig
          }
        }
        this.startLoader()
        Promise.all([
          this.app.saveBrand(),
          this.saveMessestand()
        ])
          .then(() => {
            if (!hasValidPflichtfeldStatus) {
              const loginFromStorage = localStorage.getItem('FPMB_Login')
              const login = loginFromStorage ? JSON.parse(loginFromStorage) : {}
              const username = login.user.Name
              const postData = {
                BrandID: this.currentBrand.BrandID,
                Username: username
              }

              this.app.APIStripe._post(
                'completeBrandRegistration',
                postData
              ).then(() => {
                this.stopLoader()
                this.$router.push('/markeanlegensuccess')
              })
                .catch((error) => {
                  this.stopLoader()
                  console.error(error)
                  this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.saveerrortryagain'), 'error')
                  this.currentBrand.PflichfeldStatusID = origPflichtfeldStatus
                  this.app.saveBrand()
                })
            } else {
              this.stopLoader()
              this.$router.push('/dashboard')
            }
          })
          .catch((error) => {
            this.stopLoader()
            console.error(error)
            this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.saveerrortryagain'), 'error')
            this.currentBrand.PflichfeldStatusID = origPflichtfeldStatus
            this.app.saveBrand()
          })
      }
      return event.preventDefault()
    },
    getCurrencyLabel (currencyId) {
      return currencyId
    },
    showLoginError () {
      this.password = ''
      this.stopLoader()
    },
    showMemberInfo () {
      return this.currentBrand.Membership != null && (this.currentBrand.PflichfeldStatusID === 1 || this.currentBrand.PflichfeldStatusID === 2)
    },
    hasValidPflichtfeldstatus () {
      return this.currentBrand.PflichfeldStatusID != null && this.currentBrand.PflichfeldStatusID >= 1
    },
    resetValues () {
      this.app.showSaveSuccessPopup = true
      this.app.showSaveBar = true
      this.app.showPageLoadingOnSave = true
      this.app.loadBrandDataAfterSaveBrand = true
      this.app.rewriteDefaultValuesOnLoadBrand = true
      this.app.shortlyRegistered = false
      this.app.shortlyRegisteredBrand = false
      this.app.showUnloadPrompt = true
      this.hiddenElements = {}
      clearInterval(this.saveInterval)
    }
  }
}
</script>

<style lang="scss" scoped>
.mb-markeanlegen {
  margin-left: 80px;
  > form {
    > .form-group {
      margin-bottom: 30px;
    }

    .btn {
      width: 100%;
      margin-bottom: 10px;
    }

    a {
      color: white;
      text-align: center;
    }
  }
}
.clientVersionHint {
  font-size: 10px;
  color: #fff;
}

.mb-progress {
  margin-right: 0px;
}

.mb-progress h3 {
  padding-top: 10px;
  margin-bottom: 30px;
}
ul.mb-info-lst {
  li {
    margin-bottom: 20px;
  }
}
</style>
