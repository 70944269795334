<template>
  <div id="detailedContractAvailable">
    <div class="row mb-3">
        <div class="form-group col">
            <label for="ContractAvailable">{{ $t('page.yourdata.applicant.systemmanualavailable') }}</label>
            <b-form-radio-group
            id="ContractAvailable"
            v-model="currentBrand.DetailedSystemHandbook"
            name="ContractAvailable"
            @change="detailedManualChange"
            class="radio-stacked"
            stacked
            >
            <b-form-radio :value="1">
                {{ $t('choice.yes') }}
            </b-form-radio>
            <b-form-radio :value="0">
                {{ $t('choice.no') }}
            </b-form-radio>
            </b-form-radio-group>
        </div>
    </div>
    <div class="row mb-3" v-if="hideSystemType == null">
        <div class="form-group col">
            <label for="SystemType">
            {{ $t('page.newbrand.systemtype') }}
            </label>
            <b-form-radio-group
              v-if="showRadioGroup"
              id="SystemType"
              v-model="systemType"
              :options="systemTypeOptions"
              name="SystemType"
              @change="systemTypeChange"
              class="radio-stacked"
              ref="systemType"
              stacked
            >
            </b-form-radio-group>
            <b-check-group
              v-if="!showRadioGroup"
              id="SystemType"
              v-model="systemTypes"
              :options="systemTypeOptions"
              name="SystemType"
              class="radio-stacked"
              ref="systemType"
              stacked
            >
            </b-check-group>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['name', 'value', 'hideSystemType'],
  data: function () {
    return {
      systemType: 0,
      systemTypes: [],
      systemTypeOptions: [],
      showRadioGroup: true
    }
  },
  computed: {
    app: function () {
      return this.$parent || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    }
  },
  methods: {
    systemTypeChange () {
      this.currentBrand.ConceptExpansionFormFranchise = (this.systemType === 1 ? 1 : 0)
      this.currentBrand.ConceptExpansionFormLicense = (this.systemType === 2 ? 1 : 0)
      this.$emit('systemtypechange')
    },
    detailedManualChange () {
      if (this.currentBrand.DetailedSystemHandbook === 1) {
        this.systemTypeOptions[0].disabled = false
        this.systemTypeOptions[0].text = this.$t('systemform.franchise')
      } else {
        this.systemType = 2
        this.systemTypeOptions[0].disabled = true
        this.systemTypeOptions[0].text = this.$t('systemform.franchise') + ' (' + this.$t('page.yourdata.applicant.systemtypechangewarning') + ')'
        if (this.hideSystemType == null && this.currentBrand.ConceptExpansionFormFranchise === 1) this.showSystemTypeChangeWarning()
        if (this.hideSystemType == null) this.systemTypeChange()
      }
      this.$emit('detailedmanualchange')
    },
    showSystemTypeChangeWarning () {
      if (this.hideSystemType == null && this.currentBrand.ConceptExpansionFormFranchise === 1) {
        this.$toast.error(this.$t('page.yourdata.applicant.systemtypechangewarning'),
          {
            position: 'top-center',
            showCloseButtonOnHover: false,
            timeout: 10000,
            icon: true
          })
      }
    }
  },
  mounted: function () {
    this.systemTypeOptions = [
      { text: this.$t('systemform.franchise'), value: 1, disabled: false },
      { text: this.$t('systemform.license'), value: 2, disabled: false }
    ]
    this.systemType = this.currentBrand.ConceptExpansionFormFranchise === 1 ? this.systemType = 1 : 2
    if (this.currentBrand.DetailedSystemHandbook === 1) {
      this.systemTypeOptions[0].disabled = false
    } else {
      if (this.systemType === 2) {
        this.systemTypeOptions[0].disabled = true
        this.systemTypeOptions[0].text = this.$t('systemform.franchise') + ' (' + this.$t('page.yourdata.applicant.systemtypechangewarning') + ')'
      }
    }
    if (this.currentBrand.ConceptExpansionFormFranchise === 1) this.systemTypes.push(1)
    if (this.currentBrand.ConceptExpansionFormLicense === 1) this.systemTypes.push(2)
    this.showRadioGroup = !(this.currentBrand.ConceptExpansionFormFranchise === 1 && this.currentBrand.ConceptExpansionFormLicense === 1)
  },
  watch: {
    '$i18n.locale' () {
      this.systemTypeOptions[0].text = this.$t('systemform.franchise')
      this.systemTypeOptions[1].text = this.$t('systemform.license')
    },
    systemType (newValue, oldValue) {
      if (newValue === oldValue || oldValue === 0) return
      this.currentBrand.ConceptExpansionFormFranchise = newValue === 1 ? 1 : 0
      this.currentBrand.ConceptExpansionFormLicense = newValue === 2 ? 1 : 0
      this.$emit('systemtypechange')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
