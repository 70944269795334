<template>
  <img v-if="previewImage" :src="computedImageUrl" class="img-fluid" />
</template>

<script>
import { useBrandStore } from '@/stores/brandStore'
import Config from '@/config'

export default {
  name: 'VideoPreviewImageContainer',
  props: ['video'],
  data: function () {
    return {
      previewImage: null
    }
  },
  computed: {
    computedImageUrl: function () {
      return `${Config.MEDIA_API_URL}id/${this.previewImage.FileID}`
    }
  },
  watch: {
    video: {
      handler: async function (newValue, oldValue) {
        if (typeof newValue === 'undefined') return
        try {
          if (typeof this.video === 'undefined') return
          if (typeof this.video.StillImageID === 'undefined') return
          if (this.video.StillImageID === null) return
          if (this.previewImage && this.video.StillImageID === this.previewImage.MediumID) return

          this.previewImage = null

          const brandStore = useBrandStore()
          const previewImageResponse = await brandStore.fpApi._post('Medium', {
            MediumID: this.video.StillImageID
          })
          if (previewImageResponse) {
            this.previewImage = previewImageResponse.body.Result.Data
          }
        } catch (error) {
          console.error('Error at setting preview image', error)
        }
      },
      immediate: true
    }
  }
}

</script>
