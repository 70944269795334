<!-- component template -->
<template>
    <div>
        <label class="mb-2">{{label}} <span class="badge badge-primary" v-if="saving">Änderungen werden gespeichert...</span></label>
        <custom-multiselect v-model="currentCountryIDs" :options="options" label="Caption" identifier="CountryID" :multiple="true" @input="onChange" />
    </div>
</template>

<script>
// import Utils plugin
import CustomMultiselect from '../components/CustomMultiselect'

// register the grid component
export default {
  name: 'BrandLicenseSelector',
  components: {
    CustomMultiselect
  },
  props: ['countries', 'type', 'options', 'label', 'BrandID'],
  data: function () {
    return {
      currentLicenses: [],
      currentCountryIDs: [],
      currentCountryIDsStore: [],
      saving: false
    }
  },
  methods: {
    startSaving () {
      this.saving = true
    },
    stopSaving () {
      this.saving = false
    },
    onChange (event) {
      if (!this.saving) this.startSaving()
      else return

      const oldData = this.currentCountryIDsStore.slice(0)
      const newData = this.currentCountryIDs.slice(0)
      let changedData = []

      if (oldData.length < newData.length) {
        // item has been added
        changedData = newData.filter((value) => {
          return oldData.indexOf(value) === -1
        }).shift()
        this.currentCountryIDsStore = this.currentCountryIDs.slice(0)

        const newCountry = {
          BrandCountryLicID: -1,
          BrandID: this.BrandID,
          CountryID: changedData,
          LicAvail: this.type === 'LicAvail' ? 1 : 0,
          FutureLic: 0,
          FutureMasterLic: this.type === 'FutureMasterLic' ? 1 : 0
        }

        this.$root.getApp().API._put('brandcountrylic', newCountry).then(response => {
          if (
            response.status === 200 &&
                        response.body.Result.Result === 'Succeeded'
          ) {
            newCountry.BrandCountryLicID = response.body.Result.NewID
            this.currentLicenses.push(newCountry)
            this.stopSaving()
          }
        })
      } else {
        // item has been removed
        changedData = oldData.filter((value) => {
          return newData.indexOf(value) === -1
        }).shift()
        this.currentCountryIDsStore = this.currentCountryIDs.slice(0)

        const removeCountryId = parseInt(changedData)

        const removedItemId = this.currentLicenses.filter((license) => {
          return license.CountryID === removeCountryId
        }).shift().BrandCountryLicID

        const deleteString = String(`brandcountrylic/${removedItemId}`)

        this.$root.getApp().API._delete(deleteString).then(response => {
          if (
            response.status === 200 &&
                        response.body.Result.Result === 'Succeeded'
          ) {
            this.currentLicenses.forEach((elem, index) => {
              if (elem.BrandCountryLicID === removedItemId) delete this.currentLicenses[index]
            })
            this.currentLicenses = this.currentLicenses.filter(() => { return true })
            this.stopSaving()
          }
        })
      }
      this.stopSaving()
      this.$emit('change')
    },
    init () {
      this.currentLicenses = this.countries ? this.countries.slice(0) : []
      this.currentCountryIDs = this.currentLicenses.filter((license) => {
        return license[this.type] === 1
      })
        .filter(license => {
          return license[this.type] === 1
        })
        .map((license) => {
          return license.CountryID
        })
      this.currentCountryIDsStore = this.currentCountryIDs.slice(0)
    }
  },
  mounted: function () {
    this.init()
  },
  computed: {
  },
  watch: {
    BrandID: function (newValue) {
      this.init()
    }
  }
}
</script>

<style scoped>
ul.categoryList {
    list-style: none;
    padding-left: 0.33em;
}
ul.categoryList li {
    display: flex;
    justify-content: flex-start;
    padding: 0.33em 0;
}
ul.categoryList li span {
    min-width: 33%;
}
</style>
