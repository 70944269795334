<template>
  <div :class="computedClasses">
    <a href="javascript:" class="thumbs-up" :class="computedIsUpClass" @click="voteUp">
      <inline-svg
        width="24"
        height="24"
        :src="require('@/assets/images/icons/3.0/thumb-up.svg')"
      />
    </a>
    <a href="javascript:" class="thumbs-down" :class="computedIsDownClass" @click="voteDown">
      <inline-svg
        width="24"
        height="24"
        :src="require('@/assets/images/icons/3.0/thumb-down.svg')"
      />
    </a>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'ThumbRateForm',
  props: {
    contactId: {
      type: Number,
      required: true
    },
    brandId: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    values: {
      type: Array,
      default: () => []
    }
  },
  components: {
    InlineSvg
  },
  data: function () {
    return {
      isUp: false,
      isDown: false
    }
  },
  computed: {
    computedClasses: function () {
      return {
        'thumb-rate-form': true
      }
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    computedIsUpClass () {
      return {
        active: this.values.filter(value => value.Property === this.name && value.IsGood).length > 0
      }
    },
    computedIsDownClass () {
      return {
        active: this.values.filter(value => value.Property === this.name && !value.IsGood).length > 0
      }
    }
  },
  created: function () {},
  mounted: function () {},
  methods: {
    async deleteCurrentRating () {
      try {
        const currentEntry = this.values.find(value => value.Property === this.name)
        const currentEntryId = currentEntry.ContactPropertyRatingID
        await this.app.APIService._delete(`talentpool/leads/ai/rate/property/${currentEntryId}`)
        this.$emit('deleted', currentEntryId)
        this.isUp = false
        this.isDown = false
      } catch (error) {
        console.error(error)
      }
    },
    async voteUp () {
      const isCurrentlyUp = this.computedIsUpClass.active
      this.isUp = !this.isUp
      this.isDown = false

      try {
        if (isCurrentlyUp) {
          this.deleteCurrentRating()
        } else {
          // Call POST route
          const result = await this.app.APIService._post('talentpool/leads/ai/rate/property', {
            contactId: this.contactId,
            brandId: this.brandId,
            property: this.name,
            isGood: true
          })
          this.$emit('rated', result.body)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async voteDown () {
      const isCurrentlyDown = this.computedIsDownClass.active
      this.isDown = !this.isDown
      this.isUp = false

      try {
        if (isCurrentlyDown) {
          this.deleteCurrentRating()
        } else {
          // Call POST route
          const result = await this.app.APIService._post('talentpool/leads/ai/rate/property', {
            contactId: this.contactId,
            brandId: this.brandId,
            property: this.name,
            isGood: false
          })
          this.$emit('rated', result.body)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.thumb-rate-form {
  display: flex;
  gap: 8px;

  &.is-rated {
    a {
      pointer-events: none;
    }
  }

}

a {
  svg {
    stroke: #a0abbb;
  }

  &.thumbs-up {
    &.active svg {
      stroke: rgba(145, 193, 36, 1);
    }

    &:hover:not(.active) svg {
      stroke: rgba(145, 193, 36, 0.66);
    }
  }

  &.thumbs-down {
    &.active svg {
      stroke: rgba(229, 58, 20, 1);
    }

    &:hover:not(.active) svg {
      stroke: rgba(229, 58, 20, 0.66);
    }
  }
}

svg {
  width: 16px;
}
</style>
