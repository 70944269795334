<!-- component template -->
<template>
  <div>
    <a href="javascript:" class="product mb-4" :class="!available.combined || !available.available.includes('weekly') ? 'unavailable' : ''" @click="changeSelected">

      <span :class="computedCheckboxClass">

        <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>

      </span>

      <span :class="flagClass"></span>

      <div class="price-container">
        <div class="prices">
          <strong v-if="discountAvailable">{{ $t('page.topbrand.productprice', { price: price }) }}<span v-if="saleText">*</span></strong>
          <strong v-else>{{ $t('page.topbrand.productprice', { price: calculatedPrice }) }}</strong>
          <strong class="price-before" v-if="discountAvailable">{{ $t('page.topbrand.productprice', { price: priceBefore }) }}</strong>
        </div>
        <span class="sale-text" v-if="saleText">{{saleText}}</span>
        <div v-if="!available.membership" class="already-member">{{ $t('page.topbrand.membershiprequired', { country: countryNames[country] }) }}</div>
        <div v-if="available.membership && available.topBrand" class="already-member">{{ $t('page.topbrand.alreadybooked', { country: countryNames[country] }) }}</div>
        <div v-if="available.membership && !available.topBrand && !available.available.includes('weekly')" class="already-member">{{ $t('page.topbrand.bookedout', { country: countryNames[country] }) }}</div>
      </div>

    </a>

  </div>
</template>

<script>

export default {
  name: 'ProductContainer',
  components: {
  },
  props: ['value', 'price', 'priceBefore', 'discountAvailable', 'country', 'available', 'saleText'],
  data: function () {
    const countryNames = {
      de: this.$t('country.de'),
      at: this.$t('country.at'),
      ch: this.$t('country.ch')
    }

    return {
      countryNames
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    changeSelected () {
      if (!this.available.combined) return
      this.$emit('input', !this.value)
    }
  },
  computed: {
    calculatedPrice () {
      if (this.priceBefore && this.discountAvailable === true) {
        return this.priceBefore
      }
      return this.price
    },
    flagClass () {
      return `flag flag-${this.country}`
    },
    computedCheckboxClass () {
      const classes = ['checkbox']
      if (this.value === true) {
        classes.push('active')
      }
      return classes.join(' ')
    }
  }
}
</script>
