<template>
  <div class="mb-main-content" v-if="hasCurrentBrand">
    <h1>Meine Credit-Historie</h1>
    <div class="row">
      <div class="col-12">
        <div class="card credit-history">
          <table>
            <thead>
              <tr>
                <th>Datum – Uhrzeit</th>
                <th>Typ</th>
                <th>Produkt</th>
                <th>Credits</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!hasCreditHistory">
                <td colspan="4">Keine Einträge vorhanden</td>
              </tr>
              <tr v-for="entry in userStore.getCreditHistory" :key="entry.CreditTransactionID">
                <td>{{ computedDate(entry.Created) }}</td>
                <td>{{ entry.Type }}</td>
                <td>{{ computedProductLine(entry) }}</td>
                <td :class="{'credit-positive': entry.Amount > 0, 'credit-negative': entry.Amount < 0}">
                  {{ entry.Amount > 0 ? '+' : '' }}{{ entry.Amount }} Credits
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '../stores/userStore'
import moment from 'moment'

export default {
  components: {
  },
  data () {
    return {
      userStore: useUserStore()
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    hasCurrentBrand () {
      return typeof this.currentBrand.BrandID !== 'undefined'
    },
    currentBrand () {
      const current = this.app.currentBrand || {}
      return current
    },
    hasCreditHistory () {
      return this.userStore.getCreditHistory.length > 0
    },
    computedProductLine () {
      return (entry) => {
        if (entry.Type === 'Kauf') {
          return `${entry.Amount} Credits`
        }
        return ''
      }
    },
    computedDate () {
      return (entry) => {
        return [
          moment(entry.Created).format('DD.MM.YYYY'),
          ' - ',
          moment(entry.Created).format('HH:mm'),
          ' Uhr'
        ].join('')
      }
    }
  },
  mounted () {
    this.userStore.loadCreditHistory().catch((error) => {
      this.$swal({
        title: 'Fehler',
        text: error.message,
        icon: 'error'
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.credit-history {
  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 0.5rem;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }

    thead {
      th {
        background-color: #f5f5f5;
      }
    }

    tbody {
      tr:hover {
        background-color: #f5f5f5;
      }
      .credit-positive {
        color: green;
      }
      .credit-negative {
        color: red;
      }
    }
  }
}
</style>
