<template>
  <div class="booking-content" v-if="hasCurrentBrand">
    <div class="row mb-wide-content">
      <div class="col-6 left-side text-center">

        <img src="../../assets/images/icons/top-marke-weiss-ecken.png" />
        <h1>
          <strong v-t="'page.topbrand.title'"></strong>
          <div v-t="'page.topbrand.description'"></div>
        </h1>

        <img
          class="img-fluid"
          src="../../assets/images/topmarke.png"
        />

      </div>
      <div class="col-6 checkout-container typeform" v-if=0>
        <iframe id="typeform-full" width="100%" height="100%" frameborder="0" allow="camera; microphone; autoplay; encrypted-media;" :src="'https://form.typeform.com/to/BhoyvErj?typeform-medium=embed-snippet#de=' + isProductAvailable('de').membership + '&at=' + isProductAvailable('at').membership + '&ch=' + isProductAvailable('ch').membership + '&brandid=' + currentBrand.BrandID + '&contactid=' + app.currentContact.ContactID"></iframe>
      </div>
      <div class="col-6 checkout-container" ref="checkout" v-if=1>

        <loading
          :active.sync="isLoading"
          :can-cancel="false"
          :is-full-page="false"></loading>

        <div v-if="isFQStripeCustomer">
          <strong v-t="'page.membership.billingaddress'"></strong>
          <span class="d-block mb-2">{{computedInvoiceAddress}}</span>
          <strong v-t="'page.membership.paymentmethod'"></strong>
          <span class="d-block">{{computedInvoiceMethod}}</span>
        </div>

        <h2 class="mt-3" v-t="'page.topbrand.booking'"></h2>

        <div>

          <router-link v-if=0
            to="/stripe-customer"
          >
            {{ $t('page.membership.editbillingandpayment') }}
          </router-link>

          <div class="products" v-if="this.app.currentMembership">

            <div>

              <div class="product mb-4" :class="isProductAvailable('de').combined ? '' : 'unavailable'" @click="changeSelected">

                <span :class="computedCheckboxClass">
                  <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
                </span>

                <span class="flag flag-de"></span>

                <div class="price-container">
                  <div class="prices">
                    <strong>{{ $t('page.topbrand.costpermonthde') }}</strong>
                  </div>
                  <span class="sale-text" v-t="'page.topbrand.monthlybill'"></span>
                  <div v-if="!isProductAvailable('de').membership" class="already-member">{{ $t('page.topbrand.membershiprequired', { country: this.$t('country.de') }) }}</div>
                  <div v-if="isProductAvailable('de').membership && isProductAvailable('de').topBrand" class="already-member" v-t="{ path: 'page.topbrand.alreadybooked', args: { country: this.$t('country.de') } }"></div>
                </div>

              </div>
              <ul v-if="isProductAvailable('de').combined && availableTopBrands.DE != null">
                <li :class="availableTopBrands.DE.includes('weekly') ? '' : 'unavailable'">
                  <input
                    type="radio"
                    name="selectionDE"
                    id="topmarkeDE1"
                    v-model="Checkout.SelectedPeriod.DE"
                    value="weekly"
                    @change="deRadioSelected"
                    :disabled="!availableTopBrands.DE.includes('weekly')"
                  />
                  <label for="topmarkeDE1" v-t="'page.topbrand.weeklyde'"></label>
                </li>
                <li :class="availableTopBrands.DE.includes('two-weekly') ? '' : 'unavailable'">
                  <input
                    type="radio"
                    name="selectionDE"
                    id="topmarkeDE2"
                    v-model="Checkout.SelectedPeriod.DE"
                    value="two-weekly"
                    @change="deRadioSelected"
                    :disabled="!availableTopBrands.DE.includes('two-weekly')"
                  />
                  <label for="topmarkeDE2" v-t="'page.topbrand.twoweeklyde'"></label>
                </li>
                <li :class="availableTopBrands.DE.includes('four-weekly') ? '' : 'unavailable'">
                  <input
                    type="radio"
                    name="selectionDE"
                    id="topmarkeDE3"
                    v-model="Checkout.SelectedPeriod.DE"
                    value="four-weekly"
                    @change="deRadioSelected"
                    :disabled="!availableTopBrands.DE.includes('four-weekly')"
                  />
                  <label for="topmarkeDE3" v-t="'page.topbrand.fourweeklyde'"></label>
                </li>
              </ul>

            </div>

            <Product
              country="at"
              v-model="Checkout.SelectedTopMarke.AT"
              :available="isProductAvailable('at')"
              :saleText="$t('page.topbrand.weeklyat')"
              :price="149"
            />
            <Product
              country="ch"
              v-model="Checkout.SelectedTopMarke.CH"
              :available="isProductAvailable('ch')"
              :saleText="$t('page.topbrand.weeklych')"
              :price="99"
            />
          </div>

          <p class="mb-5" v-t="'page.membership.termsinfo'"></p>

          <CustomCheckbox
            :text="$t('page.membership.externalallowed', { company: currentCompany.Name })"
            v-model=Checkout.Legal.ExternalAllowed
          ></CustomCheckbox>

          <CustomCheckbox
            :text="$t('page.membership.agb')"
            v-model=Checkout.Legal.AGB
            class="mb-terms"
            onlyCheckboxClick=true
          ></CustomCheckbox>

          <button :class="finalStepClasses" @click="bookMemberships" v-t="'page.topbrand.booknow'"></button>
        </div>
      </div>
    </div>
    <TopBrandShowcase />
  </div>
</template>

<script>
import Config from '../../config/index'
import Product from '../../components/CustomProductTopBrandContainer'
import CustomCheckbox from '../../components/CustomCheckboxContainer'
import Loading from 'vue-loading-overlay'

import { loadStripe } from '@stripe/stripe-js'
import TopBrandShowcase from './TopBrandShowcase'
export default {
  name: 'TopBrand',
  props: [],
  data: function () {
    return {
      Checkout: {
        SelectedTopMarke: {
          DE: false,
          AT: false,
          CH: false
        },
        SelectedPeriod: {
          DE: 'weekly',
          AT: 'weekly',
          CH: 'weekly'
        },
        Legal: {
          ExternalAllowed: false,
          AGB: false
        }
      },
      availableTopBrands: {},
      isLoading: false,
      isBookingInProgress: false
    }
  },
  components: {
    Product,
    CustomCheckbox,
    TopBrandShowcase,
    Loading
  },
  computed: {
    isStripeCustomer () {
      if (typeof this.currentBrand.StripeCustomerID === 'undefined') return false
      if (this.currentBrand.StripeCustomerID === null) return false

      if (typeof this.currentMembership.Checkout === 'undefined') return false
      if (this.currentMembership.Checkout === null) return false

      return true
    },
    isFQStripeCustomer () {
      if (!this.isStripeCustomer) return false

      if (typeof this.currentMembership.Checkout.Payment === 'undefined') return false
      if (this.currentMembership.Checkout.Payment === null) return false

      return true
    },
    hasCurrentBrand () {
      return typeof this.currentBrand.BrandID !== 'undefined'
    },
    currentBrand () {
      const current = this.$parent.currentBrand || {}
      return current
    },
    currentCompany () {
      return this.currentBrand.Company
    },
    membershipTypes () {
      return this.app.membershipTypes
    },
    membershipType () {
      return this.app.membershipType
    },
    isActiveMember () {
      return this.app.isActiveMember
    },
    isCheckoutReady () {
      return this.isStripeCustomer === true && this.Checkout.Legal.AGB !== false && this.currentMembership.Checkout !== null
    },
    currentBrandSlug () {
      return (
        this.app.getSlug(this.currentBrand.Name, {
          lang: 'de',
          custom: {
            '+': ''
          }
        }) +
        '-m-' +
        this.currentBrand.BrandID
      )
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    currentMembership: function () {
      return this.app.currentMembership || {}
    },
    finalStepClasses () {
      const classes = ['final-step', 'btn', 'final-cta']
      if (this.isCheckoutReady === false) {
        classes.push('unavailable')
      }
      return classes.join(' ')
    },
    computedInvoiceAddress () {
      if (typeof this.currentMembership === 'undefined') return ''
      if (typeof this.currentMembership.Checkout === 'undefined') return ''
      if (typeof this.currentMembership.Checkout.Address !== 'undefined') {
        const Name = this.currentMembership.Checkout.Name
        const Address = this.currentMembership.Checkout.Address
        return `${Name}, ${Address.line1}, ${Address.postal_code} ${Address.city}`
      }
      return ''
    },
    computedInvoiceMethod () {
      if (typeof this.currentMembership === 'undefined') return ''
      if (typeof this.currentMembership.Checkout === 'undefined') return ''
      if (typeof this.currentMembership.Checkout.Payment !== 'undefined') {
        const Payment = this.currentMembership.Checkout.Payment

        switch (Payment) {
          case 'sepa':
            return this.$t('payment.sepa')
          case 'card':
            return this.$t('payment.creditcard')
        }
      }
      return ''
    },
    switchColors () {
      return {
        checked: '#42b2e4',
        unchecked: '#42b2e4',
        disabled: '#CCCCCC'
      }
    },
    switchLabels () {
      return {
        checked: this.$t('date.yearly'),
        unchecked: this.$t('date.monthly')
      }
    },
    hasSelectedSubscription () {
      return this.Checkout.SelectedTopMarke.DE || this.Checkout.SelectedTopMarke.AT || this.Checkout.SelectedTopMarke.CH
    },
    computedCheckboxClass () {
      const classes = ['checkbox']
      if (this.Checkout.SelectedTopMarke.DE) {
        classes.push('active')
      }
      return classes.join(' ')
    }
  },
  methods: {
    changeSelected () {
      if (!this.isProductAvailable('de').combined) return
      this.Checkout.SelectedTopMarke.DE = !this.Checkout.SelectedTopMarke.DE
      if (this.Checkout.SelectedTopMarke.DE && this.Checkout.SelectedPeriod.DE === '') {
        this.Checkout.SelectedPeriod.DE = 'weekly'
      }
      if (this.Checkout.SelectedTopMarke.DE === false) {
        this.Checkout.SelectedPeriod.DE = ''
      }
      this.$emit('input', !this.Checkout.SelectedTopMarke.DE)
    },
    deRadioSelected () {
      this.Checkout.SelectedTopMarke.DE = true
    },
    bookMemberships () {
      if (!this.isStripeCustomer) {
        return this.$swal(this.$t('popupmessage.warn.missingdata'), this.$t('popupmessage.warn.paymentinformation'), 'warning')
      }

      if (!this.hasSelectedSubscription) {
        return this.$swal(this.$t('popupmessage.warn.missingdata'), this.$t('popupmessage.warn.choosecountry'), 'warning')
      }

      if (!this.Checkout.Legal.ExternalAllowed) {
        return this.$swal(this.$t('popupmessage.warn.missingdata'), this.$t('popupmessage.warn.bookingpermission'), 'warning')
      }

      if (!this.Checkout.Legal.AGB) {
        return this.$swal(this.$t('popupmessage.warn.missingdata'), this.$t('popupmessage.warn.readterms'), 'warning')
      }

      this.startLoader()

      // Case Reference https://app.milanote.com/1M5yPO1mN7Dk86/fp-checkout-routes
      if (this.isStripeCustomer) {
        if (this.isFQStripeCustomer) {
          // Case #1 book subscription with parameters and charge stripe customer and payment method
          const currentBookingData = this.getBookingData()
          this.completeTopBrandBooking(currentBookingData)
        } else {
          // Case #3 redirect customer to stripe checkout
          this.linkBookingData()

          this.app.APIStripe._post(
            'setupPaymentData',
            {
              BrandID: this.currentBrand.BrandID,
              ReturnLocation: 'topmarke-buchen'
            }
          ).then(result => {
            localStorage.removeItem('returnLocationAfterAddressSetup')
            const sessionId = result.body.StripeCheckoutSessionID
            loadStripe(Config.STRIPE_PUBLIC_KEY).then(stripe => {
              stripe.redirectToCheckout({ sessionId })
            }).catch(error => {
              console.error(error)
              this.stopLoader()
              this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.occurred'), 'error')
            })
          }).catch(error => {
            console.error(error)
            this.stopLoader()
            this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.occurred'), 'error')
          })
        }
      } else {
        // Case #2 redirect customer to address setup
        this.startAddressSetup()
      }
    },
    startAddressSetup () {
      this.linkBookingData()
      this.$router.push('/zahlungsdaten-hinterlegen')
    },
    getBookingData () {
      const loginFromStorage = localStorage.getItem('FPMB_Login')
      const login = loginFromStorage ? JSON.parse(loginFromStorage) : {}
      return {
        BrandID: this.currentBrand.BrandID,
        BookedPortals: {
          DE: this.Checkout.SelectedTopMarke.DE ? this.Checkout.SelectedPeriod.DE : false,
          AT: this.Checkout.SelectedTopMarke.AT ? 'weekly' : false,
          CH: this.Checkout.SelectedTopMarke.CH ? 'weekly' : false
        },
        Username: login.user.Name
      }
    },
    completeTopBrandBooking (bookingData) {
      if (this.isBookingInProgress === true) return false
      this.isBookingInProgress = true

      this.app.APIStripe._post(
        'completeTopBrandBooking',
        bookingData
      ).then(response => {
        this.releaseBookingData()
        if (response.body.Error === false) {
          this.app.getBrandMembershipValues().then(() => {
            this.stopLoader()
            this.isBookingInProgress = false
            this.$router.push('/topmarke-buchen-erfolgreich')
          })
        } else {
          this.stopLoader()
          this.isBookingInProgress = false
          console.error(response.body.Error)
          this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.unknown'), 'error')
        }
      }).catch(error => {
        console.error(error)
        this.stopLoader()
        this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.unknown'), 'error')
      })
    },

    isProductAvailable (countrySlug) {
      let result = {
        membership: false,
        topBrand: false,
        available: [],
        combined: false
      }
      if (typeof this.app.currentMembership === 'undefined') return result
      if (typeof this.app.currentMembership.Memberships === 'undefined') return result

      const Membership = this.app.currentMembership

      if (Membership.Status !== 'Mitglied') return result

      const slug = countrySlug.toUpperCase()
      result = {
        membership: Membership.Memberships[slug],
        topBrand: Membership.TopBrands[slug],
        available: this.availableTopBrands[slug],
        combined: Membership.Memberships[slug] && !Membership.TopBrands[slug]
      }
      return result
    },
    startLoader () {
      this.isLoading = true
      // this.loader = this.$loading.show({
      //   // Pass props by their camelCased names
      //   container: this.$refs.checkout,
      //   color: '#005578',
      //   loader: 'spinner',
      //   width: 64,
      //   height: 64,
      //   backgroundColor: '#ffffff',
      //   opacity: 0.75,
      //   zIndex: 1001
      // })
    },
    stopLoader () {
      this.isLoading = false
    },
    loadBookingData () {
      try {
        const loadedBookingData = localStorage.getItem('bookingDataAfterSetup')
        if (loadedBookingData !== null) {
          return JSON.parse(loadedBookingData)
        }
      } catch (error) {
        console.error(error)
        return null
      }

      return null
    },
    linkBookingData () {
      localStorage.setItem('bookingDataAfterSetup', JSON.stringify(this.getBookingData()))
      this.storeReturnLocation()
    },
    releaseBookingData () {
      localStorage.removeItem('bookingDataAfterSetup')
    },
    storeReturnLocation () {
      localStorage.setItem('returnLocationAfterAddressSetup', 'topmarke-buchen')
    },
    startPaymentSetup () {
      const Membership = this.app.currentMembership
      if (Membership.Checkout === null || typeof Membership.Checkout.Address === 'undefined') {
        this.storeReturnLocation()
        this.$router.push('/zahlungsdaten-hinterlegen')
      } else {
        this.startLoader()

        this.app.APIStripe._post(
          'setupPaymentData',
          {
            BrandID: this.currentBrand.BrandID,
            ReturnLocation: 'topmarke-buchen'
          }
        ).then(result => {
          const sessionId = result.body.StripeCheckoutSessionID
          loadStripe(Config.STRIPE_PUBLIC_KEY).then(stripe => {
            stripe.redirectToCheckout({ sessionId })
          }).catch(error => {
            console.error(error)
            this.stopLoader()
            this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.occurred'), 'error')
          })
        }).catch(error => {
          console.error(error)
          this.stopLoader()
          this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.occurred'), 'error')
        })
      }
    },
    getAvailableTopBrand () {
      return new Promise(resolve => {
        this.app.APIStripe._post(
          'getAvailableTopBrandPortals',
          {
            BrandID: this.currentBrand.BrandID
          }
        ).then(result => {
          this.availableTopBrands = result.body.Packages.availablePortals
          this.stopLoader()
        }).catch(error => {
          console.error(error)
          this.stopLoader()
          this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.occurred'), 'error')
        }).finally(resolve)
      })
    },
    resetCheckout () {
      this.Checkout = {
        SelectedTopMarke: {
          DE: false,
          AT: false,
          CH: false
        },
        SelectedPeriod: {
          DE: '',
          AT: '',
          CH: ''
        },
        Legal: {
          ExternalAllowed: false,
          AGB: false
        }
      }
    },
    updateScreen () {
      if (typeof this.currentBrand.BrandID !== 'undefined') {
        this.resetCheckout()
        this.getAvailableTopBrand()
      }
    }
  },
  mounted: function () {
    this.updateScreen()
  },
  watch: {
    currentBrand: function () {
      this.updateScreen()
    },
    'currentMembership.Checkout': function (newValue) {
      if (newValue !== false && newValue !== null && this.isFQStripeCustomer) {
        const loadedBookingData = this.loadBookingData()
        if (loadedBookingData !== null && this.isFQStripeCustomer && this.isBookingInProgress === false) {
          this.startLoader()
          this.completeMembershipBooking(loadedBookingData)
        }
      }
    },
    '$route' (to, from) {
      if (to.path === '/topmarke-buchen') {
        this.updateScreen()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.mb-first-container {
  margin-top: 80px;
}
.dashboard-logo {
  max-width: 350px;
}
.blueCard {
  background-color: #019FE0;
  border-radius: 10px;
  display: block;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 1rem;
}
.blueCard span {
  text-transform: uppercase;
  font-weight: bold;
}
.left-side {
  background-image: url("../../assets/images/topbrand-bg.png");
  background-position: top left;
  background-size: cover;
}
h1 {
  font-weight: 400 !important;
  text-align: center !important;
  text-transform: uppercase;
  width: 100%;
  max-width: 100% !important;
}
.left-side p {
  font: normal normal normal 18px/30px;
  text-align: left;
  color: white;
}
a.btn-topbrand {
  font: normal normal 900 12px/25px;
  letter-spacing: 0.3px;
  color: #FFFFFF;
  text-transform: uppercase;
  box-shadow: 0px 3px 3px #00000029;
  border: 2px solid #FFFFFF;
  border-radius: 20px;
  background: transparent;
  padding: 6px 32px;
}

ul {
  list-style: none;
  padding-left: 65px;

  li.unavailable {
    label {
      text-decoration: line-through;
      color: #aaa;
    }

    &::after {
      content: 'Ausgebucht!';
      font-weight: 700;
      color: red;
      margin-left: 10px;
      position: absolute;
    }
  }

  input {
    margin-right: 15px;
  }
}

.checkout-container.typeform {
  padding: 0px;
}
</style>
