<!-- component template -->
<template>
    <div class="custom-multiselect">
        <multiselect
            v-model="computedValue"
            :label="label"
            :track-by="identifier"
            :open-direction="computedOpenDirection"
            :options="options"
            :multiple="multiple"
            :searchable="computedSearchable"
            :loading="isLoading"
            :close-on-select="closeOnSelect"
            :hide-selected="false"
            :placeholder="$t('component.typetosearch')"
            :selectLabel="$t('multiselect.add')"
            :selectedLabel="$t('multiselect.selected')"
            :deselectLabel="$t('multiselect.remove')"
            @input="$emit('change')"
        >
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'CustomMultiselect',
  components: {
    multiselect: Multiselect
  },
  props: ['identifier', 'label', 'value', 'options', 'multiple', 'openDirection', 'searchable'],
  data: function () {
    return {
      isLoading: false
    }
  },
  methods: {
    checkArrayForNoServiceValue (arValue) {
      if (arValue == null || arValue.length === 0) return arValue

      const noServiceCaptionDE = this.$t('providedmanagementassistances.20', 'de')
      const noServiceCaptionEN = this.$t('providedmanagementassistances.20', 'en')
      const valLength = arValue.length - 1
      if (arValue[valLength].Caption === noServiceCaptionDE ||
          arValue[valLength].Caption === noServiceCaptionEN) {
        arValue = [arValue[valLength]]
      } else {
        arValue = arValue.filter(item => {
          return item.Caption !== noServiceCaptionDE && item.Caption !== noServiceCaptionEN
        })
      }

      return arValue
    }
  },
  computed: {
    closeOnSelect () {
      return !this.multiple
    },
    computedOpenDirection () {
      return this.openDirection || 'bottom'
    },
    computedSearchable () {
      return this.searchable || true
    },
    computedOptions () {
      return this.options || []
    },
    computedValue: {
      get: function () {
        if (typeof this.value === 'undefined') return []
        return this.value.map(id => {
          return this.computedOptions.find(option => {
            return option[this.identifier] === id
          })
        })
      },
      set: function (newValue = []) {
        newValue = this.checkArrayForNoServiceValue(newValue)
        this.$emit('input', newValue.map(option => {
          return option[this.identifier]
        }))
      }
    }
  },
  watch: {
  },
  mounted: function () {

  }
}
</script>

<style>
.custom-multiselect .multiselect__tags span {
  font-size: 110%;
}
</style>
