<template>
  <b-modal
    id="creditModal"
    ref="creditModal"
    size="xl"
    hide-footer
    @hide="resetCurrentVariant"
    :no-close-on-backdrop="computedIsBooking"
  >
    <template #modal-header>
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="d-flex align-items-center justify-content-start gap-2">
          <img class="coin" src="@/assets/images/icons/3.0/coin.png" />
          <h5 v-if="!hasCurrentVariant">Credits für den Talentpool kaufen</h5>
          <h5 v-else>Kauf bestätigen</h5>
        </div>
        <a href="javascript:" aria-label="Close" @click="closeModal">
          <inline-svg class="stroke-black" :src="require('@/assets/images/icons/3.0/close.svg')" />
        </a>
      </div>
    </template>

      <div v-if="!hasCurrentVariant" class="row content-rows">
        <div class="col-12">
          <div class="credit-variants">
            <div
              class="credit-variant"
              v-for="creditVariant in creditVariants"
              :key="creditVariant.id"
            >
              <div class="credit-header">
                <img v-for="(i, index) in creditVariant.credits" :key="index" class="coin" src="@/assets/images/icons/3.0/coin.png" />
              </div>
              <div class="credit-content">
                <span class="amount">{{ creditVariant.credits }} Credit{{ creditVariant.credits > 1 ? 's' : '' }}</span>
                <div class="prices">
                  <span v-if="creditVariant.hasDiscount" class="price-before">{{
                    (creditVariant.price - creditVariant.discountAmount)
                      | formatPrice
                  }}</span>
                  <span class="price">{{
                    creditVariant.price | formatPrice
                  }}</span>
                  <span
                    v-if="!creditVariant.hasDiscount"
                    class="discount-percentage"
                    >Kein Rabatt</span
                  >
                  <span v-else class="discount-percentage"
                    >{{ creditVariant.discountPercentage }}% Rabatt</span
                  >
                </div>
                <a
                  class="cta"
                  href="javascript:"
                  @click="setCurrentVariant(creditVariant.id)"
                  >{{ creditVariant.credits }} Credit{{ creditVariant.credits > 1 ? 's' : '' }} kaufen</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 description">
          <span class="headline"
            >So funktioniert die Bezahlung im Talentpool:
          </span>
          <ul>
            <li>
              Sie benötigen 1 Credit, um im Talentpool einen Kontakt anzufragen.
            </li>
            <li>
              Wenn die Kontaktanfrage akzeptiert wird, erhalten Sie die
              freigeschalteten Kontaktdaten und Zugriff auf die Chat-Funktion.
            </li>
            <li>
              Sie erhalten Ihre Credits automatisch wieder gutgeschrieben, wenn
              hre Anfrage abgelehnt oder aufgrund fehlender Rückmeldung nach 14
              Tagen automatisch zurückgenommen wird.
            </li>
          </ul>
        </div>
        <div class="col-12">
          <a href="javascript:" class="history-link" @click="goToCreditHistory"
            >Meine Credit-Historie ansehen</a
          >
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12">
          <div class="current-variant">

            <div class="credit-variant-container">
              <div class="credit-variant">
                <div class="credit-header">
                  <img ref="coins" v-for="(i, index) in currentVariant.credits" :key="index" class="coin" src="@/assets/images/icons/3.0/coin.png" />
                </div>
                <div class="credit-content">
                  <span class="amount">{{ currentVariant.credits }} Credit{{ currentVariant.credits > 1 ? 's' : '' }}</span>
                  <div class="prices">
                    <span v-if="currentVariant.hasDiscount" class="price-before">{{
                      (currentVariant.price - currentVariant.discountAmount)
                        | formatPrice
                    }}</span>
                    <span class="price">{{
                      currentVariant.price | formatPrice
                    }}</span>
                    <span
                      v-if="!currentVariant.hasDiscount"
                      class="discount-percentage"
                      >Kein Rabatt</span
                    >
                    <span v-else class="discount-percentage"
                      >{{ currentVariant.discountPercentage }}% Rabatt</span
                    >
                  </div>
                </div>
              </div>
              <a href="javascript:" class="change-link" @click="resetCurrentVariantNow">Auswahl ändern</a>
            </div>
            <div class="buy-form">
              <b-checkbox
                v-model="isAuthorized"
                name="termsAccepted"
                class="mb-3"
                required
              >
                Ich bin berechtigt, Buchungen für die Firma Rentas Franchise GmbH
                zu tätigen.
              </b-checkbox>
              <b-checkbox
                v-model="hasAccepted"
                name="termsAccepted"
                class="mb-3"
                required
              >
                Ich habe die AGB und die Datenschutzrichtlinien gelesen und bin
                damit einverstanden.
              </b-checkbox>
              <button ref="purchaseButton" class="btn btn-primary" :disabled="!hasAcceptedAll" @click="handleCreditPurchase">Kauf bestätigen</button>
            </div>
          </div>
        </div>
      </div>
  </b-modal>
</template>

<script>
import { useUserStore } from '@/stores/userStore'
import * as Ladda from 'ladda'
import InlineSvg from 'vue-inline-svg'

export default {
  components: {
    InlineSvg
  },
  data: function () {
    return {
      currentVariant: null,
      creditVariants: [
        {
          id: 1,
          credits: 1,
          price: 7990,
          discountPercentage: 0,
          discountAmount: 0,
          hasDiscount: false
        },
        {
          id: 2,
          credits: 3,
          price: 22771,
          discountPercentage: 5,
          discountAmount: 1199,
          hasDiscount: true
        },
        {
          id: 3,
          credits: 5,
          price: 35955,
          discountPercentage: 10,
          discountAmount: 3995,
          hasDiscount: true
        },
        {
          id: 4,
          credits: 10,
          price: 67915,
          discountPercentage: 15,
          discountAmount: 11985,
          hasDiscount: true
        }
      ],
      isAuthorized: false,
      hasAccepted: false,
      laddaButton: null,
      isBooking: false,
      animationCoordinates: null,
      userStore: useUserStore()
    }
  },
  computed: {
    hasCurrentVariant () {
      return this.currentVariant !== null
    },
    hasAcceptedAll () {
      return this.isAuthorized && this.hasAccepted
    },
    computedIsBooking () {
      return this.isBooking
    }
  },
  methods: {
    closeModal () {
      if (this.isBooking) return
      this.hide()
    },
    async handleCreditPurchase () {
      this.isBooking = true

      const loadingButton = Ladda.create(this.$refs.purchaseButton)
      loadingButton.start()

      try {
        await this.userStore.purchaseCredits(this.currentVariant.credits)
        this.animateCoins()
        this.hide()
      } catch (error) {
        console.error(error)
      } finally {
        loadingButton.stop()
        this.isBooking = false
      }
    },
    goToCreditHistory () {
      this.hide()
      if (this.$route.name === 'CreditHistory') return
      this.$router.push({ name: 'CreditHistory' })
    },
    resetCurrentVariantNow () {
      if (this.isBooking) return
      this.currentVariant = null
      this.isAuthorized = false
      this.hasAccepted = false
    },
    resetCurrentVariant () {
      setTimeout(() => {
        this.currentVariant = null
        this.isBooking = false
        this.isAuthorized = false
        this.hasAccepted = false
      }, 300)
    },
    setCurrentVariant (creditVariantId) {
      this.currentVariant = this.creditVariants.find(
        (creditVariant) => creditVariant.id === creditVariantId
      )
    },
    show () {
      this.$refs.creditModal.show()
    },
    hide () {
      this.$refs.creditModal.hide()
    },
    animateCoins () {
      const coins = this.$refs.coins
      const target = document.querySelector('.credits-header-location') // This should be the location of your Credits counter
      const coinRect = coins[0].getBoundingClientRect()

      coins.forEach((coin, index) => {
        setTimeout(() => {
        // Clone the coin
          const coinClone = coin.cloneNode(true)

          // const coinRect = coin.getBoundingClientRect()
          coinClone.style.position = 'absolute'
          coinClone.style.left = `${coinRect.left}px`
          coinClone.style.top = `${coinRect.top}px`

          document.body.appendChild(coinClone)

          const targetRect = target.getBoundingClientRect()
          const translateX = targetRect.left - coinRect.left - 46
          const translateY = targetRect.top - coinRect.top - 46

          coinClone.style.animation = 'flyToCredits 1s forwards'
          coinClone.style.transform = `translate(${translateX}px, ${translateY}px) scale(0.25)`

          coinClone.addEventListener('animationend', () => {
            coinClone.parentNode.removeChild(coinClone)
          })
        }, index * 22)
      })
    }
  },
  mounted: function () {
    this.laddaButton = Ladda.create(this.$refs.purchaseButton)
  }
}
</script>

<style lang="scss">
#creditModal {

  font-family: DM Sans, sans-serif;

  .modal-content {
    width: calc(1000px + 64px);
    max-width: 100vw;
    padding: 32px;
    header {
      padding: 0;
      border: 0;
      margin-bottom: 32px;
    }

    .content-rows {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .history-link {
        color: #00719f;
        text-decoration: none;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }

      .description {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .headline {
          color: #3f4957;
          font-size: 23px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px; /* 139.13% */
        }

        ul {
          padding-left: 1.5rem;

          li {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
    }

    .modal-body {
      padding: 0;
    }

    .credit-variants {
      display: flex;
      gap: 32px;
      justify-content: space-between;
    }

    .credit-variant {
      width: 226px;

      .credit-header {
        display: flex;
        padding: 8px 32px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 12px 12px 0px 0px;
        background: #00719f;

        .coin {
          &:not(:first-child) {
            margin-left: -20px;
          }
        }

      }

      .credit-content {
        background-size: contain;
        background-position: center;
        background-repeat: repeat;
        background-image: url("../../assets/images/chat-bg.png");
        display: flex;
        padding: 32px;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        align-self: stretch;
        border-radius: 0px 0px 12px 12px;
        border: 2px solid #00719f;

        .amount {
          color: #3f4957;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: 44px;
        }

        .prices {
          display: flex;
          height: 96px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 16px;

          .price {
            color: #3f4957;
            font-size: 19px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }

          .price-before {
            color: #a0abbb;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            text-decoration: line-through;
          }

          .discount-percentage {
            color: #a0abbb;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
        }

        .cta {
          text-decoration: none;
          color: white;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          display: flex;
          padding: 14px 20px;
          justify-content: center;
          align-items: center;
          text-align: center;
          text-wrap: nowrap;
          gap: 8px;
          align-self: stretch;
          border-radius: 4px;
          background: #00719f;

          &:hover {
            background: #005e85;
          }
        }
      }
    }

    .current-variant {
      display: flex;
      gap: 32px;
      justify-content: flex-start;
      align-items: center;

      .credit-variant-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: space-between;

        .change-link {
          display: block;
          text-align: center;

          color: #00719F;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          text-decoration: underline;
        }
      }

      .buy-form {
        flex-grow: 1;

        .custom-checkbox {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
        }

        label {
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }

        button {
          color: white;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          border-radius: 4px;
          background: #00719F;
          width: 100%;
          display: flex;
          padding: 14px 20px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          border-width: 0;

          &:hover {
            background: #005e85;
          }

        }

      }

    }

  }

  h5 {
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 0;
  }

  .coin {
    width: 24px;
    height: 24px;
  }
}
</style>
