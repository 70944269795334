<template>

  <FairFieldEditor title="Begrüßung am Messestand" :index="5" buttonSaveText="Änderungen speichern"
    @toggle="toggleContent" @save="handleSave" :is-modal-open.sync="isModalOpen" :loading="isLoading"
    :saveDisabled="!computedIsValid" @update:isModalOpen="updateFormFields" :usePageStore="true">

    <div class="d-flex flex-column flex-md-row gap-3 w-100">
      <div class="portrait-container">
        <img v-if="computedFair.FairContactPortrait" :src="computedFairContactPortrait" />
      </div>
      <div class="d-flex flex-column gap-2 flex-grow-1">

        <span><strong>Ansprechpartner für Interessenten:</strong> {{ computedFair.FairContactName
          }}</span>
        <span><strong>Email des Ansprechpartners:</strong> {{ currentBrand.InterestedMail }}</span>

        <div class="d-flex flex-column gap-2 p-3 quote-box">
          <div v-html="computedFair.FairContactWelcome" />
        </div>

      </div>
    </div>

    <template v-slot:edit>

      <div class="d-flex flex-column gap-3">

        <div class="d-flex flex-column flex-md-row gap-4 w-100">
          <div class="d-flex flex-column gap-2 flex-grow-1">
            <span class="blue-headline">Name des Ansprechpartners für Interessenten</span>
            <input type="text" class="form-control w-100" v-model="formFields.FairContactName" />
          </div>
          <div class="d-flex flex-column gap-2 flex-grow-1">
            <span class="blue-headline">Email des Ansprechpartners (Nur im Infopaket)</span>
            <input type="text" class="form-control w-100" v-model="formFields.FairContactMail" />
          </div>
        </div>

        <div class="d-flex flex-column flex-md-row gap-4">
          <div class="d-flex flex-column gap-2">
            <span class="blue-headline">Ansprechpartner-Foto</span>
            <image-connected-selector v-model="formFields.FairContactPortraitID" class="mb-edit-img"
              :default="defaultImage" :save="saveImageToContact" :ratio="1" editClass="silhouette-portrait"
              :resolution-hint="$t('image.recoprofilepicture')" />
          </div>
          <div class="d-flex flex-column gap-2 flex-grow-1">
            <span class="blue-headline">Begrüßungstext am Messestand</span>
            <textarea class="form-control w-100 flex-grow-1 py-3" rows="10" style="min-height: 200px;"
              v-model="formFields.FairContactWelcome"></textarea>
            <div class="d-flex flex-column flex-md-row gap-2">
              <a href="javascript:" class="btn btn-secondary flex-grow-1"
                @click="generateFairContactWelcome('collegial')">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                  <path d="M1.9165 14.1298V10.1298H5.9165" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M16.5835 3.46301V7.46301H12.5835" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M3.58984 6.7964C3.92795 5.84092 4.50259 4.98667 5.26015 4.31334C6.0177 3.64002 6.93348 3.16957 7.92203 2.9459C8.91058 2.72223 9.93967 2.75262 10.9133 3.03425C11.8869 3.31587 12.7733 3.83954 13.4898 4.5564L16.5832 7.46306M1.9165 10.1297L5.00984 13.0364C5.72634 13.7533 6.61275 14.2769 7.58638 14.5585C8.56 14.8402 9.5891 14.8706 10.5776 14.6469C11.5662 14.4232 12.482 13.9528 13.2395 13.2795C13.9971 12.6061 14.5717 11.7519 14.9098 10.7964"
                    stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span>Neuen Vorschlag generieren (Locker)</span>
              </a>
              <a href="javascript:" class="btn btn-secondary flex-grow-1"
                @click="generateFairContactWelcome('factual')">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                  <path d="M1.9165 14.1298V10.1298H5.9165" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M16.5835 3.46301V7.46301H12.5835" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M3.58984 6.7964C3.92795 5.84092 4.50259 4.98667 5.26015 4.31334C6.0177 3.64002 6.93348 3.16957 7.92203 2.9459C8.91058 2.72223 9.93967 2.75262 10.9133 3.03425C11.8869 3.31587 12.7733 3.83954 13.4898 4.5564L16.5832 7.46306M1.9165 10.1297L5.00984 13.0364C5.72634 13.7533 6.61275 14.2769 7.58638 14.5585C8.56 14.8402 9.5891 14.8706 10.5776 14.6469C11.5662 14.4232 12.482 13.9528 13.2395 13.2795C13.9971 12.6061 14.5717 11.7519 14.9098 10.7964"
                    stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span>Neuen Vorschlag generieren (Professionell)</span>
              </a>
            </div>
          </div>
        </div>

        <div class="d-flex flex-column gap-3 new-text-area" v-if="computedHasGeneratedText">

          <div class="d-flex flex-column gap-2">

            <div class="d-flex justify-content-between align-items-center">
              <span class="headline">Neuer Vorschlag <em>({{ computedGeneratedTextType }})</em></span>
              <a href="javascript:" @click="resetGeneratedFairWelcome">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path d="M12 4.79639L4 12.7964" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M4 4.79639L12 12.7964" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </a>
            </div>

            <div v-html="computedGeneratedText" />

          </div>

          <a href="javascript:" class="btn btn-secondary" @click="useGeneratedText">
            Vorschlag übernehmen und anpassen
          </a>

        </div>

      </div>

      <hr />
      <div class="d-flex flex-column w-100 gap-3">
        <div class="d-flex flex-column gap-1">
          <span class="fw-bold">Anforderungen:</span>
          <ul class="special">
            <li v-if="formFields.FairContactName" :class="{
              success: String(formFields.FairContactWelcome).includes(formFields.FairContactName),
              error: !String(formFields.FairContactWelcome).includes(formFields.FairContactName)
            }">Der Text muss den Namen <strong>“{{ formFields.FairContactName }}”</strong> enthalten
            </li>
            <li :class="{
              success: String(formFields.FairContactWelcome).includes(currentBrand.Name) || (currentBrand.ShortBrandName ? String(formFields.FairContactWelcome).includes(currentBrand.ShortBrandName) : false),
              error: !String(formFields.FairContactWelcome).includes(currentBrand.Name) && !(currentBrand.ShortBrandName ? String(formFields.FairContactWelcome).includes(currentBrand.ShortBrandName) : false)
            }">Der Text muss den Markennamen <strong>“{{ currentBrand.Name }}”</strong>
              <template v-if="currentBrand.ShortBrandName">oder
                <strong>“{{ currentBrand.ShortBrandName }}”</strong>
              </template>
              enthalten
            </li>
            <li :class="{
              success: formFields.FairContactWelcome.length <= 400,
              error: formFields.FairContactWelcome.length > 400
            }">Der Text darf <strong>max. 400 Zeichen</strong> haben</li>
            <li>Der Text soll eine freundliche Begrüßung des Messestand-Besuchers enthalten</li>
            <li>Der Text soll dem Interessenten zeigen, dass Sie ihn auf dem weiteren Weg beraten und
              begleiten werden</li>
            <li>Der Name sollte von einer echten Person sein (<strong>Kein</strong> “Franchise-Team” o.ä.)
            </li>
            <li>Das Foto sollte Sie freundlich lächelnd zeigen und eine gute Qualität haben</li>
          </ul>
        </div>
      </div>
    </template>

    <template v-slot:editButtonCaption>
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-pencil"></i>
        <span>Bearbeiten</span>
      </div>
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/2_WelcomeText.png" />
    </template>

  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import ImageConnectedSelector from '@/components/ImageConnectedSelector.vue'
import AppConfig from '@/config/index'
import { useBrandStore } from '@/stores/brandStore'

export default {
  name: 'FairFormWelcome',
  components: {
    FairFieldEditor,
    ImageConnectedSelector
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      formFields: {
        FairContactName: '',
        FairContactMail: '',
        FairContactPortraitID: '',
        FairContactWelcome: '',
        GeneratedText: '',
        GeneratedTextType: ''
      }
    }
  },
  methods: {
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    updateFormFields () {
      this.formFields.FairContactName = this.computedFair.FairContactName ?? ''
      this.formFields.FairContactMail = this.currentBrand.InterestedMail ?? ''
      this.formFields.FairContactPortraitID = this.computedFair.FairContactPortrait?.MediumID
      this.formFields.FairContactWelcome = this.computedFair.FairContactWelcome ?? ''
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Änderungen erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Änderungen ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async submitForm () {
      const brandStore = useBrandStore()
      await brandStore.updateCurrentBrand({
        InterestedMail: this.formFields.FairContactMail
      })
      await brandStore.updateCurrentFair({
        FairContactName: this.formFields.FairContactName,
        FairContactPortraitID: this.formFields.FairContactPortraitID,
        FairContactWelcome: this.formFields.FairContactWelcome
      })
      brandStore.setCurrentFairUpdate({
        FairContactPortrait: this.formFields.FairContactPortrait
      })
    },
    async generateFairContactWelcome (type) {
      this.isLoading = true
      this.formFields.GeneratedText = ''
      this.formFields.GeneratedTextType = type
      try {
        const generatedTextRequest = await this.app.APIService._get(`mb/fair/new/2024/generate-welcome-text/${this.currentBrand.BrandID}/${type}`)
        this.formFields.GeneratedTextType = type === 'collegial' ? 'Locker' : 'Professionell'
        this.formFields.GeneratedText = generatedTextRequest.body.NewText
      } catch (error) {
        console.error('Error at Generating Welcome Text', error)
        this.$toast.error('Beim Generieren des Begrüßungstextes ist leider ein Fehler aufgetreten. Bitte versuche es später erneut.')
      } finally {
        this.isLoading = false
      }
      return false
    },
    saveImageToContact (data) {
      this.formFields.FairContactPortraitID = data.MediumID
      this.formFields.FairContactPortrait = {
        FileID: data.FileID,
        MediumID: data.MediumID
      }
    },
    resetGeneratedFairWelcome () {
      this.formFields.GeneratedText = ''
      this.formFields.GeneratedTextType = ''
    },
    useGeneratedText () {
      this.formFields.FairContactWelcome = String(this.formFields.GeneratedText).replace('</p><p>', '\n\n').replace('<p>', '').replace('</p>', '')
      this.resetGeneratedFairWelcome()
    }
  },
  computed: {
    computedIsValid () {
      return (
        this.formFields.FairContactName.length > 0 &&
        this.formFields.FairContactMail.length > 0 &&
        this.formFields.FairContactWelcome.length > 0 &&
        this.formFields.FairContactWelcome.length <= 400 &&
        this.formFields.FairContactWelcome.includes(this.formFields.FairContactName) &&
        (this.currentBrand.ShortBrandName
          ? this.formFields.FairContactWelcome.includes(this.currentBrand.ShortBrandName) ||
          this.formFields.FairContactWelcome.includes(this.currentBrand.Name)
          : this.formFields.FairContactWelcome.includes(this.currentBrand.Name))
      )
    },
    computedFairContactPortrait () {
      return `${AppConfig.MEDIA_API_URL}id/${this.computedFair.FairContactPortrait.FileID}`
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand
    },
    computedFair () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentFair
    },
    defaultImage () {
      return require('@/assets/images/platzhalter-portrait-weiss.jpg')
    },
    computedHasGeneratedText () {
      return String(this.formFields.GeneratedText).length > 0
    },
    computedGeneratedText () {
      return String(this.formFields.GeneratedText)
    },
    computedGeneratedTextType () {
      return this.formFields.GeneratedTextType === 'collegial' ? 'Locker' : 'Professionell'
    }
  }

}
</script>

<style lang="scss" scoped>
.portrait-container {
  img {
    width: 166px;
    border-radius: 8px;
    object-fit: cover;
  }
}
</style>
