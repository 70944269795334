<template>
  <div class="mb-login">
    <div class="mb-login-top">
      <h1>FranchiseUNIVERSUM</h1>
      <h2 v-t="'page.passwordreset.memberarea'"></h2>
    </div>

    <form @submit.prevent="unsubscribe" v-if="!hideForm">
      <p
        class="text-white text-center"
        v-html="$t('page.unsubscribemail.description')"
      ></p>
      <button
        ref="doLoginButton"
        type="submit"
        class="btn btn-primary"
        data-style="zoom-in"
        v-t="'page.unsubscribemail.unsubscribebtn'"
      ></button>
    </form>
    <div class="text-center" style="margin-top: 100px;">
      <div>
        <label class="text-white" v-t="'page.unsubscribemail.btminfo'"></label>
      </div>
        <router-link
          to="/login"
          class="btn btn-outline-light"
        >
          {{ $t('page.register.tologin') }}
        </router-link>
      </div>
  </div>
</template>

<script>
import Config from '../config'
import * as Ladda from 'ladda'
export default {
  name: 'UnsubscribeMail',
  components: {},
  props: [],
  data: function () {
    return {
      loading: false,
      hideForm: false
    }
  },
  computed: {
    currentAppVersion: function () {
      return Config.CLIENT_VERSION
    },
    isLoggedIn: function () {
      return this.$parent.isLoggedIn
    },
    app: function () {
      return this.$parent || {}
    }
  },
  watch: {
    loading: function (newValue) {
      if (newValue === true) {
        this.startLoader()
      } else {
        this.stopLoader()
      }
    }
  },
  mounted: function () {
    this.$refs.doLoginButton.loader = Ladda.create(this.$refs.doLoginButton)
  },
  methods: {
    startLoader () {
      if (
        typeof this.$refs.doLoginButton !== 'undefined' &&
        typeof this.$refs.doLoginButton.loader !== 'undefined'
      ) {
        this.$refs.doLoginButton.loader.start()
      }
    },
    stopLoader () {
      if (
        typeof this.$refs.doLoginButton !== 'undefined' &&
        typeof this.$refs.doLoginButton.loader !== 'undefined'
      ) {
        this.$refs.doLoginButton.loader.stop()
      }
    },
    unsubscribe () {
      this.loading = true
      this.errorMessage = ''

      this.app.API.unsubscribeMail(this.$route.params.id)
        .then(() => {
          this.$swal(
            this.$t('popupmessage.success.title'),
            this.$t('page.unsubscribemail.successmessage'),
            'success'
          )
          this.loading = false
          this.$router.push('/login').catch(() => {})
        })
        .catch(reason => {
          console.error(reason)
          this.loading = false
          this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.unknown'), 'error')
        })
    }
  }
}
</script>

<style scoped>
.btn {
  margin-bottom: 120px;
}
</style>
