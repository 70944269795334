<template>
  <div class="d-flex justify-content-between align-items-center">
    <router-link to="/" class="fu-logo-link">
      <img :src="computedLogo" alt="FranchiseUNIVERSUM" />
    </router-link>
    <LocaleSwitcher />
  </div>
</template>

<script>
import LocaleSwitcher from '@/components/LocaleSwitcher'
export default {
  name: 'PublicHeader',
  components: {
    LocaleSwitcher
  },
  computed: {
    computedLogo () {
      return require('@/assets/images/fu-logo-white.png')
    }
  }
}
</script>
