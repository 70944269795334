<template>
  <div>
    <div
      v-if="app.hasCurrentBrand"
      id="konzept"
      class="container mb-info-konzept p-0"
    >
      <div class="card mb-5">
        <div class="card-body">
          <form>
            <h5 class="card-title" v-t="'page.yourdata.concept.title'"></h5>
            <label v-t="'page.yourdata.concept.description'"></label>
            <h6 class="mb-card-title" v-html="$t('page.yourdata.concept.characteristics')">
            </h6>
            <system-type @detailedmanualchange="registerChange" @systemtypechange="registerChange"></system-type>
            <div class="mb-list">
              <label v-t="'page.yourdata.concept.pilot'"></label>
              <div>
                <b-form-radio-group
                  id="pilotbetriebRadioButtons"
                  v-model="currentBrand.ConceptHasPilotProject"
                  name="pilotbetrieb"
                  @change="registerChange"
                >
                  <b-form-radio value="1">
                    {{ $t('choice.yes') }}
                  </b-form-radio>
                  <b-form-radio value="0">
                    {{ $t('choice.no') }}
                  </b-form-radio>
                  <b-form-radio value="-1">
                    {{ $t('choice.notspecified') }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
            <h6 class="mb-card-title" v-html="$t('page.yourdata.concept.conceptdesc')">
            </h6>
            <div class="mb-list">
              <label>
                {{ $t('page.yourdata.concept.businesstype') }}
                <a
                  id="tt_businesstype"
                  href="javascript:"
                  class="badge badge-warning"
                >?</a>
              </label>
              <b-tooltip
                target="tt_businesstype"
                placement="top"
              >
                <div v-html="$t('page.yourdata.concept.businesstypehint')"></div>
              </b-tooltip>
              <div>
                <input
                  v-model="currentBrand.ConceptBusinessType"
                  type="text"
                  :placeholder="$t('page.yourdata.concept.businesstype')"
                  class="form-control"
                  maxlength="90"
                  @input="registerChange"
                >
              </div>
            </div>
            <div class="mb-list">
              <label>
                {{ $t('page.yourdata.concept.businessconcept') }}
                <a
                  id="tt_services"
                  href="javascript:"
                  class="badge badge-warning"
                >?</a>
              </label>

              <b-tooltip
                target="tt_services"
                placement="top"
              >
                <div v-html="$t('page.yourdata.concept.businessconcepthint')"></div>
              </b-tooltip>
              <div>
                <custom-editor
                  v-model="currentBrand.ConceptServices"
                  :maxlength="4000"
                  :small="true"
                  @change="registerChange"
                  :showProgressBar="true"
                  :progressCharCountMin="400"
                  :progressCharCountOK="1000"
                />
              </div>
            </div>
            <div class="mb-list">
              <label>
                {{ $t('page.yourdata.concept.competitivestrength') }}
                <a
                  id="tt_usp"
                  href="javascript:"
                  class="badge badge-warning"
                >?</a>
              </label>

              <b-tooltip
                target="tt_usp"
                placement="top"
              >
                <div class="text-left" v-html="$t('page.yourdata.concept.competitivestrengthhint')"></div>
              </b-tooltip>
              <div>
                <custom-editor
                  v-model="currentBrand.ConceptUSP"
                  :maxlength="4000"
                  :small="true"
                  @change="registerChange"
                  :showProgressBar="true"
                  :progressCharCountMin="180"
                  :progressCharCountOK="500"
                />
              </div>
            </div>
            <div class="mb-list">
              <label>
                {{ $t('page.yourdata.concept.moresysteminformation') }}
                <a
                  id="tt_others"
                  href="javascript:"
                  class="badge badge-warning"
                >?</a>
              </label>
              <b-tooltip
                target="tt_others"
                placement="top"
              >
                <div class="text-left" v-html="$t('page.yourdata.concept.moresysteminformationhint')"></div>
              </b-tooltip>
              <div>
                <custom-editor
                  v-model="currentBrand.ConceptSpecialFeatures"
                  maxlength="4000"
                  :small="true"
                  @change="registerChange"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomEditor from '@/components/CustomEditor'
import SystemType from '@/components/mydata/SystemType'

export default {
  name: 'BrandProfileDataConcept',
  components: {
    CustomEditor,
    SystemType
  },
  props: [],
  data: function () {
    return {}
  },
  computed: {
    app: function () {
      return this.$parent.$parent || {}
    },
    currentBrand: function () {
      return this.$parent.currentBrand || {}
    },
    preloads: function () {
      return this.$parent.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    }
  },
  methods: {
    registerChange () {
      this.app.registerChangedContent(this.app.saveBrand)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
