<template>
  <div class="chat-message" :class="computedClass">
    <div class="message-wrapper">
      <div class="message-header">
        <div>
          <span>{{ message.FromName }}</span>
          <span class="brand" v-if="computedIsFromMe" :title="brandStore.getCurrentBrand.Name">{{ brandStore.getCurrentBrand.Name }}</span>
        </div>
        <span>{{ computedDate }} | {{ computedTime }} Uhr</span>
      </div>
      <div class="message-content" v-html="computedMessageContent"></div>
    </div>
    <div class="user-image">
      <img
        :src="computedPortrait"
        alt=""
      />
    </div>

  </div>
</template>

<script>
import { useChatStore } from '@/stores/chatStore'
import { useUserStore } from '@/stores/userStore'
import { useBrandStore } from '@/stores/brandStore'
import moment from 'moment'

export default {
  name: 'ChatMessage',
  components: {},
  props: ['message'],
  data: function () {
    return {
      chatStore: useChatStore(),
      userStore: useUserStore(),
      brandStore: useBrandStore()
    }
  },
  computed: {
    computedMessageContent () {
      return this.message.Content.replace(/\n/g, '<br />')
    },
    computedName () {
      if (this.message.FromContactID === this.userStore.getCurrentContactID) {
        return this.message.FromName
      }
      return this.message.ToName
    },
    computedClass () {
      if (this.message.ToContactID === this.userStore.getCurrentContactID) {
        return 'from-me' // actually andersrum
      }

      return 'to-me'
    },
    computedIsToMe () {
      return this.message.ToContactID === this.userStore.getCurrentContactID
    },
    computedIsFromMe () {
      return this.message.FromContactID === this.userStore.getCurrentContactID
    },
    computedPortrait () {
      if (this.message.FromPortraitURL !== null) {
        return this.message.FromPortraitURL
      }
      return require('@/assets/images/user-none.png')
    },
    computedDate () {
      return moment(this.message.Created).format('DD.MM.YYYY')
    },
    computedTime () {
      return moment(this.message.Created).format('HH:mm')
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.chat-message {
  display: flex;
  gap: 8px;

  &.to-me {
    flex-direction: row-reverse;

    .message-content {
      background-color: #00719f !important;
      color: white !important;
    }
  }

  .message-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;

    .message-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      div {
        display: flex;
        flex-direction: column;

        max-width: 230px;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;

        span.brand {
          font-weight: bold;

          max-width: 230px;
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: ellipsis;

        }
      }

      span {
        color: #3f4957;
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .message-content {
      display: flex;
      padding: 16px;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 12px;
      background: #fff;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);

      color: #3f4957;
      /* Paragraph/P2/Regular */
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }

  .user-image {
    width: 60px;
    height: 60px;

    border-radius: 100px;
    border: 3px solid #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      object-fit: cover;
      border-radius: 100px;
    }
  }
}
</style>
