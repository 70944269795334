<template>
  <div>
    <div
      v-if="app.hasCurrentBrand"
      id="taetigkeit"
      class="container mb-info-konzept p-0"
    >
      <div class="card mb-5">
        <div class="card-body">
          <form>
            <h5 class="card-title" v-t="'page.yourdata.applicant.title'"></h5>
            <p v-t="'page.yourdata.applicant.description'"></p>
            <h6 class="mb-card-title" v-html="$t('page.yourdata.applicant.searchedprofile')"></h6>
            <div class="row slim mb-3">
              <div class="form-group col">
                <label for="CandidatePropertyIDs" v-t="'page.yourdata.applicant.desiredproperties'"></label>
                <custom-multiselect v-model="currentBrand.CandidatePropertyIDs" :options="app.preloads.candidateproperties" label="Caption" identifier="CandidatePropertiesID" :multiple="true" @change="registerChange" />
              </div>
            </div>
            <div class="row slim mb-3">
              <div class="form-group col">
                <label for="CandidatePrevKnowledgeIDs" v-t="'page.yourdata.applicant.desiredknowledge'"></label>
                <custom-multiselect v-model="currentBrand.CandidatePrevKnowledgeIDs" :options="app.preloads.candidateprevknowledges" label="Caption" identifier="CandidatePrevKnowledgesID" :multiple="true" @change="registerChange" />
              </div>
            </div>
            <div class="row slim">
              <div class="form-group col">
                <label for="CandidateRemark" v-t="'page.yourdata.applicant.remarkprofile'"></label>
                <div>
                  <custom-editor
                    v-model="currentBrand.CandidateRemark"
                    :maxlength="1100"
                    :small="true"
                    @change="registerChange"
                    :showProgressBar="true"
                    :progressCharCountMin="180"
                    :progressCharCountOK="500"
                  />
                </div>
              </div>
            </div>
            <h6 class="mb-card-title" v-html="$t('page.yourdata.applicant.partneractivity')"></h6>
            <div class="row slim mb-3">
              <div class="form-group col">
                <label for="PartnerMainActivityIDs" v-t="'page.yourdata.applicant.mainactivityofpartner'"></label>

                <div>
                  <b-form-checkbox-group
                    id="PartnerMainActivityIDs"
                    v-model="currentBrand.PartnerMainActivityIDs"
                    class="stacked"
                    name="PartnerMainActivityIDs"
                    :options="app.preloads.partnermainactivities"
                    value-field="PartnerMainActivitiesID"
                    text-field="Caption"
                    @change="registerChange"
                  />
                </div>
              </div>
            </div>
            <div class="row slim">
              <div class="form-group col">
                <label for="PartnerActivityShortDescription" v-t="'page.yourdata.applicant.shortdescofactivity'"></label>
                <div class="mb-3">
                  <custom-editor
                    v-model="currentBrand.PartnerActivityShortDescription"
                    :maxlength="1100"
                    :small="true"
                    @change="registerChange"
                    :showProgressBar="true"
                    :progressCharCountMin="180"
                    :progressCharCountOK="500"
                  />
                </div>
              </div>
            </div>
            <div class="row slim mb-3">
              <div class="form-group col">
                <label for="PartnerTargetGroupIDs" v-t="'page.yourdata.applicant.targetgroupofpartner'"></label>

                <div>
                  <b-form-checkbox-group
                    id="PartnerTargetGroupIDs"
                    v-model="currentBrand.PartnerTargetGroupIDs"
                    class="stacked"
                    name="PartnerTargetGroupIDs"
                    :options="app.preloads.partnertargetgroups"
                    value-field="PartnerTargetGroupID"
                    text-field="Caption"
                    @change="registerChange"
                  />
                </div>
              </div>
              <div class="form-group col">
                <label for="fld_Title" v-t="'page.yourdata.applicant.fulltimeparttime'"></label>

                <div>
                  <b-form-checkbox
                    v-model="currentBrand.PartnerFullTimeJob"
                    value="1"
                    unchecked-value="0"
                    class="stacked"
                    @change="registerChange"
                  >
                    {{ $t('choice.fulltimepossible') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="currentBrand.PartnerPartTimeJob"
                    value="1"
                    unchecked-value="0"
                    class="stacked"
                    @change="registerChange"
                  >
                    {{ $t('choice.parttimepossible') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
            <div class="row slim mb-3">
              <div class="form-group col">
                <label for="PartnerLateralEntrance" v-t="'page.yourdata.applicant.startascareerchanger'"></label>

                <b-form-radio-group
                  id="PartnerLateralEntrance"
                  v-model="currentBrand.PartnerLateralEntrance"
                  name="PartnerLateralEntrance"
                  @change="registerChange"
                  class="radios-stacked"
                  stacked
                >
                  <b-form-radio value="1">
                    {{ $t('page.yourdata.applicant.startascareerchangeryes') }}
                  </b-form-radio>
                  <b-form-radio value="0">
                    {{ $t('page.yourdata.applicant.startascareerchangerno') }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
              <div class="form-group col">
                <label for="PartnerHomeOffice" v-t="'page.yourdata.applicant.startinhomeoffice'"></label>
                <b-form-radio-group
                  id="PartnerHomeOffice"
                  v-model="currentBrand.PartnerHomeOffice"
                  name="PartnerHomeOffice"
                  @change="registerChange"
                  class="radios-stacked"
                  stacked
                >
                  <b-form-radio value="1">
                    {{ $t('page.yourdata.applicant.startinhomeofficeyes') }}
                  </b-form-radio>
                  <b-form-radio value="0">
                    {{ $t('page.yourdata.applicant.startinhomeofficeno') }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
            <div class="row slim">
              <div class="form-group col">
                <label for="PartnerSmallBusiness" v-t="'page.yourdata.applicant.startassmallbusiness'"></label>
                <b-form-radio-group
                  id="PartnerSmallBusiness"
                  v-model="currentBrand.PartnerSmallBusiness"
                  name="PartnerSmallBusiness"
                  @change="registerChange"
                  class="radio-stacked"
                  stacked
                >
                  <b-form-radio value="1">
                    {{ $t('page.yourdata.applicant.startassmallbusinessyes') }}
                  </b-form-radio>
                  <b-form-radio value="0">
                    {{ $t('page.yourdata.applicant.startassmallbusinessno') }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
              <div class="form-group col">
                <label for="PartnerMultipleLocations" v-t="'page.yourdata.applicant.openingofseveralbusiness'"></label>
                <b-form-radio-group
                  id="PartnerMultipleLocations"
                  v-model="currentBrand.PartnerMultipleLocations"
                  name="PartnerMultipleLocations"
                  @change="registerChange"
                  class="radios-stacked"
                  stacked
                >
                  <b-form-radio value="1">
                    {{ $t('page.yourdata.applicant.openingofseveralbusinessyes') }}
                  </b-form-radio>
                  <b-form-radio value="0">
                    {{ $t('page.yourdata.applicant.openingofseveralbusinessno') }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomEditor from '@/components/CustomEditor'
import CustomMultiselect from '@/components/CustomMultiselect'
export default {
  name: 'BrandProfileDataApplications',
  components: {
    CustomEditor,
    CustomMultiselect
  },
  props: [],
  data: function () {
    return {}
  },
  computed: {
    app: function () {
      return this.$parent.$parent || {}
    },
    currentBrand: function () {
      return this.$parent.currentBrand || {}
    },
    preloads: function () {
      return this.$parent.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    }
  },
  methods: {
    registerChange () {
      this.app.registerChangedContent(this.app.saveBrand)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
