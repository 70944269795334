<template>
  <div>
    <div
      v-if="app.hasCurrentBrand"
      id="natpraesenz"
      class="container mb-info-konzept p-0"
    >
      <div class="card mb-5">
        <div class="card-body">
          <form>
            <h5 class="card-title" v-t="'page.yourdata.natpresence.title'"></h5>
            <p>{{ $t('page.yourdata.natpresence.description', {country: datenLand}) }}</p>
            <h6 class="mb-card-title" v-html="$t('page.yourdata.natpresence.commonpart', { country: datenLand })"></h6>
            <div class="row slim mb-3">
              <div class="col form-group">
                <label for="fld_ComParamOriginCountryID" v-t="'page.yourdata.natpresence.origincountry'"></label>
                <div>{{ app.getCountryNameByID(currentBrand.ComParamOriginCountryID) }}</div>
              </div>
              <div class="col form-group">
                <label for="fld_ArticleTypeID">{{ $t('page.yourdata.natpresence.foundingyearin', { country: datenLand }) }}</label>
                <input
                  v-model="currentBrand.ComParamFoundingYear"
                  type="number"
                  class="form-control"
                  @input="registerChange"
                >
              </div>
              <div class="col form-group">
                <label for="fld_ArticleTypeID">{{ $t('page.yourdata.natpresence.firstpartnerin', { country: datenLand }) }}</label>
                <input
                  v-model="currentBrand.ComParamFirstPartnerYear"
                  type="number"
                  class="form-control"
                  @input="registerChange"
                >
              </div>
            </div>
            <div class="row slim mb-3">
              <div class="col form-group">
                <label for="fld_ArticleTypeID" v-t="'page.yourdata.natpresence.countemployee'"></label>
                <input
                  v-model="currentBrand.ComParamCountEmp"
                  type="number"
                  class="form-control"
                  @input="registerChange"
                >
              </div>
              <div class="col form-group">
                <label for="fld_ArticleTypeID" v-t="'page.yourdata.natpresence.countmanager'"></label>
                <input
                  v-model="currentBrand.ComParamCountAccManagers"
                  type="number"
                  class="form-control"
                  @input="registerChange"
                >
              </div>
            </div>
            <div class="row slim mb-3">
              <div class="col form-group">
                <b-form-group :label="$t('page.yourdata.natpresence.associatedmemberoffranchise')" class="mb-1">
                  <b-form-checkbox
                    v-model="currentBrand.ComParamAssocMemberDFV"
                    value="1"
                    unchecked-value="0"
                    class="stacked"
                    @change="registerChange"
                  >
                    {{ $t('page.yourdata.natpresence.germfranchiseverb') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="currentBrand.ComParamAssocMemberOEFV"
                    value="1"
                    unchecked-value="0"
                    class="stacked"
                    @change="registerChange"
                  >
                    {{ $t('page.yourdata.natpresence.austfranchiseverb') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="currentBrand.ComParamAssocMemberSFV"
                    value="1"
                    unchecked-value="0"
                    class="stacked"
                    @change="registerChange"
                  >
                    {{ $t('page.yourdata.natpresence.switfranchiseverb') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="checks.ComParamAssocMemberOther"
                    :value="1"
                    :unchecked-value="0"
                    class="stacked"
                    @change="registerChange"
                  >
                    {{ $t('page.yourdata.natpresence.otherfranchiseverb') }}
                  </b-form-checkbox>
                </b-form-group>
                <input
                  v-show="checks.ComParamAssocMemberOther===1"
                  v-model="currentBrand.ComParamAssocMemberOther"
                  type="text"
                  class="form-control"
                  placeholder="$t('page.yourdata.natpresence.otherfranchiseverbplaceholder')"
                  @input="registerChange"
                >
              </div>
            </div>
            <div class="row slim">
              <div class="col form-group">
                <b-form-group :label="$t('page.yourdata.natpresence.memberoffranchise')" class="mb-1">
                  <b-form-checkbox
                    v-model="currentBrand.ComParamMemberDFV"
                    value="1"
                    unchecked-value="0"
                    class="stacked"
                    @change="registerChange"
                  >
                    {{ $t('page.yourdata.natpresence.germfranchiseverb') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="currentBrand.ComParamMemberOEFV"
                    value="1"
                    unchecked-value="0"
                    class="stacked"
                    @change="registerChange"
                  >
                    {{ $t('page.yourdata.natpresence.austfranchiseverb') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="currentBrand.ComParamMemberSFV"
                    value="1"
                    unchecked-value="0"
                    class="stacked"
                    @change="registerChange"
                  >
                    {{ $t('page.yourdata.natpresence.switfranchiseverb') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="checks.ComParamMemberOther"
                    :value="1"
                    :unchecked-value="0"
                    class="stacked"
                    @change="registerChange"
                  >
                    {{ $t('page.yourdata.natpresence.otherfranchiseverb') }}
                  </b-form-checkbox>
                </b-form-group>
                <input
                  v-show="checks.ComParamMemberOther===1"
                  v-model="currentBrand.ComParamMemberOther"
                  type="text"
                  class="form-control"
                  placeholder="$t('page.yourdata.natpresence.otherfranchiseverbplaceholder')"
                  @input="registerChange"
                >
              </div>
            </div>
            <h6 class="mb-card-title" v-html="$t('page.yourdata.natpresence.economicindicators', { country: datenLand })"></h6>
            <div class="row mb-3">
              <div class="form-group col-4">
                <label for="ComParamCurrency" v-t="'currency.title'"></label>
                <select
                  v-model="currentBrand.ComParamCurrency"
                  class="form-control"
                  name="ComParamCurrency"
                  @change="registerChange"
                >
                  <option
                    v-for="(option, i) in app.preloads.currencies"
                    :key="i"
                    :value="option.Currency"
                  >
                    {{ option.Currency }}
                  </option>
                </select>
              </div>
              <div class="col-8 form-group">
                <label for="fld_ArticleTypeID">{{ $t('page.yourdata.natpresence.totalsalesin', { country: datenLand }) }}</label>
                <currency-input
                  v-model="currentBrand.ComParamTurnover"
                  class="form-control"
                  :currency="currentBrand.ComParamCurrency"
                  locale="de"
                  @input="registerChange"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="form-group col-4">
                <label
                  for="ComParamAvgPartnerTurnoverFrom"
                >{{ $t('page.yourdata.natpresence.avaragesaleofparners', { country: datenLand }) }}</label>
              </div>
              <div class="form-group col-4">
                <currency-input
                  v-model="currentBrand.ComParamAvgPartnerTurnoverFrom"
                  class="form-control"
                  :currency="currentBrand.ComParamCurrency"
                  locale="de"
                  @input="registerChange"
                />
              </div>
              <div class="form-group col-4 position-relative">
                <label
                  for="ComParamAvgPartnerTurnoverTo"
                  class="prefixed-absolute"
                >-</label>
                <currency-input
                  v-model="currentBrand.ComParamAvgPartnerTurnoverTo"
                  class="form-control"
                  :currency="currentBrand.ComParamCurrency"
                  locale="de"
                  @input="registerChange"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="form-group col-4">
                <label for="ComParamReturnOnSalesFrom" v-t="'page.yourdata.natpresence.returnonsales'"></label>
              </div>
              <div class="form-group col-4">
                <currency-input
                  v-model="currentBrand.ComParamReturnOnSalesFrom"
                  class="form-control"
                  :currency="{ 'suffix': '%' }"
                  @input="registerChange"
                />
              </div>
              <div class="form-group col-4 position-relative">
                <label
                  for="ComParamReturnOnSalesTo"
                  class="prefixed-absolute"
                >-</label>
                <currency-input
                  v-model="currentBrand.ComParamReturnOnSalesTo"
                  class="form-control"
                  :currency="{ 'suffix': '%' }"
                  @input="registerChange"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="form-group col-4">
                <label for="ComParamStartupTimeFrom">
                  {{ $t('page.yourdata.natpresence.startupshase') }}
                  <b-link
                    id="tt_StartupTime"
                    class="badge badge-warning"
                  >?</b-link>
                </label>
              </div>
              <b-tooltip
                target="tt_StartupTime"
                placement="top"
              >
                <p v-t="'page.yourdata.natpresence.startupshasehint'"></p>
              </b-tooltip>
              <div class="form-group col-4">
                <currency-input
                  v-model="currentBrand.ComParamStartupTimeFrom"
                  class="form-control"
                  :decimal-length="0"
                  :currency="{ 'suffix': ' ' + $tc('date.month', 2) }"
                  @input="registerChange"
                />
              </div>
              <div class="form-group col-4 position-relative">
                <label
                  for="ComParamStartupTimeTo"
                  class="prefixed-absolute"
                >-</label>
                <currency-input
                  v-model="currentBrand.ComParamStartupTimeTo"
                  class="form-control"
                  :decimal-length="0"
                  :currency="{ 'suffix': ' ' + $tc('date.month', 2) }"
                  @input="registerChange"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-4">
                <label for="ComParamBreakEvenTimeFrom">
                  {{ $t('page.yourdata.natpresence.breakevenpoint') }}
                  <b-link
                    id="tt_BreakEvenTime"
                    class="badge badge-warning"
                  >?</b-link>
                </label>
              </div>
              <b-tooltip
                target="tt_BreakEvenTime"
                placement="top"
              >
                <p v-t="'page.yourdata.natpresence.breakevenpoint'"></p>
              </b-tooltip>
              <div class="form-group col-4">
                <currency-input
                  v-model="currentBrand.ComParamBreakEvenTimeFrom"
                  class="form-control"
                  :decimal-length="0"
                  :currency="{ 'suffix': ' ' + $tc('date.month', 2) }"
                  @input="registerChange"
                />
              </div>
              <div class="form-group col-4 position-relative">
                <label
                  for="ComParamBreakEvenTimeTo"
                  class="prefixed-absolute"
                >-</label>
                <currency-input
                  v-model="currentBrand.ComParamBreakEvenTimeTo"
                  class="form-control"
                  :decimal-length="0"
                  :currency="{ 'suffix': ' ' + $tc('date.month', 2) }"
                  @input="registerChange"
                />
              </div>
            </div>

            <h6 class="mb-card-title">
              <div v-html="$t('page.yourdata.natpresence.natinformation', { country: datenLand })"></div>
              <br />
              <small class="d-block pt-3" v-html="$t('page.yourdata.intpresence.savewarning')"></small>
            </h6>

            <div class="row">
              <div class="col mb-5">
                <operation-unit-form
                  v-model="currentBrand.OperationUnits"
                  :label="$t('page.yourdata.natpresence.companiesincountry', { country: datenLand })"
                  total-qualifier="OperationsNational"
                  child-qualifier="PartnerNational"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input'
import OperationUnitForm from '../../../components/OperationUnitForm'
export default {
  name: 'BrandProfileDataNational',
  components: {
    CurrencyInput,
    OperationUnitForm
  },
  props: [],
  data: function () {
    return {
      checks: {
        ComParamAssocMemberOther: 0,
        ComParamMemberOther: 0
      }
    }
  },
  computed: {
    datenLand: function () {
      if (this.currentBrand.ComParamOriginCountryID) {
        return this.preloads.countries.filter(country => {
          return country.CountryID === this.currentBrand.ComParamOriginCountryID
        }).shift().Caption
      }
      return ''
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    },
    preloads: function () {
      return this.app.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    }
  },
  watch: {
    'app.hasCurrentBrand': function () {
      this.loadData()
    },
    'checks.ComParamAssocMemberOther': function (newVal) {
      const _this = this
      if (newVal === 0) {
        if (this.currentBrand.ComParamAssocMemberOther !== null) {
          if (this.currentBrand.ComParamAssocMemberOther.length !== 0) {
            this.$swal(this.$t('popupmessage.warn.attentiontitle'), 'Das zugehörige Feld muss erst geleert werden!', 'warning')
            setTimeout(function () {
              _this.checks.ComParamAssocMemberOther = 1
            }, 10)
          } else {
            this.currentBrand.ComParamAssocMemberOther = 0
          }
        } else {
          this.currentBrand.ComParamAssocMemberOther = 0
        }
      } else {
        if (this.currentBrand.ComParamAssocMemberOther === 0) {
          this.currentBrand.ComParamAssocMemberOther = ''
        }
      }
    },
    'checks.ComParamMemberOther': function (newVal) {
      const _this = this
      if (newVal === 0) {
        if (this.currentBrand.ComParamMemberOther !== null) {
          if (this.currentBrand.ComParamMemberOther.length !== 0) {
            this.$swal(this.$t('popupmessage.warn.attentiontitle'), 'Das zugehörige Feld muss erst geleert werden!', 'warning')
            setTimeout(function () {
              _this.checks.ComParamMemberOther = 1
            }, 10)
          } else {
            this.currentBrand.ComParamMemberOther = 0
          }
        } else {
          this.currentBrand.ComParamMemberOther = 0
        }
      } else {
        if (this.currentBrand.ComParamMemberOther === 0) {
          this.currentBrand.ComParamMemberOther = ''
        }
      }
    }
  },
  mounted: function () {
    this.loadData()
  },
  methods: {
    registerChange () {
      this.app.registerChangedContent(this.app.saveBrand)
    },
    loadData () {
      let testString = ''
      if (
        typeof this.currentBrand.ComParamAssocMemberOther !== 'undefined' &&
        this.currentBrand.ComParamAssocMemberOther !== null &&
        this.currentBrand.ComParamAssocMemberOther !== 0
      ) {
        testString = String(this.currentBrand.ComParamAssocMemberOther)
        if (testString.length > 0) {
          this.checks.ComParamAssocMemberOther = 1
        }
      }
      if (
        typeof this.currentBrand.ComParamMemberOther !== 'undefined' &&
        this.currentBrand.ComParamMemberOther !== null &&
        this.currentBrand.ComParamMemberOther !== 0
      ) {
        testString = String(this.currentBrand.ComParamMemberOther)
        if (testString.length > 0) {
          this.checks.ComParamMemberOther = 1
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
