import { render, staticRenderFns } from "./MatchScoreRate.vue?vue&type=template&id=799bf583&scoped=true"
import script from "./MatchScoreRate.vue?vue&type=script&lang=js"
export * from "./MatchScoreRate.vue?vue&type=script&lang=js"
import style0 from "./MatchScoreRate.vue?vue&type=style&index=0&id=799bf583&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "799bf583",
  null
  
)

export default component.exports