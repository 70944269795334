<template>
  <div class="mb-mtgld-showcase">
    <section class="mb-testimonials" id="mb-membership-testimonials">
      <div class="container">
        <h2 v-t="'testimonials.members.title'"></h2>
        <ul>
          <li v-for="(testimonial, index) in app.membershipTestimonials" :key="index">
            <testimonial :testimonial="testimonial"></testimonial>
          </li>
        </ul>
      </div>
      <div class="mb-brand-wall"></div>
    </section>
    <section class="mb-prices">
      <div class="container">
        <h2 class="mb-title" v-t="'page.membership.pricestitle'"></h2>
        <ul class="mb-price-list">
          <li>
            <div class="mb-price">
              <div class="mb-price-header">
                <img :src="require('../../assets/images/flags/de.svg')">
                <span>
                  <h3 v-t="'page.membership.membershiptitle'"></h3>
                  <span v-t="'country.de'"></span>
                </span>
              </div>
              <div class="mb-price-content">
                <div class="mb-price-from" v-html="$t('page.membership.pricestartsatde')"></div>
                <strong v-t="'page.membership.automaticleads'"></strong>
                <ul>
                  <li><i class="fa fa-check"></i><span v-html="$t('page.membership.virtualfairde')"></span></li>
                  <li><i class="fa fa-check"></i><span v-html="$t('page.membership.brandpageug')"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.komparator'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.brochuretointerest'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.memberarealeads'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.zapier'"></span><i class="fa fa-info-circle ms-2 h4 mb-zapier"></i></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.freerps'"></span></li>
                </ul>
                <div class="mb-small-present" v-if="$i18n.locale == 'de'"><i class="fa fa-gift"></i><span v-t="'page.membership.memberpresent'"></span></div>
                <strong v-t="'page.membership.addons'"></strong>
                <ul>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.chatbot'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.topbrandde'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.newsletter'"></span></li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div class="mb-price">
              <div class="mb-price-header">
                <img :src="require('../../assets/images/flags/at.svg')">
                <span>
                  <h3 v-t="'page.membership.membershiptitle'"></h3>
                  <span v-t="'country.at'"></span>
                </span>
              </div>
              <div class="mb-price-content">
                <div class="mb-price-from" v-html="$t('page.membership.pricestartsatat')"></div>
                <strong v-t="'page.membership.automaticleads'"></strong>
                <ul>
                  <li><i class="fa fa-check"></i><span v-html="$t('page.membership.virtualfairat')"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.komparator'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.brochuretointerest'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.memberarealeads'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.zapier'"></span><i class="fa fa-info-circle ms-2 h4 mb-zapier"></i></li>
                </ul>
                <div class="mb-small-present" v-if="$i18n.locale == 'de'"><i class="fa fa-gift"></i><span v-t="'page.membership.memberpresent'"></span></div>
                <strong v-t="'page.membership.addons'"></strong>
                <ul>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.chatbot'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.topbrandat'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.newsletter'"></span></li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div class="mb-price">
              <div class="mb-price-header">
                <img :src="require('../../assets/images/flags/ch.svg')">
                <span>
                  <h3 v-t="'page.membership.membershiptitle'"></h3>
                  <span v-t="'country.ch'"></span>
                </span>
              </div>
              <div class="mb-price-content">
                <div class="mb-price-from" v-html="$t('page.membership.pricestartsatch')"></div>
                <strong v-t="'page.membership.automaticleads'"></strong>
                <ul>
                  <li><i class="fa fa-check"></i><span v-html="$t('page.membership.virtualfairch')"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.komparator'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.brochuretointerest'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.memberarealeads'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.zapier'"></span><i class="fa fa-info-circle ms-2 h4 mb-zapier"></i></li>
                </ul>
                <div class="mb-small-present" v-if="$i18n.locale == 'de'"><i class="fa fa-gift"></i><span v-t="'page.membership.memberpresent'"></span></div>
                <strong v-t="'page.membership.addons'"></strong>
                <ul>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.chatbot'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.topbrandch'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.membership.newsletter'"></span></li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="mb-portal-info">
      <div class="container">
        <h2 v-t="'page.membership.howyougetnewpartner'"></h2>
        <div class="mb-poral-container">
          <div>
            <img :src="require('../../assets/images/fp-logo.jpg')">
            <strong>FranchisePORTAL:</strong>
            <div v-t="'page.membership.fpintro'"></div>
          </div>
          <div>
            <img :src="require('../../assets/images/ug-logo.png')">
            <strong>unternehmer-gesucht.com:</strong>
            <div v-t="'page.membership.ugintro'"></div>
          </div>
          <div>
            <img :src="require('../../assets/images/logo-komparator-farbig.png')">
            <strong>FranchiseKOMPARATOR:</strong>
            <div v-t="'page.membership.kompintro'"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="mb-howitworks">
      <span class="mb-triangle mb-tri-top"></span>
      <div class="container">
        <h2 v-t="'page.membership.howitworks.title'"></h2>
        <div class="mb-desc" v-html="$t('page.membership.howitworks.description')"></div>
        <img :src="require('../../assets/images/howitworks-desktop.png')" class="d-none d-md-block">
        <img :src="require('../../assets/images/howitworks-mobile.png')" class="d-md-none">
      </div>
    </section>
    <section class="mb-whatyouget">
      <div class="container">
        <h2 v-t="'page.membership.howitworks.automatedcontactreq'"></h2>
        <div class="card p-3 mb-5">
          <div class="mb-content-wrap">
            <strong v-t="'page.membership.howitworks.title'"></strong>
            <ul>
              <li><i class="fa fa-check"></i><span v-t="'page.membership.howitworks.addressdata'"></span></li>
              <li><i class="fa fa-check"></i><span v-t="'page.membership.howitworks.emailandphone'"></span></li>
              <li><i class="fa fa-check"></i><span v-t="'page.membership.howitworks.reachability'"></span></li>
              <li><i class="fa fa-check"></i><span v-t="'page.membership.howitworks.equidity'"></span></li>
              <li><i class="fa fa-check"></i><span v-t="'page.membership.howitworks.jobhistory'"></span></li>
              <li><i class="fa fa-check"></i><span v-t="'page.membership.howitworks.responsibilities'"></span></li>
              <li><i class="fa fa-check"></i><span v-t="'page.membership.howitworks.experience'"></span></li>
              <li><i class="fa fa-check"></i><span v-t="'page.membership.howitworks.furthertraining'"></span></li>
              <li><i class="fa fa-check"></i><span v-t="'page.membership.howitworks.concretisation'"></span></li>
              <li><i class="fa fa-check"></i><span v-t="'page.membership.howitworks.cv'"></span></li>
            </ul>
            <img :src="require('../../assets/images/papers.png')" class="d-none d-md-block">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Testimonial from '@/components/Testimonial'

export default {
  name: 'MembershipShowcase',
  props: [],
  components: {
    Testimonial
  },
  data: function () {
    return {
      observer: null,
      doScheduleMails: true
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    }
  },
  methods: {
    setWallOfFameImages () {
      const elmBrandWall = document.getElementsByClassName('mb-brand-wall')
      if (elmBrandWall.length === 0) {
        setTimeout(this.setWallOfFameImages, 1000)
        return
      }
      const brands = this.app.getSimilarBrandsBySpecialSector(this.app.currentBrand.SpecialSectorID, this.app.getPortalIDFromBrand(this.app.currentBrand))
      brands.then((response) => {
        const backgroundImages = []
        for (let index = 0; index <= 4; index++) {
          response.forEach(brand => {
            backgroundImages.push('url(' + this.app.getImage({ FileID: brand.Logo.FileID }) + '?size=xxs)')
          })
        }
        elmBrandWall[0].style.backgroundImage = backgroundImages.slice(0, 77).join(',')
      })
    },
    setPlannedMails (entries) {
      if (!this.doScheduleMails) return
      if (!this.currentBrand) return

      let endHere = false
      entries.forEach(entry => {
        endHere = !entry.isIntersecting
      })
      if (endHere) return

      this.doScheduleMails = false
      if (this.currentBrand.Membership === 3 || this.app.membershipPlaned) return
      this.app.getMailHistoryForMembership()
        .then(result => {
          if (result != null && Object.keys(result).length === 0) {
            this.app.getPlannedMailsForMembershipView()
              .then(result => {
                if (result != null && Object.keys(result).length === 0) {
                  this.app.scheduleMembershipMails()
                }
              })
          }
        })
    }
  },
  mounted: function () {
    setTimeout(this.setWallOfFameImages, 2000)

    if (this.app.currentBrand.Membership === 3 ||
        this.app.membershipPlaned ||
        (this.app.currentBrand.Company.CorrespondenceLanguageID != null &&
        this.app.currentBrand.Company.CorrespondenceLanguageID !== 2)) return

    const testimonials = document.getElementById('mb-membership-testimonials')
    const options = {
      root: null,
      threshold: 0.5,
      rootMargin: '0px'
    }

    this.observer = new IntersectionObserver(this.setPlannedMails, options)
    this.observer.observe(testimonials)
  },
  beforeDestroy () {
    if (this.observer && typeof this.observer.disconnect === 'function') {
      this.observer.disconnect()
    }
  }
}
</script>

<style lang="less">

</style>
