<template>
  <li class="nav-item">
    <router-link
      v-if="to"
      :to="computedLink"
      class="nav-link d-flex align-items-center justify-items-start"
    >
      <inline-svg
        :src="iconPath"
        :class="{'nav-link-image': true, 'fill-on-hover': fillOnHover}"
      />
      {{ $t(title) }}
    </router-link>
    <a v-else
      href="javascript:"
      class="nav-link d-flex align-items-center justify-items-start"
      @click="handleClick"
    >
      <inline-svg
        :src="iconPath"
        :class="{'nav-link-image': true, 'fill-on-hover': fillOnHover}"
      />
      {{ $t(title) }}
    </a>
  </li>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'SidebarLink',
  components: {
    InlineSvg
  },
  props: ['to', 'title', 'icon', 'fillOnHover'],
  computed: {
    computedLink () {
      return '/' + this.to.toLowerCase()
    },
    iconPath () {
      const iconName = this.icon || this.to
      try {
        return require(`@/assets/images/icons/3.0/${iconName}.svg`)
      } catch (err) {
        return require('@/assets/images/icons/3.0/none.svg')
      }
    }
  },
  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
</script>
