<template>
  <div>
    <div
      v-if="testimonial && testimonial.contact != null"
      class="d-flex justify-content-center"
    >
      <div
        v-bind="testimonial"
        v-if="testimonial && testimonial.contact != null"
        class="mb-testimonial"
      >
        <div class="mb-tbrand-imgs">
          <div class="mb-tbrand-logo">
            <img :src="testimonial.logo" :alt="testimonial.brandname" />
            <div class="mb-tbrand-contact">
              <img :src="testimonial.contact.img" :alt="testimonial.contact.name" />
            </div>
          </div>
        </div>
        <div class="mb-tbrand-name">
          <div class="mb-tbrand-company">{{testimonial.brandname}}</div>
          <div>{{testimonial.contact.name}}</div>
        </div>
        <div class="mb-testi-text" v-html="$t(testimonial.text)"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['testimonial'],
  data () {
    return {
    }
  },
  computed: {
  },
  mounted: function () {
  }
}
</script>
<style scoped>
</style>
