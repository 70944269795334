<template>
  <router-link class="latest-message" :to="computedLink">
    <div class="user-image">
      <img
        :src="computedPortrait"
        alt=""
      />
    </div>

    <div class="message">
      <div class="info">
        <span class="title">{{ computedName }}</span>
        <span>{{ computedDate }} | {{ computedTime }} Uhr</span>
      </div>
      <div class="content">
        <div class="inner">
          <div class="html" v-html="computedMessageContent"></div>
        </div>
        <span>{{ message.UnreadMessageCount }}</span>
      </div>
    </div>

  </router-link>
</template>

<script>

// TODO latestChatMessages

import moment from 'moment'

export default {
  name: 'LatestChatMessage',
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {}
  },
  computed: {
    computedMessageContent () {
      return this.message.Content.replace(/\n/g, '<br />')
    },
    computedLink () {
      return `/leads/detail/${this.message.LeadID}`
    },
    computedName () {
      return this.message.FromName
    },
    computedPortrait () {
      if (this.message.FromPortraitURL !== null) {
        return this.message.FromPortraitURL
      }
      return require('@/assets/images/user-none.png')
    },
    computedDate () {
      return moment(this.message.Created).format('DD.MM.YYYY')
    },
    computedTime () {
      return moment(this.message.Created).format('HH:mm')
    }
  },
  mounted: function () {},
  methods: {}
}

</script>

<style lang="scss" scoped>

.latest-message {

  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;

  text-decoration: none;

  .message {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span.title {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
      }

      span {
        color: #3F4957;
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      .inner {
        flex-grow: 1;

        height: 2.4em; /* Assuming line height is 1.2em, so 2 rows */
        overflow: hidden;
        position: relative;

        .html {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 1.2em;

          color: #3F4957;
          font-family: DM Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;

        }

        .unread {
          font-weight: 700;
        }

      }

      span {

        flex-shrink: 0;

        border-radius: 50%;
        background: #D82E4F;

        display: flex;
        width: 20px;
        height: 20px;
        flex-direction: column;
        justify-content: center;

        text-align: center;
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        color: #fff;

      }

    }

  }

}

.user-image {
    width: 60px;
    height: 60px;

    border-radius: 100px;
    border: 3px solid #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      object-fit: cover;
      border-radius: 100px;
    }
  }

</style>
