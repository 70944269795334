import Vue from 'vue'
import Router from 'vue-router'

// Containers
import Login from '@/views/Login'
import Logout from '@/views/Logout'
import PasswordReset from '@/views/PasswordReset'
import UnsubscribeMail from '@/views/UnsubscribeMail'
import Dashboard from '@/views/Dashboard'
import Register from '@/views/Register'
import MarkeAnlegen from '@/views/MarkeAnlegen'
import MarkeAnlegenDetails from '@/views/MarkeAnlegen_Details'
import MarkeAnlegenSuccess from '@/views/MarkeAnlegen_Success'
import Statistics from '@/views/Statistics'

import BrandSelection from '@/views/BrandSelection'
import Contacts from '@/views/Brand/Contacts'
import Talentpool from '@/views/Brand/Talentpool'
import Leads from '@/views/Brand/Leads'
import Present from '@/views/Present'

import SubscriptionView from '@/views/SubscriptionView'
import CreditHistoryView from '@/views/CreditHistoryView'

// import Profile from '../views/Brand/Profile'
import FairForm from '@/views/Brand/NewFair.vue'
import Infopaket from '@/views/Brand/Infopaket'
import AddressChange from '@/views/Brand/AddressChange'

import Membership from '@/views/Booking/Membership'
import TopBrand from '@/views/Booking/TopBrand'
import Newsletter from '@/views/Booking/Newsletter'
import Chatbot from '@/views/Booking/Chatbot'
import MembershipSuccess from '@/views/Booking/MembershipSuccess'
import TopBrandSuccess from '@/views/Booking/TopBrandSuccess'
import ChatbotSuccess from '@/views/Booking/ChatbotSuccess'

import RegionalPartnerSearch from '@/views/Brand/RegionalPartnerSearch'

import AddressSetup from '@/views/Booking/AddressSetup'
import AVVSetup from '@/views/Booking/AVVSetup'
import NewFairForm from '@/views/Brand/NewFairForm'

Vue.use(Router)

function isLoggedInUser () {
  const login = localStorage.getItem('FPMB_Login')
  return login !== null
}
function hasCurrentBrand () {
  return getCurrentBrand() !== null
}
function getCurrentBrand () {
  return localStorage.getItem('FPMB_CurrentBrandID')
}
function redirectToHashPage (currentBrand, hash) {
  if (hash != null && hash !== '') {
    MBRouter.app.$router.push(hash.replace('#', ''))
  } else {
    MBRouter.app.$router.push('/markeanlegendetails/all#' + currentBrand)
  }
}
const onlyLoggedInUserHook = (to, from, next) => {
  if (!isLoggedInUser()) {
    if (
      to.path.includes('neuer-messestand-2024') &&
      typeof to.params.brandId !== 'undefined'
    ) {
      const brandId = to.params.brandId
      localStorage.setItem('FPMB_NewFairScreenNext', brandId)
    }

    goToLogin(to, from, next)
  } else {
    next()
  }
}
const onlyLoggedInUserWithBrandHook = (to, from, next) => {
  if (!hasCurrentBrand()) {
    if (!isLoggedInUser()) {
      goToLogin(to, from, next)
    } else {
      next('/markenauswahl')
    }
  } else {
    next()
  }
}
const onlyLoggedOutUsersHook = (to, from, next) => {
  if (isLoggedInUser()) {
    if (hasCurrentBrand()) {
      next('/dashboard')
    } else {
      next('/markenauswahl')
    }
  } else {
    next()
  }
}

function goToLogin (to, from, next) {
  let brandLogin = ''
  const currentBrand = to.hash || getCurrentBrand()
  if (currentBrand != null && currentBrand !== '') brandLogin = '/' + currentBrand.replace('#', '') + '#' + to.path
  next('/login' + brandLogin)
}

const MBRouter = new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [
    { path: '*', redirect: '/dashboard' },
    {
      path: '/login/:id?',
      name: 'Login',
      component: Login,
      meta: {
        sidebar: false,
        bodyClass: 'mb-login'
      },
      beforeEnter: onlyLoggedOutUsersHook
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
      beforeEnter: onlyLoggedInUserHook
    },
    {
      path: '/login/reset/:id',
      name: 'Passwort zurücksetzen',
      component: PasswordReset,
      meta: {
        sidebar: false,
        bodyClass: 'mb-login'
      }
    },
    {
      path: '/unsubscribemail/:id',
      name: 'Mailabfolge abmelden',
      component: UnsubscribeMail,
      meta: {
        sidebar: false,
        bodyClass: 'mb-login'
      }
    },
    {
      path: '/register',
      name: 'Registrierung',
      component: Register,
      meta: {
        sidebar: false,
        bodyClass: 'mb-site-register'
      },
      beforeEnter: onlyLoggedOutUsersHook
    },
    {
      path: '/markeanlegen',
      name: 'Neue Marke anlegen',
      component: MarkeAnlegen,
      meta: {
        sidebar: false,
        bodyClass: 'mb-site-createbrand'
      },
      beforeEnter: onlyLoggedInUserHook
    },
    {
      path: '/markeanlegendetails/:all?',
      name: 'marke-anlegen-details',
      component: MarkeAnlegenDetails,
      meta: {
        sidebar: false,
        bodyClass: 'mb-site-createbrand'
      },
      beforeEnter: onlyLoggedInUserHook
    },
    {
      path: '/markeanlegensuccess',
      name: 'Neue Marke anlegen - Fertig',
      component: MarkeAnlegenSuccess,
      meta: {
        sidebar: false,
        bodyClass: 'mb-site-createbrand-success'
      },
      beforeEnter: onlyLoggedInUserHook
    },
    {
      path: '/markenauswahl',
      name: 'Markenauswahl',
      component: BrandSelection,
      meta: {
        sidebar: false,
        bodyClass: 'mb-login'
      },
      beforeEnter: onlyLoggedInUserHook
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        sidebar: true
      },
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/zahlungsdaten-hinterlegen',
      name: 'AddressSetup',
      component: AddressSetup,
      meta: {
        sidebar: false,
        global: true
      },
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/avv-unterzeichnen',
      name: 'AVVSetup',
      component: AVVSetup,
      meta: {
        sidebar: false,
        global: true
      }
    },
    {
      path: '/neuer-messestand-2024/:brandId',
      name: 'new-fair-form',
      component: NewFairForm,
      meta: {
        sidebar: false,
        mainApp: false
      },
      beforeEnter: onlyLoggedInUserHook
    },
    {
      path: '/mitgliedschaft-buchen',
      name: 'Membership',
      component: Membership,
      meta: {
        sidebar: true
      },
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/mitgliedschaft-buchen-erfolgreich',
      name: 'MembershipSuccess',
      component: MembershipSuccess,
      meta: {
        sidebar: false,
        bodyClass: 'mb-area_mb-booking-success'
      },
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/topmarke-buchen',
      name: 'TopBrandBooking',
      component: TopBrand,
      meta: {
        sidebar: true
      },
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/topmarke-buchen-erfolgreich',
      name: 'TopBrandSuccess',
      component: TopBrandSuccess,
      meta: {
        sidebar: false,
        bodyClass: 'mb-area_mb-booking-success'
      },
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/newsletter-buchen',
      name: 'NewsletterBooking',
      component: Newsletter,
      meta: {
        sidebar: true
      },
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/chatbot-buchen',
      name: 'ChatbotBooking',
      component: Chatbot,
      meta: {
        sidebar: true
      },
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/chatbot-buchen-erfolgreich',
      name: 'ChatbotSuccess',
      component: ChatbotSuccess,
      meta: {
        sidebar: false,
        bodyClass: 'mb-area_mb-booking-success'
      },
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/leads',
      name: 'Leads',
      component: Leads,
      children: [
        {
          path: 'detail/:id',
          name: 'leadDetail',
          component: { render: h => h('router-view') }
        }
      ]
    },
    {
      path: '/talentpool',
      name: 'Talentpool',
      component: Talentpool,
      children: [
        {
          path: 'detail/:id',
          name: 'talentpoolDetail',
          component: { render: h => h('router-view') }
        }
      ]
    },
    {
      path: '/statistics',
      name: 'Statistiken',
      component: Statistics,
      meta: {
        sidebar: true
      },
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/profil',
      name: 'Markenprofil',
      component: FairForm,
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/infopaket',
      name: 'Infopaket',
      component: Infopaket,
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/adresse-aendern',
      name: 'AddressChange',
      component: AddressChange,
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/team',
      name: 'Team',
      component: Contacts,
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/rps',
      name: 'Regionale Partnersuche',
      component: RegionalPartnerSearch,
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/geschenk',
      name: 'Geschenk',
      component: Present,
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/abos',
      name: 'Abrechnung / Abos',
      component: SubscriptionView,
      beforeEnter: onlyLoggedInUserWithBrandHook
    },
    {
      path: '/credits',
      name: 'CreditHistory',
      component: CreditHistoryView,
      beforeEnter: onlyLoggedInUserWithBrandHook
    }
  ]
})

MBRouter.beforeEach((to, from, next) => {
  if (typeof to.params.LeadID !== 'undefined' && typeof to.params.BrandID !== 'undefined') {
    localStorage.setItem('_DELAYED_LEADID', JSON.stringify({
      BrandID: to.params.BrandID,
      LeadID: to.params.LeadID
    }))
  }
  next()
})

MBRouter.beforeEach((to, from, next) => {
  if (MBRouter.app.$children.length) {
    const app = MBRouter.app.$children[0]
    if (app.changesNotSaved === true) {
      return app.getUnloadPrompt().then(result => {
        if (typeof result.isConfirmed !== 'undefined' && result.isConfirmed === true) {
          app.saveChanges()
        }

        app.resetRegisteredChanges()
        next()
      })
    }

    if (
      to.path !== '/markeanlegendetails' &&
      to.path !== '/markenauswahl' &&
      to.path !== '/logout' &&
      app.currentBrand != null &&
      (
        (app.currentBrand.Membership < 2 ||
        app.currentBrand.StatusID !== 5) &&
        (
          app.currentBrand.PflichfeldStatusID < 1 || // Vollständig oder in Prüfung
          app.currentBrand.PflichfeldStatusID == null ||
          app.checkPflichtfeldStatus().counter > 0
        )
      )
    ) {
      next({
        path: '/markeanlegendetails#' + app.currentBrand.BrandID,
        name: 'marke-anlegen-details',
        component: MarkeAnlegenDetails,
        meta: {
          sidebar: false,
          bodyClass: 'mb-site-createbrand'
        },
        beforeEnter: onlyLoggedInUserHook
      })
    } else {
      next()
      if (app.Tour != null && app.Tour.tour.isActive() && to.path !== '/dashboard') {
        setTimeout(() => {
          app.Tour.nextStep()
        }, 1000)
      }
    }
  } else {
    if (to.name === 'Login' && to.params != null && to.params.id != null &&
      isLoggedInUser() && hasCurrentBrand()) {
      const currentBrand = getCurrentBrand()
      redirectToHashPage(currentBrand, to.hash)
    } else next()
  }
})

export default MBRouter
