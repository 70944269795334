<template>
  <div v-if="hasCurrentBrand">
    <div class="mb-main-content mb-present-content">
      <div
        v-if="app.giftAvailable()"
        class="mb-gift"
        >
        <div class="container">
          <div class="row mb-5">
            <div class="col-12">
                <h1 class="mb-title">Ihr Geschenk:
                  <div class="mt-1">Wir produzieren für Sie ein kostenfreies Präsentationsvideo!</div>
                </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 d-none d-lg-block mb-trailer-tablet">
              <img :src="require('../assets/images/trailer-tablet.png')">
            </div>
            <div class="col-lg-6">
              <h1>Worum geht es?</h1>
              <div class="mt-4 mb-present-desc">
                  <p>Sie erhalten von uns ein <strong>kostenfreies Präsentationsvideo</strong>, in dem Ihr Franchise- bzw. Lizenzkonzept in ca. 60 Sekunden erläutert wird.</p>
                  <p>Dieser &bdquo;60-Sekunden-Trailer&ldquo; wird im FranchisePORTAL überall dort angezeigt, wo das Logo Ihres Systems auftaucht. Außerdem wird es <strong>im Multimedia-Bereich Ihres Messestandes</strong> veröffentlicht.</p>
                  <p>Ziel des Videos ist es, den Franchise-Interessenten einen <strong>schnellen Überblick Ihres Angebotes</strong> zu verschaffen und sie zur Kontaktaufnahme zu motivieren.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-white-bg">
        <div class="container pt-5 pb-5">
          <div class="row mt-5 mb-5">
            <div class="col-lg-5">
              <h2 class="mb-5">Wie sieht ein 60-Sekunden-Trailer aus?</h2>
              <p>Die sehr <strong>kompakte</strong> und <strong>standardisierte Darstellung</strong> Ihres Geschäftskonzepts ermöglicht Interessenten Ihre Marke besonders <strong>schnell und komfortabel</strong> zu erfassen.</p>
              <p>Das beste: Sie erhalten einen <strong>Youtube-Link</strong>, den Sie z.B. in <strong>Ihrer Webseite</strong> oder in Ihren <strong>Social Media</strong> Kanälen verwenden können.</p>
            </div>
            <div class="col-lg-7">
              <iframe
                id="ytplayer"
                type="text/html"
                src="https://www.youtube-nocookie.com/embed/hwuYXW2l2OE"
                frameborder="0"
                allowfullscreen="allowfullscreen"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="container pt-5">
        <div class="row mt-5">
          <div class="col-lg-5">
            <h2 class="mb-5">Holen Sie sich Ihren 60-Sekunden-Trailer!</h2>
            <p>So kann Ihr Geschenk ein echter Freudenbringer werden - für Sie und für Ihre Interessenten:</p>
            <p><strong>Zeigen Sie den Interessenten Ihre Zukunft.</strong><br>(Den Alltag Ihrer Franchisepartner)</p>
            <p><strong>Anforderungen:</strong>
              <ul>
                <li>5 oder 8 Bilder</li>
                <li>Querformat, mind. 1200px Breite<br>(Ideal: 1920 x 1080px)</li>
                <li>JPG- oder PNG-Dateiformat</li>
                <li>Ohne Text im Bild</li>
              </ul>
            </p>
          </div>
          <div class="col-lg-7 card shadow p-3 mb-5 bg-white">
            <iframe
              v-if="frameUrl != ''"
              class="airtable-embed airtable-dynamic-height"
              :src="frameUrl"
              frameborder="0"
              onmousewheel=""
              width="100%"
              height="783"
              style="background: transparent; border: 1px solid #ccc;"
            >
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Present',
  props: [],
  data: function () {
    return {
      frameUrl: ''
    }
  },
  computed: {
    hasCurrentBrand () {
      return typeof this.currentBrand.BrandID !== 'undefined'
    },
    currentBrand () {
      const current = this.$parent.currentBrand || {}
      return current
    },
    isActiveMember () {
      return this.app.isActiveMember
    },
    currentBrandSlug () {
      return (
        this.app.getSlug(this.currentBrand.Name, {
          lang: 'de',
          custom: {
            '+': ''
          }
        }) +
        '-m-' +
        this.currentBrand.BrandID
      )
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    currentMembership: function () {
      return this.app.currentMembership || {}
    },
    showGiftPanel: function () {
      return this.app.hasActiveMembership && localStorage.getItem('FPMB_hideGift') !== 'true'
    }
  },
  created: function () {
    if (!this.hasCurrentBrand) {
      this.$router.push('/markenauswahl')
    }
  },
  methods: {
    getRndTestimonial () {
      const testimonials = [{
        brandname: 'datarev',
        logo: this.app.getImage({ FileID: 169962710 }),
        contact: {
          img: this.app.getImage({ FileID: 936732583 }),
          name: 'Jürgen Recha'
        },
        text: '“Ein preislich sehr faires Angebot! Das hat mich absolut begeistert: Professionelles Personal mit Tipps was man verbessern kann. Das ist echt WOW, das ist genial und besonders! Direkt bei Onlinegang kamen die ersten Bewerbungen. Wir stehen kurz vor dem Abschluss für 2 neue Franchisenehmer.”'
      },
      {
        brandname: 'FT-Club',
        logo: this.app.getImage({ FileID: 441451285 }),
        contact: {
          img: this.app.getImage({ FileID: 642170974 }),
          name: 'Jessica Mumdey'
        },
        text: '“Ich bin sehr zufrieden, denn wir erhalten regelmäßig gute Leads und haben bereits einige neue Partner gewonnen! Der Support vom Team ist toll und man ist durch den interessanten Newsletter, die zahlreichen Franchise-spezifischen Fachartikel und News aus der Branche immer auf dem neuesten Stand! Man erhält einen tollen Blick über den gesamten Franchise Markt.”'
      },
      {
        brandname: 'Mail Boxes Etc.',
        logo: this.app.getImage({ FileID: 603601090 }),
        contact: {
          img: this.app.getImage({ FileID: 739920273 }),
          name: 'Ute Petrenko, Eik Briege, Silvia Kachel'
        },
        text: '“Professionelle, innovative Betreuung, hohe Serviceorientierung und Qualität der Bewerber machen FranchisePORTAL für uns zu einem wichtigen Partner.”'
      }]
      return testimonials[Math.floor(Math.random() * testimonials.length)]
    }
  },
  watch: {
    '$route' (to, from) {
      if (to.path === '/geschenk') {
        this.testimonial = this.getRndTestimonial()
      }
      if (from.path === '/geschenk') {
        this.app.loadBrand(this.app.currentBrand.BrandID)
      }
    },
    'app.hasCurrentBrand': function () {
      this.frameUrl = 'https://airtable.com/embed/shrA0N8a72e9sjHr6?backgroundColor=cyan&prefill_Verschlüsselte+BrandID=' + this.app.maskedBrandID() + '&prefill_Email+AP+der+Marke=' + this.app.currentContact.Mail
    }
  },
  mounted: function () {
    this.testimonial = this.getRndTestimonial()
    const externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'https://static.airtable.com/js/embed/embed_snippet_v1.js')
    document.head.appendChild(externalScript)
    this.frameUrl = 'https://airtable.com/embed/shrA0N8a72e9sjHr6?backgroundColor=cyan&prefill_Verschlüsselte+BrandID=' + this.app.maskedBrandID() + '&prefill_Email+AP+der+Marke=' + this.app.currentContact.Mail
  }
}
</script>

<style lang="less" scoped>
.mb-first-container {
  margin-top: 80px;
}
.dashboard-logo {
  max-width: 350px;
}
.blueCard {
  background-color: #019FE0;
  border-radius: 10px;
  display: block;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 1rem;
}
.blueCard span {
  text-transform: uppercase;
  font-weight: bold;
}
</style>
