<template>
  <div>
    <money
      v-model="computedValue"
      class="percentage-input"
      v-bind="money"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { Money } from 'v-money'

export default {
  components: { Money },
  props: ['value', 'disabled'],
  data () {
    return {
      price: this.value || 0,
      money: {
        decimal: ',',
        suffix: ' %',
        precision: 2,
        masked: false
      }
    }
  },
  computed: {
    computedValue: {
      get: function () {
        return this.value || 0
      },
      set: function (newValue) {
        return this.$emit('input', newValue)
      }
    }
  },
  watch: {
    computedValue: function (newValue, oldValue) {
      this.$emit('change')
    }
  }
}
</script>
<style scoped>
.percentage-input {
  width: 80px;
}
</style>
