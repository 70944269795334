<template>
    <div
      class="App-Container App-Container--setupMode flex-container justify-content-center"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"></loading>
      <div
        class="App-Background"
        style="background-color: rgb(0, 85, 120)"
      ></div>
      <div class="App App--singleItem">
        <div class="App-Overview is-darkBackground">
          <header
            class="Header is-darkBackground"
            style="background-color: rgb(0, 85, 120)"
          >
            <div
              class="Header-Content flex-container justify-content-space-between align-items-stretch"
            >
              <div
                class="Header-business flex-item width-grow flex-container align-items-center"
              >
                <a
                  class="Link Header-businessLink Link--primary"
                  :href="computedBackLink"
                  aria-label="Vorherige Seite"
                  title="FranchisePORTAL GmbH"
                  ><div style="position: relative">
                    <div class="flex-container align-items-center">
                      <div
                        class="Header-backArrowContainer"
                        style="opacity: 1; transform: none"
                      >
                        <svg
                          class="InlineSVG Icon Header-backArrow mr2 Icon--sm"
                          focusable="false"
                          width="12"
                          height="12"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M3.417 7H15a1 1 0 0 1 0 2H3.417l4.591 4.591a1 1 0 0 1-1.415 1.416l-6.3-6.3a1 1 0 0 1 0-1.414l6.3-6.3A1 1 0 0 1 8.008 2.41z"
                            fill-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <div
                        class="Header-merchantLogoContainer"
                        style="transform: none"
                      >
                        <div
                          class="Header-merchantLogoWithLabel flex-item width-grow"
                        >
                          <div
                            class="HeaderImage HeaderImage--logo flex-item width-fixed flex-container justify-content-center align-items-center width-fixed"
                          >
                            <img
                              alt="FranchisePORTAL GmbH logo"
                              src="https://stripe-camo.global.ssl.fastly.net/aba52fe0aa23a69573e7b342a4a8a24fba22d82c5cd4ab366a890174c7f4dc21/68747470733a2f2f66696c65732e7374726970652e636f6d2f66696c65732f4d44423859574e6a6446387853556c6861304a4253554a364f48706a4e44647666475a6662476c325a5639455745383151336454566e704b63446c724d4574614f48707a53564a4e566d5130304935425a3330306b"
                              class="HeaderImage-img"
                            />
                          </div>
                          <span
                            class="Header-businessLink-label Text Text-color--gray800 Text-fontSize--14 Text-fontWeight--500"
                            v-t="'page.signavv.back'"></span
                          >
                        </div>
                      </div>
                    </div>
                  </div></a
                >
                <div class="mx2 flex-item width-fixed" v-if="!isProduction">
                  <div class="Tag Header-testTag Tag-orange">
                    <span
                      class="Text Text-color--orange Text-fontSize--11 Text-fontWeight--700 Text-transform--uppercase"
                      >Test Mode</span
                    >
                  </div>
                  <div class="Tag Header-testTagMobile Tag-orange">
                    <span
                      class="Text Text-color--orange Text-fontSize--11 Text-fontWeight--700 Text-transform--uppercase"
                      >Test</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div class="App-Payment">
          <div class="CheckoutPaymentForm">
            <form @submit.prevent="submitAddressForm">
              <div class="Tabs is-icontabs is-desktop">
                <div class="Tabs-TabPanelContainer">
                  <div style="opacity: 1; width: 100%; transform: none">
                    <div
                      id="card-tab-panel"
                      role="tabpanel"
                      aria-labelledby="card-tab"
                    >
                      <div
                        class="flex-container spacing-16 direction-row wrap-wrap"
                      >
                        <div
                          class="billing-container flex-item width-12"
                          aria-hidden="false"
                        >
                          <div
                            style="
                              pointer-events: auto;
                              height: auto;
                              opacity: 1;
                            "
                          >
                            <div
                              class="flex-container spacing-16 direction-row wrap-wrap"
                            >

                              <div class="flex-item width-12">
                                <h4 v-t="'page.signavv.title'"></h4>
                                <span>{{currentBrand.Name}}</span>
                              </div>

                              <div class="flex-item width-12">
                                <div class="FormFieldGroup">
                                  <div
                                    class="FormFieldGroup-labelContainer flex-container justify-content-space-between"
                                  >
                                    <label for="billingName"
                                      ><span
                                        class="Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500"
                                        v-t="'page.signavv.signer'"></span
                                      ></label
                                    >
                                  </div>
                                  <div class="FormFieldGroup-Fieldset">
                                    <div
                                      class="FormFieldGroup-container"
                                      id="billingName-fieldset"
                                    >
                                      <div
                                        class="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight FormFieldGroup-childTop FormFieldGroup-childBottom"
                                      >
                                        <div class="FormFieldInput">
                                          <span
                                            class="InputContainer"
                                            data-max=""
                                            ><input
                                              class="CheckoutInput Input Input--empty"
                                              autocorrect="off"
                                              spellcheck="false"
                                              id="billingName"
                                              name="billingName"
                                              type="text"
                                              aria-invalid="false"
                                              v-model="FormData.Name"
                                              required
                                          /></span>
                                        </div>
                                      </div>
                                      <div style="opacity: 0; height: 0px">
                                        <span
                                          class="FieldError Text Text-color--red Text-fontSize--13"
                                          ><span aria-hidden="true"></span
                                        ></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="flex-item width-12">
                                <div class="FormFieldGroup">
                                  <div
                                    class="FormFieldGroup-labelContainer flex-container justify-content-space-between"
                                  >
                                    <label for="billingName"
                                      ><span
                                        class="Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500"
                                        v-t="'page.signavv.mail'"></span
                                      ></label
                                    >
                                  </div>
                                  <div class="FormFieldGroup-Fieldset">
                                    <div
                                      class="FormFieldGroup-container"
                                      id="billingName-fieldset"
                                    >
                                      <div
                                        class="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight FormFieldGroup-childTop FormFieldGroup-childBottom"
                                      >
                                        <div class="FormFieldInput">
                                          <span
                                            class="InputContainer"
                                            data-max=""
                                            ><input
                                              class="CheckoutInput Input Input--empty"
                                              autocorrect="off"
                                              spellcheck="false"
                                              id="billingName"
                                              name="billingName"
                                              type="text"
                                              aria-invalid="false"
                                              v-model="FormData.Mail"
                                              required
                                          /></span>
                                        </div>
                                      </div>
                                      <div style="opacity: 0; height: 0px">
                                        <span
                                          class="FieldError Text Text-color--red Text-fontSize--13"
                                          ><span aria-hidden="true"></span
                                        ></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div class="flex-item width-12"></div>
                        <div class="flex-item width-12">
                          <button
                            class="SubmitButton SubmitButton--incomplete"
                            type="submit"
                            style="
                              background-color: rgb(6, 208, 117);
                              color: rgb(0, 0, 0);
                            "
                          >
                            <div
                              class="SubmitButton-Shimmer"
                              style="
                                background: linear-gradient(
                                  to right,
                                  rgba(6, 208, 117, 0) 0%,
                                  rgb(64, 234, 141) 50%,
                                  rgba(6, 208, 117, 0) 100%
                                );
                              "
                            ></div>
                            <div class="SubmitButton-TextContainer">
                              <span
                                class="SubmitButton-Text SubmitButton-Text--current Text Text-color--default Text-fontWeight--500 Text--truncate"
                                aria-hidden="false"
                                ><i class="fa fa-lock me-2"></i> {{ $t('page.signavv.continue') }}</span>
                            </div>
                            <div class="SubmitButton-IconContainer">
                              <div
                                class="SubmitButton-Icon SubmitButton-Icon--pre"
                              >
                                <div class="Icon Icon--md Icon--square">
                                  <svg
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                    focusable="false"
                                  >
                                    <path
                                      d="M3 7V5a5 5 0 1 1 10 0v2h.5a1 1 0 0 1 1 1v6a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V8a1 1 0 0 1 1-1zm5 2.5a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1zM11 7V5a3 3 0 1 0-6 0v2z"
                                      fill="#000000"
                                      fill-rule="evenodd"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                              <div
                                class="SubmitButton-Icon SubmitButton-SpinnerIcon SubmitButton-Icon--pre"
                              >
                                <div class="Icon Icon--md Icon--square">
                                  <svg
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    focusable="false"
                                  >
                                    <ellipse
                                      cx="12"
                                      cy="12"
                                      rx="10"
                                      ry="10"
                                      style="stroke: rgb(0, 0, 0)"
                                    ></ellipse>
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div class="SubmitButton-CheckmarkIcon">
                              <div class="Icon Icon--md">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="14"
                                  focusable="false"
                                >
                                  <path
                                    d="M 0.5 6 L 8 13.5 L 21.5 0"
                                    fill="transparent"
                                    stroke-width="2"
                                    stroke="#000000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Config from '../../config/index'
// import Product from '../../components/CustomProductContainer'
// import CustomCheckbox from '../../components/CustomCheckboxContainer'
// import MembershipShowcase from './MembershipShowcase'
import Loading from 'vue-loading-overlay'

export default {
  name: 'AVVSetup',
  props: [],
  components: {
    Loading
  },
  data: function () {
    return {
      FormData: {
        Name: '',
        Mail: ''
      },
      isLoading: false
    }
  },
  computed: {
    isProduction () {
      return Config.PRODUCTION
    },
    isStripeCustomer () {
      return this.currentBrand.StripeCustomerID !== null && this.currentMembership.Checkout !== null
    },
    hasCurrentBrand () {
      return typeof this.currentBrand.BrandID !== 'undefined'
    },
    currentBrand () {
      const current = this.$parent.currentBrand || {}
      return current
    },
    currentBrandSlug () {
      return (
        this.app.getSlug(this.currentBrand.Name, {
          lang: 'de',
          custom: {
            '+': ''
          }
        }) +
        '-m-' +
        this.currentBrand.BrandID
      )
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentMembership: function () {
      return this.app.currentMembership || {}
    },
    enabledVatInput: function () {
      const currentCountryID = parseInt(this.FormData.Country)
      if (currentCountryID && currentCountryID !== 1) {
        return true
      }
      return false
    },
    computedBackLink: () => {
      return `/${localStorage.getItem('returnLocationAfterAVVSetup')}`
    }
  },
  created: function () {},
  methods: {
    startLoader () {
      this.isLoading = true
    },
    stopLoader () {
      this.isLoading = false
    },
    submitAddressForm () {
      this.startLoader()

      const requestData = {
        BrandID: this.currentBrand.BrandID,
        ...this.FormData,
        ReturnLocation: this.computedBackLink
      }

      this.app.APIStripe._post('createAVVLink', requestData).then(result => {
        const body = result.body
        if (body.Error === false && typeof body.Link !== 'undefined') {
          window.location.href = body.Link
        } else {
          console.error(result)
          this.stopLoader()
          this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.occurred'), 'error')
        }
      }).catch(error => {
        console.error(error)
        this.stopLoader()
        this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.occurred'), 'error')
      })

      return false
    }
  },
  mounted: function () {
  }
}
</script>

<style lang="less" scoped>
.left-side {
  background-image: url("../../assets/images/left-gradient-bg.png");
}
.radius-bottom {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.radius-top {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
small {
  font-size: 70%;
  color: rgba(26, 26, 26, 0.5)
}

/*! CSS Used from: https://js.stripe.com/v3/fingerprinted/css/checkout-dbc6d1df2d338bf872fa6fbf3f78634c.css */
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
a {
  background-color: transparent;
}
img {
  border-style: none;
}
button,
input,
select {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type="button"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
[hidden] {
  display: none;
}
.mr2 {
  margin-right: 8px;
}
.mx2 {
  margin-left: 8px;
}
.mx2 {
  margin-right: 8px;
}
* {
  box-sizing: border-box;
}
html {
  line-height: 1.3;
}
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  color: rgba(26, 26, 26, 0.9);
  -webkit-font-smoothing: antialiased;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
input {
  -webkit-animation: native-autofill-out 1ms;
}
/*! CSS Used from: https://js.stripe.com/v3/fingerprinted/css/checkout-app-init-3b6732e61983210d31aac188a1684493.css */
.Text {
  margin: 0;
}
.Text-fontSize--11 {
  font-size: 11px;
}
.Text-fontSize--12 {
  font-size: 12px;
}
.Text-fontSize--13 {
  font-size: 13px;
}
.Text-fontSize--14 {
  font-size: 14px;
}
.Text-fontWeight--400 {
  font-weight: 400;
}
.Text-fontWeight--500 {
  font-weight: 500;
}
.Text-fontWeight--700 {
  font-weight: 700;
}
.Text-color--gray400 {
  color: rgba(26, 26, 26, 0.5);
}
.Text-color--gray500 {
  color: rgba(26, 26, 26, 0.6);
}
.Text-color--gray600 {
  color: rgba(26, 26, 26, 0.7);
}
.Text-color--gray800 {
  color: rgba(26, 26, 26, 0.9);
}
.App-Overview.is-darkBackground .Text-color--gray800 {
  color: hsla(0, 0%, 100%, 0.9);
}
.Text-color--red {
  color: #dc2727;
}
.Text-color--orange {
  color: #bb5504;
}
.Text-transform--uppercase {
  text-transform: uppercase;
}
.Text--truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.flex-container {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.flex-item {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  min-width: 0;
  max-width: 100%;
}
.flex-item:empty {
  display: none;
}
.direction-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.spacing-16 {
  margin: -8px;
}
.spacing-16 > .flex-item {
  padding: 8px;
}
.justify-content-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-content-space-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.align-items-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.align-items-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.wrap-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.width-fixed {
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.width-fixed {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.width-grow {
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.width-12 {
  width: 100%;
}
.Icon--sm {
  height: 12px;
}
.Icon--md {
  height: 16px;
}
.Icon--md.Icon--square {
  width: 16px;
}
.HeaderImage--logo .HeaderImage-img {
  max-width: 150px;
  max-height: 28px;
  margin-right: 8px;
}
.App-Container--setupMode .HeaderImage--logo .HeaderImage-img {
  max-height: 35px;
}
.Link {
  text-decoration: none;
  cursor: pointer;
}
.Link:focus {
  outline: 1px dotted rgba(26, 26, 26, 0.5);
}
.Link--primary {
  color: #0074d4;
}
.Button {
  border: 1px solid transparent;
  border-radius: 6px;
  transition: background-color 0.1s ease-in, -webkit-transform 0.08s ease-in;
  transition: background-color 0.1s ease-in, transform 0.08s ease-in;
  transition: background-color 0.1s ease-in, transform 0.08s ease-in,
    -webkit-transform 0.08s ease-in;
  cursor: pointer;
}
.Button--md {
  font-size: 14px;
  padding: 8px 16px;
}
.Button--link {
  padding: 0;
  color: #0074d4;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 0;
  text-decoration-skip-ink: none;
  -webkit-tap-highlight-color: transparent;
}
.Tag {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 2px 4px;
  border-radius: 4px;
}
.Tag-orange {
  background-color: #ffde92;
}
.InputContainer {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}
.InputContainer:after {
  height: 0;
  visibility: hidden;
  content: attr(data-max);
}
.CheckoutInput {
  position: relative;
  width: 100%;
  height: 44px;
  padding: 8px 12px;
  color: rgba(26, 26, 26, 0.9);
  font-size: 16px;
  line-height: 1.5;
  border: 0;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.08s ease-in, color 0.08s ease-in,
    -webkit-filter 50000s;
  transition: box-shadow 0.08s ease-in, color 0.08s ease-in, filter 50000s;
  transition: box-shadow 0.08s ease-in, color 0.08s ease-in, filter 50000s,
    -webkit-filter 50000s;
  background: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.CheckoutInput::-moz-placeholder,
.CheckoutInput::-webkit-input-placeholder {
  color: rgba(26, 26, 26, 0.4);
}
.CheckoutInput::-moz-placeholder,
.CheckoutInput::-webkit-input-placeholder,
.CheckoutInput::placeholder {
  color: rgba(26, 26, 26, 0.4);
}
.CheckoutInput:focus {
  z-index: 2;
  outline: none;
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 0 0 4px rgba(50, 151, 211, 0.3);
}
.CheckoutInput:disabled {
  color: rgba(26, 26, 26, 0.4);
  transition: background-color 100000000s, box-shadow 0s linear;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.05), inset 0 0 0 1000px rgba(26, 26, 26, 0.1);
}
.CheckoutInput::-ms-clear {
  display: none;
}
.Header {
  z-index: 12;
  min-height: 28px;
}
.Header-businessLink {
  display: block;
  margin-left: -24px;
  padding-left: 24px;
}
.Header-business .Header-backArrow {
  opacity: 0.4;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: opacity 0.25s ease, width 0.25s ease, -webkit-transform 0.25s ease;
  transition: opacity 0.25s ease, transform 0.25s ease, width 0.25s ease;
  transition: opacity 0.25s ease, transform 0.25s ease, width 0.25s ease,
    -webkit-transform 0.25s ease;
}
.Header-business .Header-backArrowContainer,
.Header-business .Header-merchantLogoContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.App-Overview.is-darkBackground .Header-business .Header-backArrow {
  fill: #fff;
}
.Header-businessLink:focus .Header-backArrow,
.Header-businessLink:hover .Header-backArrow {
  opacity: 1;
  -webkit-transform: translateX(-2px);
  transform: translateX(-2px);
  -ms-transform: translateX(4px);
}
.Header-business .Header-businessLink-label {
  opacity: 0;
  position: absolute;
  left: 0;
  -webkit-transform: translateX(4px);
  transform: translateX(4px);
  -ms-transform: translateX(4px) translateY(-50%);
  transition: opacity 0.1s ease 0ms, -webkit-transform 0.1s ease 0ms;
  transition: opacity 0.1s ease 0ms, transform 0.1s ease 0ms;
  transition: opacity 0.1s ease 0ms, transform 0.1s ease 0ms,
    -webkit-transform 0.1s ease 0ms;
}
@media only screen and (min-width: 576px) {
  .Header-business .Header-backArrow {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    -ms-transform: translateX(-20px) translateY(-50%);
    position: absolute;
  }
  .Header-businessLink:focus .Header-backArrow,
  .Header-businessLink:hover .Header-backArrow {
    -webkit-transform: translateX(-22px);
    transform: translateX(-22px);
    -ms-transform: translateX(-22px) translateY(-50%);
  }
  .Header-businessLink:focus .Header-businessLink-label,
  .Header-businessLink:hover .Header-businessLink-label {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -ms-transform: translateY(-50%);
    transition: opacity 0.25s ease 50ms, -webkit-transform 0.25s ease 50ms;
    transition: opacity 0.25s ease 50ms, transform 0.25s ease 50ms;
    transition: opacity 0.25s ease 50ms, transform 0.25s ease 50ms,
      -webkit-transform 0.25s ease 50ms;
  }
  .Header-businessLink .HeaderImage--logo {
    transition: opacity 0.25s ease 0.1s, -webkit-transform 0.25s ease 0.1s;
    transition: opacity 0.25s ease 0.1s, transform 0.25s ease 0.1s;
    transition: opacity 0.25s ease 0.1s, transform 0.25s ease 0.1s,
      -webkit-transform 0.25s ease 0.1s;
  }
  .Header-businessLink:focus .HeaderImage--logo,
  .Header-businessLink:hover .HeaderImage--logo {
    opacity: 0;
    transition: opacity 0.1s ease 0ms, -webkit-transform 0.1s ease 0ms;
    transition: opacity 0.1s ease 0ms, transform 0.1s ease 0ms;
    transition: opacity 0.1s ease 0ms, transform 0.1s ease 0ms,
      -webkit-transform 0.1s ease 0ms;
    -webkit-transform: translateX(-2px);
    -ms-transform: translateX(-2px);
    transform: translateX(-2px);
  }
}
.Header-merchantLogoWithLabel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.Header-merchantLogoWithLabel > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  min-width: 0;
  max-width: 100%;
}
.Header-merchantLogoWithLabel .HeaderImage {
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.Header-testTagMobile {
  display: none;
}
@media only screen and (max-width: 991.98px) {
  .Header-testTag {
    display: none;
  }
  .Header-testTagMobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
.Tabs-TabPanelContainer {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.Select {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.Select-source {
  width: 100%;
  height: 44px;
  font-size: 16px;
  padding: 8px 24px 8px 12px;
  color: rgba(26, 26, 26, 0.9);
  line-height: 1.5;
  border: 0;
  background-color: #fff;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.08s ease-in, color 0.08s ease-in,
    -webkit-filter 50000s;
  transition: box-shadow 0.08s ease-in, color 0.08s ease-in, filter 50000s;
  transition: box-shadow 0.08s ease-in, color 0.08s ease-in, filter 50000s,
    -webkit-filter 50000s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.Select-source:focus {
  z-index: 2;
  outline: none;
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 0 0 4px rgba(50, 151, 211, 0.3);
}
.Select-source:disabled {
  color: rgba(26, 26, 26, 0.4);
  transition: background-color 100000000s, box-shadow 0s linear;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.05), inset 0 0 0 1000px rgba(26, 26, 26, 0.1);
}
.Select-source::-ms-expand {
  display: none;
}
.Select-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -6px;
  pointer-events: none;
  width: 12px;
  height: 12px;
  z-index: 3;
}
.Select-source:disabled + .Select-arrow {
  display: none;
}
body:after {
  display: block;
  content: "";
  height: 0;
  width: 100%;
  background: transparent;
  transition: height 0.4s ease;
}
.FormFieldInput {
  position: relative;
}
.FieldError {
  transition: all 0.3s ease;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  pointer-events: none;
}
.FormFieldGroup-Fieldset {
  margin: 4px 0 0;
  border: none;
  padding: 0;
}
.FormFieldGroup-labelContainer {
  position: relative;
}
.FormFieldGroup-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.FormFieldGroup-child {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  min-width: 0;
  max-width: 100%;
  -webkit-transform-origin: "0%";
  -ms-transform-origin: "0%";
  transform-origin: "0%";
}
.FormFieldGroup-child--width-12 {
  width: 100%;
}
.FormFieldGroup-child--width-6 {
  width: 50%;
}
.FormFieldGroup-child .CheckoutInput,
.FormFieldGroup-child .Select-source {
  border-radius: 0;
}
.SubmitButton {
  position: relative;
  overflow: hidden;
  height: 44px;
  width: 100%;
  margin-top: 12px;
  padding: 0;
  color: #fff;
  box-shadow: inset 0 0 0 1px rgba(50, 50, 93, 0.1),
    0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07);
  border: 0;
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease, box-shadow 0.08s ease-in;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.SubmitButton:disabled {
  cursor: default;
}
.SubmitButton-Shimmer {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 150%;
  opacity: 0;
  -webkit-transform: translateX(-150%);
  -ms-transform: translateX(-150%);
  transform: translateX(-150%);
  transition: opacity 0.2s ease;
}
.SubmitButton-Text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 44px;
  transition: all 0.2s ease;
}
.SubmitButton-IconContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform: translateX(-16px);
  -ms-transform: translateX(-16px);
  transform: translateX(-16px);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.SubmitButton-Icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.2s ease;
}
.SubmitButton-SpinnerIcon .Icon,
.SubmitButton-SpinnerIcon svg {
  height: 20px;
  width: 20px;
}
.SubmitButton-SpinnerIcon ellipse {
  stroke-width: 1;
  stroke-linecap: round;
  stroke-dasharray: 70;
  stroke-dashoffset: 30;
  fill: transparent;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-animation: spinner-spin 0.6s linear infinite;
  animation: spinner-spin 0.6s linear infinite;
}
.SubmitButton-CheckmarkIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(calc(-50% + 2px)) translateX(-50%) scale(0.6);
  -ms-transform: translateY(calc(-50% + 2px)) translateX(-50%) scale(0.6);
  transform: translateY(calc(-50% + 2px)) translateX(-50%) scale(0.6);
}
.SubmitButton-CheckmarkIcon path {
  stroke-linecap: round;
  stroke-dasharray: 30;
  stroke-dashoffset: 30;
  opacity: 0;
}
.SubmitButton--incomplete:focus {
  box-shadow: inset 0 0 0 1px rgba(50, 50, 93, 0.1),
    0 6px 15px 0 rgba(50, 50, 93, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.1),
    0 0 0 4px rgba(50, 151, 211, 0.3);
}
.SubmitButton--incomplete .SubmitButton-Text--current {
  opacity: 0.6;
}
.SubmitButton-Text--pre {
  opacity: 0;
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
.SubmitButton-Icon--pre {
  opacity: 0;
  -webkit-transform: translateY(-50%) scale(0.9);
  -ms-transform: translateY(-50%) scale(0.9);
  transform: translateY(-50%) scale(0.9);
}
.ConfirmTerms {
  line-height: 17px;
  text-align: center;
}
.ConfirmTerms--setup {
  text-align: left;
}
.ConfirmPayment-PostSubmit {
  margin-top: 12px;
}
.ConfirmPayment-PostSubmit > * {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(26, 26, 26, 0.1);
}
.ConfirmPayment-PostSubmit > :last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
.mr2 {
  margin-right: 8px;
}
.mx2 {
  margin-left: 8px;
}
.mx2 {
  margin-right: 8px;
}
.App-Background {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}
.App-Container {
  min-height: 100vh;
}
.App-Container:not(.is-noBackground) {
  background-image: linear-gradient(
    180deg,
    transparent,
    transparent 500px,
    #fff 0,
    #fff
  );
}
.App {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0;
}
.App.App--singleItem {
  padding-top: 16px;
}
.App-Overview,
.App-Payment > * {
  max-width: 380px;
  margin: 0 auto;
}
.App-Overview,
.App-Payment {
  padding: 0 16px 16px;
  width: 100%;
}
.App-Payment {
  padding-top: 24px;
  background-color: #fff;
}
.App-Container:not(.is-noBackground) .App-Payment {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.04);
}
.App-Container .App {
  -webkit-animation: enter-no-scale 0.6s;
  animation: enter-no-scale 0.6s;
  position: relative;
}
@media only screen and (min-width: 992px) {
  .App {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transform: translateY(max(48px, calc(50vh - 55%)));
    -ms-transform: translateY(max(48px, calc(50vh - 55%)));
    transform: translateY(max(48px, calc(50vh - 55%)));
  }
  .App.App--singleItem {
    padding-top: 0;
  }
  .App-Container:not(.is-noBackground) {
    background-image: none;
  }
  .App-Container:before {
    box-shadow: 15px 0 30px 0 rgba(0, 0, 0, 0.18);
  }
  .App-Payment {
    padding-top: 0;
    height: 100%;
  }
  .App-Container:not(.is-noBackground) .App-Payment {
    box-shadow: none;
  }
  .App-Container {
    min-height: 0;
  }
  .App-Container--setupMode .App {
    max-width: 444px;
  }
  .App-Container--setupMode .App-Payment {
    padding: 32px;
  }
  .App-Overview,
  .App-Payment {
    padding: 0;
    margin: 0;
  }
  .App-Container--setupMode:not(.is-noBackground) .App-Payment {
    margin: 24px 0;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.07);
  }
  .App-Container--setupMode .Header {
    padding: 0 32px;
  }
}
/*! CSS Used keyframes */
@-webkit-keyframes native-autofill-out {
  0% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes spinner-spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes spinner-spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@-webkit-keyframes enter-no-scale {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes enter-no-scale {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
