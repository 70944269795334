<template>
  <div>
    <div
      v-if="app.hasCurrentBrand"
      id="leistungen"
      class="container mb-info-konzept p-0"
    >
      <div class="card mb-5">
        <div class="card-body">
          <form>
            <h5 class="card-title" v-t="'page.yourdata.services.title'"></h5>
            <p v-t="'page.yourdata.services.description'"></p>
            <h6 class="mb-card-title" v-html="$t('page.yourdata.services.services')"></h6>
            <div class="row slim mb-3">
              <div class="form-group col">
                <label for="ProvidedSupportIDs" v-t="'page.yourdata.services.offeredservices'"></label>
                <custom-multiselect v-model="currentBrand.ProvidedSupportIDs" :options="app.preloads.providedsupports" label="Caption" identifier="ProvidedSupportID" :multiple="true" @change="registerChange" />
              </div>
            </div>
            <div class="row slim">
              <div class="form-group col">
                <label for="fld_Title" v-t="'page.yourdata.services.remarkservices'"></label>
                <div>
                  <custom-editor
                    v-model="currentBrand.ProvidedSupportRemark"
                    maxlength="700"
                    :small="true"
                    @change="registerChange"
                  />
                </div>
              </div>
            </div>
            <h6 class="mb-card-title" v-html="$t('page.yourdata.services.trainingoffers')"></h6>
            <div class="row slim mb-3">
              <div class="form-group col">
                <label for="ProvidedTrainingIDs" v-t="'page.yourdata.services.offeredtrainings'"></label>
                <custom-multiselect v-model="currentBrand.ProvidedTrainingIDs" :options="app.preloads.providedtrainings" label="Caption" identifier="ProvidedTrainingID" :multiple="true" @change="registerChange" />
              </div>
            </div>
            <div class="row slim">
              <div class="form-group col">
                <label
                  for="ProvidedTrainingRemark"
                  v-t="'page.yourdata.services.remarkofferedtrainings'"
                ></label>
                <div>
                  <custom-editor
                    v-model="currentBrand.ProvidedTrainingRemark"
                    maxlength="700"
                    :small="true"
                    @change="registerChange"
                  />
                </div>
              </div>
            </div>
            <h6 class="mb-card-title" v-html="$t('page.yourdata.services.remarkofferedtrainings')"></h6>
            <div class="row slim mb-3">
              <div class="form-group col">
                <label for="ProvidedFinancialAidIDs" v-t="'page.yourdata.services.offeredfinancialhelp'"></label>
                <custom-multiselect v-model="currentBrand.ProvidedFinancialAidIDs" :options="app.preloads.providedfinancialaids" label="Caption" identifier="ProvidedFinancialAidID" :multiple="true" @change="registerChange" />
              </div>
            </div>
            <div class="row slim">
              <div class="form-group col">
                <label for="ProvidedFinancialAidRemark" v-t="'page.yourdata.services.remarkofferedfinancialhelp'"></label>
                <div>
                  <custom-editor
                    v-model="currentBrand.ProvidedFinancialAidRemark"
                    maxlength="700"
                    :small="true"
                    @change="registerChange"
                  />
                </div>
              </div>
            </div>
            <h6 class="mb-card-title" v-html="$t('page.yourdata.services.managementhelp')"></h6>
            <div class="row slim mb-3">
              <div class="form-group col">
                <label for="ProvidedManagementAssistanceIDs" v-t="'page.yourdata.services.offeredmanagementhelp'"></label>
                <custom-multiselect v-model="currentBrand.ProvidedManagementAssistanceIDs" :options="app.preloads.providedmanagementassistances" label="Caption" identifier="ProvidedManagementAssistanceID" :multiple="true" @change="registerChange" />
              </div>
            </div>
            <div class="row slim">
              <div class="form-group col">
                <label
                  for="ProvidedManagementAssistanceRemark"
                  v-t="'page.yourdata.services.remarkmanagementhelp'"
                ></label>
                <div>
                  <custom-editor
                    v-model="currentBrand.ProvidedManagementAssistanceRemark"
                    maxlength="700"
                    :small="true"
                    @change="registerChange"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomEditor from '@/components/CustomEditor'
import CustomMultiselect from '@/components/CustomMultiselect'

export default {
  name: 'BrandProfileDataServices',
  components: {
    CustomEditor,
    CustomMultiselect
  },
  props: [],
  data: function () {
    return {
      lists: {
        providedfinancialaids: [],
        providedmanagementassistances: [],
        providedsupports: [],
        providedtrainings: []
      }
    }
  },
  computed: {
    app: function () {
      return this.$parent.$parent || {}
    },
    currentBrand: function () {
      const current = this.$parent.currentBrand || {}
      if (current.ProvidedSupportRemark === null) {
        current.ProvidedSupportRemark = ''
      }
      if (current.ProvidedTrainingRemark === null) {
        current.ProvidedTrainingRemark = ''
      }
      if (current.ProvidedTrainingRemark === null) {
        current.ProvidedTrainingRemark = ''
      }
      if (current.ProvidedManagementAssistanceRemark === null) {
        current.ProvidedManagementAssistanceRemark = ''
      }

      return current
    },
    preloads: function () {
      return this.$parent.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    }
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
    registerChange () {
      this.app.registerChangedContent(this.app.saveBrand)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
