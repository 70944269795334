<template>
  <FairFieldEditor :title="computedTitle" :index="7" buttonSaveText="Korrekturwunsch absenden" :showContentOnEdit="true"
    :loading="isLoading" @save="handleSave" :isEditing="isEditing" :saveDisabled="!computedIsValid"
    :is-modal-open.sync="isModalOpen" @toggleEdit="toggleEdit" @toggle="toggleContent" :showOpenButton="false"
    :usePageStore="true" v-if="computedShowStep">
    <div class="d-flex flex-column gap-2">
      <div v-html="computedWhatForHtml" />
    </div>
    <template v-slot:edit>
      <hr />
      <div class="d-flex flex-column w-100 gap-3">
        <input type="text" class="form-control w-100" :placeholder="formFields.placeholder"
          v-model="formFields.value" />
        <div class="d-flex flex-column gap-1">
          <span class="fw-bold">Anforderungen:</span>
          <ul class="special">
            <li>Der Text soll einen schnellen Überblick über Ihr Geschäftskonzept geben</li>
            <li>Der Text soll motivierend und positiv sein und in aller Kürze die wichtigsten Vorteile aufzeigen</li>
            <li>2-3 Absätze sind optimal</li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:editButtonCaption>
      Stimmt etwas nicht?
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/7_WhatFor.png" />
    </template>
  </FairFieldEditor>
</template>

<script>
import { useBrandStore } from '@/stores/brandStore'
import FairFieldEditor from '@/components/FairFieldEditor.vue'

export default {
  name: 'FairFormWhatFor',
  components: {
    FairFieldEditor
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      loadedContent: '',
      formFields: {
        placeholder: 'Tragen Sie hier Ihren neuen Vorschlag ein.',
        value: ''
      }
    }
  },
  methods: {
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Korrekturwünsche wurden erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Korrekturwünsche ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async submitForm () {
      await this.app.APIService._post('mb/fair/update/7', {
        brandId: this.currentBrand.BrandID,
        value: this.formFields.value
      })
    }
  },
  computed: {
    computedShowStep () {
      return this.currentFair.FeaturedSnippet !== null
    },
    computedTitle () {
      return `Überblick: Wofür steht ${this.currentBrand.Name}?`
    },
    computedIsValid () {
      return this.formFields.value.length > 0
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand
    },
    currentFair: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentFair
    },
    computedWhatForHtml () {
      return this.loadedContent
    }
  },
  async mounted () {
    if (this.currentFair.FeaturedSnippet !== null) {
      const brandStore = useBrandStore()
      const loadedContentResponse = await brandStore.fpApi._post('content', {
        ContentID: this.currentFair.FeaturedSnippet
      })
      this.loadedContent = loadedContentResponse.body.Result.Data.Body
    }
  }

}
</script>
