<template>
  <div>
    <div class="section-bottom" v-if="computedShowAboutMe">
      <h4>Über mich</h4>
      <div v-if="isLoading">
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="55%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
      </div>
      <div class="d-flex flex-column gap-3" v-else>
        <div class="data-row" v-if="computedProfession">
          <span class="title">Welcher Personengruppe gehörst du an?</span>
          <div>
            <span class="answer">{{ computedProfession }}</span>
            <thumb-rate-form name="Contact.Profession" :values="contactPropertyRatings" :contactId="computedContactId" :brandId="computedBrandId" @rated="handleRatedProperty" @deleted="handleRatedPropertyDelete" />
          </div>
        </div>

        <div class="data-row" v-if="computedLastProfessionalSector">
          <span class="title"
            >Was war deine letzte berufliche Position? In welcher Branche?</span
          >
          <div>
            <span class="answer">{{ computedLastProfessionalSector }}</span>
            <thumb-rate-form name="Contact.ProfessionalPosition" :values="contactPropertyRatings" :contactId="computedContactId" :brandId="computedBrandId" @rated="handleRatedProperty" @deleted="handleRatedPropertyDelete" />
          </div>
        </div>

        <template v-if="computedContactExperience !== null">
          <div class="data-row" v-if="computedContactExperience">
            <span class="title">Hast du bereits Führungserfahrung?</span>
            <div>
              <span class="answer">
                <inline-svg
                  class="yes"
                  :src="require('@/assets/images/icons/3.0/check.svg')"
                />
                {{ computedContactExperience }}
              </span>
              <thumb-rate-form name="Contact.LeadExperienceAs" :values="contactPropertyRatings" :contactId="computedContactId" :brandId="computedBrandId" @rated="handleRatedProperty" @deleted="handleRatedPropertyDelete" />
            </div>
          </div>
          <div class="data-row" v-else>
            <span class="title">Hast du bereits Führungserfahrung?</span>
            <div>
              <span class="answer">
                <inline-svg
                  class="no"
                  :src="require('@/assets/images/icons/3.0/close.svg')"
                />
                Nein, bisher nicht
              </span>
              <thumb-rate-form name="Contact.LeadExperienceAs" :values="contactPropertyRatings" :contactId="computedContactId" :brandId="computedBrandId" @rated="handleRatedProperty" @deleted="handleRatedPropertyDelete" />
            </div>
          </div>
        </template>

        <div class="data-row" v-show="computedShowWasIndependent">
          <span class="title">Warst du bereits selbstständig?</span>
          <div>
            <span class="answer d-flex align-items-center gap-2">
              <inline-svg
                class="yes"
                v-if="computedWasIndependent"
                :src="require('@/assets/images/icons/3.0/check.svg')"
              />
              <inline-svg
                class="no"
                v-else
                :src="require('@/assets/images/icons/3.0/close.svg')"
              />
              {{ computedWasIndependentText }}
            </span>
            <thumb-rate-form name="Contact.WasIndependent" :values="contactPropertyRatings" :contactId="computedContactId" :brandId="computedBrandId" @rated="handleRatedProperty" @deleted="handleRatedPropertyDelete" />
          </div>
        </div>

        <div class="data-row" v-if="computedReasonIndependent">
          <span class="title"
            >Warum möchtest du dich selbstständig machen?</span
          >
          <div>
            <span class="answer">{{ computedReasonIndependent }}</span>
            <thumb-rate-form name="Contact.ReasonIndependent" :values="contactPropertyRatings" :contactId="computedContactId" :brandId="computedBrandId" @rated="handleRatedProperty" @deleted="handleRatedPropertyDelete" />
          </div>
        </div>

        <div v-if="isCurrentLeadWithCV">
          <a :href="currentLeadCVLink" target="_blank" class="cv-cta">
            <inline-svg
              class="yes"
              width="24"
              height="24"
              :src="require('@/assets/images/icons/3.0/check.svg')"
            />
            <span>Lebenslauf herunterladen</span>
          </a>
        </div>
      </div>
    </div>

    <div class="section-bottom" v-if="computedShowAdditionalAboutMe">
      <h4>Mein Gründungsvorhaben</h4>
      <div v-if="isLoading">
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="55%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
      </div>
      <div class="d-flex flex-column gap-3" v-else>
        <div class="data-row" v-if="computedHasSpecialGroupCaptions">
          <span class="title"
            >Branchen von Interesse</span
          >
          <div>
            <div class="answer sector-tags-container">
              <span v-for="(specialGroupCaption, index) in computedSpecialGroupCaptions" :key="index">
                {{ specialGroupCaption }}
              </span>
            </div>
            <thumb-rate-form name="Contact.SpecialGroups" :values="contactPropertyRatings" :contactId="computedContactId" :brandId="computedBrandId" @rated="handleRatedProperty" @deleted="handleRatedPropertyDelete" />
          </div>
        </div>

        <div class="data-row" v-if="computedEquity">
          <span class="title"
            >Wieviel Eigenkapital bist du bereit in deine Gründung zu
            investieren?</span
          >
          <div>
            <span class="answer">
              Ich möchte <strong>{{ computedEquity }}</strong> in meine
              Gründung investieren
            </span>
            <thumb-rate-form name="Contact.Equity" :values="contactPropertyRatings" :contactId="computedContactId" :brandId="computedBrandId" @rated="handleRatedProperty" @deleted="handleRatedPropertyDelete" />
          </div>
        </div>

        <div class="data-row" v-if="hasFoundingWill">
          <span class="title">Wie stark ist dein Gründungswille (1-5)?</span>
          <div>
            <div class="founding-will-bar">
              <div
                class="check"
                v-for="value in [1, 2, 3, 4, 5]"
                :key="value"
                :class="computedFoundingWillClass(value)"
              >
                <inline-svg
                  width="24"
                  height="24"
                  :src="require('@/assets/images/icons/3.0/checked-circle.svg')"
                />
                <span>{{ value }}</span>
              </div>
            </div>
            <thumb-rate-form name="Contact.FoundingWill" :values="contactPropertyRatings" :contactId="computedContactId" :brandId="computedBrandId" @rated="handleRatedProperty" @deleted="handleRatedPropertyDelete" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import ThumbRateForm from '@/components/3.0/ThumbRateForm.vue'
import Config from '../../config'

function getBasename (filePath) {
  return filePath.split(/[/\\]/).pop()
}

export default {
  name: 'ContactPropertiesView',
  props: ['isLoading', 'lead', 'contactProperties', 'cv', 'contactPropertyRatings'],
  inject: ['API'],
  components: {
    ThumbRateForm,
    InlineSvg
  },
  data: function () {
    return {}
  },
  methods: {
    handleRatedProperty (data) {
      this.$emit('rated', data)
    },
    handleRatedPropertyDelete (ContactPropertyRatingID) {
      this.$emit('deleted', ContactPropertyRatingID)
    }
  },
  computed: {
    computedShowAboutMe () {
      return (
        this.computedProfession ||
        this.computedLastProfessionalSector ||
        this.computedContactExperience ||
        this.computedShowWasIndependent ||
        this.computedReasonIndependent ||
        this.isCurrentLeadWithCV
      )
    },
    computedShowAdditionalAboutMe () {
      return (
        this.computedHasSpecialGroupCaptions ||
        this.computedEquity ||
        this.hasFoundingWill
      )
    },
    computedProfession () {
      switch (this.lead.Contact.Profession) {
        case 0:
          return 'Manager*in / Führungskraft'
        case 1:
          return 'Angestellt'
        case 2:
          return 'Studierend/ in Ausbildung'
        case 3:
          return 'Arbeitssuchend'
        case 4:
          return 'Unternehmer*in / Selbstständig'
        case -1:
          return 'Keine Angabe'
        default:
          return null
      }
    },
    computedContactId () {
      return this.lead.ContactID
    },
    computedBrandId () {
      return this.lead.BrandID
    },
    isCurrentLeadWithCV () {
      if (this.lead === null) return false
      if (this.lead.LeadUpgrade !== 1) return false
      if (this.lead.Upgrade === null) return false
      if (typeof this.lead.Upgrade.CV === 'undefined') return false
      return true
    },
    currentLeadCVLink () {
      if (!this.lead.Upgrade.CV) return null
      return `${Config.MEDIA_API_URL}${this.lead.Upgrade.CV.URL}`
    },
    currenLeadCVFilename () {
      return getBasename(this.lead.Upgrade.CV.Filename)
    },
    computedReasonIndependent () {
      if (this.lead.Contact.ReasonIndependent === null) return null
      return this.lead.Contact.ReasonIndependent
    },
    computedShowWasIndependent () {
      if (this.lead.Contact.WasIndependent === null) return false
      return true
    },
    computedWasIndependent () {
      return (
        this.computedShowWasIndependent && this.lead.Contact.WasIndependent
      )
    },
    computedWasIndependentText () {
      if (this.computedShowWasIndependent === false) return null
      if (this.lead.Contact.WasIndependent) {
        return `Ja, ${this.lead.Contact.IndependentProfession}`
      }
      return 'Nein, bisher nicht'
    },
    computedLastProfessionalSector () {
      const strings = []
      if (this.lead.Contact.ProfessionalPosition !== null) {
        strings.push(this.lead.Contact.ProfessionalPosition)
      }
      if (this.lead.Contact.ProfessionalSector !== null) {
        strings.push(this.lead.Contact.ProfessionalSector)
      }
      return strings.join(', ')
    },
    computedContactExperience () {
      if (this.lead.Contact.LeadExperienceAs) {
        return this.lead.Contact.LeadExperienceAs
      }
      return null
    },
    computedEquity () {
      const contact = this.lead.Contact

      if (typeof contact.EquityFrom === 'undefined' && typeof contact.EquityTo === 'undefined') return null

      const from = contact.EquityFrom
      const to = contact.EquityTo

      if (from === null && to === null) return null

      const formatter = new Intl.NumberFormat('de-DE')

      if (from === null && to !== null) {
        return `bis zu ${formatter.format(to)} €`
      }

      if (from !== null && to === null) {
        return `ab ${formatter.format(from)} €`
      }

      return `${formatter.format(from)} € - ${formatter.format(to)} €`
    },
    hasFoundingWill () {
      return typeof this.contactProperties.FoundingWill !== 'undefined'
    },
    computedFoundingWill () {
      return this.contactProperties.FoundingWill
    },
    computedFoundingWillClass () {
      return (value) => {
        return {
          active: value <= this.computedFoundingWill,
          current: value === this.computedFoundingWill
        }
      }
    },
    computedHasSpecialGroupCaptions () {
      return this.lead.Contact.SpecialGroupCaptions.length > 0
    },
    computedSpecialGroupCaptions () {
      return this.lead.Contact.SpecialGroupCaptions
    }
  },
  created: function () {},
  mounted: function () {}
}
</script>

<style lang="scss" scoped>
.data-row {
  display: flex;
  flex-direction: column;
  gap: 4px;

  > div {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }

  div,
  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .title {
    font-weight: 700;
  }

  .answer {
    svg {
      width: 16px;
      height: 16px;
    }

    &.sector-tags-container {
      display: flex;
      align-items: center;
      align-content: center;
      gap: 8px 16px;
      flex: 1 0 0;
      flex-wrap: wrap;

      span {
        display: flex;
        padding: 4px 8px;
        align-items: flex-start;
        border-radius: 4px;
        background: #EFF1F4;
        color: #3F4957;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

    }

  }
}

svg.yes {
  stroke: #91c124;
}
svg.no {
  stroke: #c83312;
}

.founding-will-bar {
  display: flex;
  gap: 60px;
  align-items: center;
  justify-content: flex-start;

  div.check {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;

    color: #a0abbb;

    svg {
      stroke: #a0abbb;
    }

    &.current {
      span {
        font-weight: 700;
      }
    }

    &.active {
      color: #00719f;

      svg {
        stroke: #00719f;
      }
    }
  }
}

.cv-cta {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #00719f;
  background: #fff;
  flex-grow: 0;

  width: fit-content;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: #00719f;
    color: #fff;
    svg {
      stroke: #fff;
    }
  }

  color: #00719F;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

}
</style>
