import { render, staticRenderFns } from "./ChatbotShowcase.vue?vue&type=template&id=d5b47022"
import script from "./ChatbotShowcase.vue?vue&type=script&lang=js"
export * from "./ChatbotShowcase.vue?vue&type=script&lang=js"
import style0 from "./ChatbotShowcase.vue?vue&type=style&index=0&id=d5b47022&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports