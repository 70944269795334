<template>
  <div class="booking-content" v-if="hasCurrentBrand">
    <div class="row mb-wide-content">
      <div class="col-12 col-xl-6 text-center left-side">
        <h1 v-t="'page.membership.title'"></h1>
        <h2 v-if="showCoffeeText" v-t="'page.dashboard.banner.profilereadytext'"></h2>
        <h2 v-else v-t="'page.dashboard.banner.onlyafewclicks'"></h2>
        <ul>
          <li><i class="fa fa-check"></i><span>{{ $t('page.dashboard.banner.presentationfp') }}</span></li>
          <li><i class="fa fa-check"></i><span>{{ $t('page.dashboard.banner.freeprofile.freeug') }}</span></li>
          <li><i class="fa fa-check"></i><span>{{ $t('page.dashboard.banner.list3') }}</span></li>
          <li><i class="fa fa-check"></i><span>{{ $t('page.dashboard.banner.list4') }}</span></li>
          <li><i class="fa fa-check"></i><span>{{ $t('page.dashboard.banner.list5') }}</span></li>
          <li><i class="fa fa-check"></i><span>{{ $t('page.dashboard.banner.list6') }}</span></li>
          <li><i class="fa fa-check"></i><span>{{ $t('page.dashboard.banner.list7') }}</span></li>
          <li><i class="fa fa-check"></i><span>{{ $t('page.dashboard.banner.list8') }}</span></li>
        </ul>
        <div class="mb-badge-cont">
          <div class="mb-big-badge" v-if="!app.isDiscountAvailable">
            <h4 v-html="$t('page.dashboard.banner.badge.title')"></h4>
            <div>{{ $t('page.dashboard.banner.badge.sub') }}</div>
          </div>
          <div class="mb-big-badge" v-else>
            <h3 v-html="$t('page.dashboard.banner.badge.discounttitle')"></h3>
            <div>{{ $t('page.dashboard.banner.badge.discountsub') }}</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6 checkout-container" ref="checkout">

        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>

        <div v-if="isFQStripeCustomer">
          <strong v-t="'page.membership.billingaddress'"></strong>
          <span class="d-block mb-2">{{ computedInvoiceAddress }}</span>
          <strong v-t="'page.membership.paymentmethod'"></strong>
          <span class="d-block">{{ computedInvoiceMethod }}</span>
        </div>

        <h2 class="mt-3" v-t="'page.membership.bookmembership'"></h2>

        <div class="period-switch d-flex">
          <div class="custom-control custom-control-inline custom-radio">
            <input type="radio" name="period" class="custom-control-input" v-model="Checkout.PeriodYearly"
              :value="false" id="mb-mbr-mnthly">
            <label class="switch-label custom-control-label" for="mb-mbr-mnthly">
              <span v-t="'date.monthly'"></span>
              <span class="orange" v-if="!app.isDiscountAvailable"><span
                  v-html="$t('page.membership.3monthdiscount')"></span> <i v-if="!Checkout.PeriodYearly">*</i></span>
            </label>
          </div>
          <div style="width: 80px"></div>

          <div class="custom-control custom-control-inline custom-radio">
            <input type="radio" name="period" class="custom-control-input" v-model="Checkout.PeriodYearly" :value="true"
              id="mb-mbr-yrly">
            <label class="switch-label custom-control-label" for="mb-mbr-yrly">
              <span v-t="'date.yearly'"></span>
              <span class="orange">{{ $t('page.membership.permanentdiscount') }} <i
                  v-if="Checkout.PeriodYearly">*</i></span>
            </label>
          </div>

        </div>

        <div class="pb-5">

          <router-link v-if=0 to="/stripe-customer">
            {{ $t('page.membership.editbillingandpayment') }}
          </router-link>

          <div class="products" v-if="!Checkout.PeriodYearly">
            <!-- Monatlich -->
            <Product country="de" v-model="Checkout.SelectedMemberships.DE" :available="isProductAvailable('de')"
              :price="319" :price-before="429" :discount-available="app.isDiscountAvailable"
              :membershipState="currentMembership.Status" :sale-text="$t('page.membership.costpermonthde')" />
            <Product country="at" v-model="Checkout.SelectedMemberships.AT" :available="isProductAvailable('at')"
              :price="59" :price-before="79" :discount-available="app.isDiscountAvailable"
              :membershipState="currentMembership.Status" :sale-text="$t('page.membership.costpermonthat')" />
            <Product country="ch" v-model="Checkout.SelectedMemberships.CH" :available="isProductAvailable('ch')"
              :price="59" :price-before="79" :discount-available="app.isDiscountAvailable"
              :membershipState="currentMembership.Status" :sale-text="$t('page.membership.costpermonthch')"
              class="mb-lst-prod" />
            <p v-if="app.isDiscountAvailable"><small v-t="'page.membership.exactdiscountmonth'"></small></p>
          </div>

          <!-- Jährlich -->
          <div class="products" v-else>
            <Product country="de" v-model="Checkout.SelectedMemberships.DE" :available="isProductAvailable('de')"
              :price="319" :price-before="429" :discount-available="true" :membershipState="currentMembership.Status"
              :sale-text="$t('page.membership.costperyearde')" />
            <Product country="at" v-model="Checkout.SelectedMemberships.AT" :available="isProductAvailable('at')"
              :price="59" :price-before="79" :discount-available="true" :membershipState="currentMembership.Status"
              :sale-text="$t('page.membership.costperyearat')" />
            <Product country="ch" v-model="Checkout.SelectedMemberships.CH" :available="isProductAvailable('ch')"
              :price="59" :price-before="79" :discount-available="true" :membershipState="currentMembership.Status"
              :sale-text="$t('page.membership.costperyearch')" class="mb-lst-prod" />
            <p><small v-t="'page.membership.exactdiscountyear'"></small></p>
          </div>
          <p class="mb-5 mt-3" v-t="'page.membership.termsinfo'"></p>

          <CustomCheckbox :text="$t('page.membership.externalallowed', { company: currentCompany.Name })"
            v-model=Checkout.Legal.ExternalAllowed></CustomCheckbox>

          <CustomCheckbox :text="$t('page.membership.agb')" v-model=Checkout.Legal.AGB class="mb-terms"
            onlyCheckboxClick=true></CustomCheckbox>

          <button :class="finalStepClasses" @click="checkFieldsAndBook"
            v-t="'page.membership.startmembership'"></button>
          <small v-if="!isStripeCustomer" class="text-center d-block" v-t="'page.membership.redirectinfo'"></small>
        </div>
      </div>
    </div>
    <b-modal centered size="750px" id="bookWizzard" modal-class="blue-modal mb-mbr-wiz" v-model="showWiz">
      <template #modal-title>
        {{ $t("membership.bookwizzard.title") }}
      </template>
      <div v-if="currentWizIndex == 0 || isLastWizItem">
        <label v-if="currentWizIndex == 0" v-t="'membership.bookwizzard.firstnotification'"></label>
        <label v-if="isLastWizItem && currentWizIndex > 0" v-t="'membership.bookwizzard.lastnotification'"></label>
        <hr>
      </div>
      <div class="text-center">({{ currentWizIndex + 1 }}/{{ requiredFields.length }})</div>
      <div class="mb-mbr-wiz-slide-cont">
        <div class="mb-wiz-container">
          <div v-for="(item, i) in requiredFields" :key="i" class="mb-wiz-q-cont"
            :class="currentWizIndex == i ? '' : 'mb-wiz-hidden'">
            <div class="mb-wiz-question">
              {{ $t('membership.bookwizzard.q' + requiredFields[i], {
                BrandName: currentBrand.Name, country: datenLand,
                systemType: getSystemType
              }) }}
              <div class="text-center font-weight-normal"
                v-if="requiredFields[i] == 'PartnerTargetGroupID' || requiredFields[i] == 'PartnerFulltimeJob'"
                v-t="'multiselect.possibletext'"></div>
            </div>
            <div class="text-center mb-small-cont" v-if="requiredFields[i] == 'ComParamFoundingYear'">
              <input v-model="currentBrand.ComParamFoundingYear" type="number" class="form-control"
                @input="registerChange">
            </div>
            <div class="text-center" v-if="requiredFields[i] == 'ComParamFirstPartnerYear'">
              <b-form-radio-group id="ContractAvailable" v-model="ComParamFirstPartnerYear" name="ContractAvailable">
                <b-form-radio value="1">
                  <input v-model="currentBrand.ComParamFirstPartnerYear" type="number" @input="registerChange"
                    :placeholder="$t('membership.bookwizzard.yearplaceholder')" style="border: none; max-height: 26px;"
                    @focus="ComParamFirstPartnerYear = 1">
                </b-form-radio>
                <b-form-radio value="0">
                  {{ $t('membership.bookwizzard.aNoPartnerYet') }}
                </b-form-radio>
              </b-form-radio-group>
            </div>
            <div class="text-center mb-small-cont" v-if="requiredFields[i] == 'ContractAvailable'">
              <b-form-radio-group id="ContractAvailable" v-model="currentBrand.ContractAvailable"
                name="ContractAvailable" @change="registerChange">
                <b-form-radio value="1">
                  {{ $t('choice.yes') }}
                </b-form-radio>
                <b-form-radio value="0">
                  {{ $t('choice.no') }}
                </b-form-radio>
              </b-form-radio-group>
            </div>
            <div class="text-center" v-if="requiredFields[i] == 'ProvidedManagementAssistanceID'">
              <b-form-radio-group id="ContractAvailable" v-model="detailedSystemManualAvailable"
                name="ContractAvailable" @change="detailedManualChange">
                <b-form-radio :value="true">
                  {{ $t('choice.yes') }}
                </b-form-radio>
                <b-form-radio :value="false">
                  {{ $t('choice.no') }}
                </b-form-radio>
              </b-form-radio-group>
            </div>
            <div class="text-center" v-if="requiredFields[i] == 'PartnerTargetGroupID'">
              <b-form-checkbox-group id="PartnerTargetGroupIDs" v-model="currentBrand.PartnerTargetGroupIDs"
                class="stacked" name="PartnerTargetGroupIDs" :options="app.preloads.partnertargetgroups"
                value-field="PartnerTargetGroupID" text-field="Caption" @change="registerChange" />
            </div>
            <div class="text-center" v-if="requiredFields[i] == 'PartnerFulltimeJob'">
              <b-form-checkbox v-model="currentBrand.PartnerFullTimeJob" value="1" unchecked-value="0"
                class="custom-control-inline" @change="registerChange">
                {{ $t('choice.fulltimepossible') }}
              </b-form-checkbox>
              <b-form-checkbox v-model="currentBrand.PartnerPartTimeJob" value="1" unchecked-value="0"
                class="custom-control-inline" @change="registerChange">
                {{ $t('choice.parttimepossible') }}
              </b-form-checkbox>
            </div>
            <div class="text-center" v-if="requiredFields[i] == 'PartnerLateralEntrance'">
              <b-form-radio-group id="PartnerLateralEntrance" v-model="currentBrand.PartnerLateralEntrance"
                name="PartnerLateralEntrance" @change="registerChange">
                <b-form-radio value="1">
                  {{ $t('membership.bookwizzard.aPartnerLateralEntranceYes') }}
                </b-form-radio>
                <b-form-radio value="0">
                  {{ $t('membership.bookwizzard.aPartnerLateralEntranceNo') }}
                </b-form-radio>
              </b-form-radio-group>
            </div>
            <div class="text-center" v-if="requiredFields[i] == 'PartnerHomeOffice'">
              <b-form-radio-group id="PartnerHomeOffice" v-model="currentBrand.PartnerHomeOffice"
                name="PartnerHomeOffice" @change="registerChange">
                <b-form-radio value="1">
                  {{ $t('choice.yes') }}
                </b-form-radio>
                <b-form-radio value="0">
                  {{ $t('choice.no') }}
                </b-form-radio>
              </b-form-radio-group>
            </div>
            <div class="text-center" v-if="requiredFields[i] == 'PartnerSmallBusiness'">
              <b-form-radio-group id="PartnerSmallBusiness" v-model="currentBrand.PartnerSmallBusiness"
                name="PartnerSmallBusiness" @change="registerChange">
                <b-form-radio value="1">
                  {{ $t('choice.yes') }}
                </b-form-radio>
                <b-form-radio value="0">
                  {{ $t('choice.no') }}
                </b-form-radio>
              </b-form-radio-group>
            </div>
            <div class="text-center" v-if="requiredFields[i] == 'PartnerMultipleLocations'">
              <b-form-radio-group id="PartnerMultipleLocations" v-model="currentBrand.PartnerMultipleLocations"
                name="PartnerMultipleLocations" @change="registerChange">
                <b-form-radio value="1">
                  {{ $t('choice.yes') }}
                </b-form-radio>
                <b-form-radio value="0">
                  {{ $t('choice.no') }}
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="d-flex flex-column">
          <button v-if="!isLastWizItem" class="btn btn-primary" @click="checkBeforeNextWizItem">{{
            $t('membership.bookwizzard.btnContinue') }}</button>
          <a href="javascript:" v-if="!isLastWizItem" class="mt-2"><small @click="nextWizItem">{{
            $t('membership.bookwizzard.skipQuestion') }}</small></a>
          <button v-if="isLastWizItem" class="btn btn-primary" @click="wizContinueAndBook">{{
            $t('membership.bookwizzard.btnContinueAndBook') }}</button>
          <a href="javascript:" v-if="isLastWizItem" class="mt-2"><small @click="wizContinueAndBook">{{
            $t('membership.bookwizzard.skipQuestionAndBook') }}</small></a>
        </div>
      </template>
    </b-modal>
    <MembershipShowcase />
  </div>
</template>

<script>
import Config from '../../config/index'
import Product from '../../components/CustomProductContainer'
import CustomCheckbox from '../../components/CustomCheckboxContainer'
import MembershipShowcase from './MembershipShowcase'
import Loading from 'vue-loading-overlay'

import { useBrandStore } from '@/stores/brandStore'

import { loadStripe } from '@stripe/stripe-js'
export default {
  name: 'Membership',
  props: [],
  components: {
    Product,
    CustomCheckbox,
    MembershipShowcase,
    Loading
  },
  data: function () {
    return {
      Checkout: {
        SelectedMemberships: {
          DE: false,
          AT: false,
          CH: false
        },
        Legal: {
          ExternalAllowed: false,
          AGB: false
        },
        PeriodYearly: false
      },
      isLoading: false,
      isBookingInProgress: false,
      currentWizIndex: 0,
      requiredFields: [],
      detailedSystemManualAvailable: null,
      showWiz: false,
      lastToastNotification: null,
      ComParamFirstPartnerYear: null,
      brandStore: useBrandStore()
    }
  },
  computed: {
    isStripeCustomer () {
      if (typeof this.currentBrand.StripeCustomerID === 'undefined') return false
      if (this.currentBrand.StripeCustomerID === null) return false

      if (typeof this.currentMembership.Checkout === 'undefined') return false
      if (this.currentMembership.Checkout === null) return false

      return true
    },
    isFQStripeCustomer () {
      if (!this.isStripeCustomer) return false

      if (typeof this.currentMembership.Checkout.Payment === 'undefined') return false
      if (this.currentMembership.Checkout.Payment === null) return false

      return true
    },
    hasCurrentBrand () {
      return typeof this.currentBrand.BrandID !== 'undefined'
    },
    currentBrand () {
      const current = this.$parent.currentBrand || {}
      return current
    },
    currentCompany () {
      return this.currentBrand.Company
    },
    membershipTypes () {
      return this.app.membershipTypes
    },
    membershipType () {
      return this.app.membershipType
    },
    isActiveMember () {
      return this.app.isActiveMember
    },
    isCheckoutReady () {
      return this.Checkout.Legal.AGB !== false && this.Checkout.Legal.ExternalAllowed !== false && this.hasSelectedSubscription === true
    },
    currentBrandSlug () {
      return (
        this.app.getSlug(this.currentBrand.Name, {
          lang: 'de',
          custom: {
            '+': ''
          }
        }) +
        '-m-' +
        this.currentBrand.BrandID
      )
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    currentMembership: function () {
      return this.app.currentMembership || {}
    },
    finalStepClasses () {
      const classes = ['final-step', 'btn', 'final-cta']
      if (this.isCheckoutReady === false) {
        classes.push('unavailable')
      }
      return classes.join(' ')
    },
    computedInvoiceAddress () {
      if (typeof this.currentMembership === 'undefined') return ''
      if (typeof this.currentMembership.Checkout === 'undefined' && this.currentMembership.Checkout !== null) return ''
      if (typeof this.currentMembership.Checkout.Address !== 'undefined') {
        const Name = this.currentMembership.Checkout.Name
        const Address = this.currentMembership.Checkout.Address
        return `${Name}, ${Address.line1}, ${Address.postal_code} ${Address.city}`
      }
      return ''
    },
    computedInvoiceMethod () {
      if (typeof this.currentMembership === 'undefined') return ''
      if (typeof this.currentMembership.Checkout === 'undefined' && this.currentMembership.Checkout !== null) return ''
      if (typeof this.currentMembership.Checkout.Payment !== 'undefined') {
        const Payment = this.currentMembership.Checkout.Payment

        switch (Payment) {
          case 'sepa':
            return this.$t('payment.sepa')
          case 'card':
            return this.$t('payment.creditcard')
        }
      }
      return ''
    },
    switchColors () {
      return {
        checked: '#42b2e4',
        unchecked: '#42b2e4',
        disabled: '#CCCCCC'
      }
    },
    switchLabels () {
      return {
        checked: this.$t('date.yearly'),
        unchecked: this.$t('date.monthly')
      }
    },
    hasSelectedSubscription () {
      return this.Checkout.SelectedMemberships.DE === true || this.Checkout.SelectedMemberships.AT || this.Checkout.SelectedMemberships.CH
    },
    showCoffeeText: function () {
      return this.currentBrand.Membership === 2 &&
        this.currentBrand.StatusID === 5 &&
        this.currentBrand.SDStatus === 5
    },
    isLastWizItem () {
      return this.currentWizIndex + 1 === this.requiredFields.length
    },
    datenLand: function () {
      if (this.currentBrand.ComParamOriginCountryID) {
        return this.$t('country.' + this.app.preloads.countries.filter(country => {
          return country.CountryID === this.currentBrand.ComParamOriginCountryID
        }).shift().Code.toLowerCase())
      }
      return ''
    },
    getSystemType: function () {
      return this.currentBrand.ConceptExpansionFormFranchise === 1 ? this.$t('systemform.franchisetext') : this.$t('systemform.licensetext')
    }
  },
  created: function () { },
  methods: {
    registerChange () {
      this.app.registerChangedContent(this.app.saveBrand)
    },
    detailedManualChange () {
      if (this.detailedSystemManualAvailable) this.currentBrand.ProvidedManagementAssistanceIDs.push(4)
      else this.currentBrand.ProvidedManagementAssistanceIDs = this.currentBrand.ProvidedManagementAssistanceIDs.filter(item => { return item !== 4 })
      this.registerChange()
    },
    getBookingData () {
      const loginFromStorage = localStorage.getItem('FPMB_Login')
      const login = loginFromStorage ? JSON.parse(loginFromStorage) : {}
      return {
        BrandID: this.currentBrand.BrandID,
        BookedPortals: Object.keys(this.Checkout.SelectedMemberships).filter(CountryKey => {
          return this.Checkout.SelectedMemberships[CountryKey] === true
        }),
        Yearly: this.Checkout.PeriodYearly, // Checked State of Switch is Yearly
        Username: login.user.Name
      }
    },
    checkFieldsAndBook () {
      if (this.currentMembership.Memberships.DE === true) {
        this.Checkout.SelectedMemberships.DE = false
      }
      if (this.currentMembership.Memberships.AT === true) {
        this.Checkout.SelectedMemberships.AT = false
      }
      if (this.currentMembership.Memberships.CH === true) {
        this.Checkout.SelectedMemberships.CH = false
      }

      if (!this.hasSelectedSubscription) {
        return this.$swal(this.$t('popupmessage.warn.missingdata'), this.$t('popupmessage.warn.choosecountry'), 'warning')
      }

      if (!this.Checkout.Legal.AGB) {
        return this.$swal(this.$t('popupmessage.warn.missingdata'), this.$t('popupmessage.warn.readterms'), 'warning')
      }

      if (!this.Checkout.Legal.ExternalAllowed) {
        return this.$swal(this.$t('popupmessage.warn.missingdata'), this.$t('popupmessage.warn.bookingpermission'), 'warning')
      }
      this.requiredFields = this.checkRequiredWizFields()
      document.body.style.setProperty('--mbr-wiz-count', this.requiredFields.length)
      document.body.style.setProperty('--mbr-wiz-index', 0)
      this.currentWizIndex = 0
      if (this.requiredFields.length > 0) {
        this.$bvModal.show('bookWizzard')
      } else this.bookMemberships()
    },
    checkRequiredWizFields (showAll = false) {
      const requiredFields = []
      if (this.currentBrand.ComParamFoundingYear == null || showAll) requiredFields.push('ComParamFoundingYear')
      if ((this.currentBrand.ComParamFirstPartnerYear == null && this.ComParamFirstPartnerYear === 1) || this.ComParamFirstPartnerYear === null || showAll) requiredFields.push('ComParamFirstPartnerYear')
      if (this.currentBrand.ContractAvailable == null || this.currentBrand.ContractAvailable === -1 || showAll) requiredFields.push('ContractAvailable')
      if (this.currentBrand.ConceptExpansionFormFranchise === 1 || showAll) {
        if (
          ((this.currentBrand.ProvidedManagementAssistanceIDs == null ||
            this.currentBrand.ProvidedManagementAssistanceIDs.length === 0 ||
            this.currentBrand.ProvidedManagementAssistanceIDs.indexOf(4) === -1) &&
            this.detailedSystemManualAvailable === null) ||
          showAll
        ) requiredFields.push('ProvidedManagementAssistanceID')
      }
      if (this.currentBrand.PartnerTargetGroupIDs == null || this.currentBrand.PartnerTargetGroupIDs.length === 0 || showAll) requiredFields.push('PartnerTargetGroupID')
      if ((this.currentBrand.PartnerFullTimeJob == null && this.currentBrand.PartnerPartTimeJob == null) || showAll) requiredFields.push('PartnerFulltimeJob')
      if (this.currentBrand.PartnerLateralEntrance == null || this.currentBrand.PartnerLateralEntrance === -1 || showAll) requiredFields.push('PartnerLateralEntrance')
      if (this.currentBrand.PartnerHomeOffice == null || this.currentBrand.PartnerHomeOffice === -1 || showAll) requiredFields.push('PartnerHomeOffice')
      if (this.currentBrand.PartnerSmallBusiness == null || this.currentBrand.PartnerSmallBusiness === -1 || showAll) requiredFields.push('PartnerSmallBusiness')
      if (this.currentBrand.PartnerMultipleLocations == null || this.currentBrand.PartnerMultipleLocations === -1 || showAll) requiredFields.push('PartnerMultipleLocations')

      return requiredFields
    },
    wizContinueAndBook () {
      this.$bvModal.hide('bookWizzard')
      this.app.showSaveSuccessPopup = false
      this.app.saveBrand()
        .then(() => {
          this.app.showSaveSuccessPopup = true
          this.app.showSaveBar = true
          this.bookMemberships()
        })
    },
    bookMemberships () {
      this.startLoader()

      // Case Reference https://app.milanote.com/1M5yPO1mN7Dk86/fp-checkout-routes
      if (this.isStripeCustomer) {
        if (this.isFQStripeCustomer) {
          // Case #1 book subscription with parameters and charge stripe customer and payment method
          const currentBookingData = this.getBookingData()
          this.completeMembershipBooking(currentBookingData)
        } else {
          // Case #3 redirect customer to stripe checkout
          this.linkBookingData()

          this.app.APIStripe._post(
            'setupPaymentData',
            {
              BrandID: this.currentBrand.BrandID,
              ReturnLocation: 'mitgliedschaft-buchen'
            }
          ).then(result => {
            localStorage.removeItem('returnLocationAfterAddressSetup')
            const sessionId = result.body.StripeCheckoutSessionID
            loadStripe(Config.STRIPE_PUBLIC_KEY).then(stripe => {
              stripe.redirectToCheckout({ sessionId })
            }).catch(error => {
              console.error(error)
              this.stopLoader()
              this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.occurred'), 'error')
            })
          }).catch(error => {
            console.error(error)
            this.stopLoader()
            this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.occurred'), 'error')
          })
        }
      } else {
        // Case #2 redirect customer to address setup
        this.startAddressSetup()
      }
    },
    loadBookingData () {
      try {
        const loadedBookingData = localStorage.getItem('bookingDataAfterSetup')
        if (loadedBookingData !== null) {
          return JSON.parse(loadedBookingData)
        }
      } catch (error) {
        console.error(error)
        return null
      }

      return null
    },
    linkBookingData () {
      localStorage.setItem('bookingDataAfterSetup', JSON.stringify(this.getBookingData()))
      this.storeReturnLocation()
    },
    releaseBookingData () {
      localStorage.removeItem('bookingDataAfterSetup')
    },
    completeMembershipBooking (bookingData) {
      if (this.isBookingInProgress === true) return false
      this.isBookingInProgress = true

      this.app.APIStripe._post(
        'completeMembershipBooking',
        bookingData
      ).then(response => {
        this.releaseBookingData()
        if (response.body.Error === false) {
          this.app.getBrandMembershipValues().then(() => {
            this.stopLoader()
            this.isBookingInProgress = false
            this.$router.push('/mitgliedschaft-buchen-erfolgreich')
          })
        } else {
          this.stopLoader()
          this.isBookingInProgress = false
          console.error(response.body.Error)
          this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.unknown'), 'error')
        }
      }).catch(error => {
        this.stopLoader()
        this.isBookingInProgress = false
        console.error(error)
        this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.unknown'), 'error')
      })
    },
    isProductAvailable (countrySlug) {
      if (this.brandStore.getCurrentMembership === null) return false
      const Membership = this.brandStore.getCurrentMembership
      const slug = countrySlug.toUpperCase()
      return !Membership.Memberships[slug]
    },
    startLoader () {
      this.isLoading = true
    },
    stopLoader () {
      this.isLoading = false
    },
    storeReturnLocation () {
      localStorage.setItem('returnLocationAfterAddressSetup', 'mitgliedschaft-buchen')
    },
    startAddressSetup () {
      this.linkBookingData()
      this.$router.push('/zahlungsdaten-hinterlegen')
    },
    nextWizItem () {
      this.currentWizIndex += 1
      document.body.style.setProperty('--mbr-wiz-index', this.currentWizIndex)
      this.$toast.clear()
    },
    checkBeforeNextWizItem () {
      const requiredFields = this.checkRequiredWizFields()
      if (requiredFields.includes(this.requiredFields[this.currentWizIndex])) {
        this.lastToastNotification = this.$toast(this.$t('membership.bookwizzard.pleaseAnswerQuestion'),
          {
            position: 'top-center',
            showCloseButtonOnHover: false,
            timeout: 10000,
            icon: false
          })
      } else {
        this.nextWizItem()
      }
    }
  },
  mounted: function () {
    const loadedBookingData = this.loadBookingData()
    if (loadedBookingData !== null) {
      loadedBookingData.BookedPortals.forEach(BookedPortalSlug => {
        this.Checkout.SelectedMemberships[BookedPortalSlug] = true
      })
      this.Checkout.Legal.AGB = true
      this.Checkout.Legal.ExternalAllowed = true
    }
  },
  watch: {
    'currentMembership.Checkout': function (newValue) {
      if (newValue !== false && newValue !== null && this.isFQStripeCustomer) {
        const loadedBookingData = this.loadBookingData()
        if (loadedBookingData !== null && this.isFQStripeCustomer && this.isBookingInProgress === false) {
          this.startLoader()
          this.completeMembershipBooking(loadedBookingData)
        }
      }
    },
    $route: function () {
      if (typeof this.Checkout !== 'undefined') {
        this.Checkout = {
          SelectedMemberships: {
            DE: false,
            AT: false,
            CH: false
          },
          Legal: {
            ExternalAllowed: false,
            AGB: false
          },
          PeriodYearly: false
        }
      }
    },
    showWiz: function (oldVal, newVal) {
      this.app.showSaveBar = !oldVal
      this.app.showPageLoadingOnSave = !oldVal
      if (!oldVal) {
        this.$toast.clear()
        this.app.showSaveSuccessPopup = false
        if (this.ComParamFirstPartnerYear === '0') {
          let BrandOperationUnitsID = -1
          const currentYear = new Date().getFullYear()
          const currentYearOperations = this.currentBrand.OperationUnits.filter((unit) => {
            return unit.Year === currentYear
          })
          if (currentYearOperations.length > 0) {
            BrandOperationUnitsID = currentYearOperations[0].BrandOperationUnitsID
          }
          const newYearData = {
            BrandOperationUnitsID,
            BrandID: this.currentBrand.BrandID,
            Year: currentYear,
            PartnerNational: 0
          }
          this.app.API._put('brandoperationunit/', newYearData)
        }
        this.app.saveBrand()
          .then(() => {
            this.app.showSaveSuccessPopup = true
            this.app.showSaveBar = true
          })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.left-side {
  background-image: url("../../assets/images/left-gradient-bg.png");
}

.booking-content {
  .left-side {
    padding-left: 40px;
    padding-right: 40px;

    h1 {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 30px;
    }

    h2 {
      color: #fff;
      font-size: 28px;
      font-weight: 400;
      line-height: 29px;
    }

    ul {
      color: #fff;
      background: url(../../assets/images/membership.png) no-repeat left -150px top 0px;
      list-style: none;
      margin-left: -40px;
      margin-top: 40px;
      padding-left: 300px;
      text-align: left;
      font-weight: 700;
      font-size: 14px;

      li {
        margin-bottom: 15px;
        display: flex;
      }

      i {
        margin-right: 10px;
        font-size: 16px;

        &.fa-check {
          &::before {
            background-color: #fff;
            color: #00B363;
            border-radius: 50%;
            padding: 2px;
            display: grid;
            place-items: center;
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }

  .mb-big-badge {
    transform: rotate(-10deg);
    width: 140px;
    height: 140px;
    margin-top: -100px;

    h3 {
      font-size: 20px;
      line-height: 20px;
    }

    h4 {
      font-size: 18px;
      line-height: 18px;
      margin-top: -20px;
    }

    div {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .period-switch {

    input[type=radio] {
      width: 20px;
      height: 20px;
    }
  }

  .custom-control {
    min-width: 155px;
  }

  .custom-control-label {
    cursor: pointer;

    &::before,
    &::after {
      width: 20px;
      height: 20px;
      left: -30px;
    }
  }
}

.mb-mbr-wiz-slide-cont {
  width: 100%;
  overflow: hidden;
}

.mb-wiz-container {
  width: calc(var(--mbr-wiz-count) * 100%);
  transition: 0.5s ease;
  transform: translateX(calc((-1) * var(--mbr-wiz-index) * (100% / var(--mbr-wiz-count))));
  min-height: 100px;
  display: flex;
}

.mb-wiz-q-cont {
  width: 100%;
  min-height: 100px;
  display: inline-block;
}

.mb-wiz-question {
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.mb-wiz-hidden {
  max-height: 100px;
}

.mb-small-cont {
  margin-inline: auto;
  width: 50%;
  min-width: 150px;
}
</style>
<style lang="less">
.modal-body {
  .custom-control-inline {

    &.custom-radio .custom-control-label,
    &.custom-checkbox .custom-control-label {
      border: 2px solid #e6e6e6;
      border-radius: 10px;
      padding: 5px 10px 5px 30px;
      cursor: pointer;

      &::before,
      &::after {
        top: 0.57rem;
        left: 0.5rem;
      }
    }
  }
}

#bookWizzard {
  .modal-header {
    display: flex;
    justify-content: space-between;
    .close {
      background: transparent;
      outline: none;
      border: none;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .mb-wiz-container {
    div[role="radiogroup"] {
      display: flex;
      justify-content: center;
      gap: 1rem;
    }
  }
}
</style>
