<template>
  <div class="mb-main-content">
    <h1 v-t="'page.rps.title'"></h1>
    <div class="mb-head-desc mb-5" v-html="$t('page.rps.description')"></div>

    <div class="mb-rps">
      <h5 class="mb-card-title" v-t="'page.rps.overview'"></h5>

      <div v-if="currentBrand.BrandID" class="module-rps">
        <div v-if="!rpsbrand">
          <div class="card shadow p-3 mb-5 col-6">
            <div class="card-body">
              <div class="d-flex">
                <label class="w-75" v-t="'page.rps.addon'"></label>
                <label class="w-25" v-t="'state.inactive'"></label>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div :class="'card shadow p-3 mb-5 col-' + String(isMember ? 6 : 12)">
            <div v-if="isMember" class="card-body">
              <div class="d-flex">
                <label class="w-75" v-t="'page.rps.addon'"></label>
                <label
                  v-if="rpsbrand.CampaignsPaid > 0"
                  class="w-25"
                  v-t="'state.active'"
                ></label>
                <label v-else class="w-25" v-t="'state.inactive'"></label>
              </div>
              <div class="d-flex">
                <label class="w-75" v-t="'page.rps.contingent'"></label>
                <label class="w-25">{{ rpsbrand.CampaignsPaid }}</label>
              </div>
              <div class="d-flex">
                <label class="w-75" v-t="'page.rps.activecampaigns'"></label>
                <label class="w-25">{{ rpsbrand.CampaignsActive }}</label>
              </div>
            </div>
            <div
              v-else
              class="card-body"
              v-html="$t('page.rps.nomembership')"
            ></div>
          </div>

          <div class="d-flex justify-content-between">
            <h5 class="mb-card-title" v-t="'page.rps.campaigns'"></h5>
          </div>

          <table class="table">
            <thead>
              <tr>
                <th scope="col" v-t="'page.rps.tableid'"></th>
                <th scope="col" v-t="'address.zip'"></th>
                <th scope="col" v-t="'address.city'"></th>
                <th scope="col" v-t="'page.rps.tabletype'"></th>
                <th scope="col" v-t="'state.title'"></th>
                <th class="last-head" scope="col" />
              </tr>
            </thead>
            <tbody v-if="!rpscampaigns">
              <tr>
                <td colspan="5" v-t="'page.rps.tablenoresults'"></td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="(rpscampaign, rpscampaignIndex) in rpscampaigns"
                :key="rpscampaignIndex"
                :class="{
                  active: formData.RPSCampaignID === rpscampaign.RPSCampaignID,
                }"
              >
                <td>
                  <strong>{{ rpscampaign.RPSCampaignID }}</strong>
                </td>
                <td>{{ rpscampaign.Zip }}</td>
                <td>{{ rpscampaign.City }}</td>
                <td>{{ showCampaignType(rpscampaign.Type) }}</td>
                <td>{{ showCampaignState(rpscampaign.CampaignActive) }}</td>
                <td>
                  <a
                    v-show="
                      rpscampaign.RPSCampaignID !== formData.RPSCampaignID
                    "
                    class="btn btn-sm btn-primary"
                    href="javascript:"
                    @click.prevent="edit(rpscampaign, rpsbrand)"
                  >
                    <i class="fa fa-edit" />
                  </a>
                  <a
                    class="btn btn-sm btn-danger"
                    href="javascript:"
                    @click.prevent="
                      deleteCampaign(rpscampaign, rpscampaignIndex)
                    "
                  >
                    <i class="icon-trash" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <button
            type="button"
            class="btn btn-primary"
            @click.prevent="add(rpsbrand)"
            v-t="'page.rps.newcampaign'"
          ></button>
        </div>
      </div>
    </div>

    <b-modal ref="editModal" no-close-on-backdrop>
      <template v-slot:modal-title>
        {{ computedModalTitle }}
      </template>
      <div class="modal-body">
        <div v-show="formData.RPSCampaignID !== false" class="campaignEditForm">
          <ValidationObserver ref="validator">
            <form class="col-12">
              <div class="row">
                <div class="col-12 form-group">
                  <label for="Title" v-t="'page.rps.campaignname'"></label>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <input
                      key="TitleInput"
                      v-model="formData.Title"
                      class="form-control"
                      name="Title"
                      type="text"
                      maxlength="60"
                    />
                    <span class="errorMessage">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group col-6">
                  <label for="Type"
                    >{{ $t("page.rps.campaigntype") }}
                    <a
                      id="tt_rpsCampaignType"
                      href="javascript:"
                      class="badge badge-warning"
                      >?</a
                    ></label
                  >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    vid="campaignType"
                  >
                    <select
                      key="TypeSelect"
                      v-model="formData.Type"
                      class="form-control"
                      name="Type"
                      @change="onTypeChange"
                    >
                      <option :value="0" v-t="'page.rps.newlocation'"></option>
                      <option
                        :value="1"
                        v-t="'page.rps.existinglocation'"
                      ></option>
                    </select>
                    <span class="errorMessage">{{ errors[0] }}</span>
                  </validation-provider>

                  <b-tooltip target="tt_rpsCampaignType" placement="top">
                    <p v-t="'page.rps.campaigntypehint'"></p>
                  </b-tooltip>
                </div>
                <div class="form-group col-6">
                  <label for="State" v-t="'page.rps.stateofcampaign'"></label>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <select
                      key="StateSelect"
                      v-model="formData.CampaignActive"
                      class="form-control"
                      name="State"
                    >
                      <option
                        v-for="(name, key) in campaignStates"
                        :key="key"
                        :value="key"
                      >
                        {{ name }}
                      </option>
                    </select>
                    <span class="errorMessage">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="divider col-12" v-t="'address.title'"></div>
                <div class="col-12 form-group">
                  <label for="Street" v-t="'address.streetandnumber'"></label>
                  <input
                    key="StreetInput"
                    v-model="formData.Street"
                    class="form-control"
                    name="Street"
                    type="text"
                  />
                </div>

                <div class="col-3 form-group">
                  <label for="Zip">{{ $t("address.zip") }} *</label>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <input
                      key="ZipInput"
                      v-model="formData.Zip"
                      class="form-control"
                      name="Zip"
                      data-vv-name="Zip"
                      type="text"
                      inputmode="numeric"
                      pattern="[0-9]*"
                    />
                    <span class="errorMessage">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="col-3 form-group">
                  <label for="City">{{ $t("address.city") }} *</label>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <input
                      key="CityInput"
                      v-model="formData.City"
                      class="form-control"
                      name="City"
                      type="text"
                    />
                    <span class="errorMessage">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group col-6">
                  <label for="ProvinceID">{{ $t("address.state") }} *</label>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <select
                      key="ProvinceIDSelect"
                      v-model="formData.ProvinceID"
                      class="form-control"
                      name="ProvinceID"
                    >
                      <option
                        v-for="(province, provinceIndex) in app.preloads
                          .provinces"
                        :key="provinceIndex"
                        :value="province.ProvinceID"
                      >
                        {{ province.Caption }}
                      </option>
                    </select>
                    <span class="errorMessage">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group col-6">
                  <label for="CountryCode" v-t="'address.country'"></label>
                  <select
                    key="CountryCodeSelect"
                    v-model="formDataCountryID"
                    class="form-control"
                    name="CountryCode"
                    disabled
                  >
                    <option
                      v-for="country in app.preloads.countries"
                      :key="country.CountryID"
                      :value="country.CountryID"
                    >
                      {{ country.Caption }}
                    </option>
                  </select>
                </div>

                <div class="col-12 form-group">
                  <label for="RealEstate" v-t="'page.rps.properties'"></label>
                  <input
                    key="RealEstateInput"
                    v-model="formData.RealEstate"
                    class="form-control"
                    name="RealEstate"
                    type="text"
                  />
                </div>

                <div class="divider col-12" v-t="'page.rps.moredata'"></div>

                <div class="col-12 form-group">
                  <label v-t="'page.rps.locationdescription'"></label>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <custom-editor v-model="formData.LocationDescription" />
                    <span class="errorMessage">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="col-4 form-group">
                  <label
                    for="TotalInvestment"
                    v-t="'page.rps.totalinvestment'"
                  ></label>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <currency-input
                      key="TotalInvestmentInput"
                      v-model="formData.TotalInvestment"
                      class="form-control"
                      :currency="currentBrand.CondCurrency"
                      name="TotalInvestment"
                      locale="de"
                    />
                    <span class="errorMessage">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="col-4 form-group">
                  <label
                    for="EntryFee"
                    v-t="'page.yourdata.conditions.entrancefee'"
                  ></label>
                  <currency-input
                    key="EntryFeeInput"
                    v-model="formData.EntryFee"
                    class="form-control"
                    :currency="currentBrand.CondCurrency"
                    name="EntryFee"
                    locale="de"
                  />
                </div>
                <div class="col-4 form-group">
                  <label
                    for="EquityCapital"
                    v-t="'page.rps.equitycapital'"
                  ></label>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <currency-input
                      key="EquityCapitalInput"
                      v-model="formData.EquityCapital"
                      class="form-control"
                      :currency="currentBrand.CondCurrency"
                      name="EquityCapital"
                      locale="de"
                    />
                    <span class="errorMessage">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="col-12 form-group">
                  <label
                    for="RemarkInvestment"
                    v-t="'page.rps.remarktotalinvestment'"
                  ></label>
                  <input
                    key="RemarkInvestmentInput"
                    v-model="formData.RemarkInvestment"
                    class="form-control"
                    name="RemarkInvestment"
                    type="text"
                  />
                </div>

                <div class="col-12">
                  <div v-show="formData.Type === 0" class="row">
                    <div
                      class="col-12 divider"
                      v-t="'page.rps.startupdata'"
                    ></div>
                    <div class="col-12 form-group">
                      <label
                        for="CampaignReason"
                        v-t="'page.rps.locationsearchreason'"
                      ></label>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required_if:campaignType,0"
                      >
                        <input
                          key="CampaignReasonInput"
                          v-model="formData.CampaignReason"
                          class="form-control"
                          name="CampaignReason"
                          type="text"
                        />
                        <span class="errorMessage">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                    <div class="col-12 form-group">
                      <label
                        for="AvailableUntil"
                        v-t="'page.rps.availablefrom'"
                      ></label>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required_if:campaignType,0"
                      >
                        <datetime
                          v-model="formData.AvailableUntil"
                          format="dd.MM.yyyy"
                        />
                        <span class="errorMessage">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div v-show="formData.Type === 1" class="row">
                    <div
                      class="col-12 divider"
                      v-t="'page.rps.successiondata'"
                    ></div>

                    <div class="col-12 form-group">
                      <label
                        for="HandoverReason"
                        v-t="'page.rps.handoverreason'"
                      ></label>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required_if:campaignType,1"
                      >
                        <input
                          key="HandoverReasonInput"
                          v-model="formData.HandoverReason"
                          class="form-control"
                          name="HandoverReason"
                          type="text"
                        />
                        <span class="errorMessage">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                    <div class="col-12 form-group">
                      <label
                        for="FoundingYearLocation"
                        v-t="'page.rps.startupyear'"
                      ></label>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required_if:campaignType,1"
                      >
                        <input
                          key="FoundingYearLocationInput"
                          v-model="formData.FoundingYearLocation"
                          class="form-control"
                          name="FoundingYearLocation"
                          type="number"
                        />
                        <span class="errorMessage">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>

                    <div class="col-12 form-group">
                      <label for="LegalForm" v-t="'page.rps.legalform'"></label>
                      <select
                        key="LegalFormSelect"
                        v-model="formData.LegalForm"
                        class="form-control"
                        name="LegalForm"
                      >
                        <option :value="0" v-t="'choice.notspecified'"></option>
                        <option
                          :value="1"
                          v-t="'choice.legalform.onemanbusiness'"
                        ></option>
                        <option
                          :value="2"
                          v-t="'choice.legalform.ohg'"
                        ></option>
                        <option :value="3" v-t="'choice.legalform.kg'"></option>
                        <option
                          :value="4"
                          v-t="'choice.legalform.gmbh'"
                        ></option>
                        <option :value="5" v-t="'choice.legalform.ag'"></option>
                      </select>
                    </div>

                    <div class="col-12 form-group">
                      <label
                        for="NumEmployees"
                        v-t="'page.rps.numberemployees'"
                      ></label>
                      <input
                        key="NumEmployeesInput"
                        v-model="formData.NumEmployees"
                        class="form-control"
                        name="NumEmployees"
                        type="number"
                      />
                    </div>

                    <div class="col-12 form-group">
                      <label
                        for="SalesCategory"
                        v-t="'page.rps.salescategory'"
                      ></label>
                      <select
                        key="SalesCategorySelect"
                        v-model="formData.SalesCategory"
                        class="form-control"
                        name="SalesCategory"
                      >
                        <option :value="0" v-t="'choice.notspecified'"></option>
                        <option
                          :value="1"
                          v-t="'page.rps.salescategory1'"
                        ></option>
                        <option
                          :value="2"
                          v-t="'page.rps.salescategory2'"
                        ></option>
                        <option
                          :value="3"
                          v-t="'page.rps.salescategory3'"
                        ></option>
                        <option
                          :value="4"
                          v-t="'page.rps.salescategory4'"
                        ></option>
                        <option
                          :value="5"
                          v-t="'page.rps.salescategory5'"
                        ></option>
                        <option
                          :value="6"
                          v-t="'page.rps.salescategory6'"
                        ></option>
                        <option
                          :value="7"
                          v-t="'page.rps.salescategory7'"
                        ></option>
                        <option
                          :value="8"
                          v-t="'page.rps.salescategory8'"
                        ></option>
                        <option
                          :value="9"
                          v-t="'page.rps.salescategory9'"
                        ></option>
                        <option
                          :value="10"
                          v-t="'page.rps.salescategory10'"
                        ></option>
                        <option
                          :value="11"
                          v-t="'page.rps.salescategory11'"
                        ></option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button
          variant="danger"
          size="sm"
          class="float-left"
          @click="cancelEdit(cancel)"
        >
          {{ $t("button.cancel") }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="saveEdit(ok)"
        >
          {{ $t("button.save") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CustomEditor from '../../components/CustomEditor'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, required_if as requiredIf } from 'vee-validate/dist/rules'
import { Datetime } from 'vue-datetime'

import { CurrencyInput } from 'vue-currency-input'

import moment from 'moment'

const DEFAULT_FORM_CAMPAIGN = {
  RPSCampaignID: false,
  RPSBrandID: null,
  Profile: null,
  Type: null,
  State: null,
  Title: null,
  Street: null,
  Zip: null,
  City: null,
  CampaignReason: null,
  RealEstate: null,
  LocationDescription: '',
  TotalInvestment: null,
  EntryFee: null,
  EquityCapital: null,
  RemarkInvestment: null,
  StopDate: null,
  HandoverReason: null,
  LegalForm: null,
  NumEmployees: null,
  SalesCategory: null,
  FoundingYearLocation: null,
  TakeoverUntil: null,
  AvailableUntil: null,
  ProvinceID: null,
  CountryID: null
}

export default {
  name: 'RegionalPartnerSearch',
  components: {
    CustomEditor,
    ValidationProvider,
    ValidationObserver,
    Datetime,
    CurrencyInput
  },
  props: {},
  data: function () {
    return {
      currentRS: {},
      currentOriginal: false,
      currentRPSBrand: {},
      rpsbrand: null,
      rpscampaigns: [],
      formData: {},
      formSubmitted: false,
      saveButtonLoader: {},
      campaignStates: {
        0: this.$t('state.inactive'),
        1: this.$t('state.active')
      }
    }
  },
  computed: {
    computedModalTitle () {
      if (this.formData.RPSCampaignID === -1) {
        return this.$t('page.rps.newcampaigntitle')
      }
      return this.$t('page.rps.editcampaigntitle')
    },
    isMember () {
      if (this.currentBrand.Membership >= 3) return true
      return false
    },
    formDataCountryID () {
      if (!this.formData.CountryID) return null
      return (
        this.formData.CountryID ||
        this.preloads.countries.find((country) => {
          return country.Code === 'DE'
        }).CountryID
      )
    },
    app: function () {
      return this.$parent || {}
    },
    currentBrand: function () {
      return this.$parent.currentBrand || {}
    },
    preloads: function () {
      return this.$parent.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    }
  },
  watch: {
    'app.hasCurrentBrand': function () {
      this.loadData()
    },
    $route (to) {
      if (to.path === '/rps') {
        this.loadData()
      }
    }
  },
  mounted () {
    this.loadData()
    const requiredfield = this.$t('validation.requiredfield')
    // Override the default message.
    extend('required', {
      ...required,
      message: requiredfield
    })
    extend('required_if', {
      ...requiredIf,
      message: requiredfield
    })
  },
  methods: {
    add (rpsbrand) {
      this.currentRPSBrand = Object.assign({}, rpsbrand)
      this.$refs.editModal.show()
      this.formData = Object.assign({}, DEFAULT_FORM_CAMPAIGN)
      this.formData.RPSBrandID = rpsbrand.RPSBrandID
      this.formData.RPSCampaignID = -1
      this.currentOriginal = false
      this.formSubmitted = false
    },
    deleteCampaign (rpscampaign, index) {
      this.$swal({
        title: this.$t('popupmessage.warn.areyousuretitle'),
        text: this.$t('popupmessage.warn.deletecampaign', {
          id: rpscampaign.RPSCampaignID,
          zip: rpscampaign.Zip,
          city: rpscampaign.City
        }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('button.yesdelete'),
        cancelButtonText: this.$t('button.no')
      }).then((result) => {
        if (typeof result.value !== 'undefined' && result.value === true) {
          const deleteQuery = ['rpscampaign', rpscampaign.RPSCampaignID].join(
            '/'
          )
          this.app.API._delete(deleteQuery).then((response) => {
            if (
              response.status === 200 &&
              response.body.Result.Result === 'Succeeded'
            ) {
              this.rpscampaigns.splice(this.rpscampaigns.indexOf(index), 1)
              this.$swal(
                this.$t('popupmessage.success.deleted'),
                this.$t('popupmessage.success.campaigndeleted'),
                'success'
              )
              this.loadData()
            }
          })
        }
      })
    },
    edit (rpscampaign, rpsbrand) {
      this.currentRPSBrand = Object.assign({}, rpsbrand)
      this.$refs.editModal.show()
      this.currentOriginal = Object.assign({}, rpscampaign)
      this.formData.RPSBrandID = rpsbrand.RPSBrandID
      this.formData = rpscampaign
      this.formData.CountryCode = 'DE'
      this.formSubmitted = false
    },
    showCampaignType (typeId) {
      const campaignTypes = {
        0: this.$t('page.rps.newlocation'),
        1: this.$t('page.rps.existinglocation')
      }
      return campaignTypes[typeId]
    },
    showCampaignState (stateId) {
      return typeof this.campaignStates[stateId] !== 'undefined'
        ? this.campaignStates[stateId]
        : 0
    },
    loadCampaigns () {
      this.app.API._post('rpscampaigns/', {
        ResFrom: 1,
        ResTo: this.rpsbrand.RPSCampaignIDs.length || 1,
        FullSubEntities: true,
        Filter: [
          {
            Field: 'RPSBrandID',
            Operator: 'EQ',
            Value: this.rpsbrand.RPSBrandID
          }
        ]
      }).then((response) => {
        if (response.body.Result) {
          this.rpscampaigns = response.body.Result.Data.map((campaign) => {
            delete campaign.RPSBrand
            delete campaign.Province

            campaign.AvailableUntil = moment(
              campaign.AvailableUntil
            ).toISOString(true)
            campaign.TakeoverUntil = moment(campaign.TakeoverUntil).toISOString(
              true
            )

            return campaign
          })
          this.reset()
        }
      })
    },
    onTypeChange () {
      if (this.formData.Type === 0) {
        this.formData.HandoverReason = null
        this.formData.FoundingYearLocation = null
        this.formData.LegalForm = null
        this.formData.NumEmployees = null
        this.formData.TakeoverUntil = null
        this.formData.SalesCategory = null
      }

      if (this.formData.Type === 1) {
        this.formData.CampaignReason = null
        this.formData.AvailableUntil = null
      }
    },
    loadData () {
      if (!this.app) return
      if (!this.app.hasCurrentBrand) return

      this.rpsbrand = null
      this.rpscampaigns = []

      this.formData = Object.assign({}, DEFAULT_FORM_CAMPAIGN)

      this.app.API._post('rpsbrands/', {
        ResFrom: 1,
        ResTo: 10,
        FullSubEntities: false,
        Filter: [
          {
            Field: 'BrandID',
            Operator: 'EQ',
            Value: this.currentBrand.BrandID
          }
        ]
      }).then((response) => {
        if (response.body.Result) {
          this.rpsbrand = Object.assign({}, response.body.Result.Data.shift())
          this.loadCampaigns()
        }
      })
    },
    startSaveBtnLoader () {
      if (typeof this.saveButtonLoader.start === 'function') {
        this.saveButtonLoader.start()
      }
    },
    stopSaveBtnLoader () {
      if (
        typeof this.saveButtonLoader !== 'undefined' &&
        typeof this.saveButtonLoader.stop === 'function'
      ) {
        this.saveButtonLoader.stop()
      }
    },
    reset () {
      if (
        typeof this.$validator !== 'undefined' &&
        typeof this.$validator.reset === 'function'
      ) {
        this.$validator.reset()
      }
      this.formData = Object.assign({}, DEFAULT_FORM_CAMPAIGN)
    },
    cancelEdit (callback) {
      callback()
      this.reset()
    },
    saveEdit (cb) {
      this.$refs.validator.validate().then((isValid) => {
        if (isValid) {
          this.saveCampaign(cb)
        }
      })
    },
    saveCampaign (cb) {
      this.formSubmitted = true

      this.startSaveBtnLoader()

      const findChangedData = (before, after) => {
        const strippedAfter = Object.assign({}, after)
        if (before === false) return strippedAfter

        const changedData = {}
        const strippedBefore = Object.assign({}, before)

        for (const index in strippedBefore) {
          if (
            typeof strippedBefore[index] !== 'undefined' &&
            typeof strippedAfter[index] !== 'undefined'
          ) {
            const isDataChanged =
              String(strippedBefore[index]) !== String(strippedAfter[index])

            if (isDataChanged) {
              changedData[index] = strippedAfter[index]
            }
          }
        }

        return Object.assign({}, changedData)
      }

      let isNew = false
      const saveQuery =
        this.currentOriginal === false
          ? Object.assign({}, this.formData)
          : findChangedData(this.currentOriginal, this.formData)

      if (saveQuery.TakeoverUntil) {
        saveQuery.TakeoverUntil = moment(saveQuery.TakeoverUntil).toISOString(
          true
        )
      }

      if (saveQuery.AvailableUntil) {
        saveQuery.AvailableUntil = moment(saveQuery.AvailableUntil).toISOString(
          true
        )
      }

      saveQuery.RPSCampaignID = parseInt(this.formData.RPSCampaignID)

      if (saveQuery.RPSCampaignID === -1) {
        isNew = true
      }

      this.clean(saveQuery)

      if (typeof saveQuery.CampaignActive !== 'undefined') {
        if (
          this.formData.CampaignActive === '1' &&
          this.currentRPSBrand.CampaignsActive >=
            this.currentRPSBrand.CampaignsPaid
        ) {
          this.$swal(
            this.$t('popupmessage.warn.attentiontitle'),
            this.$t('popupmessage.error.maxcampaignreached'),
            'error'
          )
          return this.stopSaveBtnLoader()
        }

        const intCampaignState = parseInt(saveQuery.CampaignActive)

        if (intCampaignState === 0) {
          saveQuery.State = 4
        } else {
          saveQuery.State = 2
        }
      }

      delete saveQuery.CampaignActive

      this.app.API._put('rpscampaign/', saveQuery).then((response) => {
        if (response.body.Result) {
          if (isNew && typeof response.body.Result.NewID !== 'undefined') {
            const newId = parseInt(response.body.Result.NewID)
            saveQuery.RPSCampaignID = newId
            this.rpscampaigns.push(saveQuery)
          } else {
            if (response.body.Result.Result === 'Failed') {
              return this.$swal(
                this.$t('popupmessage.error.title'),
                this.$t('popupmessage.error.unknown'),
                'error'
              )
            }
          }
          cb()
          this.stopSaveBtnLoader()
          this.$swal(
            this.$t('popupmessage.success.savedtitle'),
            this.$t('popupmessage.success.campaignsaved'),
            'success'
          )
          this.formSubmitted = false
          this.loadData()
        }
      })
    },
    strlen: function (x) {
      if (!x) return 0
      return x.length
    },
    nothing () {
      return true
    },
    clean (obj) {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
    }
  }
}
</script>

<style scoped>
.modal-body .static-data p {
  padding: 0 1rem;
}
.modal-body .errorMessage {
  color: red;
  padding-top: 0.33rem;
  font-size: 75%;
  margin-left: 0.33rem;
}
.table tr.active td {
  background-color: #daedf4;
}
.table th.last-head {
  width: 100px;
}
.table td {
  vertical-align: middle;
}
.table tr td:last-child {
  text-align: right;
}
.mx-datepicker {
  display: block;
  width: 100%;
}
.reload-button {
  margin-top: -4px;
}
.campaignEditForm {
  padding-bottom: 1.66rem;
  background-color: white;
}
.divider {
  font-weight: bold;
  margin-bottom: 0.33rem;
}
</style>
