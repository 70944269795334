<!-- component template -->
<template>
    <div class="multiselect-list">
        <span
          v-bind="computedOptions"
          v-for="(option, index) in computedOptions"
          :key="index"
          class="lst-item"
          :class="getCssClass(option, index)"
        >
          <span>
            <input type="checkbox" :value="option[identifier]" :id="identifier + option[identifier]" v-model="computedValue">
            <label :for="identifier + option[identifier]" v-text="option[label]"></label>
          </span>
        </span>
    </div>
</template>

<script>
export default {
  name: 'MultiselectList',
  components: {},
  props: ['identifier', 'label', 'value', 'options', 'showFirstItemSeparately'],
  data: function () {
    return {
      isLoading: false
    }
  },
  methods: {
    checkArrayForNoServiceValue (arValue) {
      if (arValue == null || arValue.length === 0) return arValue

      const noServiceCaptionDE = this.$t('providedmanagementassistances.20', 'de')
      const noServiceCaptionEN = this.$t('providedmanagementassistances.20', 'en')
      const valLength = arValue.length - 1
      let newItem = this.computedOptions.filter(option => {
        return option[this.identifier] === arValue[valLength]
      })
      if (newItem.length > 0) {
        newItem = newItem[0]
      }

      if (newItem.Caption === noServiceCaptionDE ||
          newItem.Caption === noServiceCaptionEN) {
        arValue = [arValue[valLength]]
      } else {
        let firstItem = this.computedOptions.filter(option => {
          return option[this.identifier] === arValue[0]
        })
        if (firstItem.length > 0) {
          firstItem = firstItem[0]
        }
        if (firstItem.Caption === noServiceCaptionDE ||
            firstItem.Caption === noServiceCaptionEN) {
          arValue = arValue.splice(1, 1)
        }
      }

      return arValue
    },
    getCssClass (option, index) {
      let cssClass = ''
      cssClass += this.showFirstItemSeparately && index === 0 ? 'lst-first' : ''
      cssClass += this.computedValue.includes(option[this.identifier]) ? ' mb-checked' : ''

      return cssClass
    }
  },
  computed: {
    computedOptions () {
      return this.options || []
    },
    computedValue: {
      get: function () {
        if (typeof this.value === 'undefined') return []
        return this.value
      },
      set: function (newValue = []) {
        newValue = this.checkArrayForNoServiceValue(newValue)
        this.$emit('input', newValue)
        this.$emit('change', newValue)
      }
    }
  },
  watch: {
  },
  mounted: function () {
  }
}
</script>

<style lang="less" scoped>
.lst-item {
  display: inline-block;
  padding: 5px;
  border: 2px solid #e6e6e6;
  border-radius: 10px;
  margin: 5px;

  &.mb-checked {
    border-color: #00B363;
  }

  span {
    display: flex;
    align-items: center;
    position: relative;
  }

  label {
    margin-bottom: 0px;
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      vertical-align: -25%;
      height: 20px;
      width: 20px;
      background-color: white;
      border: 1px solid rgb(166, 166, 166);
      border-radius: 4px;
      margin-right: 0.5em;
      position: relative;
      top: -1px;
    }

    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 8px;
      top: 6px;
      width: 5px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  input {
    width: 0px;
    height: 0px;
    position: absolute;
    visibility: hidden;

    &:checked {
      & ~ label {
        &::before {
          background-color: #00B363;
        }
        &::after {
          display: block;
        }
      }
    }
  }
}

.lst-first {
  display: block;
  width: 100%;
  border: none;
}
</style>
