<template>
    <div class="mb-map-chart">
      <div class="mb-map-chart p-4 mb-4">
        <h2 v-t="'page.statistics.leadsmap.title'"></h2>
        <div class="mb-map-legend mb-4" v-t="'page.statistics.leadpies.totalinperiod'"></div>
        <div id="mb-map-container">
          <img src="../../assets/images/map-02.png" width="1000px">
          <span
            v-for="(data, idx) in mapHeadquarter"
            :key="idx"
            class="mb-map-icon mb-building"
            :data-zip="data.zip_code"
            :style="'top: ' + data.x + 'px; left: ' + data.y + 'px;'"
            data-toggle="tooltip"
            data-html="true"
            :title="$t('page.statistics.leadsmap.headquarter')"
            v-b-tooltip.html
            placement="auto"
          >
            <img src="../../assets/images/icons/office-building.svg" />
          </span>
          <span
            v-for="(data, index) in mapLeadPointer"
            :key="index"
            class="mb-map-icon" :data-zip="data.zip_code"
            :style="'top: ' + data.x + 'px; left: ' + data.y + 'px;'"
            data-toggle="tooltip"
            data-html="true"
            :title="getTooltipContent(data.zip_code)"
            v-b-tooltip.html
            placement="auto"
          >
            <img src="../../assets/images/icons/pin-02.svg" />
          </span>
        </div>
        <div v-if="isLoading" class="mb-loading"><span v-t="'page.statistics.loading'"></span></div>
        <div v-if="!isLoading && showNoDataHint" class="mb-no-chart-data"><span v-t="'page.statistics.nodata'"></span></div>
      </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
  components: {},
  name: 'LeadsMap',
  props: ['startDate', 'endDate'],
  data: function () {
    return {
      isLoading: false,
      showNoDataHint: false,
      leadsData: [],
      mapLeadPointer: [],
      mapHeadquarter: [],
      toolTipLeads: {}
    }
  },
  computed: {
    currentBrand () {
      const current = this.app.currentBrand || {}
      return current
    },
    app: function () {
      return this.$root.getApp() || {}
    }
  },
  created: function () {
  },
  methods: {
    getChartData () {
      if (this.isLoading || this.currentBrand.BrandID == null) return
      this.isLoading = true
      let resultZentrale = null
      resultZentrale = this.getGeoDataByZipCodes(this.currentBrand.Company.Zip, this.currentBrand.Company.CountryCode)
      resultZentrale.then((result) => {
        result.result.forEach(reqRes => {
          this.mapHeadquarter.push(reqRes)
        })
      })
      const momentStarDate = moment(this.startDate, 'DD.MM.YYYY')
      const momentEndDate = moment(this.endDate, 'DD.MM.YYYY')
      const postData = {
        ResFrom: 1,
        ResTo: 1000,
        FullSubEntities: true,
        Filter: [
          {
            Field: 'Created',
            Operator: 'GE',
            Value: momentStarDate.format('YYYY-MM-DD 00:00:00')
          },
          {
            Field: 'Created',
            Operator: 'LT',
            Value: momentEndDate.format('YYYY-MM-DD 23:59:59')
          },
          {
            Field: 'BrandID',
            Operator: 'EQ',
            Value: this.currentBrand.BrandID
          }
        ]
      }
      this.app.API._post('leads/', postData)
        .then(response => {
          this.leadsData = response.body.Result.Data
          console.info(response)
          if (this.leadsData == null || this.leadsData.length === 0) {
            this.showNoDataHint = true
            this.isLoading = false
            this.mapLeadPointer = []
          } else this.showNoDataHint = false
          const countryLeads = {
            de: [],
            at: [],
            ch: []
          }
          this.leadsData.forEach(lead => {
            const contact = lead.Contact
            let countryID = lead.LocationCountryID
            if (countryID == null) countryID = contact.CountryID
            let zipCode = lead.LocationZip
            if (zipCode == null) zipCode = contact.Zip
            if (countryID === 1) countryLeads.de.push(zipCode)
            if (countryID === 2) countryLeads.at.push(zipCode)
            if (countryID === 5) countryLeads.ch.push(zipCode)
          })
          let resultDE = null
          let resultAT = null
          let resultCH = null
          if (countryLeads.de.length > 0) resultDE = this.getGeoDataByZipCodes(countryLeads.de.toString(), 'de')
          if (countryLeads.at.length > 0) resultAT = this.getGeoDataByZipCodes(countryLeads.at.toString(), 'at')
          if (countryLeads.ch.length > 0) resultCH = this.getGeoDataByZipCodes(countryLeads.ch.toString(), 'ch')
          Promise.all([resultDE, resultAT, resultCH])
            .then((result) => {
              const mapLeads = []
              result.forEach(countryResults => {
                if (countryResults != null) {
                  countryResults.result.forEach(reqRes => {
                    mapLeads.push(reqRes)
                  })
                }
              })
              this.mapLeadPointer = mapLeads
            })
        }).finally(() => {
          this.isLoading = false
        })
    },
    getGeoDataByZipCodes (zipCodes, country) {
      return new Promise((resolve, reject) => {
        const postData = {
          zip_codes: zipCodes,
          country,
          mapSouth: 45.3,
          mapNorth: 54.8,
          mapEast: 17.9,
          mapWest: 5.6,
          imgWidth: 1000,
          imgHeight: 1109
        }
        this.app.API.getGeoDataByZipCodes(postData)
          .then(result => {
            if (!result.status) {
              reject(new Error(result.message))
            } else {
              resolve(result)
            }
          })
      })
    },
    getTooltipContent (zipCode) {
      if (zipCode == null) return ''
      const leads = this.leadsData.filter((lead) => { return lead.Contact.Zip === zipCode || lead.LocationZip === zipCode })
      let leadCount = 0
      let ttHtml = ''
      let city = leads[0].LocationCity
      if (city == null) city = leads[0].Contact.City
      if (leads.length > 0) ttHtml += '<div class="mb-1"><strong>' + zipCode + ' ' + city + '</strong></div>'
      leads.forEach(lead => {
        ttHtml += '<div' + (leadCount > 0 ? ' class="mt-2"' : '') + '>' + lead.Contact.Firstname + ' ' + lead.Contact.Lastname + '</div>'
        ttHtml += '<div>' + this.$t('page.statistics.leadsmap.leadfrom') + ' <strong>' + moment(lead.Created).format('DD.MM.YYYY') + '</strong></div>'
        leadCount++
      })

      return ttHtml
    }
  },
  watch: {
    startDate: function () {
      this.getChartData()
    },
    endDate: function () {
      this.getChartData()
    }
  },
  mounted: function () {
    this.getChartData()
  }
}
</script>

<style lang="less">
.mb-map-chart {
  min-height: 400px;
  position: relative;

  h2, .mb-map-legend {
    text-align: center;
  }

  .col-4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.mb-no-chart-data, .mb-loading {
  background-color: rgba(255,255,255,0.5);
  position: absolute;
  display: grid;
  place-items: center;
  height: calc(100% - (1.5rem * 2));
  width: calc(100% - (1.5rem * 2));
  font-weight: 700;
  font-size: 20px;
  z-index: 1;
  top: 1.5rem;
}
#mb-map-container {
  position: relative;
}
.mb-map-icon {
  position: absolute;
  color: green;
  z-index: 1;
  display: block;
  width: 40px;
  height: 40px;

  img {
    width: 100%;
  }

  &.mb-building {
    width: 60px;
    height: 60px;
  }
}
.b-tooltip {
  .tooltip-inner {
    background-color: #fff;
    color: #353535;
  }

  .arrow::before {
    border-top-color: #fff !important;
  }
}
</style>
