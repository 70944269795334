<template>
  <FairFieldEditor title="Häufig gestellte Fragen (FAQ)" :index="16" :is-modal-open.sync="isModalOpen"
    :loading="isLoading" @save="handleSave" :saveDisabled="!computedIsValid" buttonSaveText="Änderungen speichern"
    @toggle="toggleContent" :usePageStore="true" @update:isModalOpen="updateFormFields">

    <div class="d-flex flex-column gap-3 w-100">

      <FaqQuestionContainer v-for="(faq, index) of computedPreSelectedFaqs" :key="index" :faqId="faq.FaqID"
        :title="rewrittenQuestion(faq)" :index="index + 1" :isOpen="computedCurrentFaqIsOpen(index + 1)"
        @toggle="toggleFaq" :showSelect="false">
        <div v-html="rewrittenAnswer(faq)" />
      </FaqQuestionContainer>

    </div>

    <template v-slot:editButtonCaption>
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-pencil"></i>
        <span>Auswahl ändern</span>
      </div>
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/5_FAQ.png" />
    </template>

    <template v-slot:edit>
      <p>Die häufig gestellten Fragen werden ganz unten am Messestand angezeigt und sollen mögliche Einwände
        von zögernden Interessenten entkräften.</p>

      <hr />

      <div class="d-flex flex-column gap-4">

        <div class="d-flex flex-column align-items-start gap-3">
          <div class="special-headline bg-green">Fragen / Antworten speziell für Ihre Marke</div>
          <div>Diese Fragen / Antworten haben wir speziell für Ihren Messestand als Einwandbehandlung geschrieben.
            Sollten
            sie Fehler enthalten, melden Sie sich bitte bei uns.</div>
        </div>

        <div class="d-flex flex-column gap-3 w-100">

          <FaqQuestionContainer v-for="(faq, index) of computedBrandFaqs" :key="faq.FaqID"
            :title="rewrittenQuestion(faq)"
            :faqId="faq.FaqID" :index="index + 1" :isOpen="computedCurrentBrandFaqIsOpenInsideModal(index + 1)"
            @toggle="toggleBrandFaqInsideModal" :selected="formFields.brandSelected" :showSelect="false">
            <div v-html="rewrittenAnswer(faq)" />
          </FaqQuestionContainer>

        </div>

      </div>
      <hr />

      <div class="d-flex flex-column gap-4">

        <div class="d-flex flex-column align-items-start gap-3">
          <div class="special-headline bg-orange">Weitere Fragen / Antworten zur Auswahl</div>
          <div>Lesen Sie die zusätzlich verfügbaren Fragen / Antworten durch und entscheiden Sie, welche davon zu Ihrem
            Geschäftskonzept passend sind und an Ihrem Messestand angezeigt werden sollen. </div>
        </div>

        <div class="d-flex flex-column gap-3 w-100">

          <FaqQuestionContainer v-for="(faq, index) of computedAllFaqs" :key="faq.ExternalID"
            :title="rewrittenQuestion(faq)" @select="handleFaqSelect" @deselect="handleFaqDeSelect"
            :faqId="faq.ExternalID" :index="index + 1" :isOpen="computedCurrentFaqIsOpenInsideModal(index + 1)"
            @toggle="toggleFaqInsideModal" :selected="formFields.defaultSelected">
            <div v-html="rewrittenAnswer(faq)" />
          </FaqQuestionContainer>

        </div>

      </div>

      <hr />

      <div class="d-flex flex-column w-100 gap-3">
        <div class="d-flex flex-column gap-1">
          <span class="fw-bold">Anforderungen:</span>
          <ul class="special">
            <li :class="{
              success: computedSelectedFaqIds.length >= 3,
              error: computedSelectedFaqIds.length < 3
            }">Mindestens 3 Fragen müssen ausgewählt sein</li>
            <li :class="{
              success: computedSelectedFaqIds.length <= 6,
              error: computedSelectedFaqIds.length > 6
            }">Maximal 6 Fragen dürfen ausgewählt werden</li>
            <li>Die Fragen / Antworten müssen zu Ihrem Geschäftskonzept passen (Quereinstieg / Konditionen /
              angebotene Unterstützungen etc.)</li>
            <li>Eigene, individuelle Fragen / Antworten sind aktuell noch nicht möglich</li>
          </ul>
        </div>
      </div>

    </template>

  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import FaqQuestionContainer from '@/components/FaqQuestionContainer.vue'
import { useBrandStore } from '@/stores/brandStore'

export default {
  name: 'FairFormFAQ',
  components: {
    FairFieldEditor,
    FaqQuestionContainer
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      openedFaqIndexes: [1],
      openedFaqIndexesInsideModal: [],
      openedFaqIndexesInsideModalBrand: [],
      formFields: {
        brandSelected: [],
        defaultSelected: []
      },
      faq: {
        default: [],
        brand: [],
        selected: []
      }
    }
  },
  methods: {
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    updateFormFields () {
      this.formFields.defaultSelected = this.computedPreSelectedFaqIds
    },
    async loadFaqs () {
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Änderungen erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Änderungen ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    rewrittenQuestion (question) {
      return String(question.Question ?? '')
        .replaceAll('{{EXPANSIONSFORM}}', this.computedExpansionFormCaption)
        .replaceAll('{{MARKE}}', this.currentBrand.Name)
    },
    rewrittenAnswer (answer) {
      return String(answer.Answer ?? '')
        .replaceAll('{{EXPANSIONSFORM}}', this.computedExpansionFormCaption)
        .replaceAll('{{MARKE}}', this.currentBrand.Name)
    },
    toggleFaq (index) {
      if (this.openedFaqIndexes.includes(index)) {
        this.openedFaqIndexes = this.openedFaqIndexes.filter(i => i !== index)
      } else {
        this.openedFaqIndexes.push(index)
      }
    },
    toggleFaqInsideModal (index) {
      if (this.openedFaqIndexesInsideModal.includes(index)) {
        this.openedFaqIndexesInsideModal = this.openedFaqIndexesInsideModal.filter(i => i !== index)
      } else {
        this.openedFaqIndexesInsideModal.push(index)
      }
    },
    handleFaqSelect (faqId) {
      if (this.formFields.defaultSelected.includes(faqId)) {
        return
      }
      this.formFields.defaultSelected.push(faqId)
      this.formFields.defaultSelected.sort((a, b) => a - b)
    },
    handleFaqDeSelect (faqId) {
      this.formFields.defaultSelected = this.formFields.defaultSelected.filter(faqIdInArray => faqIdInArray !== faqId)
    },
    handleBrandFaqSelect (faqId) {
      if (this.formFields.brandSelected.includes(faqId)) {
        return
      }
      this.formFields.brandSelected.push(faqId)
      this.formFields.brandSelected.sort((a, b) => a - b)
    },
    handleBrandFaqDeSelect (faqId) {
      this.formFields.brandSelected = this.formFields.brandSelected.filter(faqIdInArray => faqIdInArray !== faqId)
    },
    toggleBrandFaqInsideModal (index) {
      if (this.openedFaqIndexesInsideModalBrand.includes(index)) {
        this.openedFaqIndexesInsideModalBrand = this.openedFaqIndexesInsideModalBrand.filter(i => i !== index)
      } else {
        this.openedFaqIndexesInsideModalBrand.push(index)
      }
    },
    async submitForm () {
      await this.app.APIService._post('mb/fair/update/16', {
        brandId: this.currentBrand.BrandID,
        value: this.formFields.defaultSelected
      })
      const brandStore = useBrandStore()
      brandStore.setDefaultSelectedFaqIds(this.formFields.defaultSelected)
    }
  },
  computed: {
    computedIsValid () {
      return this.formFields.defaultSelected.length >= 3 && this.formFields.defaultSelected.length <= 6
    },
    computedExpansionFormCaption () {
      if (this.currentBrand) {
        if (this.currentBrand.ConceptExpansionFormFranchise) return 'Franchise'
        if (this.currentBrand.ConceptExpansionFormLicense) return 'Lizenz'
      }
      return ''
    },
    computedDefaultFaqs () {
      const brandStore = useBrandStore()
      return brandStore.getDefaultFaqs
    },
    computedDefaultSelectedExternalIds () {
      const brandStore = useBrandStore()
      return brandStore.getDefaultSelectedFaqIds
        .filter((externalId) => {
          return externalId !== 13
        })
    },
    computedBrandFaqs () {
      const brandStore = useBrandStore()
      return brandStore.getBrandFaqs
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    },
    computedPreSelectedFaqs () {
      return [
        ...this.computedBrandFaqs,
        ...this.computedDefaultSelectedExternalIds.map((externalId) => {
          return this.computedDefaultFaqs.find(faq => faq.ExternalID === externalId)
        })
      ].filter((faq) => {
        return faq.ExternalID !== 13
      })
    },
    computedPreSelectedFaqIds () {
      return this.computedDefaultSelectedExternalIds
    },
    computedAllFaqs () {
      return this.computedDefaultFaqs.filter(faq => {
        return faq.ExternalID !== 13
      })
    },
    computedSelectedFaqIds () {
      return this.formFields.defaultSelected
    },
    computedCurrentFaqIsOpen () {
      return function (tab) {
        return this.openedFaqIndexes.includes(tab)
      }
    },
    computedCurrentFaqIsOpenInsideModal () {
      return function (tab) {
        return this.openedFaqIndexesInsideModal.includes(tab)
      }
    },
    computedCurrentBrandFaqIsOpenInsideModal () {
      return function (tab) {
        return this.openedFaqIndexesInsideModalBrand.includes(tab)
      }
    }
  },
  async mounted () {
    this.isLoading = true
    await this.loadFaqs()
    this.isLoading = false
  }
}
</script>

<style lang="scss" scoped>
.rps-entry-row {
  display: flex;
  background-color: #EFF1F4;
  width: 100%;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  .type {
    width: 150px;
  }

  .place {
    font-weight: 700;
  }

}

.special-headline {
  color: white;
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;

  &.bg-green {
    background: #91C124;
  }

  &.bg-orange {
    background: #D97706;
  }
}
</style>
