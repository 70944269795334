<template>
  <div class="d-flex align-items-center justify-content-start">
    <span>{{ $t(`country.${countryCode}`) }}</span>
    <router-link
      class="d-flex justify-content-center align-items-center"
      v-if="computedIsLink"
      to="/mitgliedschaft-buchen"
    >
      <inline-svg :src="computedMembershipState" />
    </router-link>
    <inline-svg v-else :src="computedMembershipState" />
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import ClockIcon from '@/assets/images/icons/3.0/clock.svg'
import AddIcon from '@/assets/images/icons/3.0/add.svg'
import CheckIcon from '@/assets/images/icons/3.0/check.svg'
import { useBrandStore } from '@/stores/brandStore'

export default {
  name: 'HeaderMembershipLink',
  components: {
    InlineSvg
  },
  data: function () {
    return {
      brandStore: useBrandStore()
    }
  },
  props: {
    countryCode: {
      type: String,
      required: true
    }
  },
  computed: {
    computedMembershipState () {
      if (
        this.brandStore.getLoadingMembership ||
        this.brandStore.getCurrentMembership === null
      ) {
        return ClockIcon
      }

      return this.computedIsLink ? AddIcon : CheckIcon
    },
    computedIsLink () {
      if (
        this.brandStore.getLoadingMembership ||
        this.brandStore.getCurrentMembership === null
      ) {
        return false
      }

      const memberships = this.brandStore.getCurrentMembership.Memberships
      const countryCodeUpper = this.countryCode.toUpperCase()

      if (memberships[countryCodeUpper]) {
        return false
      }

      return true
    }
  }
}
</script>
