<template>
  <div class="booking-content" v-if="hasCurrentBrand">
    <div class="mb-main-content">
      <div class="row mb-cb-top">
        <div class="col-lg-6 mb-cb-leftcol">
          <div class="mb-cb-prev">
            <h4 v-t="'page.chatbot.title'"></h4>
            <div v-t="'page.chatbot.description'"></div>
            <img :src="require('../../assets/images/chatbot.png')">
          </div>
        </div>
        <div class="col-lg-6 mb-cb-rightcol">
          <div class="mb-cb-buchen">
            <div class="mb-3" v-if="!app.isActiveMember">
              <span v-t="'page.newsletter.membershiprequiredpart1'"></span>
              <router-link to="/mitgliedschaft-buchen">{{ $t('page.newsletter.membership') }}</router-link>
              <span v-t="'page.newsletter.membershiprequiredpart2'"></span>
            </div>
            <div class="products">
              <Product
                country="de"
                v-model="Checkout.SelectedChatbots.DE"
                :available="isAvailable('DE')"
                :alreadyBooked="isAlreadyBooked('DE')"
                @costlimit="costLimitChangeDE"
              />
              <Product
                country="at"
                v-model="Checkout.SelectedChatbots.AT"
                :available="isAvailable('AT')"
                :alreadyBooked="isAlreadyBooked('AT')"
                @costlimit="costLimitChangeAT"
              />
              <Product
                country="ch"
                v-model="Checkout.SelectedChatbots.CH"
                :available="isAvailable('CH')"
                :alreadyBooked="isAlreadyBooked('CH')"
                @costlimit="costLimitChangeCH"
              />
              <div class="mb-3" v-t="'page.membership.termsinfo'"></div>
            <CustomCheckbox
              :text="$t('page.membership.externalallowed', { company: currentCompany.Name })"
              v-model=Checkout.Legal.ExternalAllowed
              class="mb-terms"
            ></CustomCheckbox>

            <CustomCheckbox
              :text="$t('page.chatbot.agb')"
              v-model=Checkout.Legal.AGB
              class="mb-terms"
              onlyCheckboxClick=true
            ></CustomCheckbox>
            <button
              class="btn final-cta w-100 mt-3"
              @click="bookChatbot"
              :disabled="!hasProductsSelected"
              v-t="'page.chatbot.bookbutton'"
            ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ChatbotShowcase />
  </div>
</template>

<script>
import ChatbotShowcase from './ChatbotShowcase'
import Product from '../../components/CustomProductChatbotContainer'
import CustomCheckbox from '../../components/CustomCheckboxContainer'

export default {
  name: 'Chatbot',
  props: [],
  data: function () {
    return {
      alreadyBookedChatbots: {
        DE: false,
        AT: false,
        CH: false
      },
      alreadyBookedChatbotsLoaded: false,
      Checkout: {
        SelectedChatbots: {
          DE: false,
          AT: false,
          CH: false
        },
        CostLimit: {
          DE: 0,
          AT: 0,
          CH: 0
        },
        Legal: {
          ExternalAllowed: false,
          AGB: false
        }
      },
      scriptIsLoaded: false,
      scriptInterval: null
    }
  },
  components: {
    ChatbotShowcase,
    Product,
    CustomCheckbox
  },
  computed: {
    hasCurrentBrand () {
      return typeof this.currentBrand.BrandID !== 'undefined'
    },
    currentBrand () {
      const current = this.$parent.currentBrand || {}
      return current
    },
    currentCompany () {
      return this.currentBrand.Company
    },
    currentMembership: function () {
      return this.app.currentMembership || {}
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    hasProductsSelected () {
      return this.Checkout.Legal.ExternalAllowed && this.Checkout.Legal.AGB &&
             (
               (this.Checkout.SelectedChatbots.DE && this.Checkout.CostLimit.DE >= 500) ||
               (this.Checkout.SelectedChatbots.AT && this.Checkout.CostLimit.AT >= 500) ||
               (this.Checkout.SelectedChatbots.CH && this.Checkout.CostLimit.CH >= 500)
             )
    }
  },
  methods: {
    isAvailable (country) {
      if (typeof this.currentMembership === 'undefined') return false
      if (typeof this.currentMembership.Memberships === 'undefined') return false
      return this.currentMembership.Memberships[country]
    },
    isAlreadyBooked (country) {
      return this.alreadyBookedChatbots[country]
    },
    costLimitChangeDE (value) {
      this.Checkout.CostLimit.DE = value
    },
    costLimitChangeAT (value) {
      this.Checkout.CostLimit.AT = value
    },
    costLimitChangeCH (value) {
      this.Checkout.CostLimit.CH = value
    },
    bookChatbot () {
      const chatbotLoader = this.app.startLoader()
      const loginFromStorage = localStorage.getItem('FPMB_Login')
      const login = loginFromStorage ? JSON.parse(loginFromStorage) : {}
      this.app.APIStripe._post(
        'completeChatbotBooking',
        {
          BrandID: this.currentBrand.BrandID,
          ...this.Checkout,
          UserMail: login.user.Email
        }
      ).then(async (result) => {
        this.getAvailableChatbots()
        this.$router.push('/chatbot-buchen-erfolgreich')
      }).catch(error => {
        console.error(error)
        this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.occurred'), 'error')
      }).finally(() => {
        this.app.stopLoader(chatbotLoader)
      })
    },
    getAvailableChatbots () {
      if (!this.hasCurrentBrand) return false
      return new Promise(resolve => {
        this.app.APIStripe._post(
          'getAlreadyBookedChatbots',
          {
            BrandID: this.currentBrand.BrandID
          }
        ).then(result => {
          this.Checkout = Object.assign({}, {
            SelectedChatbots: {
              DE: false,
              AT: false,
              CH: false
            },
            CostLimit: {
              DE: 0,
              AT: 0,
              CH: 0
            },
            Legal: {
              ExternalAllowed: false,
              AGB: false
            }
          })
          this.alreadyBookedChatbots = Object.assign({}, result.body.Packages.alreadyBookedChatbots)
          this.app.stopLoader()
        }).catch(error => {
          console.error(error)
          this.app.stopLoader()
          this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.occurred'), 'error')
        }).finally(resolve)
      })
    }
  },
  mounted: function () {
    this.getAvailableChatbots()
  },
  watch: {
    'app.hasCurrentBrand': function () {
      this.getAvailableChatbots()
    }
  }
}
</script>

<style lang="less" scoped>
.mb-first-container {
  margin-top: 80px;
}
.dashboard-logo {
  max-width: 350px;
}
.blueCard {
  background-color: #019FE0;
  border-radius: 10px;
  display: block;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 1rem;
}
.blueCard span {
  text-transform: uppercase;
  font-weight: bold;
}

.mb-main-content {
  background-color: #f3f3f3;
  padding-bottom: 60px !important;
  min-height: inherit !important;
}

.mb-cb-prev {
  background: url("../../assets/images/waves-bg.svg") no-repeat;
  background-size: cover;
  text-align: center;
  padding: 40px 20px 20px;
  color: #fff;
  font-size: 20px;
  min-height: 600px;
  height: 100%;

  h4 {
    font-weight: 700;
  }

  img {
    margin-top: 120px;
  }
}

.mb-cb-leftcol {
  padding-right: 0px;
  align-items: stretch;
}

.mb-cb-rightcol {
  padding-left: 0px;
}

.mb-cb-buchen {
  background-color: #fff;
  height: 100%;
  padding: 40px;
}

</style>
