<template>
  <div id="leads" class="mb-main-content" v-if="hasCurrentBrand">
    <h1>{{ $t("menue.talentpool") }}</h1>

    <div class="sorting-row d-flex justify-content-end">
      <a href="javascript:">
        <inline-svg
          width="16"
          height="16"
          :src="require('@/assets/images/icons/3.0/export.svg')"
        />
        Exportieren
      </a>
    </div>

    <p v-if="isLoading">{{ $t("page.contactrequests.loadingcontacts") }}</p>
    <div v-else class="d-flex">
      <AnonymousContactItem
        v-for="currentLead in currentLeadsByScore"
        :key="currentLead.LeadID"
        :currentContact="currentLead"
      />
    </div>

    <p v-if="computedShowEmptyResult">
      Keine Profile im Talentpool gefunden. Schau bald wieder vorbei.
    </p>

  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import AnonymousContactItem from '@/components/3.0/AnonymousContactItem.vue'

export default {
  components: {
    InlineSvg,
    AnonymousContactItem
  },
  name: 'LeadsOverview',
  props: [],
  inject: ['currentBrand', 'hasCurrentBrand', 'GoogleMap'],
  data: function () {
    return {
      isLoading: false,
      currentLeads: []
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    currentLeadsByScore: function () {
      const leads = this.currentLeads.slice().sort((a, b) => {
        return b.Score - a.Score
      })
      return leads
    },
    computedShowEmptyResult () {
      return !this.isLoading && this.currentLeads.length === 0
    }
  },
  created: function () {},
  methods: {
    async loadPool () {
      if (this.isLoading || !this.currentBrand) {
        return
      }

      this.isLoading = true
      const leadRequest = await this.app.APIService._post('talentpool/leads', {
        brandId: this.currentBrand.BrandID
      })
        .catch(() => {
          this.$swal.fire({
            title: 'Fehler',
            text: 'Es ist ein Fehler beim Laden der Leads aufgetreten.',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })

      this.currentLeads = leadRequest.body.items
      this.isLoading = false
    }
  },
  watch: {
    currentBrand (newValue) {
      if (newValue) {
        this.loadPool()
      }
    }
  },
  mounted: function () {
    this.loadPool()
  }
}
</script>

<style lang="scss" scoped>
#leads {
  max-width: calc(5 * 296px + 4 * 32px);

  > .d-flex {
    gap: 32px;
    flex-wrap: wrap;
  }
}

a {
  color: #00719f;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
}

.sorting-row {
  margin: 32px 0;
}
</style>
