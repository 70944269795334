<template>
  <div class="locale-switcher" @click="changeLocale">
    <img :src="require('../assets/images/flags/' + getCurrentLocaleFlag + '.svg')" />
    <span class="d-none d-md-inline" v-if="!hideCaption">{{ $t('language.change') }}</span>
  </div>
</template>

<script>
import { getSupportedLocales } from '@/modules/i18n/supported-locales'
export default {
  props: ['storeLocale', 'hideCaption'],
  data: () => ({ locales: getSupportedLocales() }),
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    getNextLocaleFlag: function () {
      return (this.$i18n.locale === 'de' ? 'gb' : 'de')
    },
    getCurrentLocaleFlag: function () {
      return (this.$i18n.locale === 'de' ? 'de' : 'gb')
    },
    getNextLocale: function () {
      return (this.$i18n.locale === 'de' ? 'en' : 'de')
    }
  },
  methods: {
    changeLocale () {
      this.$i18n.locale = this.getNextLocale
      if (this.storeLocale) {
        this.storeCorrespondenceLang()
      } else {
        this.app.createJivoChat()
        this.app.reinitializeUsetiful()
      }
    },
    storeCorrespondenceLang () {
      this.app.changeToBrandLang()
      const currentCompany = this.app.currentBrand.Company

      const company = {
        CompanyID: currentCompany.CompanyID,
        CorrespondenceLanguageID: currentCompany.CorrespondenceLanguageID
      }

      return new Promise((resolve, reject) => {
        this.app.API._put('company/', company)
          .then((response) => {
            const resultString = response.body.Result.Result

            if (resultString === 'Succeeded') {
              resolve()
            } else {
              this.app.showSaveError(null, this.app.ErrorReport.sendReport(response, this.app.currentBrand, this.app.currentContact, company))
              reject(new Error('Ein Fehler ist beim Speichern der company aufgetreten'))
            }
          })
          .catch((error) => {
            console.error(error)
            this.app.showSaveError(null, this.app.ErrorReport.sendReport(error, this.app.currentBrand, this.app.currentContact, company))
          })
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.locale-switcher {
  cursor: pointer;
  color: #fff;
  font-weight: 700;

  img {
    width: 40px;
    margin-right: 10px;
  }
}
</style>
