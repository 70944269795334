<template>
  <div class="mb-main-content" :class="compareView ? 'mb-compare-view' : ''">
    <div>
      <h1 class="mb-4" v-t="'page.statistics.title'"></h1>
    <div class="card mb-stat-top mb-4">
      <div class="row p-4">
      <date-picker class="col-3 mb-1" v-model="dateRange" range :shortcuts="datePickerShortcuts" :formatter="momentFormat" :lang="lang"></date-picker>
      <div class="d-flex align-items-center">
          <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input me-1" id="compare-view" v-model="compareView">
              <label class="custom-control-label" for="compare-view">{{$t('page.statistics.compareview')}}</label>
          </div>
      </div>
      <div class="col d-flex justify-content-end">
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label class="btn d-flex" :class="groupBy == 'Day' ? 'btn-success' : 'btn-primary'" for="group-day">
              <input type="radio" id="group-day" autocomplete="off"
                      value="Day"
                      v-model="groupBy"
                      :checked="groupBy == 'Day'"
                      name="groupby"
              />{{$t('page.statistics.viewgroups.day')}}
          </label>
          <label class="btn d-flex" :class="groupBy == 'Week' ? 'btn-success' : 'btn-primary'" for="group-week">
              <input type="radio" id="group-week" autocomplete="off"
                      value="Week"
                      v-model="groupBy"
                      :checked="groupBy == 'Week'"
                      name="groupby"
              />{{$t('page.statistics.viewgroups.week')}}
          </label>
          <label class="btn d-flex" :class="groupBy == 'Month' ? 'btn-success' : 'btn-primary'" for="group-month">
              <input type="radio" id="group-month" autocomplete="off"
                      value="Month"
                      v-model="groupBy"
                      :checked="groupBy == 'Month'"
                      name="groupby"
              />{{$t('page.statistics.viewgroups.month')}}
          </label>
        </div>
      </div>
      </div>
    </div>
    <impressions-bar-chart v-if="pageIsLoaded" :startDate="startDate" :endDate="endDate" :groupby="groupBy" :currentBrand="currentBrand" :title="$t('page.statistics.views.title')" :showMemberSelection="false" :scaleYstepSize="groupBy == 'Month' ? 25000 : 2000" :filterByBrandIDs="this.currentBrand.BrandID" :loadCounter="loadCounter" :showAvgData="false"></impressions-bar-chart>
    <top-brand-bar-chart v-if="pageIsLoaded && currentBrand != null && currentBrand.BrandID != null" :startDate="startDate" :endDate="endDate" :groupby="groupBy" :title="$t('page.statistics.addons.title')" :API="this.app.API" :currentBrand="currentBrand"></top-brand-bar-chart>
    <leads-bar-chart v-if="pageIsLoaded && currentBrand.BrandID != null" :startDate="startDate" :endDate="endDate" :groupby="groupBy" :currentBrand="currentBrand" :title="$t('page.statistics.leads.title')" :parent="this" :API="this.app.API" :showAvgDataOpt="false"></leads-bar-chart>
    <leads-pie-chart v-if="pageIsLoaded && currentBrand.BrandID != null" :startDate="startDate" :endDate="endDate"></leads-pie-chart>
    <leads-map v-if="pageIsLoaded && currentBrand.BrandID != null && currentBrand.BrandID != 15885" :startDate="startDate" :endDate="endDate"></leads-map>
    </div>
  </div>
</template>

<script>
import ImpressionsBarChart from '../components/charts/ImpressionsBar.vue'
import TopBrandBarChart from '../components/charts/TopBrandBar.vue'
import LeadsBarChart from '../components/charts/LeadsBar.vue'
import LeadsPieChart from '../components/charts/LeadsPieChart.vue'
import LeadsMap from '../components/charts/LeadsMap.vue'
import StatisticMethods from '../modules/StatisticMethods'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'

let _SM = null

export default {
  components: {
    DatePicker,
    ImpressionsBarChart,
    TopBrandBarChart,
    LeadsBarChart,
    LeadsPieChart,
    LeadsMap
  },
  name: 'Statistics',
  props: [],
  data: function () {
    return {
      dateRange: [],
      momentFormat: {
      // [optional] Date to String
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY') : ''
        },
        // [optional]  String to Date
        parse: (value) => {
          return value ? moment(value, 'DD.MM.YYYY').toDate() : null
        }
      },
      datePickerShortcuts: [],
      groupBy: 'Week',
      pageIsLoaded: false,
      compareView: false,
      loadCounter: 0
    }
  },
  computed: {
    currentBrand () {
      const current = this.app.currentBrand || {}
      return current
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    startDate () {
      return moment(this.dateRange[0]).format('DD.MM.YYYY')
    },
    endDate () {
      return moment(this.dateRange[1]).format('DD.MM.YYYY')
    },
    lang () {
      return this.$i18n.locale !== 'de' ? 'en' : 'de'
    },
    SM () {
      if (_SM == null) _SM = new StatisticMethods(this.app.API)
      return _SM
    }
  },
  created: function () {
    const dateFrom = new Date()
    dateFrom.setDate(dateFrom.getDate() - 29)
    const dateTo = new Date()
    this.dateRange = [
      dateFrom,
      dateTo
    ]
    this.datePickerShortcuts.push(this.getDatePickerShortcuts(this.$t('page.statistics.daterange.last7'), 6))
    this.datePickerShortcuts.push(this.getDatePickerShortcuts(this.$t('page.statistics.daterange.last30'), 29))
    this.datePickerShortcuts.push(this.getDatePickerShortcuts(this.$t('page.statistics.daterange.last90'), 89))
    this.datePickerShortcuts.push(this.getDatePickerShortcuts(this.$t('page.statistics.daterange.last365'), 364))
    this.pageIsLoaded = this.currentBrand.BrandID != null
  },
  methods: {
    getDatePickerShortcuts (text, substractDays) {
      const obj =
      {
        text,
        onClick: () => {
          const dateFrom = new Date()
          dateFrom.setDate(dateFrom.getDate() - substractDays)
          const dateTo = new Date()

          return [dateFrom, dateTo]
        }
      }
      return obj
    }
  },
  watch: {
    'currentBrand.BrandID': function () {
      this.pageIsLoaded = true
    },
    dateRange (newValue) {
      const startMmt = moment(newValue[0])
      const endMmt = moment(newValue[1])

      const monthsInRange = Math.abs(startMmt.diff(endMmt, 'month'))
      const weeksInRange = Math.abs(startMmt.diff(endMmt, 'week'))
      const daysInRange = Math.abs(startMmt.diff(endMmt, 'day'))

      if (this.groupBy === 'Month' && monthsInRange < 1) {
        if (weeksInRange > 0) {
          this.groupBy = 'Week'
        } else {
          this.groupBy = 'Day'
        }
      }

      if (this.groupBy === 'Week' && weeksInRange < 1) {
        this.groupBy = 'Day'
      }

      if (this.groupBy === 'Day' && daysInRange > 60) {
        this.groupBy = 'Week'
      }

      this.loadCounter++
    },
    groupBy (newValue) {
      this.loadCounter++
    }
  },
  mounted: function () {
    this.SM.getBrandBookings()
    this.SM.getSpecialSectorGroups()
  }
}
</script>

<style lang="less">
.mx-datepicker-sidebar {
  max-width: 150px;
  min-width: 120px;
}
.mx-datepicker-sidebar + .mx-datepicker-content {
  margin-left: 120px;
}

.mb-main-content {
    &.mb-compare-view {
        .mb-bar-chart {
            padding: 5px !important;
            margin-bottom: 10px !important;
            min-height: 250px !important;

            h2 {
                font-size: 16px;
            }

            .chartjs-render-monitor {
                height: 250px !important;
            }
        }
    }

    &:not(.mb-compare-view) {
        .mb-bar-chart {
            .chartjs-render-monitor {
                height: 400px !important;
            }
        }
    }
}

#group-day,
#group-week,
#group-month {
  display: none;
}
</style>
