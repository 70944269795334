import { defineStore } from 'pinia'
import { useUserStore } from './userStore'
// import { useBrandStore } from './brandStore'

import Api from '../modules/Api'
import ChatApiModule from '@/modules/ChatApi'

const ChatApi = new ChatApiModule()

export const useChatStore = defineStore('chat', {
  state: () => {
    return {
      isOpen: false,
      isLoading: false,
      isLoaded: false,
      messages: [],
      fpApi: new Api(),
      userStore: useUserStore()
    }
  },
  getters: {
    getIsOpen () {
      return this.isOpen
    },
    getIsLoading () {
      return this.isLoading
    },
    getIsLoaded () {
      return this.isLoaded
    },
    getMessages () {
      // sort by Created Date ASC
      return this.messages.sort((a, b) => {
        return new Date(a.Created) - new Date(b.Created)
      })
    },
    getMessagesCount () {
      return this.messages.length
    },
    getMessageCountByContactID: (state) => (ContactID) => {
      return state.messages.filter(message => message.FromContactID === ContactID || message.ToContactID === ContactID).length
    },
    getLatestMessages (state) {
      const latestMessagesMap = new Map()

      state.messages.forEach((message) => {
        const contactID = message.FromContactID === state.userStore.getCurrentContactID ? message.ToContactID : message.FromContactID

        if (!latestMessagesMap.has(contactID) ||
            new Date(latestMessagesMap.get(contactID).Created) < new Date(message.Created)) {
          latestMessagesMap.set(contactID, message)
        }
      })

      const latestMessages = Array.from(latestMessagesMap.values())

      // Sort by Created Date DESC
      latestMessages.sort((a, b) => new Date(b.Created) - new Date(a.Created))

      // Calculate UnreadMessageCount for each latest message
      latestMessages.forEach((message) => {
        const contactID = message.FromContactID === state.userStore.getCurrentContactID ? message.ToContactID : message.FromContactID
        message.UnreadMessageCount = state.messages.filter(msg =>
          (msg.FromContactID === contactID || msg.ToContactID === contactID) && msg.IsRead !== 1
        ).length
      })

      return latestMessages
    }

  },
  actions: {
    setIsOpen (value) {
      this.isOpen = value
    },
    setIsLoading (value) {
      this.isLoading = value
    },
    setIsLoaded (value) {
      this.isLoaded = value
    },
    setMessages (value) {
      this.messages = value
    },

    async openChat () {
      this.isChatOpen = true
      document.body.classList.add('chat-open')
      if (!this.getIsLoaded) {
        await this.loadMessages()
      }
    },
    closeChat () {
      this.isChatOpen = false
      document.body.classList.remove('chat-open')
    },

    async loadMessages () {
      // if (this.getIsLoaded || this.getIsLoading) {
      //   return
      // }

      // this.setIsLoading(true)

      // try {
      //   const brandStore = useBrandStore()
      //   const userStore = useUserStore()

      //   if (brandStore.getCurrentBrandID === null) {
      //     throw new Error('BrandID is null')
      //   }

      //   if (userStore.getCurrentContactID === null) {
      //     throw new Error('ContactID is null')
      //   }

      //   const chatMessages = await ChatApi._post('history', {
      //     BrandID: brandStore.getCurrentBrandID,
      //     ContactID: userStore.getCurrentContactID
      //   })

      //   this.messages = chatMessages.body.history
      //   this.setIsLoaded(true)
      // } catch (error) {
      //   console.error(error)
      // }

      // this.setIsLoading(false)
    },
    async sendMessage (message) {
      try {
        this.setIsLoading(true)
        const newMessageResponse = await ChatApi._post('message', message)
        const newMessage = newMessageResponse.body.message
        this.messages.push(newMessage)
        this.setIsLoaded(true)
      } catch (error) {
        console.error('!!!', error)
      } finally {
        this.setIsLoading(false)
      }
    }
  }
})
