<template>
  <div class="chat-wrapper open">
    <div class="chat-overlay" @click="chatStore.closeChat"></div>
    <div class="chat-drawer">
      <loading
        :active.sync="chatStore.getIsLoading"
        :can-cancel="false"
        :is-full-page="false"
      />

      <div class="d-flex justify-content-end">
        <a href="javascript:" @click="chatStore.closeChat">
          <inline-svg class="stroke-black" :src="require('@/assets/images/icons/3.0/close.svg')" />
        </a>
      </div>

      <h1>Nachrichten</h1>

      <div class="chat-conversations">
        <p v-if="chatStore.getIsLoading">
          Nachrichten werden geladen...
        </p>
        <template v-else>
          <p v-if="chatStore.getMessagesCount === 0">
            Du hast noch keine Nachrichten.
          </p>
          <template v-else>
            <LatestChatMessage v-for="latestMessage of chatStore.getLatestMessages" :message="latestMessage" :key="latestMessage.ChatMessageID" />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import { useChatStore } from '@/stores/chatStore'
import LatestChatMessage from '@/components/3.0/LatestChatMessage.vue'
import Loading from 'vue-loading-overlay'

export default {
  name: 'ChatDrawer',
  components: {
    InlineSvg,
    Loading,
    LatestChatMessage
  },
  data: function () {
    return {
      chatStore: useChatStore()
    }
  },
  computed: {},
  mounted: function () {
    // reload chat messages every minute
    // setInterval(() => {
    //   this.userStore.loadChatMessages()
    // }, 60000)
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
h1 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.chat-conversations > p {
  margin-bottom: 0;
}
</style>
