<template>
  <nav
    v-if="app.isLoggedIn && app.hasCurrentBrand && app.navIsOpen"
    class="navbar navbar-expand-lg navbar-dark mb-menue-left flex-column"
  >
    <div class="mb-left-nav-cont" id="mainNavLeft" :class="scrollClass">
      <div class="row">
        <div class="col-12">
          <router-link class="fu-logo" to="/dashboard">
            <img
              src="../assets/images/fu-logo-white.png"
              alt="FranchiseUNIVERSUM"
            />
          </router-link>
        </div>
      </div>

      <div class="mb-brand" id="topMenue">
        <change-brand-component :currentBrand="currentBrand" />
      </div>
      <ul class="nav flex-column">
        <sidebar-link
          to="dashboard"
          title="menue.dashboard"
        />
        <sidebar-link
          to="profil"
          title="menue.profil"
          class="mb-nav-profil"
        />
        <sidebar-link
          to="infopaket"
          title="menue.infopaket"
          icon="meine-daten"
          fill-on-hover="true"
          class="mb-nav-infopaket nav-margin-bottom"
        />
        <sidebar-link
          to="rps"
          title="menue.rps"
          class="nav-margin-bottom"
        />

        <sidebar-link
          v-if="!app.isAdministrator && app.isActiveMember"
          to="leads"
          title="menue.leads"
          class="nav-margin-bottom"
        />
        <sidebar-link
          v-if="0"
          to="talentpool"
          title="menue.talentpool"
          class="nav-margin-bottom"
        />
        <sidebar-link
          v-if="0"
          to="immobilien"
          title="menue.immobilien"
          class="nav-margin-bottom disabled"
          icon="immobilien"
          fill-on-hover="true"
        />

        <sidebar-link
          to="mitgliedschaft-buchen"
          title="menue.mitgliedschaftbuchen"
          class="mb-nav-membership"
        />
        <sidebar-link
          to="topmarke-buchen"
          title="menue.topmarkebuchen"
        />
        <sidebar-link
          to="newsletter-buchen"
          title="menue.newsletterbuchen"
          class="mb-nav-top-nl-chat"
        />
        <sidebar-link
          to="chatbot-buchen"
          title="menue.chatbotbuchen"
          class="nav-margin-bottom"
          icon="chatbot"
          fill-on-hover="true"
        />

        <sidebar-link
          to="team"
          title="menue.team"
          class="mb-nav-kontakte"
        />
        <sidebar-link
          v-if="app.isActiveMember"
          to="abos"
          title="menue.subscriptions"
        />
        <sidebar-link
          v-if="app.isActiveMember"
          to="statistics"
          title="menue.statistics"
          class="nav-margin-bottom"
        />

        <sidebar-link
          icon="tour"
          title="menue.tour"
          @click="app.startTour"
        />
        <sidebar-link
          icon="logout"
          title="menue.logout"
          @click="doLogout"
        />

        <li v-if="0 && showGiftPanel">
          <router-link to="/geschenk" class="mb-gift">
            <div>
              <img :src="require('../assets/images/icons/gift-blue.svg')" />
            </div>
            <span>{{ $t("menue.present") }}</span>
          </router-link>
        </li>
      </ul>
      <small class="clientVersionHint">{{
        $t("app.version", { version: currentAppVersion })
      }}</small>
      <div class="mb-scroll-down">
        <div @click="scroll"><i :class="scrollIconClass"></i></div>
      </div>
    </div>
  </nav>
</template>
<script>
import Config from '../config'
import ChangeBrandComponent from '@/components/3.0/ChangeBrandComponent'
import SidebarLink from '@/components/3.0/SidebarLink'

export default {
  name: 'Sidebar',
  inject: ['doLogout'],
  components: {
    ChangeBrandComponent,
    SidebarLink
  },
  provide () {
    return {
      closeSidebar: this.close
    }
  },
  props: [],
  data: function () {
    return {
      isHidden: false,
      scrollClass: '',
      scrollIconClass: 'icon-pfeilrunter',
      scrollDown: true
    }
  },
  created () {
    window.addEventListener('resize', this.setScrollClass)
  },
  destroyed () {
    window.removeEventListener('resize', this.setScrollClass)
  },
  computed: {
    currentAppVersion: function () {
      return Config.CLIENT_VERSION
    },
    app: function () {
      return this.$parent || {}
    },
    currentBrand: function () {
      return this.$parent.currentBrand || {}
    },
    isLoggedIn: function () {
      return this.$parent.isLoggedIn
    },
    isVisible: function () {
      if (this.isHidden === true) return false

      const currentMatchedRoute = this.$router.getMatchedComponents().shift()
      if (currentMatchedRoute) {
        if (currentMatchedRoute.name === 'BrandSelection') {
          return false
        }
      }
      return true
    },
    showGiftPanel: function () {
      return this.app.giftAvailable()
    },
    isSoftStripeCustomer () {
      return this.currentBrand.StripeCustomerID !== null
    },
    currentMembership: function () {
      return this.app.currentMembership || {}
    }
  },
  watch: {
    $route (to) {
      if (to.meta.sidebar === false) {
        this.close()
      } else {
        this.open()
      }
    },
    currentBrand (thisBrand) {
      this.setScrollClass()
    }
  },
  methods: {
    close () {
      this.isHidden = true
    },
    open () {
      this.isHidden = false
    },
    openLogoDialog () {
      this.$refs.sideLogo.openChangeModal()
    },
    setScrollClass () {
      const scrollElm = document.getElementById('mainNavLeft')
      if (scrollElm == null) {
        setTimeout(this.setScrollClass, 500)
        return
      }

      if (scrollElm.clientHeight < scrollElm.scrollHeight) {
        this.scrollClass = 'mb-scroll'
      } else this.scrollClass = ''
    },
    scroll () {
      if (this.scrollDown) {
        document
          .getElementById('mbNavLeftLogout')
          .scrollIntoView({ behavior: 'smooth' })
        this.scrollIconClass = 'icon-pfeilhoch'
      } else {
        document
          .getElementById('topMenue')
          .scrollIntoView({ behavior: 'smooth' })
        this.scrollIconClass = 'icon-pfeilrunter'
      }
      this.scrollDown = !this.scrollDown
    }
  }
}
</script>
<style lang="less" scoped>
.clientVersionHint {
  color: white;
  padding-left: 5px;
  width: 100%;
  font-size: 10px;
  margin-top: 23px;
}
.navbar {
  justify-content: flex-start;
}
.nav-margin-bottom {
  margin-bottom: 32px;
}
</style>
