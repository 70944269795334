<template>
  <div>
    <div class="mb-main-content mb-booking-success">
      <div
        class="mb-top-container"
        >
        <div class="container">
          <div class="row mb-5">
            <div class="col-12">
                <h1 class="mb-title">
                  <i class="icon-chatbot-color"></i>
                  {{ $t('page.membershipsuccess.congratulations', { firstname: currentContact.Firstname, lastname: currentContact.Lastname }) }}
                  <div>{{ $t('page.chatbot.success.booking', { brandname: currentBrand.Name }) }}</div>
                </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="mb-progress position-sticky">
                <h3 v-t="'page.membershipsuccess.howitcontinues'"></h3>
                <ul class="mb-info-lst">
                  <li><i class="fa fa-check"></i><span v-html="$t('page.chatbot.success.booked', { company: currentBrand.Name })"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.chatbot.success.moreleads'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.chatbot.success.automated'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.chatbot.success.payforleads'"></span></li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 d-flex justify-content-between flex-column">
              <div class="mb-5 text-center">
              <img
                class="img-fluid"
                :src="require('../../assets/images/chatbot.png')"
              />
              </div>
              <h4 v-t="'page.membershipsuccess.successwishes'"></h4>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-5 pb-5">
            <div class="mb-arrow-down">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-vid-cont">
        <div class="container pt-5 pb-5">
          <div class="row mt-5 mb-5">
            <div class="col-lg-5">
              <h2 class="mb-5" v-t="'page.chatbot.success.videotitle'"></h2>
              <div v-html="$t('page.chatbot.success.videodesc')"></div>
              <router-link to="/profil" class="btn mt-4 text-uppercase">{{ $t('button.tobrandprofile') }}</router-link>
            </div>
            <div class="col-lg-7">
              <iframe
                id="ytplayer"
                type="text/html"
                src="https://www.youtube-nocookie.com/embed/lZYUHZQwMGA"
                frameborder="0"
                allowfullscreen="allowfullscreen"
              />
              <a href="https://www.franchiseuniversum.de/leadhandling/" target="_blank" class="icon-pfeilrechts" v-t="'page.chatbot.success.tovideocourse'"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueConfetti from 'vue-confetti'

Vue.use(VueConfetti)

export default {
  name: 'MembershipSuccess',
  props: [],
  data: function () {
    return {
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    currentContact: function () {
      return this.app.currentContact || {}
    },
    currentType: function () {
      return this.$route.meta.type
    },
    isMembershipBooking: function () {
      return this.currentType === 'membership'
    },
    isTopBrandBooking: function () {
      return this.currentType === 'topbrand'
    },
    avvSigned: function () {
      return true
    },
    currentBrand () {
      const current = this.$parent.currentBrand || {}
      return current
    }
  },
  methods: {
  },
  mounted: function () {
    this.$confetti.start({
      particles: [
        {
          type: 'rect'
        }
      ]
    })
    setTimeout(() => {
      this.$confetti.stop()
    }, 5000)
  },
  watch: {
  }
}
</script>
