<template>
  <div class="experience-container">
    <div class="experience-header">
      <a class="flex-grow-1 d-flex gap-2 align-items-center" href="javascript:" @click="toggleOpen">
        <svg v-if="computedIsVideoExperience" xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
          <path d="M16.3327 4.93848L11.666 8.27181L16.3327 11.6051V4.93848Z" stroke="#64748B" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M10.3327 3.60498H2.99935C2.26297 3.60498 1.66602 4.20193 1.66602 4.93831V11.605C1.66602 12.3414 2.26297 12.9383 2.99935 12.9383H10.3327C11.0691 12.9383 11.666 12.3414 11.666 11.605V4.93831C11.666 4.20193 11.0691 3.60498 10.3327 3.60498Z"
            stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
          <path
            d="M13.3327 14.2717V12.9384C13.3327 12.2312 13.0517 11.5529 12.5516 11.0528C12.0515 10.5527 11.3733 10.2717 10.666 10.2717H5.33268C4.62544 10.2717 3.94716 10.5527 3.44706 11.0528C2.94697 11.5529 2.66602 12.2312 2.66602 12.9384V14.2717"
            stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M8.00065 7.60506C9.47341 7.60506 10.6673 6.41115 10.6673 4.9384C10.6673 3.46564 9.47341 2.27173 8.00065 2.27173C6.52789 2.27173 5.33398 3.46564 5.33398 4.9384C5.33398 6.41115 6.52789 7.60506 8.00065 7.60506Z"
            stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span>{{ experience.AuthorData }}</span>
      </a>
      <a href="javascript:" @click="toggleOpen">
        <svg v-if="isOpen" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
          <path d="M18 15.2715L12 9.27148L6 15.2715" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        <svg v-else class="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25"
          fill="none">
          <path d="M6 9.27173L12 15.2717L18 9.27173" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </a>
    </div>

    <div v-if="isOpen" class="experience-content">
      <span v-if="experience.Location">{{ experience.Location }}</span>
      <div v-if="computedIsVideoExperience" class="d-flex gap-3 align-items-center">
        <div class="video-container d-flex flex-column p-2 flex-shrink-0">
          <VideoPreviewContainer :video="experience.Video" />
          <span>{{ experience.Video.Title }}</span>
        </div>
        <div>
          {{ plainText }}
        </div>
      </div>
      <div v-else class="d-flex gap-3 align-items-center">
        <div v-if="computedHasThumbnail" class="d-flex flex-column gap-2">
          <div class="thumbnail-container">
            <img :src="computedThumbnailUrl" class="img-fluid" />
          </div>
        </div>
        <div>
          {{ plainText }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Config from '@/config'
import VideoPreviewContainer from '@/components/fair/VideoPreviewContainer.vue'

export default {
  name: 'FairExperienceContainer',
  components: {
    VideoPreviewContainer
  },
  props: ['experience'],
  data: function () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleOpen () {
      this.isOpen = !this.isOpen
    },
    stripHtmlTags (inputString) {
      return inputString
        .replaceAll(/<p[^>]*>/gi, '\n\n') // Replace opening <p> tags with two new lines.
        .replaceAll(/<\/p>/gi, '') // Remove closing </p> tags.
        .replaceAll(/<br\s*\/?>/gi, '\n') // Replace <br> or <br /> with a single new line.
        .replaceAll(/<[^>]+>/gi, '') // Remove all other HTML tags.
    }
  },
  computed: {
    computedHasThumbnail () {
      return this.experience.MediumID !== null
    },
    computedIsVideoExperience () {
      return this.experience.Video !== null
    },
    plainText () {
      return this.stripHtmlTags(this.experience.Text)
    },
    computedThumbnailUrl () {
      if (this.computedHasThumbnail) {
        return `${Config.MEDIA_API_URL}id/${this.experience.Medium.FileID}?size=md`
      } else {
        return ''
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.experience-container {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  background-color: #EFF1F4;
  border-radius: 8px;
  color: #3F4957;
  gap: 8px;
}

.experience-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.thumbnail-container {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}

.video-container {
  width: 200px;
  border-radius: 8px;
  background-color: #F7F8F9;
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    color: #3F4957;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

}

.video-preview-container {
  width: 100%;
  height: auto;
  position: relative;

  svg {
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

}

.experience-header {

  display: flex;
  justify-content: space-between;

  a {
    color: #3F4957;
    text-decoration: none;
  }
}
</style>
