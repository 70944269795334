<template>
  <div class="mb-bar-chart p-4 mb-4 card" :class="!statIsOpen ? 'mb-collapsed' : ''">
      <div class="d-flex align-items-center">
          <h2 v-if="title != ''" @click="openOrCloseStat()">{{title}} <i class="fa" :class="titleArrowClass" v-if="this.collapsed"></i></h2>
          <div class="ms-5 d-flex" v-if="showMemberSelection && statIsOpen">
              <b-form-checkbox v-model="includeMembers" :value="true" :unchecked-value="false">Mitglieder</b-form-checkbox>
              <b-form-checkbox v-model="includeNonMembers" :value="true" :unchecked-value="false" class="ms-4">Nicht-Mitglieder</b-form-checkbox>
              <b-button v-if="showRefreshButton" @click="getChartData()" class="ms-4" variant="success">Daten aktualisieren</b-button>
          </div>
      </div>
      <div v-if="statIsOpen">
        <div v-if="isLoading" class="mb-loading"><span v-t="'page.statistics.loading'"></span></div>
        <div v-if="!isLoading && showNoDataHint" class="mb-no-chart-data"><span v-t="'page.statistics.nodata'"></span></div>
          <bar-chart
              v-if="!isLoading && chartData.datasets != null && chartData.datasets.length > 0"
              :chartData="chartData"
              :options="chartData.options"
              :plugins="[]"
              :totalText="this.$t('page.statistics.total')"
          ></bar-chart>
      </div>
  </div>
</template>

<script>
import BarChart from './Bar.vue'
import moment from 'moment'
import ApiSql from '@/modules/Api_Sql'

const _ApiSql = new ApiSql()

export default {
  components: {
    BarChart
  },
  name: 'ImpressionsChartBar',
  props: ['startDate', 'endDate', 'groupby', 'title', 'currentBrand', 'showMemberSelection', 'scaleYstepSize', 'filterByBrandIDs', 'parent', 'collapsed', 'loadCounter', 'showAvgData'],
  data: function () {
    return {
      chartData: {},
      chartLabels: [],
      barChartDatasetsPortal: [],
      isLoading: false,
      showNoDataHint: false,
      includeMembers: true,
      includeNonMembers: false,
      showRefreshButton: false,
      dataColCount: 0,
      avgBrands: {
        de: [],
        at: [],
        ch: []
      },
      membersData: [],
      filterBrandIDs: [],
      titleArrowClass: 'fa-arrow-down',
      showFirstTime: true,
      currentPortals: ['DE', 'AT', 'CH']
    }
  },
  computed: {
    statIsOpen () {
      return this.collapsed === true ? this.titleArrowClass === 'fa-arrow-up' : true
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    parentPage: function () {
      if (this.parent == null) return this.$parent
      return this.parent
    }
  },
  created: function () {
  },
  methods: {
    getChartData () {
      if (!this.statIsOpen) return
      if (this.currentBrand != null &&
              this.currentBrand.BrandModules[0].Fair != null &&
              this.currentBrand.BrandModules[0].Fair.FairPortals.length > 0) {
        const fairPortals = this.currentBrand.BrandModules[0].Fair.FairPortals
        this.currentPortals = []
        fairPortals.forEach(fairPortal => {
          if (fairPortal.PortalID === 1) this.currentPortals.push('DE')
          if (fairPortal.PortalID === 2) this.currentPortals.push('CH')
          if (fairPortal.PortalID === 3) this.currentPortals.push('AT')
        })
      }
      const momentStarDate = moment(this.startDate, 'DD.MM.YYYY')
      this.filterBrandIDs = this.filterByBrandIDs != null && this.filterByBrandIDs.length > 0
        ? this.filterByBrandIDs
        : (this.currentBrand != null ? this.parentPage.SM.getBrandsBySpecialSectorGroup(this.currentBrand.SpecialSector.SpecialSectorGroup) : [])
      Promise.all([this.getDetailChartData(), this.getAvgChartData(), this.getMinMaxChartData()])
        .then(async ([detailData, avgData, minMaxData]) => {
          let detailChartBarData = null
          if (detailData == null || (detailData.body.Result.Data != null && detailData.body.Result.Data.length === 0)) {
            this.showNoDataHint = true
          } else {
            detailChartBarData = this.mapChartBarData(detailData.body.Result.Data.slice(0), this.dataColCount, momentStarDate)
            this.showNoDataHint = false
          }
          let avgLineChartData = []
          if (avgData != null) {
            const avgChartData = await this.mapLineChartData(avgData.body, minMaxData.body, this.dataColCount, momentStarDate)
            avgLineChartData = await avgChartData
          }
          this.chartData = this.getDefaultChartData()
          this.chartData.datasets = avgLineChartData.concat(detailChartBarData)
          this.chartData.options.tooltips.callbacks.beforeLabel = this.tooltipCallbackBeforeLabel
          this.chartData.options.tooltips.callbacks.label = this.tooltipCallbackLabel
          this.chartData.options.legend.labels.filter = this.getFilteredLabels
          this.chartData.options.legend.labels.generateLabels = this.changeLegendText
          this.chartData.labels = this.chartLabels
        }).finally(() => {
          this.isLoading = false
        })
    },
    getDetailChartData () {
      if (this.isLoading) return

      this.isLoading = true
      this.showRefreshButton = false
      const momentStarDate = moment(this.startDate, 'DD.MM.YYYY')
      const momentEndDate = moment(this.endDate, 'DD.MM.YYYY')
      this.dataColCount = momentEndDate.diff(momentStarDate, this.groupby.toLowerCase() + 's') + 1
      if (this.dataColCount === 1 && (this.groupby === 'Week' || this.groupby === 'Month')) {
        if (momentStarDate.format('w') !== momentEndDate.format('w')) this.dataColCount++
      }
      const visitorActions = []
      if (this.includeMembers) {
        visitorActions.push(1)
        visitorActions.push(3)
      }
      if (this.includeNonMembers) visitorActions.push(2)
      if (visitorActions.length === 0) {
        this.isLoading = false
        this.showNoDataHint = true
        return
      }

      const postData = {
        ResFrom: 1,
        ResTo: 10,
        Filter: [
          {
            Field: 'Created',
            Operator: 'GE',
            Value: momentStarDate.format('YYYY-MM-DD HH:m:s')
          },
          {
            Field: 'Created',
            Operator: 'LT',
            Value: momentEndDate.format('YYYY-MM-DD') + ' 23:59:59'
          },
          {
            Field: 'VisitorActionTypeID',
            Operator: 'IN',
            Value: visitorActions.join(',')
          },
          {
            Field: 'IsBot',
            Operator: 'EQ_ISNULL',
            Value: 0
          },
          {
            Field: 'BrandID',
            Operator: 'GT',
            Value: 1
          }
        ],
        OrderBy: [
          { Field: 'Created', SortOrder: 'ASC' },
          { Field: 'VisitorActionTypeID', SortOrder: 'ASC' }
        ],
        GroupBy: {
          Key: [
            { Field: 'Created', DatePart: this.groupby },
            { Field: 'SourceModule' },
            { Field: 'VisitorActionTypeID' }
          ],
          Calc: [
            { Field: '*', Func: 'CNT' }
          ]
        },
        Tablename: 'VisitorAction'
      }
      if (this.currentBrand != null) {
        postData.Filter.push(
          {
            Field: 'BrandID',
            Operator: 'EQ',
            Value: this.currentBrand.BrandID
          }
        )
      }
      if (this.filterByBrandIDs != null && this.filterByBrandIDs.length > 0) {
        postData.Filter.push(
          {
            Field: 'BrandID',
            Operator: 'IN',
            Value: this.filterByBrandIDs.join(',')
          }
        )
      }
      return this.app.API._post('groupby/', postData).then((response) => {
        return response
      })
    },
    async getAvgChartData () {
      if (this.showAvgData === false) return
      const momentStarDate = moment(this.startDate, 'DD.MM.YYYY')
      const momentEndDate = moment(this.endDate, 'DD.MM.YYYY')
      const visitorActions = [] // test
      if (this.includeMembers) {
        visitorActions.push(1)
        visitorActions.push(3)
      }
      if (this.includeNonMembers) visitorActions.push(2)
      const postData = {
        from: momentStarDate.format('YYYY-MM-DD HH:m:s'),
        to: momentEndDate.format('YYYY-MM-DD') + ' 23:59:59',
        sourcemodule: '',
        visitoractions: visitorActions.join(',')
      }
      this.filterBrandIDs = await this.filterBrandIDs
      if (this.filterBrandIDs != null && this.filterBrandIDs.length > 0) {
        postData.brandids = this.filterBrandIDs.join(',')
      }
      if (this.currentPortals.length > 0) {
        postData.sourcemodule = this.currentPortals.join('\',\'')
      }

      postData['groupby' + this.groupby.toLowerCase()] = true
      return _ApiSql._post('getVABrandViewsPerInterval/', postData).then((response) => {
        return response
      })
    },
    async getMinMaxChartData () {
      const momentStarDate = moment(this.startDate, 'DD.MM.YYYY')
      const momentEndDate = moment(this.endDate, 'DD.MM.YYYY')
      const visitorActions = []
      if (this.includeMembers) {
        visitorActions.push(1)
        visitorActions.push(3)
      }
      if (this.includeNonMembers) visitorActions.push(2)
      const postData = {
        from: momentStarDate.format('YYYY-MM-DD HH:m:s'),
        to: momentEndDate.format('YYYY-MM-DD') + ' 23:59:59',
        sourcemodule: '',
        visitoractions: visitorActions.join(',')
      }
      this.filterBrandIDs = await this.filterBrandIDs
      if (this.filterBrandIDs != null && this.filterBrandIDs.length > 0) {
        postData.brandids = this.filterBrandIDs.join(',')
      }
      if (this.currentPortals.length > 0) {
        postData.sourcemodule = this.currentPortals.join('\',\'')
      }

      postData['groupby' + this.groupby.toLowerCase()] = true
      return _ApiSql._post('getMinMaxVABrandViewsPerInterval/', postData).then((response) => {
        return response
      })
    },
    mapChartBarData (apiData, dataColCount, startDate) {
      this.chartLabels = []
      startDate = moment(this.startDate, 'DD.MM.YYYY')

      const dataBrand = {
        de: new Array(dataColCount).fill(0),
        at: new Array(dataColCount).fill(0),
        ch: new Array(dataColCount).fill(0),
        ug: new Array(dataColCount).fill(0)
      }
      const dataPrev = {
        de: new Array(dataColCount).fill(0),
        at: new Array(dataColCount).fill(0),
        ch: new Array(dataColCount).fill(0),
        ug: new Array(dataColCount).fill(0)
      }
      let currentDay = startDate
      let dayFormat = 'YYYY-MM-DD'
      let labelFormat = 'DD.MM'
      if (this.groupby === 'Week') {
        dayFormat = 'GGGG/WW'
        labelFormat = dayFormat
      }
      if (this.groupby === 'Month') {
        dayFormat = 'YYYY-MM'
        labelFormat = dayFormat
      }
      for (let i = 0; i < dataColCount; i++) {
        if (!this.chartLabels.includes(currentDay.format(labelFormat))) this.chartLabels.push(currentDay.format(labelFormat))
        if (apiData != null) {
          apiData.forEach(thisData => {
            let doContinue = false
            const splitKey = thisData.Key.split('~')
            if (splitKey[0] === currentDay.format(dayFormat)) {
              doContinue = true
              const portal = splitKey[1].toLowerCase()
              if (!this.barChartDatasetsPortal.includes(portal)) this.barChartDatasetsPortal.push(portal)
              if (parseInt(splitKey[2]) < 3) dataBrand[portal][i] += parseInt(thisData.Fields[0].Value)
              else dataPrev[portal][i] += parseInt(thisData.Fields[0].Value)
            } else if (doContinue) return true
          })
        }
        currentDay = startDate.add(1, this.groupby.toLowerCase() + 's')
      }

      return this.createDataSets(dataBrand, dataPrev)
    },
    mapPortal (portal) {
      if (portal == null || portal === '') return

      portal = portal.toLowerCase()
      let result = portal

      if (portal.startsWith('franchiseportal')) result = portal.replace('franchiseportal.', '')
      if (portal.startsWith('franchisekomparator')) result = 'kp' + portal.replace('franchisekomparator.', '')
      if (portal.startsWith('chatbot')) result = 'cb' + portal.replace('chatbot.', '')
      if (portal.startsWith('unternehmer')) result = 'ug'

      return result
    },
    createDataSets (dataBrand, dataPrev) {
      const datasets = []
      if (this.barChartDatasetsPortal.includes('de')) {
        datasets.push(
          {
            label: 'FranchisePORTAL.de ' + this.$t('page.statistics.brandpage'),
            data: dataBrand.de,
            backgroundColor: '#005578',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          },
          {
            label: 'FranchisePORTAL.de ' + this.$t('page.statistics.quickview'),
            data: dataPrev.de,
            backgroundColor: '#6397ac',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('at')) {
        datasets.push(
          {
            label: 'FranchisePORTAL.at ' + this.$t('page.statistics.brandpage'),
            data: dataBrand.at,
            backgroundColor: '#72bb53',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          },
          {
            label: 'FranchisePORTAL.at ' + this.$t('page.statistics.quickview'),
            data: dataPrev.at,
            backgroundColor: '#a9d596',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('ch')) {
        datasets.push(
          {
            label: 'FranchisePORTAL.ch ' + this.$t('page.statistics.brandpage'),
            data: dataBrand.ch,
            backgroundColor: '#ff3823',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          },
          {
            label: 'FranchisePORTAL.ch ' + this.$t('page.statistics.quickview'),
            data: dataPrev.ch,
            backgroundColor: '#ff8578',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('ug')) {
        datasets.push(
          {
            label: 'UG.com ' + this.$t('page.statistics.brandpage'),
            data: dataBrand.ug,
            backgroundColor: '#ffa834',
            xAxisID: 'xbar-stacked',
            yAxisID: 'ybar-stacked'
          }
        )
      }

      return datasets
    },
    async mapLineChartData (apiData, minMaxData, dataColCount, startDate) {
      this.chartLabels = []
      // console.info('mapLineChartData', apiData, minMaxData, dataColCount, startDate)

      const dataBrand = {
        de: new Array(dataColCount).fill(0),
        at: new Array(dataColCount).fill(0),
        ch: new Array(dataColCount).fill(0),
        ug: new Array(dataColCount).fill(0)
      }
      const avgBrandData = {
        de: new Array(dataColCount).fill(0).map(() => ({
          min: { BrandID: 0, Count: 1000 },
          max: { BrandID: 0, Count: 0 },
          BrandCount: this.avgBrands.de
        })),
        at: new Array(dataColCount).fill(0).map(() => ({
          min: { BrandID: 0, Count: 1000 },
          max: { BrandID: 0, Count: 0 },
          BrandCount: this.avgBrands.at
        })),
        ch: new Array(dataColCount).fill(0).map(() => ({
          min: { BrandID: 0, Count: 1000 },
          max: { BrandID: 0, Count: 0 },
          BrandCount: this.avgBrands.ch
        })),
        ug: new Array(dataColCount).fill(0).map(() => ({
          min: { BrandID: 0, Count: 1000 },
          max: { BrandID: 0, Count: 0 },
          BrandCount: this.avgBrands.de
        }))
      }
      const portalBrands = {
        de: new Array(dataColCount).fill(0),
        at: new Array(dataColCount).fill(0),
        ch: new Array(dataColCount).fill(0)
      }
      let currentDay = startDate
      let dayFormat = 'YYYY-MM-DD'
      let labelFormat = 'DD.MM'
      if (this.groupby === 'Week') {
        dayFormat = 'GGGG/WW'
        labelFormat = dayFormat
      }
      if (this.groupby === 'Month') {
        dayFormat = 'YYYY-MM'
        labelFormat = dayFormat
      }
      const barChartDatasetsPortal = []
      for (let i = 0; i < dataColCount; i++) {
        const currentDayFormat = currentDay.format(dayFormat)
        if (!this.chartLabels.includes(currentDay.format(labelFormat))) this.chartLabels.push(currentDay.format(labelFormat))
        const dateTo = moment(currentDay)
        dateTo.add(1, this.groupby + 's')
        portalBrands.de[i] = await this.parentPage.SM.getActiveMembersInPeriod(
          currentDay,
          dateTo,
          1,
          this.filterBrandIDs
        )
        portalBrands.at[i] = await this.parentPage.SM.getActiveMembersInPeriod(
          currentDay,
          dateTo,
          3,
          this.filterBrandIDs
        )
        portalBrands.ch[i] = await this.parentPage.SM.getActiveMembersInPeriod(
          currentDay,
          dateTo,
          2,
          this.filterBrandIDs
        )
        if (apiData != null) {
          await Promise.all(apiData.map(thisData => {
            let doContinue = false
            if (thisData.intervalDate === currentDayFormat) {
              doContinue = true
              const portal = thisData.SourceModule.toLowerCase()
              const count = parseFloat(thisData.grpcnt)
              const combinedPortal = portal === 'ug' ? 'de' : portal
              try {
                this.avgBrands[combinedPortal][i] = portalBrands[combinedPortal][i].length
                if (portalBrands[combinedPortal][i].length === 0) return true
                if (!barChartDatasetsPortal.includes(combinedPortal)) barChartDatasetsPortal.push(combinedPortal)
                dataBrand[combinedPortal][i] += count
                minMaxData.forEach(minMaxBrand => {
                  if (minMaxBrand.intervalDate === currentDayFormat &&
                                      thisData.SourceModule === minMaxBrand.SourceModule) {
                    avgBrandData[combinedPortal][i].min.Count = minMaxBrand.mincnt
                    avgBrandData[combinedPortal][i].min.BrandID = minMaxBrand.MinBrandID
                    avgBrandData[combinedPortal][i].min.Name = minMaxBrand.MinBrand

                    avgBrandData[combinedPortal][i].max.Count = minMaxBrand.maxcnt
                    avgBrandData[combinedPortal][i].max.BrandID = minMaxBrand.MaxBrandID
                    avgBrandData[combinedPortal][i].max.Name = minMaxBrand.MaxBrand
                  }
                })
              } catch (error) {
                console.error(error, 'portal: ' + combinedPortal)
              }
            } else if (doContinue) return true
            return false
          }))
        }
        let lastPortal = ''
        try { // Durchschnitt berechnen
          barChartDatasetsPortal.forEach(portal => {
            portal = portal === 'ug' ? 'de' : portal
            lastPortal = portal
            if (this.avgBrands[portal][i] > 0 && parseFloat(dataBrand[portal][i]) > 0) dataBrand[portal][i] = (parseFloat(dataBrand[portal][i]) / this.avgBrands[portal][i]).toFixed(1)
          })
          const allPortals = ['de', 'at', 'ch']
          allPortals.forEach(portal => {
            // Standardwerte setzen, wenn keine Daten in dem Portal für den Zeitraum
            avgBrandData[portal][i].BrandCount = portalBrands[portal][i].length
            if (avgBrandData[portal][i].max.Name == null) {
              avgBrandData[portal][i].max.Name = avgBrandData[portal][i].min.Name
            }
          })
        } catch (error) {
          console.error(error, 'portal: ' + lastPortal + ' i: ' + i)
        }
        currentDay = startDate.add(1, this.groupby.toLowerCase() + 's')
      }

      const datasets = []
      if (barChartDatasetsPortal.includes('de')) {
        datasets.push(
          {
            label: 'Ø DE',
            data: dataBrand.de,
            backgroundColor: '#2A6CAD',
            type: 'line',
            fill: false,
            yAxisID: 'yavg-line',
            avgBrandData: avgBrandData.de
          }
        )
      }
      if (barChartDatasetsPortal.includes('at')) {
        datasets.push(
          {
            label: 'Ø AT',
            data: dataBrand.at,
            backgroundColor: '#1F8C2A',
            type: 'line',
            fill: false,
            yAxisID: 'yavg-line',
            avgBrandData: avgBrandData.at
          }
        )
      }
      if (barChartDatasetsPortal.includes('ch')) {
        datasets.push(
          {
            label: 'Ø CH',
            data: dataBrand.ch,
            backgroundColor: '#EA9B9B',
            type: 'line',
            fill: false,
            yAxisID: 'yavg-line',
            avgBrandData: avgBrandData.ch
          }
        )
      }
      if (barChartDatasetsPortal.includes('ug')) {
        datasets.push(
          {
            label: 'Ø UG',
            data: dataBrand.ug,
            backgroundColor: '#ffa834',
            type: 'line',
            fill: false,
            yAxisID: 'yavg-line',
            avgBrandData: avgBrandData.ug
          }
        )
      }

      // console.info('mapLinechartData datasets', datasets)
      return datasets
    },
    getFilteredLabels (item, chart) { // Filtern der Legende
      return !item.text.includes(this.$t('page.statistics.quickview')) && !item.text.startsWith('Ø')
    },
    changeLegendText (chart) { // Textänderung der Legende
      const data = chart.data
      const legends = Array.isArray(data.datasets)
        ? data.datasets.map(function (dataset, i) {
          return {
            text: dataset.label.replace(' ' + this.$t('page.statistics.brandpage'), '') + '   (X)',
            fillStyle: (!Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor : dataset.backgroundColor[0]),
            hidden: !chart.isDatasetVisible(i),
            lineCap: dataset.borderCapStyle,
            lineDash: dataset.borderDash,
            lineDashOffset: dataset.borderDashOffset,
            lineJoin: dataset.borderJoinStyle,
            lineWidth: dataset.borderWidth,
            strokeStyle: dataset.borderColor,
            pointStyle: dataset.pointStyle,
            datasetIndex: i
          }
        }, this)
        : []
      return legends
    },
    tooltipCallbackBeforeLabel (tooltipItem, data) {
      const current = data.datasets[tooltipItem.datasetIndex]
      const label = current.label
      const labelGroup = label.split(' ')
      if (label.endsWith(this.$t('page.statistics.brandpage'))) {
        let count = 0
        data.datasets.forEach(dataset => {
          if (
            dataset.label.startsWith(labelGroup[0]) &&
                      dataset.data[tooltipItem.index] != null
          ) {
            count += parseInt(dataset.data[tooltipItem.index])
          }
        })
        return labelGroup[0] + ' (' + this.$t('page.statistics.total') + ': ' + count + ')'
      }
      if (label.startsWith('Ø')) {
        const avgCurrent = current.avgBrandData[tooltipItem.index]
        const specialSectorGroup = (this.currentBrand != null ? this.currentBrand.SpecialSector.SpecialSectorGroup.Caption + '<br>' : '')
        if (avgCurrent != null) return 'Ø ' + specialSectorGroup + this.$t('page.statistics.nuberofbrands') + ': ' + avgCurrent.BrandCount + '<br>Max: ' + avgCurrent.max.Count + ' - ' + avgCurrent.max.Name + '<br>Min: ' + avgCurrent.min.Count + ' - ' + avgCurrent.min.Name
      }
      return ''
    },
    tooltipCallbackLabel (tooltipItem, data) {
      let label = data.datasets[tooltipItem.datasetIndex].label
      if (label.startsWith('Ø')) return label + ': ' + tooltipItem.yLabel
      const splitLabel = label.split(' ')
      label = label.replace(splitLabel[0], '')
      return label + ': ' + tooltipItem.yLabel
    },
    getDefaultChartData () {
      return {
        labels: [],
        datasets: [],
        options: {
          responsive: true,
          tooltips: {
            enabled: false,
            mode: 'label',
            callbacks: {}
          },
          scales: {
            xAxes: [{ id: 'xbar-stacked', stacked: true }],
            yAxes: [
              { id: 'ybar-stacked', stacked: true, ticks: { stepSize: this.scaleYstepSize ? this.scaleYstepSize : (this.groupby === 'Month' ? 10 : 5) } },
              { id: 'yavg-line', ticks: { stepSize: 200 }, position: 'right', scaleLabel: { display: true, labelString: 'Ø' } }
            ]
          },
          legend: {
            display: true,
            labels: {}
          },
          maintainAspectRatio: false
        }
      }
    },
    openOrCloseStat () {
      if (this.collapsed == null) return
      this.titleArrowClass = this.statIsOpen ? 'fa-arrow-down' : 'fa-arrow-up'
      this.getChartData()
    }
  },
  watch: {
    'currentBrand.BrandID': function () {
      this.getChartData()
    },
    startDate: function () {
      if (typeof this.loadCounter !== 'undefined') return
      this.getChartData()
    },
    endDate: function () {
      if (typeof this.loadCounter !== 'undefined') return
      this.getChartData()
    },
    groupby: function () {
      if (typeof this.loadCounter !== 'undefined') return
      this.getChartData()
    },
    includeMembers: function () {
      this.showRefreshButton = true
    },
    includeNonMembers: function () {
      this.showRefreshButton = true
    },
    filterByBrandIDs: function () {
      this.getChartData()
    },
    loadCounter: function () {
      this.getChartData()
    }
  },
  mounted: function () {
    this.getChartData()
  }
}
</script>

<style lang="less" scoped>
.mb-bar-chart {
  min-height: 400px;
  position: relative;

  &.mb-collapsed {
    min-height: 0;
  }
}
.mb-no-chart-data, .mb-loading {
  background-color: rgba(255,255,255,0.5);
  position: absolute;
  display: grid;
  place-items: center;
  height: calc(100% - (1.5rem * 2) - 15px);
  width: calc(100% - (1.5rem * 2));
  font-weight: 700;
  font-size: 20px;
  bottom: 0px;
}
.custom-control {
  z-index: initial;
}
h2 {
  cursor: pointer;
}
</style>
