<template>
  <div id="totalinvestment">
    <h6 class="mb-card-title mb-dashed-text" v-html="$t('page.newbrand.basicdata.investmentsum')"></h6>
    <div class="mb-investment">
      <div class="row mb-arrow mb-a-first">
        <div class="form-group col-4">
          <select class="form-control" name="CondInvestSumType" @change="registerChange"
            v-model="currentBrand.CondInvestSumType">
            <option :value="1" v-t="'choice.fromto'"></option>
            <option :value="2" v-t="'choice.fix'"></option>
            <option :value="3" v-t="'choice.startsat'"></option>
          </select>
        </div>
        <div v-show="currentBrand.CondInvestSumType > 0" class="col">
          <div class="row">
            <div class="form-group col">
              <currency-input class="form-control" v-model="currentBrand.CondInvestSumFrom"
                :currency="getCurrencyLabel(currentBrand.CondCurrency)" locale="de" @change="registerChange" />
            </div>
            <div class="form-group col" v-show="currentBrand.CondInvestSumType === 1">
              <label class="prefixed">-</label>
              <currency-input class="form-control" v-model="currentBrand.CondInvestSumTo"
                :currency="getCurrencyLabel(currentBrand.CondCurrency)" locale="de" @change="registerChange" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-arrow mb-a-second">
        <div class="col-12">
          <h6 class="mb-card-title" v-html="$t('page.newbrand.basicdata.requiredcapital')"></h6>
        </div>
        <div class="form-group col-4">
          <select class="form-control" name="CondRequiredCapitalType" @change="registerChange"
            v-model="currentBrand.CondRequiredCapitalType">
            <option :value="1" v-t="'choice.fromto'"></option>
            <option :value="2" v-t="'choice.fix'"></option>
            <option :value="3" v-t="'choice.startsat'"></option>
          </select>
        </div>
        <div v-show="currentBrand.CondRequiredCapitalType > 0" class="col">
          <div class="row">
            <div class="form-group col">
              <currency-input class="form-control" v-model="currentBrand.CondRequiredCapitalFrom"
                :currency="getCurrencyLabel(currentBrand.CondCurrency)" locale="de" @change="registerChange" />
            </div>
            <div class="form-group col" v-show="currentBrand.CondRequiredCapitalType === 1">
              <label class="prefixed">-</label>
              <currency-input class="form-control" v-model="currentBrand.CondRequiredCapitalTo"
                :currency="getCurrencyLabel(currentBrand.CondCurrency)" locale="de" @change="registerChange" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-arrow mb-a-third">
        <div class="col-12">
          <h6 class="mb-card-title" v-html="$t('page.newbrand.basicdata.entryfee')"></h6>
        </div>
        <div class="form-group col-4">
          <select class="form-control" name="CondEntryFeeType" @change="registerChange"
            v-model="currentBrand.CondEntryFeeType">
            <option :value="1" v-t="'choice.fromto'"></option>
            <option :value="2" v-t="'choice.fix'"></option>
            <option :value="3" v-t="'choice.startsat'"></option>
          </select>
        </div>
        <div v-show="currentBrand.CondEntryFeeType > 0" class="col">
          <div class="row">
            <div class="form-group col">
              <currency-input class="form-control" v-model="currentBrand.CondEntryFeeFrom"
                :currency="getCurrencyLabel(currentBrand.CondCurrency)" locale="de" @change="registerChange" />
            </div>
            <div class="form-group col" v-show="currentBrand.CondEntryFeeType === 1">
              <label class="prefixed">-</label>
              <currency-input class="form-control" v-model="currentBrand.CondEntryFeeTo"
                :currency="getCurrencyLabel(currentBrand.CondCurrency)" locale="de" @change="registerChange" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h6 class="mb-card-title" v-html="$t('page.yourdata.conditions.basicequipment')"></h6>
        </div>
        <div class="form-group col-4">
          <select v-model="currentBrand.CondInitialCapitalType" class="form-control" name="fld_ArticleTypeID"
            @change="registerChange">
            <option :value="-1" v-t="'page.yourdata.conditions.basicequipmentnocost'"></option>
            <option :value="1" v-t="'choice.fromto'"></option>
            <option :value="2" v-t="'choice.fix'"></option>
            <option :value="3" v-t="'choice.startsat'"></option>
          </select>
        </div>
        <div v-show="currentBrand.CondInitialCapitalType > 0" class="col">
          <div class="row">
            <div class="form-group col">
              <currency-input v-model="currentBrand.CondInitialCapitalFrom" class="form-control"
                :currency="currentBrand.CondCurrency" locale="de" @change="registerChange" />
            </div>
            <div v-show="currentBrand.CondInitialCapitalType === 1" class="form-group col">
              <label class="prefixed">-</label>
              <currency-input v-model="currentBrand.CondInitialCapitalTo" class="form-control"
                :currency="currentBrand.CondCurrency" locale="de" @change="registerChange" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input'

export default {
  name: 'Investments',
  components: {
    CurrencyInput
  },
  props: ['name', 'value', 'hideSystemType'],
  data: function () {
    return {
      systemType: 0,
      systemTypeOptions: [],
      detailedSystemManualAvailable: null,
      acceptedCondRequiredCapital: false
    }
  },
  computed: {
    app: function () {
      return this.$parent || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    }
  },
  methods: {
    registerChange: function () {
      if (this.acceptedCondRequiredCapital === false && (
        (this.currentBrand.CondRequiredCapitalFrom && this.currentBrand.CondRequiredCapitalFrom) <= 0 ||
        (this.currentBrand.CondRequiredCapitalTo && this.currentBrand.CondRequiredCapitalTo <= 0)
      )
      ) {
        this.$swal({
          title: 'Sind Sie sicher?',
          html: 'Die Angabe 0 Euro Eigenkapital trifft in den meisten Fällen nicht zu. Bei einer bewusst falschen Angabe können die rechtlichen Konsequenzen aufgrund der "vorvertraglichen Aufklärungspflichten" schwerwiegend sein. Bitte hinterfragen und überprüfen Sie daher noch einmal, ob die Angabe zutrifft und ein Partner wirklich ohne jegliches Eigenkapital sein Unternehmen gründen kann.',
          type: 'warning',
          width: '42em',
          showCancelButton: true,
          confirmButtonText: 'Ja, ich bin sicher',
          cancelButtonText: 'Nein, Angabe korrigieren',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          customClass: {
            header: 'align-items-start',
            content: 'text-left',
            actions: 'justify-content-between'
          }
        }).then((result) => {
          if (result.value) {
            this.acceptedCondRequiredCapital = true
            this.$emit('change')
          }
        })
      } else {
        this.$emit('change')
      }
    },
    getCurrencyLabel (currencyId) {
      return currencyId
    }
  },
  mounted: function () {
  }
}
</script>

<style lang="scss" scoped>
.mb-investment {
  padding-left: 40px;
  position: relative;
}

.mb-card-title {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  padding-bottom: 0px !important;
  border-bottom: none !important;
}

.form-group {
  padding-bottom: 15px !important;
}

.mb-arrow {
  &::before {
    content: '';
    border-left: 2px dashed #ccc;
    position: absolute;
    border-bottom: 2px dashed #ccc;
  }

  &::after {
    content: '';
    position: absolute;
    left: 16px;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2260%22%20height%3D%2280%22%20viewBox%3D%220%200%2050%2080%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23000%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20d%3D%22M.375.375%2045.63%2038.087.375%2075.8%22%2F%3E%3C%2Fsvg%3E');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 14px;
    height: 20px;
    width: 20px;
  }

  &.mb-a-first {
    &::before {
      height: 100px;
      left: 16px;
      top: -20px;
      width: 15px;
    }

    &::after {
      top: 69px;
    }
  }

  &.mb-a-second {
    &::before {
      height: 196px;
      left: 8px;
      top: -20px;
      width: 23px;
    }

    &::after {
      top: 165px;
    }
  }

  &.mb-a-third {
    &::before {
      height: 291px;
      left: 0px;
      top: -20px;
      width: 30px;
    }

    &::after {
      top: 260px;
    }
  }
}
</style>
<style lang="scss">
.mb-dashed-text {
  >label {
    border-bottom: 2px dashed #ccc;
  }
}
</style>
