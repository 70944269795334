<template>
  <div>
    <h4>Funktionen</h4>
    <div class="d-flex flex-column gap-3">
      <a href="javascript:">
        <inline-svg :src="require('@/assets/images/icons/3.0/share.svg')" />
        <span>Profil teilen</span>
      </a>
      <a href="javascript:">
        <inline-svg :src="require('@/assets/images/icons/3.0/print.svg')" />
        <span>Profil drucken</span>
      </a>
      <a href="javascript:">
        <inline-svg :src="require('@/assets/images/icons/3.0/remove.svg')" />
        <span>Kontakt abbrechen</span>
      </a>
      <a href="javascript:">
        <inline-svg :src="require('@/assets/images/icons/3.0/flag.svg')" />
        <span>Profil melden</span>
      </a>
      <a href="javascript:">
        <inline-svg :src="require('@/assets/images/icons/3.0/similar.svg')" />
        <span>Ähnliche Profile finden</span>
      </a>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'AnonymousContactFunctions',
  props: ['contact'],
  components: {
    InlineSvg
  },
  data: function () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: #00719f;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    text-decoration: underline;
  }
}
</style>
