<template>
  <div>
    <div
      v-if="app.hasCurrentBrand"
      id="intpraesenz"
      class="container mb-info-konzept p-0"
    >
      <div class="card mb-5">
        <div class="card-body">
          <form>
            <h5 class="card-title" v-t="'page.yourdata.intpresence.title'"></h5>
            <span v-t="'page.yourdata.intpresence.description'"></span>
            <h6 class="mb-card-title" v-t="'page.yourdata.intpresence.currentpresence'"></h6>
            <p v-t="'page.yourdata.intpresence.planedexpansion'"></p>
            <div class="row slim mb-3">
              <div class="form-group col">
                <b-form-checkbox
                  v-model="currentBrand.ComParamIntMultiple"
                  :value="1"
                  :unchecked-value="0"
                  @change="registerChange"
                >
                  {{ $t('page.yourdata.intpresence.currentpresenceincountries') }} <b-link
                    id="tt_currentlyMultipleCountries"
                    class="badge badge-warning"
                  >
                    ?
                  </b-link>
                </b-form-checkbox>
              </div>
              <b-tooltip
                target="tt_currentlyMultipleCountries"
                placement="top"
              >
                <p>{{ $t('page.yourdata.intpresence.currentpresenceincountrieshint', {country: $parent.datenland}) }}</p>
              </b-tooltip>
              <div
                v-show="currentBrand.ComParamIntMultiple==1"
                class="form-group col"
              >
                <div class="form-check">
                  <brand-license-selector
                    :label="$t('page.yourdata.intpresence.currentcountryselection')"
                    :countries="currentBrand.CountryLicenses"
                    :options="app.getCountryListStartWithDeAtCh('LicAvail')"
                    :BrandID="currentBrand.BrandID"
                    type="LicAvail"
                    @change="registerChange"
                  />
                </div>
              </div>
            </div>
            <div class="row slim">
              <div class="form-group col">
                <label v-t="'page.yourdata.intpresence.planedmasterlicence'"></label>
                <div class="custom-radio-input">
                  <b-form-radio-group
                    v-model="computedComParamIntMasterLic"
                    name="aufpreis"
                    @change="registerChange"
                  >
                    <b-form-radio value="1">
                      {{ $t('choice.yes') }}
                    </b-form-radio>
                    <b-form-radio value="0">
                      {{ $t('choice.no') }}
                    </b-form-radio>
                    <b-form-radio value="-1">
                      {{ $t('choice.notspecified') }}
                    </b-form-radio>
                  </b-form-radio-group>
                </div>
              </div>

              <div
                v-show="currentBrand.ComParamIntMasterLic==1"
                class="form-group col"
              >
                <div class="form-check">
                  <brand-license-selector
                    :label="$t('page.yourdata.intpresence.planedmasterlicenceselection')"
                    :countries="currentBrand.CountryLicenses"
                    :options="app.getCountryListStartWithDeAtCh('FutureMasterLic')"
                    :BrandID="currentBrand.BrandID"
                    type="FutureMasterLic"
                    @change="registerChange"
                  />
                </div>
              </div>
            </div>
            <h6 class="mb-card-title" v-t="'page.yourdata.intpresence.originofbrandtitle'"></h6>
            <p v-t="'page.yourdata.intpresence.originofbranddesc'"></p>
            <div class="row slim">
              <div class="col form-group">
                <label for="ComParamIntRootCountryID" v-t="'page.yourdata.intpresence.origincountry'"></label>
                <div>{{ app.getCountryNameByID(currentBrand.ComParamIntRootCountryID) }}</div>
              </div>
              <div class="col form-group">
                <label for="ComParamIntRootYear">
                  {{ $t('page.yourdata.intpresence.foundingyear') }}
                  <a
                    id="tt_yearOfFounding"
                    href="javascript:"
                    class="badge badge-warning"
                  >?</a>
                </label>
                <b-tooltip
                  target="tt_yearOfFounding"
                  placement="top"
                >
                  <p>{{ $t('page.yourdata.intpresence.foundingyearhint', {brandname: currentBrand.Name}) }}</p>
                </b-tooltip>
                <input
                  v-model="currentBrand.ComParamIntRootYear"
                  type="number"
                  class="form-control"
                  placeholder="YYYY"
                  @input="registerChange"
                >
              </div>
              <div class="col form-group">
                <label for="ComParamIntRootFirstPartnerYear">
                  {{ $t('page.yourdata.intpresence.yearoffirstpartner') }}
                  <a
                    id="tt_yearOfFirstPartner"
                    href="javascript:"
                    class="badge badge-warning"
                  >?</a>
                </label>
                <b-tooltip
                  target="tt_yearOfFirstPartner"
                  placement="top"
                >
                  <p>{{ $t('page.yourdata.intpresence.yearoffirstpartnerhint', {brandname: currentBrand.Name}) }}</p>
                </b-tooltip>
                <input
                  v-model="currentBrand.ComParamIntRootFirstPartnerYear"
                  type="number"
                  class="form-control"
                  placeholder="YYYY"
                  @input="registerChange"
                >
              </div>
            </div>
            <div style>
              <h6 class="mb-card-title" v-html="$t('page.yourdata.intpresence.economicindicators')"></h6>
              <div class="row slim">
                <div class="col form-group">
                  <label for="ComParamCurrency" v-t="'currency.title'"></label>
                  <select
                    v-model="currentBrand.ComParamCurrency"
                    class="form-control"
                    name="ComParamCurrency"
                    @change="registerChange"
                  >
                    <option
                      v-for="(option, i) in app.preloads.currencies"
                      :key="i"
                      :value="option.Currency"
                    >
                      {{ option.Currency }}
                    </option>
                  </select>
                </div>
                <div class="col form-group">
                  <label for="fld_ArticleTypeID">
                    {{ $t('page.yourdata.intpresence.totalsales') }}
                    <a
                      id="tt_wholeNetwork"
                      href="javascript:"
                      class="badge badge-warning"
                    >?</a>
                  </label>
                  <b-tooltip
                    target="tt_wholeNetwork"
                    placement="top"
                  >
                    <p>{{ $t('page.yourdata.intpresence.totalsaleshint', {country: datenLand}) }}</p>
                  </b-tooltip>
                  <currency-input
                    v-model="currentBrand.ComParamIntTurnover"
                    class="form-control"
                    :currency="currentBrand.ComParamCurrency"
                    locale="de"
                    @input="registerChange"
                  />
                </div>
              </div>
              <h6 class="mb-card-title">
                <div v-html="$t('page.yourdata.intpresence.entirenetworkfigures')"></div>
                <br />
                <small class="d-block pt-3" v-html="$t('page.yourdata.intpresence.savewarning')"></small>
              </h6>
              <div class="row">
                <div class="col mb-5">
                  <operation-unit-form
                    v-model="currentBrand.OperationUnits"
                    :label="$t('page.yourdata.intpresence.companiesworldwide')"
                    total-qualifier="OperationsInternational"
                    child-qualifier="PartnerInternational"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input'
import OperationUnitForm from '@/components/OperationUnitForm'
import BrandLicenseSelector from '@/components/BrandLicenseSelector'
export default {
  name: 'BrandProfileDataInternational',
  components: {
    BrandLicenseSelector,
    CurrencyInput,
    OperationUnitForm
  },
  props: [],
  data: function () {
    return {}
  },
  computed: {
    computedComParamIntMasterLic: {
      get: function () {
        if (this.currentBrand.ComParamIntMasterLic === null) {
          return -1
        }
        return this.currentBrand.ComParamIntMasterLic
      },
      set: function (newValue) {
        this.currentBrand.ComParamIntMasterLic = newValue
      }
    },
    datenLand: function () {
      if (this.currentBrand.ComParamOriginCountryID) {
        return this.preloads.countries.filter(country => {
          return country.CountryID === this.currentBrand.ComParamOriginCountryID
        }).shift().Caption
      }
      return ''
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    },
    preloads: function () {
      return this.app.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    }
  },
  methods: {
    registerChange () {
      this.app.registerChangedContent(this.app.saveBrand)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
