<template>
  <div style="position: relative">
    <froala
      ref="editor"
      v-model="currentContent"
      tag="textarea"
      :config="editorOptions"
    />
    <div v-if="showProgressBar" ref="progress" class="mb-edit-progress">
      <small class="mb-progress-min-info" ref="progressInfo" v-html="$t('editor.minlength', { minlength: progressCharCountMin})"></small>
      <div class="mb-progress-status">
        <div class="mb-progress-status-container red" style="width:20%">
          <div class="mb-status-percent" ref="progressStatusRed"></div>
        </div>
        <div class="mb-progress-status-container yellow" style="width:40%">
          <div class="mb-status-percent" ref="progressStatusYellow"></div>
        </div>
        <div class="mb-progress-status-container green" style="width: 100%">
          <div class="mb-status-percent" ref="progressStatusGreen"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Utils plugin
import Vue from 'vue'
import Config from '../config'
import Utils from '../modules/Utils.js'
import sanitizeHtml from 'sanitize-html'

import VueFroala from 'vue-froala-wysiwyg'

import FroalaEditor from 'froala-editor'

require('froala-editor/js/plugins/align.min.js')
require('froala-editor/js/plugins/link.min.js')
require('froala-editor/js/plugins/lists.min.js')

require('froala-editor/js/plugins/char_counter.min.js')
require('froala-editor/css/plugins/char_counter.min.css')

require('froala-editor/js/plugins/video.min.js')
require('froala-editor/css/plugins/video.min.css')

require('froala-editor/js/plugins/image.min.js')
require('froala-editor/css/plugins/image.min.css')
require('froala-editor/js/third_party/image_tui.min')

require('froala-editor/js/plugins/paragraph_format.min.js')
require('froala-editor/js/plugins/paragraph_style.min.js')

require('froala-editor/js/languages/en_gb.js')
require('froala-editor/js/languages/de.js')

require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.min.css')

const editorCustomStyles =
  'img[align=left]{margin-right:20px;margin-bottom:20px}img[align=right]{margin-left:20px;margin-bottom:20px}'

Vue.use(Utils)
Vue.use(VueFroala)

// register the grid component
export default {
  name: 'CustomEditor',
  components: {},
  props: ['value', 'maxlength', 'small', 'minHeight', 'showProgressBar', 'progressCharCountMin', 'progressCharCountOK', 'toolbarStickyOffset', 'placeholderText'],
  data: function () {
    const _this = this

    if ('insertMedia' in _this) {
      window.insertMedia = _this.insertMedia
    }

    const loginFromStorage = localStorage.getItem('FPMB_Login')
    const login = loginFromStorage ? JSON.parse(loginFromStorage) : {}
    const editorlang = this.$i18n.locale !== 'de' ? 'en_gb' : 'de'

    const startEditorConfig = {
      attribution: false,
      iframeStyle: editorCustomStyles,
      key: Config.FROALA_KEY,
      pastePlain: true,
      htmlUntouched: true,
      enter: FroalaEditor.ENTER_P,
      language: editorlang,
      heightMin: this.minHeight,
      placeholderText: this.placeholderText,
      events: {
        initialized: function (e, editorInstance) {
          _this.editorInstance = this
        },
        blur: function () {
          _this.cleanup()
        },
        keyup: function () {
          _this.calculateProgress(sanitizeHtml(this.html.get(), Config.DEFAULT_HTML_ALLOWS))
        },
        'charCounter.exceeded': function () {
          _this.$swal(
            _this.$t('popupmessage.warn.attentiontitle'),
            _this.$t('popupmessage.warn.onlyxcharsallowed', { count: _this.maxlength }),
            'warning'
          )
        }
      },
      paragraphFormat: {
        N: 'Absatz',
        H2: 'Überschrift'
      },
      pluginsEnabled: [
        'lists',
        'charCounter',
        'paragraphFormat',
        'video',
        'image',
        'align'
      ],
      toolbarButtons: {
        moreText: {
          buttons: ['bold', 'italic'],
          buttonsVisible: 3
        },
        moreParagraph: {
          buttons: [
            'insertImage',
            'paragraphFormat',
            'formatOL',
            'formatUL'
          ],
          buttonsVisible: 5
        },
        showMoreButtons: false
      },
      imageUploadURL: Config.IMAGE_UPLOAD_FUNCTION,
      imageUploadParams: {
        folder: 'SystemRoot/legacy/fairpics/',
        overwriteMode: 1,
        identifier: -1
      },
      imageAllowedTypes: ['jpeg', 'jpg', 'png'],
      requestHeaders: {
        Authorization: ['Bearer', login.token].join(' ')
      },
      requestWithCORS: true,
      videoUpload: false,
      toolbarSticky: true,
      toolbarStickyOffset: this.toolbarStickyOffset != null ? this.toolbarStickyOffset : 70
    }

    if (typeof this.maxlength !== 'undefined') {
      const maxLength = parseInt(this.maxlength)
      if (maxLength > 0) {
        startEditorConfig.charCounterCount = true
        startEditorConfig.charCounterMax = maxLength
      }
    }

    if (typeof this.small !== 'undefined') {
      if (this.small === true) {
        startEditorConfig.pluginsEnabled = [
          'lists',
          'charCounter',
          'wordPaste',
          'align'
        ]
        startEditorConfig.toolbarButtons = {
          moreParagraph: {
            buttons: [
              'bold',
              'formatUL',
              'paragraphStyle'
            ],
            buttonsVisible: 10
          },
          showMoreButtons: false
        }
      }
    }

    return {
      editorOptions: startEditorConfig,
      editorInstance: null,
      initialLoad: true
    }
  },
  computed: {
    currentContent: {
      get: function () {
        if (typeof this.value === 'undefined') {
          return ''
        }

        if (this.value === undefined) {
          return ''
        }

        if (this.value === null) {
          return ''
        }

        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)

        this.calculateProgress(newValue)
        if (this.initialLoad !== true) {
          this.$emit('change')
        }
        this.initialLoad = false
      }
    }
  },
  methods: {
    cleanup: function () {
      setTimeout(() => {
        this.currentContent = sanitizeHtml(this.editorInstance.html.get(), Config.DEFAULT_HTML_ALLOWS)
      }, 100)
    },
    calculateProgress: function (newValue) {
      if (this.showProgressBar) {
        this.progressCharCountMin = parseInt(this.progressCharCountMin)
        this.progressCharCountOK = parseInt(this.progressCharCountOK)
        this.maxlength = parseInt(this.maxlength)
        const newValueLength = this.stripHtml(newValue).length
        if (newValueLength < this.progressCharCountMin) {
          const redPercent = parseInt((newValueLength / this.progressCharCountMin) * 100)
          this.$refs.progressStatusRed.style.width = redPercent + '%'
          this.$refs.progressStatusRed.style.display = 'block'
          this.$refs.progressStatusRed.parentElement.style.display = 'block'
          this.$refs.progressInfo.style.display = 'block'
        } else {
          this.$refs.progressStatusRed.style.display = 'none'
          this.$refs.progressStatusRed.parentElement.style.display = 'none'
          this.$refs.progressInfo.style.display = 'none'
        }
        if (newValueLength >= this.progressCharCountMin && newValueLength < this.progressCharCountOK) {
          const yellowPercent = parseInt(((newValueLength - this.progressCharCountMin) / (this.progressCharCountOK - this.progressCharCountMin)) * 100)
          this.$refs.progressStatusYellow.style.width = yellowPercent + '%'
          this.$refs.progressStatusYellow.style.display = 'block'
          this.$refs.progressStatusYellow.parentElement.style.display = 'block'
        } else {
          this.$refs.progressStatusYellow.style.display = 'none'
          this.$refs.progressStatusYellow.parentElement.style.display = 'none'
        }
        if (newValueLength >= this.progressCharCountOK) {
          this.$refs.progressStatusGreen.style.width = parseInt(((newValueLength - this.progressCharCountOK) / (this.maxlength - this.progressCharCountOK)) * 100) + '%'
          this.$refs.progressStatusGreen.style.display = 'block'
          this.$refs.progressStatusGreen.parentElement.style.display = 'block'
        } else {
          this.$refs.progressStatusGreen.style.display = 'none'
          this.$refs.progressStatusGreen.parentElement.style.display = 'none'
        }
      }
    },
    stripHtml: function (html) {
      const tmp = document.createElement('DIV')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    }
  },
  watch: {
    '$i18n.locale' () {
      this.$refs.editor.destroyEditor()
      this.$refs.editor.config.language = this.$i18n.locale !== 'de' ? 'en_gb' : 'de'
      this.editorOptions.placeholderText = this.placeholderText
      this.$refs.editor.createEditor()
    }
  },
  mounted: function () {
    this.calculateProgress(this.value)
  }
}
</script>

<style lang="less">
.fr-toolbar,
.second-toolbar {
  border-radius: 0 !important;
}
.second-toolbar #logo {
  display: none;
}

.fr-box .fr-counter {
  float: left;
}
.mb-edit-progress {
  position: absolute;
  right: 15px;
  bottom: 15px;

  .mb-progress-status {
    width: 200px;
    height: 15px;
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(196,33,33,0.3) 0%, rgba(196,33,33,0.3) 20%, rgba(200,238,18,0.3) 20%, rgba(200,238,18,0.3) 40%, rgba(50,173,53,0.3) 40%, rgba(50,173,53,0.3) 100%);
    overflow: hidden;
  }

  .mb-progress-min-info {
    position: absolute;
    left: -140px;
    top: -1px;
    font-weight: 700;
    min-width: 130px;
    text-align: right;
  }

  .mb-status-percent {
    display: none;
    height: 100%;
    top: 0;
  }

  small {
    color: rgba(196,33,33,1);
  }

  .mb-progress-status-container {
    height: 100%;
    position: absolute;
    border-radius: 10px;
    overflow: hidden;

    &.red {
      border-left: rgba(196,33,33,1);
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      .mb-status-percent {
        background-color: rgba(196,33,33,1);
      }
    }

    &.yellow {
      border-left: #FEEC44 solid 40px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      .mb-status-percent {
        background-color: #FEEC44;
      }
    }
    &.green {
      border-left: rgba(50,173,53,1) solid 80px;
      .mb-status-percent {
        background-color: rgba(50,173,53,1);
      }
    }
  }
}

.fr-wrapper.show-placeholder {
  overflow: hidden;

  .fr-placeholder {
    font-size: 12px !important;
    white-space: normal;
  }
}
</style>
