<template>
  <div class="mb-main-content">
    <div id="changeAddressCard" class="container mb-marke-markenseite pt-5">
      <div class="row mb-3">
        <div class="col-12 text-left">
          <router-link
            to="/dashboard"
            class="btn btn-secondary"
            v-t="'page.addresschange.backbutton'"
          ></router-link>
        </div>
      </div>
      <div class="card shadow p-3 mb-5 bg-white">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h4>
                {{
                  $t("page.addresschange.company", {
                    company: currentBrand.Name,
                  })
                }}
              </h4>
              <p>
                {{
                  $t("page.addresschange.companydesc", {
                    company: currentBrand.Name,
                  })
                }}
              </p>
            </div>

            <div class="col" v-if="app.isStripeCustomer">
              <h4>
                {{
                  $t("page.addresschange.invoice", {
                    company: currentBrand.Name,
                  })
                }}
              </h4>
              <p>
                {{
                  $t("page.addresschange.invoicedesc", {
                    company: currentBrand.Name,
                  })
                }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <ValidationObserver ref="defaultValidator">
                <form @submit.prevent="saveCompany">
                  <div class="form-group">
                    <label for="companyName" v-t="'address.name'"></label>
                    <validation-provider v-slot="{ errors }" rules="required">
                      <input
                        type="text"
                        class="form-control"
                        v-model="currentCompany.Name"
                      />
                      <span class="errorMessage">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <label for="companyName" v-t="'address.street'"></label>
                    <validation-provider v-slot="{ errors }" rules="required">
                      <input
                        type="text"
                        class="form-control"
                        v-model="currentCompany.Street"
                      />
                      <span class="errorMessage">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <label for="companyName" v-t="'address.zip'"></label>
                    <validation-provider v-slot="{ errors }" rules="required">
                      <input
                        type="text"
                        class="form-control"
                        v-model="currentCompany.Zip"
                      />
                      <span class="errorMessage">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <label for="companyName" v-t="'address.city'"></label>
                    <validation-provider v-slot="{ errors }" rules="required">
                      <input
                        type="text"
                        class="form-control"
                        v-model="currentCompany.City"
                      />
                      <span class="errorMessage">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <label for="companyName" v-t="'address.country'"></label>
                    <validation-provider v-slot="{ errors }" rules="required">
                      <select
                        key="CountryCodeSelect"
                        v-model="currentCompany.CountryID"
                        class="form-control"
                        name="CountryCode"
                      >
                        <option
                          v-for="country in app.preloads.countries"
                          :key="country.CountryID"
                          :value="country.CountryID"
                        >
                          {{ country.Caption }}
                        </option>
                      </select>
                      <span class="errorMessage">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>

                  <div class="form-group text-right">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      v-t="'page.addresschange.savecompany'"
                    ></button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <div class="col" v-if="app.isStripeCustomer">
              <ValidationObserver ref="stripeValidator">
                <form @submit.prevent="saveStripeAddress">
                  <div class="form-group">
                    <label for="companyName" v-t="'address.name'"></label>
                    <validation-provider v-slot="{ errors }" rules="required">
                      <input
                        type="text"
                        class="form-control"
                        v-model="currentStripeAddress.Name"
                      />
                      <span class="errorMessage">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <label for="companyName" v-t="'address.street'"></label>
                    <validation-provider v-slot="{ errors }" rules="required">
                      <input
                        type="text"
                        class="form-control"
                        v-model="currentStripeAddress.Street"
                      />
                      <span class="errorMessage">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <label for="companyName" v-t="'address.zip'"></label>
                    <validation-provider v-slot="{ errors }" rules="required">
                      <input
                        type="text"
                        class="form-control"
                        v-model="currentStripeAddress.Zip"
                      />
                      <span class="errorMessage">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <label for="companyName" v-t="'address.city'"></label>
                    <validation-provider v-slot="{ errors }" rules="required">
                      <input
                        type="text"
                        class="form-control"
                        v-model="currentStripeAddress.City"
                      />
                      <span class="errorMessage">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <label for="companyName" v-t="'address.country'"></label>
                    <validation-provider v-slot="{ errors }" rules="required">
                      <select
                        key="CountryCodeSelect"
                        v-model="currentStripeAddress.CountryID"
                        class="form-control"
                        name="CountryCode"
                      >
                        <option
                          v-for="country in app.preloads.countries"
                          :key="country.CountryID"
                          :value="country.CountryID"
                        >
                          {{ country.Caption }}
                        </option>
                      </select>
                      <span class="errorMessage">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>

                  <div class="form-group" v-if="computedIsVatRequired">
                    <label for="companyName" v-t="'address.taxid'"></label>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="vatNumber"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="currentStripeAddress.VatID"
                      />
                      <span class="errorMessage">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <label
                      for="stripeAddressMail"
                      v-t="'address.invoicemail'"
                    ></label>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|email"
                    >
                      <input
                        id="stripeAddressMail"
                        type="text"
                        class="form-control"
                        v-model="currentStripeAddress.Mail"
                      />
                      <span class="errorMessage">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>

                  <div class="form-group text-right">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      v-t="'page.addresschange.saveinvoicecompany'"
                    ></button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required,
  email,
  required_if as requiredIf
} from 'vee-validate/dist/rules'
import Config from '../../config'

const emptyCompany = {
  CompanyID: null,
  Name: null,
  Street: null,
  Zip: null,
  City: null,
  CountryID: null
}

const emptyStripeAddress = {
  Name: null,
  Street: null,
  Zip: null,
  City: null,
  CountryID: null,
  VatID: null,
  Mail: null
}

export default {
  name: 'AddressChange',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: [],
  data: function () {
    return {
      currentCompany: Object.assign({}, emptyCompany),
      currentStripeAddress: Object.assign({}, emptyStripeAddress),
      isLoading: false,
      countryCodesMandatoryUID: Config.MANDATORY_VAT_COUNTRIES
    }
  },
  computed: {
    app: function () {
      return this.$parent || {}
    },
    currentBrand: function () {
      return this.$parent.currentBrand || {}
    },
    preloads: function () {
      return this.$parent.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    computedIsVatRequired: function () {
      const currentCountry = this.preloads.countries.find((country) => {
        return country.CountryID === this.currentStripeAddress.CountryID
      })
      if (currentCountry) {
        return this.countryCodesMandatoryUID.includes(currentCountry.Code)
      } else {
        return false
      }
    }
  },
  watch: {
    'app.hasCurrentBrand': function () {
      this.loadData()
    },
    'app.login': function () {
      this.loadData()
    }
  },
  mounted: function () {
    this.loadData()
    const requiredfield = this.$t('validation.requiredfield')
    // Override the default message.
    extend('required', {
      ...required,
      message: requiredfield
    })
    extend('email', {
      ...email,
      message: 'Invalid Mail'
    })
    extend('required_if', {
      ...requiredIf,
      message: requiredfield
    })

    extend('vatNumber', {
      validate: async (value) => {
        const response = await this.validateVatNumber(value)

        if (response === true) {
          return true
        }

        return {
          valid: false
        }
      },
      // same prop as returned in the `data` object.
      message: 'Invalid VAT Number'
    })
  },
  methods: {
    validateVatNumber (value) {
      return new Promise((resolve) => {
        const currentCountry = this.preloads.countries.find((country) => {
          return country.CountryID === this.currentStripeAddress.CountryID
        })

        this.app.APIStripe._post('customer/address/checkVatNumber', {
          CountryCode: currentCountry.Code,
          VatNumber: value
        })
          .then(() => {
            resolve(true)
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
    saveCompany () {
      const loader = this.app.startLoader()

      this.$refs.defaultValidator
        .validate()
        .then((isValid) => {
          if (isValid) {
            const companySaveData = {
              CompanyID: this.currentBrand.CompanyID,
              Name: this.currentCompany.Name,
              Street: this.currentCompany.Street,
              Zip: this.currentCompany.Zip,
              City: this.currentCompany.City
            }

            const foundCountry = this.app.preloads.countries.find((country) => {
              return country.CountryID === this.currentCompany.CountryID
            })

            companySaveData.CountryCode = foundCountry.Code

            this.app.API._put('company', companySaveData).then(() => {
              this.currentBrand.Company.Name = this.currentCompany.Name
              this.currentBrand.Company.Street = this.currentCompany.Street
              this.currentBrand.Company.Zip = this.currentCompany.Zip
              this.currentBrand.Company.City = this.currentCompany.City
              this.app.stopLoader(loader)

              this.$swal(
                this.$t('popupmessage.success.savedtitle'),
                this.$t('popupmessage.success.companychange'),
                'success'
              )
            })
          } else {
            this.app.stopLoader(loader)
          }
        })
        .catch(() => {
          this.app.stopLoader(loader)
        })
    },
    saveStripeAddress () {
      const loader = this.app.startLoader()

      this.$refs.stripeValidator
        .validate()
        .then((isValid) => {
          if (isValid) {
            const loginFromStorage = localStorage.getItem('FPMB_Login')
            const login = loginFromStorage ? JSON.parse(loginFromStorage) : {}
            const userId = login.user.UserID
            const username = login.user.Name

            const stripeSaveData = {
              StripeCustomerID: this.currentBrand.StripeCustomerID,
              BrandID: this.currentBrand.BrandID,
              UserID: userId,
              Username: username,
              token: login.token
            }

            stripeSaveData.Name = this.currentStripeAddress.Name
            stripeSaveData.Street = this.currentStripeAddress.Street
            stripeSaveData.Zip = this.currentStripeAddress.Zip
            stripeSaveData.City = this.currentStripeAddress.City
            stripeSaveData.CountryID = this.currentStripeAddress.CountryID

            stripeSaveData.VatID = this.currentStripeAddress.VatID
            stripeSaveData.Mail = this.currentStripeAddress.Mail

            this.app.APIStripe._post('customer/address/set', stripeSaveData)
              .then(() => {
                this.app.stopLoader(loader)
                this.$swal(
                  this.$t('popupmessage.success.savedtitle'),
                  this.$t('popupmessage.success.invoicecompany'),
                  'success'
                )
              })
              .catch((error) => {
                this.app.stopLoader(loader)
                this.$swal(this.$t('popupmessage.error.title'), error, 'error')
              })
          } else {
            this.app.stopLoader(loader)
          }
        })
        .catch(() => {
          this.app.stopLoader(loader)
        })
    },
    loadData () {
      if (!this.app) return
      if (this.app.login === null) return
      if (!this.app.hasCurrentBrand) return
      if (this.isLoading) return

      this.isLoading = true
      const loader = this.app.startLoader()

      const loginFromStorage = localStorage.getItem('FPMB_Login')
      const login = loginFromStorage ? JSON.parse(loginFromStorage) : {}
      const userId = login.user.UserID
      const username = login.user.Name

      this.currentCompany = Object.assign({}, emptyCompany)
      this.currentCompany.CompanyID = this.currentBrand.CompanyID
      this.currentCompany.Name = this.currentBrand.Company.Name
      this.currentCompany.Street = this.currentBrand.Company.Street
      this.currentCompany.Zip = this.currentBrand.Company.Zip
      this.currentCompany.City = this.currentBrand.Company.City

      const foundCountry = this.app.preloads.countries.find((country) => {
        return country.Code === this.currentBrand.Company.CountryCode
      })

      if (foundCountry) {
        this.currentCompany.CountryID = foundCountry.CountryID
      }

      this.app.APIStripe._post('customer/address/get', {
        BrandID: this.currentBrand.BrandID,
        UserID: userId,
        Username: username,
        token: login.token
      }).then((response) => {
        try {
          const addressData = response.body.Data

          if (response.body.Error !== false) {
            throw new Error(response.body.Error)
          }

          if (typeof addressData.NoStripeCustomer === 'undefined') {
            this.currentStripeAddress = Object.assign({}, emptyStripeAddress)
            this.currentStripeAddress.Name = addressData.Name
            this.currentStripeAddress.Street = addressData.Street
            this.currentStripeAddress.Zip = addressData.Zip
            this.currentStripeAddress.City = addressData.City

            this.currentStripeAddress.VatID = addressData.VatID
            this.currentStripeAddress.Mail = addressData.Mail

            const foundCountryStripe = this.app.preloads.countries.find(
              (country) => {
                return country.Code === addressData.Country
              }
            )

            if (foundCountryStripe) {
              this.currentStripeAddress.CountryID = foundCountryStripe.CountryID
            }
          }
        } catch (error) {
          console.error(error)
          this.$swal(
            this.$t('popupmessage.error.title'),
            this.$t('popupmessage.error.unknown'),
            'error'
          )
        }

        this.isLoading = false
        this.app.stopLoader(loader)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.errorMessage {
  color: red;
  padding-top: 0.33rem;
  font-size: 75%;
  margin-left: 0.33rem;
}
.errorMessage:empty {
  display: none;
}
</style>
