<template>
  <div class="mb-register">
    <div class="mb-register-top">
      <a href="https://www.franchiseportal.de" target="_blank">
        <img class="fp-logo" :src="require('../assets/images/fp-logo-white.png')">
      </a>
      <h1 v-t="'page.register.title'"></h1>
    </div>

    <div class="mb-info-top" v-t="'page.register.description'"></div>

    <div class="mb-register-top" v-if="isMaintenance">
      <h2 v-t="'page.login.maintenance'"></h2>
      <p v-html="$t('page.login.maintenancetext')"></p>
    </div>
    <div class="mb-register-error" v-if="loginError">
      <div class="mb-register-err-msg" v-html="loginErrorMessage"></div>
      <div v-if="loginExists" class="mb-register-exists">
        <span class="icon-login"></span>
        <span v-t="'page.login.pleaselogin'"></span>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <form v-if="!isMaintenance" id="regForm" @submit.prevent="onClickRegisterButton">
            <div class="form-container">
              <h3 v-t="'page.register.newfreeaccount'"></h3>
              <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                      <div class="d-flex"><label for="regSalutation" v-t="'contact.salutation'"></label>*</div>
                      <select
                        v-model="salutation"
                        type="text"
                        name="anrede"
                        class="form-control"
                        required
                        id="regSalutation"
                      >
                        <option
                          v-for="salutation in this.Salutations"
                          :key="salutation.SalutationID"
                          :value="salutation.SalutationID"
                        >
                          {{ salutation.Caption }}
                        </option>
                      </select>
                    </div>
                  </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="regFirstname" v-t="'page.register.firstname'"></label>
                      <input
                        v-model="firstname"
                        type="text"
                        class="form-control"
                        required
                        id="regFirstname"
                      >
                  </div>
                </div>
                <div class="col-sm-5">
                    <label for="regLastname" v-t="'page.register.lastname'"></label>
                      <input
                        v-model="lastname"
                        type="text"
                        class="form-control"
                        required
                        id="regLastname"
                      >
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="regPhone" v-t="'page.register.phone'"></label>
                      <input
                        v-model="phone"
                        type="tel"
                        class="form-control"
                        required
                        id="regPhone"
                      >
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="regEmail" v-t="'page.register.mail'"></label>
                      <input
                        v-model="email"
                        type="email"
                        class="form-control"
                        :class="emailErrorClass"
                        required
                        id="regEmail"
                      >
                  </div>
                </div>
              </div>
              <div class="form-group">
                  <label for="regPwd" v-t="'page.register.password'"></label>
                  <input
                    id="regPwd"
                    v-model="password"
                    type="password"
                    class="form-control"
                    :class="pwdErrorClass"
                    v-on:change="checkPassword"
                    @keyup="checkPassword"
                    required
                  >
                  <div
                  class="mb-pwd-strength"
                  :class="pwdStrengthClass"
                  >
                  </div>
                  <div
                    v-if="showPwdInfo"
                    class="mb-pwd-info"
                  >
                  {{ $t('page.register.pwdrequirements') }}
                  <ul>
                    <li
                    class="fa "
                    :class="passwordValidation.Length ? 'fa-check' : 'fa-times'"
                    >
                    {{ $t('page.register.pwdlength') }}
                    </li>
                    <li
                    class="fa "
                    :class="passwordValidation.Alpha ? 'fa-check' : 'fa-times'"
                    >
                      {{ $t('page.register.pwdcase') }}
                    </li>
                    <li
                    class="fa "
                    :class="passwordValidation.Numeric ? 'fa-check' : 'fa-times'"
                    >
                      {{ $t('page.register.pwdnumeric') }}
                    </li>
                    <li
                    class="fa "
                    :class="passwordValidation.SpecialC ? 'fa-check' : 'fa-times'"
                    >
                      {{ $t('page.register.pwdspacialchar') }}
                    </li>
                  </ul>
                  </div>
              </div>
              <div class="form-group">
                  <label for="regPwdRep" v-t="'page.register.repeatpassword'"></label>
                  <input
                    id="regPwdRep"
                    v-model="passwordrepeat"
                    type="password"
                    class="form-control"
                    :class="pwdRepErrorClass"
                    v-on:change="checkPasswordRepeat"
                    @keyup="checkPasswordRepeat"
                    required
                  >
              </div>
              <label class="mb-4 mb-terms" v-html="$t('page.register.terms')"></label>
              <div class="text-center">
                <button
                  ref="doLoginButton"
                  type="submit"
                  class="btn btn-primary"
                  data-style="zoom-in"
                  v-t="'page.register.regbutton'"
                ></button>
              </div>
              <small v-t="'page.register.mandentoryfields'"></small>
            </div>
          </form>
          <div class="mb-login">
            <label v-t="'page.register.gotalreadyanaccount'"></label>
                <router-link
                  to="/login"
                  class="btn btn-outline-light"
                >
                  {{ $t('page.register.tologin') }}
                </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-mtgld-showcase">
      <section class="mb-testimonials" id="mb-membership-testimonials">
        <div class="container">
          <h2 v-t="'testimonials.members.title'"></h2>
          <ul>
            <li v-for="(testimonial, index) in app.membershipTestimonials" :key="index">
              <testimonial :testimonial="testimonial"></testimonial>
            </li>
          </ul>
        </div>
        <div class="mb-brand-wall-container">
          <div class="container-2">
            <img v-for="(brandImage, index) in computedBrandImages" :key="index" :src="brandImage.imagePath" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import * as Ladda from 'ladda'
import Config from '../config'
import isEmail from 'validator/lib/isEmail'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Vue from 'vue'
import Testimonial from '@/components/Testimonial'
import ApiSqlModule from '@/modules/Api_Sql'
const ApiSql = new ApiSqlModule()

Vue.use(VueReCaptcha, {
  siteKey: Config.GOOGLE_RECAPTCHA_SITEKEY
})

export default {
  name: 'Register',
  components: {
    Testimonial
  },
  props: [],
  data: function () {
    return {
      salutation: null,
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      password: '',
      passwordrepeat: '',
      showPwdInfo: false,
      loader: null,
      isMaintenance: false,
      loginError: false,
      loginErrorMessage: '',
      loginExists: false,
      errorClass: 'mb-field-error',
      emailErrorClass: '',
      pwdErrorClass: '',
      pwdStrengthClass: '',
      pwdRepErrorClass: '',
      passwordValidation: {
        Length: false,
        Alpha: false,
        Numeric: false,
        SpecialC: false
      },
      Salutations: [
        {
          SalutationID: 1, Caption: this.$t('salutations.1')
        },
        {
          SalutationID: 2, Caption: this.$t('salutations.2')
        },
        {
          SalutationID: 10, Caption: this.$t('salutations.10')
        }
      ],
      brandImages: []
    }
  },
  computed: {
    currentAppVersion: function () {
      return Config.CLIENT_VERSION
    },
    isLoggedIn: function () {
      return this.$parent.isLoggedIn
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    computedBrandImages: function () {
      return this.brandImages.map(fileId => {
        return {
          imageId: fileId,
          imagePath: this.app.getImage({ FileID: fileId }) + '?size=xxs'
        }
      })
    }
  },
  watch: {
    '$route' (to, from) {
      this.resetValues()
    }
  },
  mounted: function () {
    setTimeout(this.setWallOfFameImages, 2000)
    const login = localStorage.getItem('FPMB_Login')
    this.$refs.doLoginButton.loader = Ladda.create(this.$refs.doLoginButton)
    if (login !== null) {
      this.$router.push('/markenauswahl')
    }
  },
  methods: {
    startLoader () {
      if (
        typeof this.$refs.doLoginButton !== 'undefined' &&
        typeof this.$refs.doLoginButton.loader !== 'undefined'
      ) {
        this.$refs.doLoginButton.loader.start()
      }
    },
    stopLoader () {
      if (
        typeof this.$refs.doLoginButton !== 'undefined' &&
        typeof this.$refs.doLoginButton.loader !== 'undefined'
      ) {
        this.$refs.doLoginButton.loader.stop()
      }
    },
    async setWallOfFameImages () {
      if (this.brandImages.length > 0) return
      const fileIdsResponse = await ApiSql._get('testimonial-brands')
      const fileIds = fileIdsResponse.body
      for (let index = 0; index <= 4; index++) {
        fileIds.forEach(fileId => {
          this.brandImages.push(fileId)
        })
      }
    },
    async recaptcha () {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "register".
      const token = await this.$recaptcha('register')

      const postData = {
        Firstname: this.firstname,
        Lastname: this.lastname,
        Phone: this.phone,
        Mail: this.email,
        Password: this.password,
        recaptchaToken: token,
        language: this.$i18n.locale,
        SalutationID: this.salutation
      }

      this.app.APIStripe._post('registerNewUser', postData)
        .then(response => {
          if (response.status === 200 && response.body.Error === false) {
            const loginData = {
              username: String(this.email).trim(),
              password: String(this.password).trim()
            }

            this.app
              .doLogin(loginData)
              .then(() => {
                this.$parent.shortlyRegistered = true
                this.resetValues()
                this.$router.push('/markeanlegen')
              })
              .finally(this.stopLoader)
          } else if (response.body.Error === 'User with Mail already exists.') {
            this.loginError = true
            this.loginErrorMessage = response.body.Error
            this.loginExists = true
            this.$router.push(
              {
                path: '/login',
                name: 'Login',
                params: {
                  loginError: true,
                  loginExists: true,
                  email: this.email
                }
              }
            )
          }
        })
        .finally(this.stopLoader)
    },
    onClickRegisterButton: function () {
      this.startLoader()
      Promise.all([
        new Promise((resolve, reject) => {
          const email = this.checkEmail()
          if (email) resolve(email)
          else reject(new Error('Email is not valid'))
        }),
        new Promise((resolve, reject) => {
          const pwd = this.checkPassword()
          if (pwd) resolve(pwd)
          else reject(new Error('Password is not secure enough'))
        }),
        new Promise((resolve, reject) => {
          const pwd = this.checkPasswordRepeat()
          if (pwd) resolve(pwd)
          else reject(new Error('Password and repeat are different'))
        })
      ])
        .then((values) => {
          this.recaptcha()
        })
        .catch(e => {
          this.stopLoader()
        })
    },
    checkEmail () {
      this.loginError = false
      this.loginErrorMessage = ''
      this.emailErrorClass = ''
      return new Promise((resolve, reject) => {
        if (!isEmail(this.email)) {
          this.stopLoader()
          this.loginError = true
          this.loginErrorMessage = this.$t('page.register.emailerror')
          reject(new Error(this.loginErrorMessage))
        }

        this.app.API.checkEmail(this.email)
          .then(result => {
            if (!result.status) {
              this.loginError = true
              this.loginErrorMessage = result.message
              this.emailErrorClass = this.errorClass
              this.stopLoader()
              reject(new Error(result.message))
            } else {
              this.emailErrorClass = ''
            }
            resolve(result.status)
          })
      })
    },
    checkPassword () {
      if (this.password === '') {
        this.pwdErrorClass = ''
        this.showPwdInfo = false
        return false
      }
      const ruleLength = /^(?=.{10,})/
      const ruleAlpha = /^(?=.*[a-z])(?=.*[A-Z])/
      const ruleNumeric = /^(?=.*[0-9])/
      const ruleSpecialC = /^(?=.*[!@#$%^&*~+-])/
      this.passwordValidation.Length = ruleLength.test(this.password)
      this.passwordValidation.Alpha = ruleAlpha.test(this.password)
      this.passwordValidation.Numeric = ruleNumeric.test(this.password)
      this.passwordValidation.SpecialC = ruleSpecialC.test(this.password)
      let pwdStrength = 0
      if (this.passwordValidation.Length) pwdStrength += 1
      if (this.passwordValidation.Alpha) pwdStrength += 1
      if (this.passwordValidation.Numeric) pwdStrength += 1
      if (this.passwordValidation.SpecialC) pwdStrength += 1
      if (pwdStrength >= 2) this.pwdStrengthClass = 'mb-pwd-str-medium'
      if (pwdStrength >= 4) this.pwdStrengthClass = 'mb-pwd-str-strong'
      if (pwdStrength <= 1) this.pwdStrengthClass = 'mb-pwd-str-low'
      const resPwd = (
        this.passwordValidation.Length &&
        this.passwordValidation.Alpha &&
        this.passwordValidation.Numeric &&
        this.passwordValidation.SpecialC
      )
      this.showPwdInfo = !resPwd
      if (!resPwd) {
        this.pwdErrorClass = this.errorClass
      } else {
        this.pwdErrorClass = ''
      }
      return resPwd
    },
    checkPasswordRepeat () {
      const resPwdRep = this.password === this.passwordrepeat
      if (!resPwdRep) this.pwdRepErrorClass = this.errorClass
      else this.pwdRepErrorClass = ''
      return resPwdRep
    },
    showLoginError () {
      this.password = ''
      this.stopLoader()
    },
    createUserFromMail (email) {
      return new Promise((resolve, reject) => {
        if (isEmail(email)) {
          this.createUser(email)
            .then(newUser => {
              if (typeof newUser.Error !== 'undefined') {
                this.$toast.warning(this.$t('page.register.newaccount', { mail: newUser.UsedMail }))
              } else {
                this.showNewUserData(newUser)
              }
              resolve()
            })
            .catch(error => {
              this.$toast.error(error.message)
            })
        } else {
          reject(
            new Error(this.$t('page.register.noemailaddress'))
          )
        }
      })
    },
    resetValues () {
      this.firstname = ''
      this.lastname = ''
      this.phone = ''
      this.email = ''
      this.password = ''
      this.passwordrepeat = ''
      this.showPwdInfo = false
      this.loader = null
      this.loginError = false
      this.loginErrorMessage = ''
      this.loginExists = false
      this.emailErrorClass = ''
      this.pwdErrorClass = ''
      this.pwdStrengthClass = ''
      this.pwdRepErrorClass = ''
    },
    createUser (email) {
      return new Promise((resolve, reject) => {
        new Promise((resolve, reject) => {
          this.app.API._post('users/', {
            FullSubEntities: true,
            ResFrom: 1,
            ResTo: 1,
            Filter: [
              {
                Field: 'Email',
                Operator: 'EQ',
                Value: email
              }
            ]
          })
            .then(response => {
              resolve(response.body.Result.Data.length > 0)
            })
            .catch(reject)
        })
          .then(userAlreadyExists => {
            if (!userAlreadyExists) {
              const newUser = {
                Username: email,
                Password: this.password,
                Email: email,
                SysRoleID: 3 // markenverwalterSysRoleID
              }
              this.app.API._put('user/', newUser).then(response => {
                resolve(newUser)
              })
            } else {
              resolve({
                Error: true,
                UsedMail: email
              })
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.container-2 {
  position: absolute;
  top: 140px;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-auto-columns: auto;
  grid-auto-rows: 80px;
  width: 100%;
  max-width: 1700px; /* or your desired maximum width */
  gap: 36px; /* This creates the gap between images */
  z-index: 0;
  max-height: 520px;
  overflow: hidden;
}

.container-2 img {
  background-size: cover;
  background-repeat: no-repeat;
  width: 80px;
  height: 55px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  background-blend-mode: multiply;
}

.mb-brand-wall-container {
  width: 100%; /* Full width of the viewport */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fp-logo {
  width: 150px;
  margin-bottom: 30px;
}

.mb-mtgld-showcase .mb-testimonials .container > h2 {
  margin-bottom: 40px;
  color: white;
}

.mb-register {

  h1 {
    text-transform: none;
    font-weight: 600;
  }

  .mb-mtgld-showcase {

    width: 100vw;

    margin-top: 70px;
    .mb-testimonials {
      background-color: transparent;
      padding-top: 10px;
      padding-bottom: 30px;
    }
  }

  > form {
    > .form-group {
      margin-bottom: 30px;
    }

    .btn {
      width: 100%;
      margin-bottom: 10px;
    }

    a {
      color: white;
      text-align: center;
    }
  }
}
.clientVersionHint {
  font-size: 10px;
  color: #fff;
}
.locale-switcher {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}
</style>
