<template>
  <FairFieldEditor title="Was wird gegründet?" :index="6" :loading="isLoading" :showContentOnEdit="false"
    :isEditing="isEditing" @toggleEdit="toggleEdit" @save="handleSave" buttonSaveText="Korrekturwunsch absenden"
    :saveDisabled="!computedIsValid" :is-modal-open.sync="isModalOpen" @toggle="toggleContent" :usePageStore="true" v-if="computedShowStep">

    <div class="d-flex flex-column gap-3">
      <span class="fw-bold">{{ currentBrand.FoundationPurpose }}</span>
      <span>Bsp.: <em v-html="computedFoundationText" /></span>
    </div>

    <template v-slot:editButtonCaption>
      Stimmt etwas nicht?
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/3_FoundationPurpose.png" />
    </template>

    <template v-slot:edit>

      <div class="d-flex flex-column gap-3">
        <span class="fw-bold">{{ currentBrand.FoundationPurpose }}</span>
        <div class="d-flex flex-column">
          <span>Dieser Begriff muss in Sätze eingebettet werden können.</span>
          <span>Bsp.: <em v-html="computedFoundationText" /></span>
        </div>
      </div>

      <hr />
      <div class="d-flex flex-column w-100 gap-3">
        <input type="text" class="form-control w-100" :placeholder="formFields.placeholder"
          v-model="formFields.value" />
        <div class="d-flex flex-column gap-1">
          <span class="fw-bold">Anforderungen:</span>
          <ul class="special">
            <li :class="{
              success: formFields.value.length <= 40,
              error: formFields.value.length > 40
            }">Der Begriff soll möglichst kurz sein und darf max. 40 Zeichen haben</li>
            <li>Der Begriff sollte das Partnergeschäft präzise und verständlich umschreiben</li>
            <li>Der Begriff sollte aus sprachlichen Gründen keine Adjektive enthalten</li>
            <li class="d-flex align-items-start inner-list">
              <div class="d-flex flex-column align-items-start gap-1">
                <strong>Beispiele:</strong>
                <ul>
                  <li>Traditions-Bäckerei</li>
                  <li>Alltagshilfe für Pflegebedürftige</li>
                  <li>Handlauf-Installationsdienst</li>
                  <li>Leckortungs-Service</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>

  </FairFieldEditor>
</template>

<script>
import { useBrandStore } from '@/stores/brandStore'
import FairFieldEditor from '@/components/FairFieldEditor.vue'

export default {
  name: 'FairFormMembershipAssociation',
  components: {
    FairFieldEditor
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      formFields: {
        placeholder: 'Tragen Sie hier Ihren neuen Vorschlag ein.',
        value: ''
      }
    }
  },
  methods: {
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Korrekturwünsche wurden erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Korrekturwünsche ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async submitForm () {
      await this.app.APIService._post('mb/fair/update/6', {
        brandId: this.currentBrand.BrandID,
        value: this.formFields.value
      })
    }
  },
  computed: {
    computedShowStep () {
      const currentFoundationPurpose = this.currentBrand.FoundationPurpose
      return currentFoundationPurpose !== null && currentFoundationPurpose !== '' && String(currentFoundationPurpose).length > 0
    },
    computedIsValid () {
      return this.formFields.value.length > 0 && this.formFields.value.length <= 40
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand
    },
    computedFoundationText () {
      // Assuming `computedBrand.GenderArticle` can be 1, 2, or 3
      const genderArticle = this.currentBrand.GenderArticle
      const foundationPurpose = String(this.currentBrand.FoundationPurpose)

      // Map numeric genderArticle values to actual articles
      const articleText =
        genderArticle === 1
          ? 'einen ' // der
          : genderArticle === 2
            ? 'eine ' // die
            : genderArticle === 3
              ? 'ein ' // das
              : ' '

      // Map numeric genderArticle values to the appropriate ending for "eigen"
      const eigenText =
        genderArticle === 1
          ? 'en ' // der
          : genderArticle === 2
            ? 'e ' // die
            : genderArticle === 3
              ? 'es ' // das
              : ' '

      // Return the final string with concatenation
      return `Was kostet es, ${articleText}eigen${eigenText}<strong>&gt;${foundationPurpose}&lt;</strong> zu starten?`
    }
  }

}
</script>
