<template>
  <FairFieldEditor title="Bildergalerie" :index="3" buttonSaveText="Änderungen speichern" :showContentOnEdit="false"
    :loading="isLoading" @save="handleSave" :isEditing="isEditing" :saveDisabled="!computedIsValid"
    :is-modal-open.sync="isModalOpen" @toggleEdit="toggleEdit" @toggle="toggleContent" :usePageStore="true"
    @update:isModalOpen="updateFormFields" :showOpenButton="false">
    <div class="d-flex flex-column gap-32px">
      <div class="d-flex flex-column gap-2">
        <strong>Hauptbild:</strong>
        <div class="main-image">
          <img :src="computedMainPictureSource" ref="mainPicture" />
        </div>
      </div>
      <div class="d-flex flex-column gap-2">
        <strong>Weitere Bilder:</strong>
        <div class="row">
          <div class="col-md-6 col-lg-3 mb-4 fair-image" v-for="fairPicture of computedPictures"
            :key="fairPicture.FairPictureID">
            <img :src="computedPictureUrl(fairPicture)" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <template v-slot:edit>

      <div class="d-flex flex-column gap-2">
        <span class="blue-headline">Hauptbild:</span>
        <div class="quote-box p-2 d-flex flex-row justify-content-start align-items-center gap-3">
          <div class="main-image">
            <img :src="computedMainPictureSource" />
          </div>
          <div class="d-flex flex-column gap-3">
            <button class="btn btn-secondary" @click="editMainPicture">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                <path
                  d="M5.08667 1.30255L5 11.3026C5 11.6562 5.14048 11.9953 5.39052 12.2454C5.64057 12.4954 5.97971 12.6359 6.33333 12.6359H16.3333"
                  stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M1.66699 4.72253L11.667 4.63586C12.0206 4.63586 12.3598 4.77634 12.6098 5.02639C12.8598 5.27644 13.0003 5.61558 13.0003 5.9692V15.9692"
                  stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <span>Zuschnitt</span>
            </button>
            <input type="file" class="d-none" accept="image/*" @change="handleNewMainImageUpload"
              ref="newMainImageInput" />
            <button class="btn btn-secondary" @click="uploadNewMainImage">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path
                  d="M14 10.6359V13.3025C14 13.6562 13.8595 13.9953 13.6095 14.2453C13.3594 14.4954 13.0203 14.6359 12.6667 14.6359H3.33333C2.97971 14.6359 2.64057 14.4954 2.39052 14.2453C2.14048 13.9953 2 13.6562 2 13.3025V10.6359"
                  stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.3337 5.9692L8.00033 2.63586L4.66699 5.9692" stroke="#00719F" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 2.63586V10.6359" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <span>Neues Bild hochladen</span>
            </button>
          </div>

          <b-modal v-model="isMainPictureModalOpen" no-close-on-backdrop>

            <loading :active.sync="isCropModalLoading" :can-cancel="false" :is-full-page="false" />

            <template v-slot:modal-title>
              Bild anpassen
            </template>

            <template v-slot:modal-footer>

              <button class="btn btn-secondary" @click="cancelMainImageEdit">
                Abbrechen
              </button>
              <button class="btn btn-primary" @click="saveMainImageEdit">
                Speichern
              </button>

            </template>

            <div class="row">
              <div class="col-12 mb-3">
                <Cropper :src="computedMainPictureSourceEdit" @change="handleCropChange" defaultBoundaries="fill"
                  :stencil-props="{
                    aspectRatio: 16 / 9
                  }" class="position-relative" />
              </div>
              <div class="col-12">
                <div class="form-group">
                  <strong>Empfehlungen:</strong>
                  <div>
                    <ul>
                      <li>Kein Logo als Hauptbild</li>
                      <li>Kein Text als Hauptbild</li>
                      <li>Kein Transparenter Hintergrund</li>
                      <li>Optimale Größe: 1848 x 1040 Pixel</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </b-modal>

        </div>
      </div>

      <hr />

      <div class="d-flex flex-column gap-2">
        <span class="blue-headline">Weitere Bilder:</span>
        <small>Sie können die Reihenfolge der Bilder durch Drag & Drop ändern.</small>
        <draggable v-model="formFields.FairPictures" item-key="FairPictureID" class="image-grid row" group="images"
          @end="updateSortPos" :move="onMove">
          <div class="col-3 image-col d-flex flex-column gap-2" v-for="fairPicture in formFields.FairPictures"
            :key="fairPicture.FairPictureID">
            <div class="fair-image fair-image-draggable">
              <img :src="computedPictureUrl(fairPicture)" class="img-fluid" />
            </div>
            <button class="btn btn-secondary" @click="removeImage(fairPicture)">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                <path d="M2.375 4.27173H3.70833H14.375" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M5.70898 4.27177V2.93844C5.70898 2.58481 5.84946 2.24568 6.09951 1.99563C6.34956 1.74558 6.6887 1.6051 7.04232 1.6051H9.70898C10.0626 1.6051 10.4017 1.74558 10.6518 1.99563C10.9018 2.24568 11.0423 2.58481 11.0423 2.93844V4.27177M13.0423 4.27177V13.6051C13.0423 13.9587 12.9018 14.2979 12.6518 14.5479C12.4017 14.798 12.0626 14.9384 11.709 14.9384H5.04232C4.6887 14.9384 4.34956 14.798 4.09951 14.5479C3.84946 14.2979 3.70898 13.9587 3.70898 13.6051V4.27177H13.0423Z"
                  stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Entfernen
            </button>
          </div>
          <div class="col-3 image-col d-flex flex-column gap-2" v-for="(emptyFairPicture, index) in computedEmptySlots"
            :key="index">
            <div class="fair-image d-flex align-items-center justify-content-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                <path
                  d="M26.2083 4.27173H7.54167C6.06891 4.27173 4.875 5.46564 4.875 6.9384V25.6051C4.875 27.0778 6.06891 28.2717 7.54167 28.2717H26.2083C27.6811 28.2717 28.875 27.0778 28.875 25.6051V6.9384C28.875 5.46564 27.6811 4.27173 26.2083 4.27173Z"
                  stroke="#A0ABBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M28.8753 20.2718L22.2087 13.6051L7.54199 28.2718" stroke="#A0ABBB" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M12.208 13.6051C13.3126 13.6051 14.208 12.7097 14.208 11.6051C14.208 10.5005 13.3126 9.6051 12.208 9.6051C11.1034 9.6051 10.208 10.5005 10.208 11.6051C10.208 12.7097 11.1034 13.6051 12.208 13.6051Z"
                  stroke="#A0ABBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <ImageUploadButton @newImageSaved="handleNewImageSaved" folder="SystemRoot/Fair/FairPictures" :aspectRatio="null" />
          </div>
        </draggable>
      </div>

      <b-modal v-model="isRemoveFairPictureModalOpen" hide-footer hide-header size="sm">
        <div class="d-flex flex-column gap-3 p-3">
          <span class="blue-headline">Bild wirklich löschen?</span>
          <div class="d-flex justify-content-between align-items-center gap-3">
            <button class="btn btn-secondary flex-grow-1" @click="handleRemoveFairPicture">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M2 4H3.33333H14" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M5.33301 4.00016V2.66683C5.33301 2.31321 5.47348 1.97407 5.72353 1.72402C5.97358 1.47397 6.31272 1.3335 6.66634 1.3335H9.33301C9.68663 1.3335 10.0258 1.47397 10.2758 1.72402C10.5259 1.97407 10.6663 2.31321 10.6663 2.66683V4.00016M12.6663 4.00016V13.3335C12.6663 13.6871 12.5259 14.0263 12.2758 14.2763C12.0258 14.5264 11.6866 14.6668 11.333 14.6668H4.66634C4.31272 14.6668 3.97358 14.5264 3.72353 14.2763C3.47348 14.0263 3.33301 13.6871 3.33301 13.3335V4.00016H12.6663Z"
                  stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <span>Ja, Bild löschen</span>
            </button>
            <button class="btn btn-secondary flex-grow-1" @click="isRemoveFairPictureModalOpen = false">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path d="M12.5 4L4.5 12" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M4.5 4L12.5 12" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <span>Abbrechen</span>
            </button>
          </div>
        </div>
      </b-modal>

      <hr />

      <div class="d-flex flex-column w-100 gap-3">
        <div class="d-flex flex-column gap-1">
          <span class="fw-bold">Anforderungen:</span>
          <ul class="special">
            <li :class="{
              success: formFields.MainPicture !== null,
              error: formFields.MainPicture === null
            }">Hauptbild muss vorhanden sein (Optimale Größe: 1848 x 1040 Pixel)</li>
            <li :class="{
              'd-none': formFields.FairPictures.length >= 4,
            }"><strong class="orange-text">Dringende Empfehlung:</strong> Fügen Sie mindestens {{ 4 -
              formFields.FairPictures.length }} weitere Bilder hinzu
            </li>
            <li class="d-flex align-items-start inner-list">
              <div class="d-flex flex-column align-items-start gap-1">
                <strong>Nach Möglichkeit sollten die Bilder zeigen:</strong>
                <ul>
                  <li>Eine:n Partner:in bei der Tätigkeit</li>
                  <li>Glückliche, zufriedene Menschen (Kund:innen / Mitarbeiter:innen)</li>
                  <li>Ihr Ladenlokal</li>
                  <li>Ihre Produkte</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:editButtonCaption>
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-pencil"></i>
        <span>Bearbeiten</span>
      </div>
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/3_Gallery.png" />
    </template>
  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import { useBrandStore } from '../../stores/brandStore'
import Config from '@/config'
import draggable from 'vuedraggable'
import cloneDeep from 'lodash/cloneDeep'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import ImageUploadButton from '@/components/fair/ImageUploadButton.vue'

export default {
  name: 'FairFormGallery',
  components: {
    FairFieldEditor,
    draggable,
    Cropper,
    Loading,
    ImageUploadButton
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isCropModalLoading: false,
      isEditing: false,
      isModalOpen: false,
      isMainPictureModalOpen: false,
      newUploadedPictureString: null,
      editedMainPictureCanvas: null,
      editedMainPictureName: null,
      fairPictureToDelete: null,
      isRemoveFairPictureModalOpen: false,
      formFields: {
        MainPicture: null,
        FairPictures: [],
        NewFairPicture: {
          File: null
        }
      }
    }
  },
  methods: {
    async base64ToBlob (base64) {
      const response = await fetch(base64)
      return await response.blob()
    },
    cancelMainImageEdit () {
      this.newUploadedPictureString = null
      this.isMainPictureModalOpen = false
    },
    async saveMainImageEdit () {
      this.isCropModalLoading = true

      try {
        const brandStore = useBrandStore()
        const fileBlob = await new Promise(resolve => this.editedMainPictureCanvas.toBlob(resolve))

        let newUploadFilename = this.formFields.MainPicture.Filename ? this.formFields.MainPicture.Filename : this.editedMainPictureName

        if (newUploadFilename.indexOf('/blob') !== -1) {
          const fileExtension = ['', fileBlob.type.split('/').pop()].join(
            '.'
          )
          const hexHash = await this.digestMessage(await fileBlob.text())
          newUploadFilename = newUploadFilename.replace(
            '/blob',
            `/${hexHash}${fileExtension}`
          )
        }

        const uploadImageData = new FormData()
        uploadImageData.append('file', fileBlob, newUploadFilename)
        uploadImageData.append('folder', 'SystemRoot/Fair/FairPictures/' + this.currentBrand.BrandID)
        uploadImageData.append('overwriteMode', 1)
        uploadImageData.append('identifier', -1)

        const loginFromStorage = localStorage.getItem('FPMB_Login')
        const login = loginFromStorage ? JSON.parse(loginFromStorage) : {}

        const imageUploadResponse = await axios
          .post(Config.MEDIA_API_URL, uploadImageData, {
            headers: {
              Accept: 'application/json',
              Authorization: ['Bearer', login.token].join(' '),
              'Content-Type': 'multipart/form-data'
            }
          })

        const uploadedFileData = [...imageUploadResponse.data.Data].shift()

        const createMediumResponse = await this.API._put('medium/', {
          MediumID: -1,
          FileID: uploadedFileData.identifier,
          Filename: uploadedFileData.fullPath,
          URL: uploadedFileData.fullPath,
          FolderID: 5,
          Hash: uploadedFileData.hash,
          Height: uploadedFileData.mediainfo.height,
          Size: uploadedFileData.size,
          State: 0,
          TypeID: 1,
          Width: uploadedFileData.mediainfo.width
        })

        const loadedMedium = await this.API._post('medium/', {
          MediumID: createMediumResponse.body.Result.NewID
        })
        await brandStore.updateCurrentFairMainImage(loadedMedium.body.Result.Data)
        this.formFields.MainPicture = loadedMedium.body.Result.Data

        this.isMainPictureModalOpen = false
        this.newUploadedPictureString = null
        this.editedMainPictureCanvas = null
        this.editedMainPictureName = null
      } catch (error) {
        this.$toast.error('Beim Speichern des Bildes ist ein Fehler aufgetreten.')
      } finally {
        this.isCropModalLoading = false
      }
    },
    uploadNewMainImage () {
      this.newUploadedPictureString = null
      this.$refs.newMainImageInput.click()
    },
    async handleNewMainImageUpload () {
      this.isLoading = true

      const file = this.$refs.newMainImageInput.files[0]
      if (file) {
        if (file.size > 1e7) {
          this.isUploading = false
          this.$swal(this.$t('popupmessage.warn.imagetobigtitle'), this.$t('popupmessage.warn.imagetobigmessage'), 'warning')
          return
        }

        this.editedMainPictureName = file.name

        const reader = new FileReader()
        reader.onload = (e) => {
          this.newUploadedPictureString = e.target.result
          this.isMainPictureModalOpen = true
          this.isLoading = false
        }
        reader.readAsDataURL(file)
      }
    },
    editMainPicture () {
      this.newUploadedPictureString = null
      this.isMainPictureModalOpen = true
    },
    updateFormFields () {
      this.formFields.MainPicture = cloneDeep(this.currentFair.MainPicture)
      this.formFields.FairPictures = cloneDeep(this.currentFair.FairPictures)
        .sort((a, b) => a.SortPos - b.SortPos)
        .slice(0, 8)
    },
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    async handleNewImageSaved (newMedium) {
      try {
        this.isLoading = true
        this.isUploading = true
        const brandStore = useBrandStore()
        this.formFields.FairPictures = await brandStore.addNewFairPicture(newMedium.MediumID)
      } catch (error) {
        console.error('Error at Image Upload', error)
        this.$toast.error('Beim Hochladen des Bildes ist ein Fehler aufgetreten.')
      } finally {
        this.isUploading = false
        this.isLoading = false
      }
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Änderungen erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.MainPicture = null
        this.formFields.FairPictures = []
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Änderungen ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    onMove (event) {
      return typeof event.draggedContext.element !== 'undefined'
    },
    updateSortPos () {
      this.formFields.FairPictures.forEach((picture, index) => {
        picture.SortPos = index + 1
      })
    },
    removeImage (fairPicture) {
      this.fairPictureToDelete = fairPicture
      this.isRemoveFairPictureModalOpen = true
    },
    async handleRemoveFairPicture () {
      if (this.fairPictureToDelete) {
        const index = this.formFields.FairPictures.indexOf(this.fairPictureToDelete)
        if (index !== -1) {
          const brandStore = useBrandStore()
          await brandStore.deleteFairPicture(this.fairPictureToDelete)
          this.formFields.FairPictures.splice(index, 1)
          brandStore.setCurrentFairUpdate({
            FairPictures: this.formFields.FairPictures
          })
        }
      }
      this.isRemoveFairPictureModalOpen = false
    },
    async submitForm () {
      const brandStore = useBrandStore()
      await Promise.all(
        this.formFields.FairPictures.map(async (FairPicture) => {
          return await brandStore.fpApi._put('FairPicture', {
            FairPictureID: FairPicture.FairPictureID,
            SortPos: FairPicture.SortPos
          })
        })
      )

      // now check in the currentFair if there are any FairPictures that are not in the formFields
      const currentFairPictures = this.currentFair.FairPictures
      const formFairPictures = this.formFields.FairPictures
      const fairPicturesToDelete = currentFairPictures.filter(
        (currentFairPicture) => {
          return !formFairPictures.some(
            (formFairPicture) =>
              formFairPicture.FairPictureID === currentFairPicture.FairPictureID
          )
        }
      )

      await Promise.all(
        fairPicturesToDelete.map(async (FairPicture) => {
          return await brandStore.fpApi._delete(`FairPicture/${FairPicture.FairPictureID}`)
        })
      )

      brandStore.setCurrentFairUpdate({
        FairPictures: this.formFields.FairPictures
      })
    },
    handleCropChange ({ coordinates, canvas }) {
      this.editedMainPictureCanvas = canvas
    }
  },
  computed: {
    API () {
      return this.$root.getApp().API
    },
    computedIsValid () {
      return true
    },
    currentBrand: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand ?? {}
    },
    currentFair: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentFair ?? {}
    },
    computedMainPictureSourceEdit () {
      if (this.newUploadedPictureString !== null) {
        return this.newUploadedPictureString
      }

      if (this.formFields.MainPicture !== null) {
        return `${Config.MEDIA_API_URL}id/${this.formFields.MainPicture.FileID}`
      }
      return ''
    },
    computedMainPictureSource () {
      if (this.currentFair && this.currentFair.MainPicture) {
        return `${Config.MEDIA_API_URL}id/${this.currentFair.MainPicture.FileID}?size=md`
      }
      return ''
    },
    computedPictures () {
      if (this.currentFair && this.currentFair.FairPictures) {
        return [
          ...this.currentFair.FairPictures
        ].sort((a, b) => a.SortPos - b.SortPos).slice(0, 8)
      }
      return []
    },
    computedEmptySlots () {
      const emptySlots = []
      for (let i = 0; i < 8 - this.formFields.FairPictures.length; i++) {
        emptySlots.push({
          FairPictureID: `empty-${i}`
        })
      }
      return emptySlots
    },
    computedPictureUrl () {
      return (fairPicture) => {
        return `${Config.MEDIA_API_URL}${fairPicture.Medium.URL}?size=md`
      }
    },
    computedExpansionFormCaption () {
      if (this.currentBrand) {
        if (this.currentBrand.ConceptExpansionFormFranchise) return 'Franchise'
        if (this.currentBrand.ConceptExpansionFormLicense) return 'Lizenz'
      }
      return ''
    }
  }

}
</script>

<style lang="scss" scoped>
.main-image {
  width: 50%;
  max-width: 50%;
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  /* Adjust to balance out the margin on the individual columns */
  margin-right: -8px;

  .image-col {
    width: calc(25% - 16px);
    /* Ensure that four items fit in one row, minus the gap */
    margin: 8px;
    /* To create a gap of 16px both vertically and horizontally */
    border-radius: 8px;
  }
}

.fair-image {
  height: 120px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  &.fair-image-draggable {
    cursor: move;
  }

}

.image-col {
  padding: 8px;
  background-color: #EFF1F4;
}
</style>
