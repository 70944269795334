<template>
  <div>
    <h4>Funktionen</h4>
    <div class="d-flex flex-column gap-3 lead-functions mb-3">
      <a v-if="isShareAvailable" href="javascript:" @click="shareToNavigator">
        <inline-svg :src="require('@/assets/images/icons/3.0/share.svg')" />
        <span>Profil mit Markenverwalter teilen</span>
      </a>
      <a href="javascript:" @click="openPrintDialog">
        <inline-svg :src="require('@/assets/images/icons/3.0/print.svg')" />
        <span>Profil drucken</span>
      </a>
      <template v-if="0">
        <a href="javascript:">
          <inline-svg :src="require('@/assets/images/icons/3.0/remove.svg')" />
          <span>Kontakt zukünftig ausblenden</span>
        </a>
        <a href="javascript:">
          <inline-svg :src="require('@/assets/images/icons/3.0/flag.svg')" />
          <span>Kontakt melden</span>
        </a>
        <a href="javascript:">
          <inline-svg :src="require('@/assets/images/icons/3.0/similar.svg')" />
          <span>Ähnliche Profile finden</span>
        </a>
      </template>
    </div>
    <div class="muted">
      <strong>Lead-ID:</strong>
      {{ this.lead.LeadID }}
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'LeadItemFunctions',
  props: ['lead', 'computedModalId'],
  components: {
    InlineSvg
  },
  data: function () {
    return {
      loadingTimer: null
    }
  },
  computed: {
    isShareAvailable () {
      return typeof navigator.share !== 'undefined'
    }
  },
  mounted: function () {
    this.loadingTimer = setTimeout(() => {
      const selector = `#${this.computedModalId} .modal-content`
      const elem = document.querySelector(selector)

      if (!elem) {
        return
      }

      const domClone = elem.cloneNode(true)

      this.$nextTick(() => {
        const googleMap = domClone.querySelector('.google-map-container')
        if (googleMap) {
          googleMap.remove()
        }

        this.$nextTick(() => {
          let printSection = document.getElementById('printSection')
          if (printSection) {
            printSection.innerHTML = ''
          } else {
            printSection = document.createElement('div')
            printSection.id = 'printSection'
            document.body.appendChild(printSection)
          }
          printSection.appendChild(domClone)
        })
      })
    }, 500)
  },
  beforeDestroy: function () {
    if (this.loadingTimer) {
      clearTimeout(this.loadingTimer)
    }
  },
  methods: {
    // openPrintDialog () {
    //   const selector = `#${this.computedModalId} .modal-content`
    //   const elem = document.querySelector(selector)
    //   this.$nextTick(() => {
    //     this.printElement(elem)
    //   })
    // },
    openPrintDialog () {
      window.print()
    },
    shareToNavigator () {
      if (typeof navigator.share !== 'undefined') {
        navigator.share({
          title: `Profil von ${this.lead.name}`,
          url: window.location.href
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: #00719f;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.muted {
  color: #A0ABBB;
}
</style>
