<template>
  <div>
    <div
      v-if="app.hasCurrentBrand"
      id="konditionen"
      class="container mb-info-konzept p-0"
    >
      <div class="card mb-5">
        <div class="card-body">
          <form>
            <h5 class="card-title" v-t="'page.yourdata.conditions.title'"></h5>
            <label v-t="'page.yourdata.conditions.description'"></label>
            <div class="mb-list">
              <label>
                {{ $t('currency.title') }}
                <a
                  id="tt_currency"
                  href="javascript:"
                  class="badge badge-warning"
                >?</a>
              </label>

              <b-tooltip
                target="tt_currency"
                placement="top"
              >
                <p v-html="$t('currency.hint')"></p>
              </b-tooltip>
              <div>
                <select
                  v-model="currentBrand.CondCurrency"
                  class="form-control"
                  @change="registerChange"
                >
                  <option value="CHF" v-t="'currency.chf'"></option>
                  <option value="EUR" v-t="'currency.eur'"></option>
                </select>
              </div>
            </div>
            <h6 class="mb-card-title" v-t="'page.yourdata.conditions.investmenttitle'"></h6>
            <label class="mb-4 mt-4" v-t="'page.yourdata.conditions.investmentdesc'"></label>
            <div>
              <investment @change="registerChange"></investment>
              <div class="row slim">
                <div class="form-group col">
                  <label>
                     {{ $t('page.yourdata.conditions.remarkinvestment') }}
                    <a
                      id="tt_investmentinfo"
                      href="javascript:"
                      class="badge badge-warning"
                    >?</a>
                  </label>
                  <b-tooltip
                    target="tt_investmentinfo"
                    placement="top"
                  >
                    <p v-html="$t('page.yourdata.conditions.remarconditionhint')"></p>
                  </b-tooltip>
                  <div>
                    <custom-editor
                      v-model="currentBrand.CondInvestmentRemark"
                      maxlength="1000"
                      :small="true"
                      @change="registerChange"
                    />
                  </div>
                </div>
              </div>
              <licence-fee @change="registerChange"></licence-fee>
              <div class="row slim">
                <div class="form-group col">
                  <label for="fld_CondLicenseRemark">
                    {{ $t('page.yourdata.conditions.remarklicencefee') }}<span v-if="this.currentBrand.CondRegularLicenceType === -1">*</span>
                    <a
                      id="tt_licensefeeinfo"
                      href="javascript:"
                      class="badge badge-warning"
                    >?</a>
                  </label>
                  <b-tooltip
                    target="tt_licensefeeinfo"
                    placement="top"
                  >
                    <p v-html="$t('page.yourdata.conditions.remarconditionhint')"></p>
                  </b-tooltip>
                  <div>
                    <custom-editor
                      v-model="currentBrand.CondLicenceRemark"
                      maxlength="500"
                      :small="true"
                      @change="registerChange"
                    />
                  </div>
                </div>
              </div>
              <h6 class="mb-card-title" v-t="'page.yourdata.conditions.marketingfee'"></h6>
              <div class="row slim">
                <div class="col form-group-options">
                  <label
                    class="checkbox form-group"
                    :class="currentBrand.CondRegularMarketingFeeType==-1 ? 'selected' : ''"
                  >
                    <input
                      v-model="currentBrand.CondRegularMarketingFeeType"
                      type="radio"
                      name="marketingfeeType"
                      :value="-1"
                      @change="registerChange"
                    > {{ $t('choice.notspecifiedseeremark') }}
                  </label>
                  <label
                    class="checkbox form-group"
                    :class="currentBrand.CondRegularMarketingFeeType==0 ? 'selected' : ''"
                  >
                    <input
                      v-model="currentBrand.CondRegularMarketingFeeType"
                      type="radio"
                      name="marketingfeeType"
                      :value="0"
                      @change="registerChange"
                    > {{ $t('choice.nomarketingfee') }}
                  </label>
                  <label
                    class="checkbox form-group"
                    :class="currentBrand.CondRegularMarketingFeeType==1 ? 'selected' : ''"
                  >
                    <input
                      v-model="currentBrand.CondRegularMarketingFeeType"
                      type="radio"
                      name="marketingfeeType"
                      :value="1"
                      @change="registerChange"
                    >
                    <div
                      v-if="currentBrand.CondRegularMarketingFeeType!=1"
                      class="cover-parent"
                    />
                    <percentage-input
                      v-model="currentBrand.CondRegularMarketingFeePercentage"
                      class="d-inline"
                      :disabled="currentBrand.CondRegularMarketingFeeType!=1"
                      @change="registerChange"
                    />{{ $t('choice.nettopermonth') }}
                  </label>
                  <label
                    class="checkbox form-group"
                    :class="currentBrand.CondRegularMarketingFeeType==2 ? 'selected' : ''"
                  >
                    <input
                      v-model="currentBrand.CondRegularMarketingFeeType"
                      type="radio"
                      name="marketingfeeType"
                      :value="2"
                      @change="registerChange"
                    >
                    <div
                      v-if="currentBrand.CondRegularMarketingFeeType!=2"
                      class="cover-parent"
                    />
                    <currency-input
                      v-model="currentBrand.CondRegularMarketingFeeAmount"
                      style="width:120px"
                      :disabled="currentBrand.CondRegularMarketingFeeType!=2"
                      :currency="currentBrand.CondCurrency"
                      locale="de"
                      class="percentage-input"
                      @change="registerChange"
                    />{{ $t('choice.fixedpermonth') }}
                  </label>
                  <label
                    class="checkbox form-group"
                    :class="currentBrand.CondRegularMarketingFeeType==3 ? 'selected' : ''"
                  >
                    <input
                      v-model="currentBrand.CondRegularMarketingFeeType"
                      type="radio"
                      name="marketingfeeType"
                      :value="3"
                      @change="registerChange"
                    >
                    <div
                      v-if="currentBrand.CondRegularMarketingFeeType!=3"
                      class="cover-parent"
                    />
                    <percentage-input
                      v-model="currentBrand.CondRegularMarketingFeePercentage"
                      class="d-inline"
                      :disabled="currentBrand.CondRegularMarketingFeeType!=3"
                      @change="registerChange"
                    />{{ $t('choice.nettoplus') }}
                    <currency-input
                      v-model="currentBrand.CondRegularMarketingFeeAmount"
                      style="width:120px"
                      :disabled="currentBrand.CondRegularMarketingFeeType!=3"
                      :currency="currentBrand.CondCurrency"
                      locale="de"
                      class="percentage-input ms-15"
                      @change="registerChange"
                    />{{ $t('choice.fixedpermonth') }}
                  </label>
                  <label
                    class="checkbox form-group"
                    :class="currentBrand.CondRegularMarketingFeeType==5 ? 'selected' : ''"
                  >
                    <input
                      v-model="currentBrand.CondRegularMarketingFeeType"
                      type="radio"
                      name="marketingfeeType"
                      :value="5"
                      @change="registerChange"
                    >
                    <div
                      v-if="currentBrand.CondRegularMarketingFeeType!=5"
                      class="cover-parent"
                    />
                    <percentage-input
                      v-model="currentBrand.CondRegularMarketingFeePercentage"
                      class="d-inline"
                      :disabled="currentBrand.CondRegularMarketingFeeType!=5"
                      @change="registerChange"
                    />{{ $t('choice.nettobut') }}
                    <currency-input
                      v-model="currentBrand.CondRegularMarketingFeeAmount"
                      style="width:120px"
                      :disabled="currentBrand.CondRegularMarketingFeeType!=5"
                      :currency="currentBrand.CondCurrency"
                      locale="de"
                      class="percentage-input ms-15"
                      @change="registerChange"
                    />{{ $t('choice.minpermonth') }}
                  </label>

                  <label
                    class="checkbox form-group"
                    :class="currentBrand.CondRegularMarketingFeeType==6 ? 'selected' : ''"
                  >
                    <input
                      v-model="currentBrand.CondRegularMarketingFeeType"
                      type="radio"
                      name="CondRegularMarketingFeeType"
                      :value="6"
                      @change="registerChange"
                    >
                    <div
                      v-if="currentBrand.CondRegularMarketingFeeType!=6"
                      class="cover-parent"
                    />
                    {{ $t('choice.variablepercentfrom') }}
                    <percentage-input
                      v-model="currentBrand.CondRegularMarketingFeePercentage"
                      class="d-inline ms-2"
                      :disabled="currentBrand.CondRegularMarketingFeeType!=6"
                      @change="registerChange"
                    />
                    {{ $t('choice.to') }}
                    <percentage-input
                      v-model="currentBrand.CondRegularMarketingFeeAmount"
                      class="d-inline ms-2"
                      :disabled="currentBrand.CondRegularMarketingFeeType!=6"
                      @change="registerChange"
                    />
                  </label>
                  <label
                    class="checkbox form-group"
                    :class="currentBrand.CondRegularMarketingFeeType==7 ? 'selected' : ''"
                  >
                    <input
                      v-model="currentBrand.CondRegularMarketingFeeType"
                      type="radio"
                      name="CondRegularMarketingFeeType"
                      :value="7"
                      @change="registerChange"
                    >
                    <div
                      v-if="currentBrand.CondRegularMarketingFeeType!=7"
                      class="cover-parent"
                    />
                    {{ $t('choice.variableamountfrom') }}
                    <currency-input
                      v-model="currentBrand.CondRegularMarketingFeeAmount"
                      style="width:120px"
                      :disabled="currentBrand.CondRegularMarketingFeeType!=7"
                      :currency="currentBrand.CondCurrency"
                      locale="de"
                      class="percentage-input ms-15"
                      @change="registerChange"
                    />
                    {{ $t('choice.to') }}
                    <currency-input
                      v-model="currentBrand.CondRegularMarketingFeePercentage"
                      style="width:120px"
                      :disabled="currentBrand.CondRegularMarketingFeeType!=7"
                      :currency="currentBrand.CondCurrency"
                      locale="de"
                      class="percentage-input ms-15"
                      @change="registerChange"
                    />
                  </label>
                </div>
              </div>
              <div class="row slim">
                <div class="form-group col">
                  <label for="fld_CondRegularMarketingFeeRemark">
                    {{ $t('page.yourdata.conditions.remarkmarketingfee') }}
                    <a
                      id="tt_marketingfeeinfo"
                      href="javascript:"
                      class="badge badge-warning"
                    >?</a>
                  </label>
                  <b-tooltip
                    target="tt_marketingfeeinfo"
                    placement="top"
                  >
                    <p v-html="$t('page.yourdata.conditions.remarconditionhint')"></p>
                  </b-tooltip>
                  <div>
                    <custom-editor
                      v-model="currentBrand.CondRegularMarketingFeeRemark"
                      maxlength="1000"
                      :small="true"
                      @change="registerChange"
                    />
                  </div>
                </div>
              </div>
              <h6 class="mb-card-title" v-html="$t('page.yourdata.conditions.contract')"></h6>

              <div class="row slim mb-3">
                <div class="form-group col">
                  <label v-t="'page.yourdata.conditions.contractterm'"></label>
                  <div>
                    <custom-radio-input
                      v-model="currentBrand.CondContractPeriod"
                      :suffix="$tc('date.year', currentBrand.CondContractPeriod)"
                      :option-names="[$t('choice.notspecified'), $t('choice.unlimited'), $t('choice.yes')]"
                      @change="registerChange"
                    />
                  </div>
                </div>
              </div>
              <div class="row slim mb-3">
                <div class="form-group col">
                  <label v-t="'page.yourdata.conditions.contractextension'"></label>
                  <div>
                    <custom-radio-input
                      v-model="currentBrand.CondContractRenewal"
                      :suffix="$tc('date.year', currentBrand.CondContractPeriod)"
                      :option-names="[$t('choice.notspecified'), $t('choice.unlimited'), $t('choice.yes')]"
                      @change="registerChange"
                    />
                  </div>
                </div>
              </div>
              <div class="row slim mb-3">
                <div class="form-group col">
                  <label v-t="'page.yourdata.conditions.contractextentionfee'"></label>
                  <div>
                    <custom-radio-input
                      v-model="currentBrand.CondContractRenewalFee"
                      :suffix="currentBrand.CondCurrency"
                      :option-names="[$t('choice.notspecified'), $t('choice.no'), $t('choice.yes')]"
                      @change="registerChange"
                    />
                  </div>
                </div>
              </div>
              <div class="row slim mb-3">
                <div class="form-group col">
                  <label v-t="'page.yourdata.conditions.minimumsales'"></label>
                  <div>
                    <custom-radio-input
                      v-model="currentBrand.CondMinimumTurnover"
                      :suffix="currentBrand.CondCurrency"
                      :option-names="[$t('choice.notspecified'), $t('choice.no'), $t('choice.yes')]"
                      @change="registerChange"
                    />
                  </div>
                </div>
              </div>
              <div class="row slim">
                <div class="form-group col-4">
                  <label v-t="'page.yourdata.conditions.internalpurchase'"></label>
                  <div class="form-group custom-radio-input mb-5">
                    <b-form-radio-group
                      v-model="currentBrand.CondInternalPurchaseType"
                      name="CondInternalPurchaseType"
                      @change="registerChange"
                    >
                      <b-form-radio value="1">
                        {{ $t('choice.yes') }}
                      </b-form-radio>
                      <b-form-radio value="0">
                        {{ $t('choice.no') }}
                      </b-form-radio>
                      <b-form-radio value="-1">
                        {{ $t('choice.notspecified') }}
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                </div>

                <div
                  v-show="currentBrand.CondInternalPurchaseType == 1"
                  class="col-8 CondInternalPurchaseDataContainer"
                >
                  <div class="form-group">
                    <label v-t="'page.yourdata.conditions.mininternalpurchase'"></label>
                    <div>
                      {{ $t('choice.from') }}:
                      <currency-input
                        class="mx-2"
                        v-model="currentBrand.CondInternalPurchaseFrom"
                        style="width:80px"
                        :currency="{ 'suffix': '%' }"
                        @change="registerChange"
                      />{{ $t('choice.to') }}:
                      <currency-input
                        class="mx-2"
                        v-model="currentBrand.CondInternalPurchaseTo"
                        style="width:80px"
                        :currency="{ 'suffix': '%' }"
                        @change="registerChange"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label v-t="'page.yourdata.conditions.goodsprice'"></label>
                    <custom-radio-input
                      v-model="currentBrand.CondExtraCharge"
                      :suffix="currentBrand.CondCurrency"
                      :option-names="[$t('choice.notspecified'), $t('choice.no'), $t('choice.yes')]"
                      @change="registerChange"
                    />
                  </div>
                </div>
              </div>
              <div class="row slim">
                <div class="form-group col">
                  <label for="fld_CondContractRemark">
                    {{ $t('page.yourdata.conditions.contractremark') }}
                    <a
                      id="tt_contractinfo"
                      href="javascript:"
                      class="badge badge-warning"
                    >?</a>
                  </label>
                  <b-tooltip
                    target="tt_contractinfo"
                    placement="top"
                  >
                    <p v-html="$t('page.yourdata.conditions.remarconditionhint')"></p>
                  </b-tooltip>
                  <div>
                    <custom-editor
                      v-model="currentBrand.CondContractRemark"
                      maxlength="1000"
                      :small="true"
                      @change="registerChange"
                    />
                  </div>
                </div>
              </div>
              <h6 class="mb-card-title" v-html="$t('page.yourdata.conditions.locationrequirements')"></h6>
              <div class="row slim mb-3">
                <div class="form-group col">
                  <label for="fld_Title" v-t="'page.yourdata.conditions.locationrequirementstitle'"></label>
                  <div>
                    <custom-editor
                      v-model="currentBrand.CondLocationRequirements"
                      maxlength="1000"
                      :small="true"
                      @change="registerChange"
                    />
                  </div>
                </div>
              </div>
              <div class="row slim mb-3">
                <div class="form-group col-3">
                  <label v-t="'page.yourdata.conditions.sizeinm2'"></label>
                </div>

                <div class="form-group col">
                  <div class="form-group custom-radio-input mb-3">
                    <b-form-radio-group
                      id="radiosCondCommercialSpaceType"
                      v-model="currentBrand.CondCommercialSpaceType"
                      name="radiosCondCommercialSpaceType"
                      @change="registerChange"
                    >
                      <b-form-radio value="1">
                        {{ $t('choice.yes') }}
                      </b-form-radio>
                      <b-form-radio value="0">
                        {{ $t('choice.notneeded') }}
                      </b-form-radio>
                      <b-form-radio value="-1">
                        {{ $t('choice.notspecified') }}
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                  <div
                    v-show="currentBrand.CondCommercialSpaceType==1"
                    class="row"
                  >
                    <div class="form-group col">
                      <div>
                        <vue-numeric
                          v-model="currentBrand.CondCommercialSpaceFrom"
                          class="form-control"
                          currency-symbol-position="suffix"
                          currency="m²"
                          separator="."
                          :precision="0"
                          @change="registerChange"
                        />
                      </div>
                    </div>
                    <div class="col-1 d-flex align-items-center justify-content-center">
                      <label class="prefixed">-</label>
                    </div>
                    <div class="form-group col">
                      <div>
                        <vue-numeric
                          v-model="currentBrand.CondCommercialSpaceTo"
                          class="form-control"
                          currency-symbol-position="suffix"
                          currency="m²"
                          separator="."
                          :precision="0"
                          @change="registerChange"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row slim">
                <div class="form-group col-3">
                  <label v-t="'page.yourdata.conditions.catchmentarea'"></label>
                </div>

                <div class="form-group col">
                  <div class="form-group custom-radio-input mb-3">
                    <b-form-radio-group
                      id="radiosCondCatchmentType"
                      v-model="currentBrand.CondCatchmentType"
                      name="CondCatchmentType"
                      @change="registerChange"
                    >
                      <b-form-radio value="1">
                        {{ $t('choice.yes') }}
                      </b-form-radio>
                      <b-form-radio value="0">
                        {{ $t('choice.notneeded') }}
                      </b-form-radio>
                      <b-form-radio value="-1">
                        {{ $t('choice.notspecified') }}
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                  <div
                    v-show="currentBrand.CondCatchmentType==1"
                    class="row"
                  >
                    <div class="form-group col">
                      <div>
                        <vue-numeric
                          v-model="currentBrand.CondCatchmentFrom"
                          class="form-control"
                          :currency="$t('page.yourdata.conditions.residents')"
                          currency-symbol-position="suffix"
                          separator="."
                          :precision="0"
                          @change="registerChange"
                        />
                      </div>
                    </div>
                    <div class="col-1 d-flex align-items-center justify-content-center">
                      <label class="prefixed">-</label>
                    </div>
                    <div class="form-group col">
                      <div>
                        <vue-numeric
                          v-model="currentBrand.CondCatchmentTo"
                          class="form-control"
                          :currency="$t('page.yourdata.conditions.residents')"
                          currency-symbol-position="suffix"
                          separator="."
                          :precision="0"
                          @change="registerChange"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input'
import CustomEditor from '../../../components/CustomEditor'
import PercentageInput from '../../../components/PercentageInput'
import CustomRadioInput from '../../../components/CustomRadioInput'
import Investment from '../../../components/mydata/Investment.vue'
import LicenceFee from '../../../components/mydata/LicenceFee.vue'
import VueNumeric from 'vue-numeric'
export default {
  name: 'BrandProfileDataConditions',
  components: {
    CurrencyInput,
    CustomEditor,
    PercentageInput,
    CustomRadioInput,
    VueNumeric,
    Investment,
    LicenceFee
  },
  props: [],
  data: function () {
    return {}
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      const current = this.app.currentBrand || {}
      if (current.CondCommercialSpaceFrom === null) {
        current.CondCommercialSpaceFrom = 0
      }
      if (current.CondCommercialSpaceTo === null) {
        current.CondCommercialSpaceTo = 0
      }

      return current
    },
    preloads: function () {
      return this.$parent.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    }
  },
  watch: {
    'currentBrand.CondRegularLicenceType': function (newVal, oldVal) {
      if (typeof oldVal !== 'undefined') {
        this.currentBrand.CondRegularLicencePercentage = null
        this.currentBrand.CondRegularLicenceAmount = null
      }
    },
    'currentBrand.CondRegularMarketingFeeType': function (newVal, oldVal) {
      if (typeof oldVal !== 'undefined') {
        this.currentBrand.CondRegularMarketingFeePercentage = null
        this.currentBrand.CondRegularMarketingFeeAmount = null
      }
    },
    'currentBrand.CondInitialCapitalType': function (newVal, oldVal) {
      if (typeof oldVal !== 'undefined') {
        this.currentBrand.CondInitialCapitalFrom = null
        this.currentBrand.CondInitialCapitalTo = null
      }
    },
    'currentBrand.CondCommercialSpaceFrom': function (to, from) {
      if (from === undefined) return
      this.registerChange()
    },
    'currentBrand.CondCommercialSpaceTo': function (to, from) {
      if (from === undefined) return
      this.registerChange()
    },
    'currentBrand.CondCatchmentFrom': function (to, from) {
      if (from === undefined) return
      this.registerChange()
    },
    'currentBrand.CondCatchmentTo': function (to, from) {
      if (from === undefined) return
      this.registerChange()
    }
  },
  methods: {
    registerChange () {
      this.app.registerChangedContent(this.app.saveBrand)
    }
  }
}
</script>

<style lang="scss" scoped>
.row > .form-group-options label {
  align-items: center;
}
.row > .form-group-options label input:not([type="radio"]) {
  margin-right: 15px;
}
.ms-15 {
  margin-left: 15px;
}
.CondInternalPurchaseDataContainer {
  border: 1px solid #ddd;
  padding: 15px;
}
.form-group-options input.percentage-input {
  color: transparent;
}
.form-group-options .selected input {
  color: black;
}
</style>
