<template>
  <div class="contact-note">
    <div class="author">
      <div class="user-image" :style="computedPortraitStyle" />
      <div class="details">
        <span class="name">{{ computedName }}</span>
        <span class="date">{{ computedDate }} | {{ computedTime }} Uhr</span>
      </div>
    </div>
    <div class="content" v-html="computedMessageContent"></div>
  </div>
</template>

<script>
import Config from '@/config'
import moment from 'moment'

export default {
  name: 'ContactNote',
  components: {},
  props: ['message'],
  data: function () {
    return {
    }
  },
  computed: {
    computedMessageContent () {
      return this.message.Content.replace(/\n/g, '<br />')
    },
    computedName () {
      return this.message.Author.Firstname + ' ' + this.message.Author.Lastname
    },
    computedPortrait () {
      if (this.message.Author.PortraitID !== null) {
        return `${Config.MEDIA_API_URL}id/${this.message.Author.PortraitID}`
      }
      return require('@/assets/images/user-none.png')
    },
    computedPortraitStyle () {
      return {
        background: `url(${this.computedPortrait}) lightgray 50% / cover no-repeat`
      }
    },
    computedDate () {
      return moment(this.message.Created).format('DD.MM.YYYY')
    },
    computedTime () {
      return moment(this.message.Created).format('HH:mm')
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.contact-note {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  .user-image {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    border: 3px solid #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
  }

  .author {
    display: flex;
    align-items: center;
    gap: 16px;

    .details {
      display: flex;
      flex-direction: column;

      span {
        color: #3F4957;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        /* 150% */
      }

      span:first-child {
        font-weight: 700;
      }

      span:last-child {
        font-weight: 400;
      }
    }

  }

  .content {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.10);
  }

}
</style>
