<template>
  <div class="mb-bar-chart p-4 mb-4 card">
      <h2 v-if="title != ''">{{title}}</h2>
      <div v-if="isLoading" class="mb-loading"><span v-t="'page.statistics.loading'"></span></div>
      <div v-if="!isLoading && showNoDataHint" class="mb-no-chart-data"><span v-t="'page.statistics.nodata'"></span></div>
      <bar-chart
        v-if="!isLoading && chartData.datasets != null && chartData.datasets.length > 0"
        :chartData="chartData"
        :options="chartData.options"
        :plugins="[]"
        :totalText="this.$t('page.statistics.total')"
      ></bar-chart>
  </div>
</template>

<script>
import BarChart from './Bar.vue'
import moment from 'moment'

export default {
  components: {
    BarChart
  },
  name: 'TopBrandChartBar',
  props: ['startDate', 'endDate', 'groupby', 'title', 'currentBrand', 'API'],
  data: function () {
    return {
      chartData: {},
      chartLabels: [],
      barChartDatasetsPortal: [],
      isLoading: false,
      showNoDataHint: false
    }
  },
  computed: {
  },
  created: function () {
  },
  methods: {
    getChartData () {
      if (this.isLoading || this.currentBrand == null || this.currentBrand.BrandID == null) return

      this.isLoading = true
      this.chartData = this.getDefaultChartData()
      const momentStarDate = moment(this.startDate, 'DD.MM.YYYY')
      const momentEndDate = moment(this.endDate, 'DD.MM.YYYY')
      let dataColCount = momentEndDate.diff(momentStarDate, this.groupby.toLowerCase() + 's') + 1
      if (dataColCount === 1 && (this.groupby === 'Week' || this.groupby === 'Month')) {
        if (momentStarDate.format('w') !== momentEndDate.format('w')) dataColCount++
      }
      const postData = {
        ResFrom: 1,
        ResTo: 10,
        Filter: [
          {
            Field: 'Created',
            Operator: 'GE',
            Value: momentStarDate.format('YYYY-MM-DD HH:mm:ss')
          },
          {
            Field: 'Created',
            Operator: 'LT',
            Value: momentEndDate.format('YYYY-MM-DD HH:mm:ss')
          },
          {
            Field: 'BrandID',
            Operator: 'EQ',
            Value: this.currentBrand.BrandID
          },
          {
            Field: 'VisitorActionTypeID',
            Operator: 'IN',
            Value: '1,2,3'
          },
          {
            Field: 'Num1',
            Operator: 'IN',
            Value: '2,4'
          },
          {
            Field: 'IsBot',
            Operator: 'EQ_ISNULL',
            Value: 0
          }
        ],
        OrderBy: [
          { Field: 'Created', SortOrder: 'ASC' },
          { Field: 'VisitorActionTypeID', SortOrder: 'ASC' }
        ],
        GroupBy: {
          Key: [
            { Field: 'Created', DatePart: this.groupby },
            { Field: 'SourceModule' },
            { Field: 'VisitorActionTypeID' },
            { Field: 'Num1' }
          ],
          Calc: [
            { Field: '*', Func: 'CNT' }
          ]
        },
        Tablename: 'VisitorAction'
      }
      this.API._post('groupby/', postData)
        .then(response => {
          if (response.body.Result.Data != null && response.body.Result.Data.length === 0) {
            this.showNoDataHint = true
          } else this.showNoDataHint = false
          this.chartData.datasets = this.mapChartBarData(response.body.Result.Data.slice(0), dataColCount, momentStarDate)
          this.chartData.options.tooltips.callbacks.beforeLabel = this.tooltipCallbackBeforeLabel
          this.chartData.options.tooltips.callbacks.label = this.tooltipCallbackLabel
          this.chartData.options.legend.labels.filter = this.getFilteredLabels
          this.chartData.options.legend.labels.generateLabels = this.changeLegendText
          this.chartData.labels = this.chartLabels
        }).finally(() => {
          this.isLoading = false
        })
    },
    mapChartBarData (apiData, dataColCount, startDate) {
      this.chartLabels = []

      const dataBrand = {
        de: new Array(dataColCount).fill(0),
        at: new Array(dataColCount).fill(0),
        ch: new Array(dataColCount).fill(0),
        rps: new Array(dataColCount).fill(0)
      }
      const dataPrev = {
        de: new Array(dataColCount).fill(0),
        at: new Array(dataColCount).fill(0),
        ch: new Array(dataColCount).fill(0),
        rps: new Array(dataColCount).fill(0)
      }
      let currentDay = startDate
      let dayFormat = 'YYYY-MM-DD'
      let labelFormat = 'DD.MM'
      if (this.groupby === 'Week') {
        dayFormat = 'GGGG/WW'
        labelFormat = dayFormat
      }
      if (this.groupby === 'Month') {
        dayFormat = 'YYYY-MM'
        labelFormat = dayFormat
      }
      for (let i = 0; i < dataColCount; i++) {
        this.chartLabels.push(currentDay.format(labelFormat))
        if (apiData != null) {
          apiData.forEach(thisData => {
            let doContinue = false
            const splitKey = thisData.Key.split('~')
            if (splitKey[0] === currentDay.format(dayFormat)) {
              doContinue = true
              let portal = splitKey[1].toLowerCase()
              if (parseInt(splitKey[3]) === 4) portal = 'rps'
              if (!this.barChartDatasetsPortal.includes(portal)) this.barChartDatasetsPortal.push(portal)
              if (parseInt(splitKey[2]) < 3) dataBrand[portal][i] += parseInt(thisData.Fields[0].Value)
              else dataPrev[portal][i] += parseInt(thisData.Fields[0].Value)
            } else if (doContinue) return true
          })
        }
        currentDay = startDate.add(1, this.groupby.toLowerCase() + 's')
      }

      return this.createDataSets(dataBrand, dataPrev)
    },
    createDataSets (dataBrand, dataPrev) {
      const datasets = []
      if (this.barChartDatasetsPortal.includes('de')) {
        datasets.push(
          {
            label: 'TOP-' + this.$t('page.statistics.brand') + ' DE ' + this.$t('page.statistics.brandpage'),
            data: dataBrand.de,
            backgroundColor: '#005578'
          },
          {
            label: 'TOP-' + this.$t('page.statistics.brand') + ' DE ' + this.$t('page.statistics.quickview'),
            data: dataPrev.de,
            backgroundColor: '#6397ac'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('at')) {
        datasets.push(
          {
            label: 'TOP-' + this.$t('page.statistics.brand') + ' AT ' + this.$t('page.statistics.brandpage'),
            data: dataBrand.at,
            backgroundColor: '#72bb53'
          },
          {
            label: 'TOP-' + this.$t('page.statistics.brand') + ' AT ' + this.$t('page.statistics.quickview'),
            data: dataPrev.at,
            backgroundColor: '#a9d596'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('ch')) {
        datasets.push(
          {
            label: 'TOP-' + this.$t('page.statistics.brand') + ' CH ' + this.$t('page.statistics.brandpage'),
            data: dataBrand.ch,
            backgroundColor: '#ff3823'
          },
          {
            label: 'TOP-' + this.$t('page.statistics.brand') + ' CH ' + this.$t('page.statistics.quickview'),
            data: dataPrev.ch,
            backgroundColor: '#ff8578'
          }
        )
      }
      if (this.barChartDatasetsPortal.includes('rps')) {
        datasets.push(
          {
            label: this.$t('page.statistics.rps'),
            data: dataBrand.rps,
            backgroundColor: '#ffa834'
          }
        )
      }

      return datasets
    },
    getFilteredLabels (item, chart) { // Filtern der Legende
      return !item.text.includes(this.$t('page.statistics.quickview'))
    },
    changeLegendText (chart) { // Textänderung der Legende
      const data = chart.data
      const legends = Array.isArray(data.datasets)
        ? data.datasets.map(function (dataset, i) {
          return {
            text: dataset.label.replace(' ' + this.$t('page.statistics.brandpage'), '') + '   (X)',
            fillStyle: (!Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor : dataset.backgroundColor[0]),
            hidden: !chart.isDatasetVisible(i),
            lineCap: dataset.borderCapStyle,
            lineDash: dataset.borderDash,
            lineDashOffset: dataset.borderDashOffset,
            lineJoin: dataset.borderJoinStyle,
            lineWidth: dataset.borderWidth,
            strokeStyle: dataset.borderColor,
            pointStyle: dataset.pointStyle,
            datasetIndex: i
          }
        }, this)
        : []
      return legends
    },
    tooltipCallbackBeforeLabel (tooltipItem, data) {
      const label = data.datasets[tooltipItem.datasetIndex].label
      const labelGroup = label.split(' ')
      if (label.endsWith(this.$t('page.statistics.brandpage'))) {
        let count = 0
        data.datasets.forEach(dataset => {
          if (
            dataset.label.startsWith(labelGroup[0] + ' ' + labelGroup[1]) &&
            dataset.data[tooltipItem.index] != null
          ) {
            count += parseInt(dataset.data[tooltipItem.index])
          }
        })
        return labelGroup[0] + ' ' + labelGroup[1] + ' (' + this.$t('page.statistics.total') + ': ' + count + ')'
      }
      if (label === this.$t('page.statistics.rps')) return ' '
      return ''
    },
    tooltipCallbackLabel (tooltipItem, data) {
      const label = data.datasets[tooltipItem.datasetIndex].label
      if (label.startsWith(this.$t('page.statistics.regional'))) return label + ': ' + tooltipItem.yLabel

      const splitLabel = label.split(' ')
      return splitLabel[2] + ': ' + tooltipItem.yLabel
    },
    getDefaultChartData () {
      return {
        labels: [],
        datasets: [],
        options: {
          responsive: true,
          tooltips: {
            enabled: false,
            mode: 'label',
            callbacks: {}
          },
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true, ticks: { stepSize: (this.groupby === 'Month' ? 10 : 5) } }]
          },
          legend: {
            display: true,
            labels: {}
          },
          maintainAspectRatio: false
        }
      }
    }
  },
  watch: {
    'currentBrand.BrandID': function () {
      this.getChartData()
    },
    startDate: function () {
      this.getChartData()
    },
    endDate: function () {
      this.getChartData()
    },
    groupby: function () {
      this.getChartData()
    }
  },
  mounted: function () {
    this.getChartData()
  }
}
</script>

<style lang="less" scoped>
.mb-bar-chart {
  min-height: 400px;
  position: relative;
}
.mb-no-chart-data, .mb-loading {
  background-color: rgba(255,255,255,0.5);
  position: absolute;
  display: grid;
  place-items: center;
  height: calc(100% - (1.5rem * 2));
  width: calc(100% - (1.5rem * 2));
  font-weight: 700;
  font-size: 20px;
}
</style>
