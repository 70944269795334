<template>
  <div class="mb-similar-brands">
    <div class="mb-title" v-if="title">{{title}}</div>
    <vueper-slides
      v-if="slides.length > 0"
      fixed-height="70px"
      :visible-slides="9"
      autoplay
      :touchable="false"
      :arrows="true"
      :arrows-outside="false"
      :bullets-outside="false"
      :bullets="false"
    >
      <vueper-slide v-for="(slide, i) in slides" :key="i" :title="slide.Name">
        <template #content>
          <img :src="getBrandLogo(slide)" :title="slide.Name" height="50px" />
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  components: { VueperSlides, VueperSlide },
  props: ['specialSector', 'title'],
  data: () => ({ slides: [] }),
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    getNextLocaleFlag: function () {
      return (this.$i18n.locale === 'de' ? 'gb' : 'de')
    },
    getCurrentLocaleFlag: function () {
      return (this.$i18n.locale === 'de' ? 'de' : 'gb')
    },
    getNextLocale: function () {
      return (this.$i18n.locale === 'de' ? 'en' : 'de')
    }
  },
  methods: {
    getSimilarBrands () {
      if (this.specialSector <= 0) return

      const similarBrands = this.app.getSimilarBrandsBySpecialSector(this.specialSector, this.app.getPortalIDFromBrand(this.app.currentBrand))
      similarBrands.then((response) => {
        this.slides = response.filter((brand) => { return brand.Logo != null })
        this.setSliderArrows()
      })
    },
    setSliderArrows () {
      setTimeout(function () {
        const leftArrow = document.getElementsByClassName('vueperslides__arrow--prev')
        if (leftArrow.length === 0) {
          this.setSliderArrows()
          return
        }
        leftArrow[0].innerHTML = '<i class="fa fa-chevron-left"></i>'
        const rightArrow = document.getElementsByClassName('vueperslides__arrow--next')
        rightArrow[0].innerHTML = '<i class="fa fa-chevron-right"></i>'
      }.bind(this), 1000)
    },
    getBrandLogo (brand) {
      if (brand.Logo == null) { return '' }
      const result = this.app.getImage({ FileID: brand.Logo.FileID })
      return result + '?size=xxs'
    }
  },
  watch: {
    specialSector () {
      this.getSimilarBrands()
    }
  },
  mounted () {
    this.getSimilarBrands()
  }
}
</script>

<style lang="scss">
.mb-similar-brands {
  margin-top: 40px;
}
.mb-title {
  font-weight: 700;
  margin-bottom: 10px;
}
.vueperslides {
  margin-bottom: 0px;
}
.vueperslides__inner {
  background-color: #fff;
  border-radius: 10px;
}
.vueperslide {
  display: grid;
  place-items: center;
}
.vueperslides__arrows {
  color: #005578;
  font-size: 25px;
}
.vueperslides__parallax-wrapper {
  border-radius: 10px;
}
</style>
