import { render, staticRenderFns } from "./Talentpool.vue?vue&type=template&id=1266d83c&scoped=true"
import script from "./Talentpool.vue?vue&type=script&lang=js"
export * from "./Talentpool.vue?vue&type=script&lang=js"
import style0 from "./Talentpool.vue?vue&type=style&index=0&id=1266d83c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1266d83c",
  null
  
)

export default component.exports