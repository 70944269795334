<template>
  <div class="mb-main-content" v-if="hasCurrentBrand">
    <h1 v-t="'menue.subscriptions'"></h1>

    <div class="content-wrapper">
      <div class="row">
        <div class="col-12">
          <h3 class="mb-3">{{ $t("subscriptions.current.headline") }}</h3>
        </div>
      </div>
      <div
        class="col-12 product-upgradeable mb-3"
        v-for="(subscription, index) in upgradeableSubscriptions"
        :key="subscription.id"
      >
        <div class="row">
          <div class="col-12 col-lg-4 p-3 position-relative">
            <b-dropdown
              :text="$t('subscriptions.cancel.options.button')"
              class="options-dropdown"
            >
              <b-dropdown-item
                @click="
                  handleSubscriptionUpgrade(
                    subscription,
                    computedSubCounter(1, index)
                  )
                "
                >{{
                  $t("subscriptions.cancel.options.switchYearly")
                }}</b-dropdown-item
              >
              <b-dropdown-item
                @click="
                  startCancelSubscription(
                    subscription,
                    computedSubCounter(1, index)
                  )
                "
                v-if="subscription.canceled === false"
                ><span class="text-danger">{{
                  $t("subscriptions.cancel.options.cancel")
                }}</span></b-dropdown-item
              >
              <b-dropdown-item
                @click="
                  startRevertCancelation(
                    subscription,
                    computedSubCounter(1, index)
                  )
                "
                v-if="subscription.canceled === true"
                ><span class="text-danger">{{
                  $t("subscriptions.cancel.revert.button")
                }}</span></b-dropdown-item
              >
            </b-dropdown>
            <div class="d-flex">
              <h5 class="me-2 mb-3">Abo #{{ computedSubCounter(1, index) }}</h5>
              <div>
                <span :class="computedBadge(subscription.interval)">{{
                  computedPeriodName(subscription.interval)
                }}</span>
              </div>
            </div>
            <span
              class="d-block mb-1"
              v-for="(countryCode, index) in subscription.countries"
              :key="index"
            >
              <img :src="computedFlagIcon(countryCode)" class="me-1 flag" />
              {{ computedProductName(subscription.type, countryCode) }}
            </span>
            <div class="d-flex">
              <span
                v-html="
                  $t('subscriptions.invoice.nextText', {
                    amount: computedAmount(subscription.nextInvoice.amount),
                    date: subscription.nextInvoice.date,
                  })
                "
              ></span>
            </div>
          </div>
          <div
            class="col-12 col-lg-8 pt-3 pb-3 pt-lg-0 pb-lg-0 bg-row d-flex flex-column justify-content-center align-items-center upgrade-row"
          >
            <span class="badge badge-success badge-corner">{{
              $t("date.yearly")
            }}</span>
            <h5>
              <strong>{{
                $t("subscriptions.banner.headline", {
                  saveAmount: computedAmount(subscription.upgrade.amount),
                })
              }}</strong>
            </h5>
            <span
              class="mb-2"
              v-html="
                $t('subscriptions.banner.subtext', {
                  percentage: subscription.upgrade.percentage,
                })
              "
            ></span>
            <button
              type="button"
              class="upgrade-btn"
              @click="
                handleSubscriptionUpgrade(
                  subscription,
                  computedSubCounter(1, index)
                )
              "
            >
              Jetzt wechseln und sparen
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-12 col-lg-4 subscription-main-col position-relative"
          v-for="(subscription, index) in nonUpgradeableSubscriptions"
          :key="subscription.id"
        >
          <b-dropdown
            :text="$t('subscriptions.cancel.options.button')"
            class="options-dropdown"
            v-if="
              subscription.canceled === false ||
              subscription.type !== 'topbrand'
            "
          >
            <b-dropdown-item
              @click="
                startCancelSubscription(
                  subscription,
                  computedSubCounter(2, index)
                )
              "
              v-if="subscription.canceled === false"
              ><span class="text-danger">{{
                $t("subscriptions.cancel.options.cancel")
              }}</span></b-dropdown-item
            >
            <b-dropdown-item
              @click="
                startRevertCancelation(
                  subscription,
                  computedSubCounter(2, index)
                )
              "
              v-if="subscription.canceled === true"
              ><span class="text-danger">{{
                $t("subscriptions.cancel.revert.button")
              }}</span></b-dropdown-item
            >
          </b-dropdown>
          <div class="col-12 p-3 subscription-col">
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <h5 class="me-2 mb-3">
                  Abo #{{ computedSubCounter(2, index) }}
                </h5>
                <div>
                  <span :class="computedBadge(subscription.interval)">{{
                    computedPeriodName(subscription.interval)
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="d-block mb-1"
              v-for="(countryCode, index) in subscription.countries"
              :key="index"
            >
              <img :src="computedFlagIcon(countryCode)" class="me-1 flag" />
              <span>{{
                computedProductName(subscription.type, countryCode)
              }}</span>
              <small
                v-if="
                  subscription.canceled === true &&
                  subscription.canceledAllItems === false &&
                  computedCountryIsCanceled(
                    subscription.canceledItems,
                    countryCode
                  )
                "
                class="text-danger d-block"
                v-html="
                  $t('subscriptions.cancel.date', {
                    date: subscription.cancelAt,
                  })
                "
              ></small>
            </div>
            <div
              v-if="
                subscription.type === 'chatbot' &&
                subscription.newLimitEdit === false
              "
              class="d-flex"
            >
              <strong class="me-1">Deckel:</strong>
              <a href="javascript:" @click="openChatbotEditModal(subscription)"
                >{{ computedAmount(subscription.chatbot.limit) }} / Monat
                <i class="fa fa-edit"
              /></a>
            </div>
            <div
              v-if="
                subscription.type === 'chatbot' &&
                subscription.newLimitEdit === true
              "
              class="d-flex align-items-center mb-2"
            >
              <strong class="me-1">Neuer Deckel:</strong>

              <div class="input-group new-limit-input-group">
                <currency-input
                  class="form-control new-limit-input"
                  v-model="subscription.chatbot.newLimit"
                  currency="EUR"
                  locale="de"
                  :allow-negative="false"
                />
                <div class="input-group-append new-limit-buttons">
                  <button
                    class="btn btn-danger btn-sm"
                    type="button"
                    @click="dismissChatbotEditModal(subscription)"
                  >
                    <i class="fa fa-close"></i>
                  </button>
                  <button
                    class="btn btn-success btn-sm"
                    type="button"
                    @click="handleChatbotNewLimit(subscription)"
                  >
                    <i class="fa fa-check"></i>
                  </button>
                </div>
              </div>
            </div>

            <div v-if="subscription.type === 'chatbot'">
              <span
                v-html="
                  $t('subscriptions.chatbot.currentLeads', {
                    leads: computedAmount(subscription.nextInvoice.amount),
                  })
                "
              ></span>
            </div>
            <div class="d-flex" v-if="subscription.canceledAllItems === false">
              <span
                v-if="subscription.type === 'chatbot'"
                v-html="
                  $t('subscriptions.invoice.nextTextChatbot', {
                    date: subscription.nextInvoice.date,
                  })
                "
              ></span>
              <span
                v-else
                v-html="
                  $t('subscriptions.invoice.nextText', {
                    amount: computedAmount(subscription.nextInvoice.amount),
                    date: subscription.nextInvoice.date,
                  })
                "
              ></span>
            </div>
            <div
              class="d-flex flex-column"
              v-if="subscription.canceledAllItems === true"
            >
              <span
                class="text-danger"
                v-html="
                  $t('subscriptions.cancel.date', {
                    date: subscription.cancelAt,
                  })
                "
              ></span>
              <a
                v-if="
                  subscription.canceled === true &&
                  subscription.type !== 'topbrand'
                "
                href="javascript:"
                @click="
                  startRevertCancelation(
                    subscription,
                    computedSubCounter(2, index)
                  )
                "
                ><small>{{
                  $t("subscriptions.cancel.buttons.revert")
                }}</small></a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h3 class="mb-3 mt-3">
            {{ $t("subscriptions.invoice.headline") }}
          </h3>
          <a
            @click="redirectToCustomerPortal"
            class="btn btn-primary mb-3"
            href="javascript:"
            >{{ $t("subscriptions.invoice.button") }}</a
          >
          <ul>
            <li>
              <img src="../assets/images/icons/secure.svg" />
              {{ $t("subscriptions.invoice.list.0") }}
            </li>
            <li>
              <img src="../assets/images/icons/secure.svg" />
              {{ $t("subscriptions.invoice.list.1") }}
            </li>
            <li>
              <img src="../assets/images/icons/secure.svg" />
              {{ $t("subscriptions.invoice.list.2") }}
            </li>
            <li>
              <img src="../assets/images/icons/secure.svg" />
              {{ $t("subscriptions.invoice.list.3") }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <b-modal centered id="upgradeModal" modal-class="blue-modal">
      <template #modal-title>
        {{ $t("subscriptions.upgrade.headline") }}
      </template>

      <div class="row" v-if="upgradeSubscription !== null">
        <div class="col">
          <strong>Abo #{{ upgradeSubscription.subCounterIndex }}</strong>
          <span
            class="d-block mb-2 mt-2"
            v-for="(countryCode, index) in upgradeSubscription.countries"
            :key="index"
          >
            <img :src="computedFlagIcon(countryCode)" class="me-1 flag" />
            {{ computedProductName(upgradeSubscription.type, countryCode) }}
          </span>

          <span class="d-block mt-3 mb-2"
            ><strong>{{ $t("subscriptions.upgrade.current") }}:</strong>
            {{
              computedAmount(upgradeSubscription.upgrade.currentMonthlyPrice)
            }}
            / {{ computedInterval(upgradeSubscription) }} ({{
              computedAmount(upgradeSubscription.upgrade.currentYearlyPrice)
            }}
            / {{ $tc("date.year", 1) }})</span
          >
          <span class="d-block mb-1"
            ><strong>{{ $t("subscriptions.upgrade.future") }}:</strong>
            {{ computedAmount(upgradeSubscription.upgrade.futureYearlyPrice) }}
            / {{ $tc("date.year", 1) }}
            {{
              $t("subscriptions.upgrade.priceEquals", {
                amount: computedAmount(
                  upgradeSubscription.upgrade.futureMonthlyPrice
                ),
              })
            }}
            / {{ $tc("date.month", 1) }})</span
          >
          <hr />
          <strong class="d-block green-line mb-3">{{
            $t("subscriptions.upgrade.saveLine", {
              amount: computedAmount(upgradeSubscription.upgrade.amount),
            })
          }}</strong>

          <small class="d-block">{{ $t("subscriptions.upgrade.agb1") }}</small>
          <small class="d-block mb-3">{{
            $t("subscriptions.upgrade.agb2")
          }}</small>

          <CustomCheckbox
            :text="
              $t('page.membership.externalallowed', {
                company: currentCompany.Name,
              })
            "
            v-model="modalCheckboxes.ExternalAllowed"
          ></CustomCheckbox>

          <CustomCheckbox
            :text="$t('page.membership.agb')"
            v-model="modalCheckboxes.AGB"
            class="mb-terms"
            onlyCheckboxClick="true"
          ></CustomCheckbox>
        </div>
      </div>

      <template #modal-footer>
        <div class="row w-100">
          <div class="col-6">
            <button
              class="btn btn-block w-100 btn-light"
              @click="dismissModal('upgradeModal', 'upgradeSubscription')"
            >
              {{ $t("subscriptions.cancel.revert.success.dismiss") }}
            </button>
          </div>
          <div class="col-6">
            <button
              class="btn d-block w-100 upgrade-cta"
              @click="handleSubscriptionUpgradeConfirmation"
            >
              Auf jährliche Abrechnung wechseln
            </button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal centered size="750px" id="cancelModal" modal-class="blue-modal">
      <template #modal-title>
        {{ $t(`subscriptions.cancel.headline.${cancelSubscription.type}`) }}
      </template>

      <div
        v-if="
          cancelSubscription !== null && cancelSubscription.canceled === false
        "
      >
        <div class="row">
          <div class="col-6">
            <strong>Abo #{{ cancelSubscription.subCounterIndex }}</strong>
          </div>
          <div class="col-6">
            <strong>{{ $t("subscriptions.cancel.modal.th-question") }}</strong>
          </div>
        </div>
        <div
          class="row p-2"
          v-for="(countryCode, index) in cancelSubscription.countries"
          :key="index"
        >
          <div class="col-6">
            <img :src="computedFlagIcon(countryCode)" class="me-1 flag" />
            {{ computedProductName(cancelSubscription.type, countryCode) }}
          </div>
          <div class="col-6">
            <div class="d-flex">
              <b-form-radio
                class="me-3 text-success"
                v-model="
                  cancelSubscription.cancelItemsRadios[countryCode].value
                "
                :name="`radio-${countryCode}`"
                :value="0"
                :unchecked-value="0"
                @change="(e) => handleCancelItemChange(e, countryCode)"
                >{{ $t("subscriptions.cancel.buttons.keep") }}</b-form-radio
              >
              <b-form-radio
                class="text-danger"
                v-model="
                  cancelSubscription.cancelItemsRadios[countryCode].value
                "
                :name="`radio-${countryCode}`"
                :value="1"
                :unchecked-value="0"
                @change="(e) => handleCancelItemChange(e, countryCode)"
                >{{ $t("subscriptions.cancel.buttons.cancel") }}</b-form-radio
              >
            </div>
          </div>
        </div>
        <hr class="mt-3 mb-3" />
        <div v-if="cancelSubscription.type === 'membership'">
          <p class="h5 mb-4" v-if="cancelSubscription.totalLeads > 5">
            <strong
              v-html="
                $t('subscriptions.cancel.modal.membership.headline', {
                  amount: cancelSubscription.totalLeads,
                })
              "
            ></strong>
          </p>
          <p>
            <strong>{{ $t("subscriptions.cancel.modal.uspHeadline") }}</strong>
          </p>
          <div class="row small cancel-dots">
            <div class="col-5">
              <span class="d-block mb-2">
                <i class="fa fa-close"></i>
                {{ $t("subscriptions.cancel.modal.membership.usp1") }}
              </span>
              <span class="d-block mb-2">
                <i class="fa fa-close"></i>
                {{ $t("subscriptions.cancel.modal.membership.usp2") }}
              </span>
              <span class="d-block mb-2">
                <i class="fa fa-close"></i>
                {{ $t("subscriptions.cancel.modal.membership.usp3") }}
              </span>
            </div>
            <div class="col-7">
              <span class="d-block mb-2">
                <i class="fa fa-close"></i>
                {{ $t("subscriptions.cancel.modal.membership.usp4") }}
              </span>
              <span class="d-block mb-2">
                <i class="fa fa-close"></i>
                {{ $t("subscriptions.cancel.modal.membership.usp5") }}
              </span>
              <span class="d-block mb-2">
                <i class="fa fa-close"></i>
                {{ $t("subscriptions.cancel.modal.membership.usp6") }}
              </span>
            </div>
          </div>
        </div>

        <div v-if="cancelSubscription.type === 'topbrand'">
          <p class="h5 mb-4" v-if="cancelSubscription.totalLeads > 5">
            <strong
              v-html="
                $t('subscriptions.cancel.modal.topbrand.headline', {
                  amount: cancelSubscription.totalClicks,
                })
              "
            ></strong>
          </p>
          <p>
            <strong>{{ $t("subscriptions.cancel.modal.uspHeadline") }}</strong>
          </p>
          <div class="row small cancel-dots">
            <div class="col-12">
              <span class="d-block mb-2">
                <i class="fa fa-close"></i>
                {{ $t("subscriptions.cancel.modal.topbrand.usp1") }}
              </span>
              <span class="d-block mb-2">
                <i class="fa fa-close"></i>
                {{ $t("subscriptions.cancel.modal.topbrand.usp2") }}
              </span>
              <span class="d-block mb-2">
                <i class="fa fa-close"></i>
                {{ $t("subscriptions.cancel.modal.topbrand.usp3") }}
              </span>
            </div>
          </div>
        </div>

        <div v-if="cancelSubscription.type === 'chatbot'">
          <p class="h5 mb-4" v-if="cancelSubscription.totalLeads > 5">
            <strong
              v-html="
                $t('subscriptions.cancel.modal.chatbot.headline', {
                  amount: cancelSubscription.totalLeads,
                })
              "
            ></strong>
          </p>
          <p>
            <strong>{{ $t("subscriptions.cancel.modal.uspHeadline") }}</strong>
          </p>
          <div class="row small cancel-dots">
            <div class="col-12">
              <span class="d-block mb-2">
                <i class="fa fa-close"></i>
                {{ $t("subscriptions.cancel.modal.chatbot.usp1") }}
              </span>
              <span class="d-block mb-2">
                <i class="fa fa-close"></i>
                {{ $t("subscriptions.cancel.modal.chatbot.usp2") }}
              </span>
              <span class="d-block mb-2">
                <i class="fa fa-close"></i>
                {{ $t("subscriptions.cancel.modal.chatbot.usp3") }}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <CustomCheckbox
              wrapper-class="mt-3"
              :text="
                $t('page.membership.externalallowed', {
                  company: currentCompany.Name,
                })
              "
              v-model="modalCheckboxes.ExternalAllowed"
            ></CustomCheckbox>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="row w-100">
          <div class="col-6">
            <button
              class="btn d-block w-100 btn-danger"
              @click="cancelMemberships"
              :disabled="!hasCanceledItemSelected"
            >
              {{
                $t(
                  `subscriptions.cancel.modal.${cancelSubscription.type}.buttons.confirm`
                )
              }}
            </button>
          </div>
          <div class="col-6">
            <button
              class="btn btn-block w-100 upgrade-cta"
              @click="dismissModal('cancelModal', 'cancelSubscription')"
            >
              {{
                $t(
                  `subscriptions.cancel.modal.${cancelSubscription.type}.buttons.dismiss`
                )
              }}
            </button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      centered
      size="750px"
      id="revertCancelationModal"
      modal-class="blue-modal"
    >
      <template #modal-title>
        {{ $t("subscriptions.cancel.revert.headline") }}
      </template>

      <div
        v-if="
          cancelSubscription !== null && cancelSubscription.canceled === true
        "
      >
        <div class="row">
          <div class="col-6">
            <strong>Abo #{{ cancelSubscription.subCounterIndex }}</strong>
          </div>
          <div class="col-6">
            <strong>{{ $t("subscriptions.cancel.modal.th-question") }}</strong>
          </div>
        </div>
        <div
          class="row p-2"
          v-for="(countryCode, index) in cancelSubscription.countries"
          :key="index"
        >
          <div class="col-6">
            <img :src="computedFlagIcon(countryCode)" class="me-1 flag" />
            {{ computedProductName(cancelSubscription.type, countryCode) }}
          </div>
          <div class="col-6">
            <span
              v-if="
                cancelSubscription.canceledAllItems === true ||
                computedCountryIsCanceled(
                  cancelSubscription.canceledItems,
                  countryCode
                )
              "
              class="text-danger"
            >
              <i class="fa fa-check-circle"></i>
              {{
                $t("subscriptions.cancel.revert.canceled", {
                  date: cancelSubscription.cancelAt,
                })
              }}
            </span>
            <span
              v-if="
                cancelSubscription.canceledAllItems === false &&
                !computedCountryIsCanceled(
                  cancelSubscription.canceledItems,
                  countryCode
                )
              "
              class="text-success"
            >
              <i class="fa fa-check-circle"></i>
              {{ $t("subscriptions.cancel.revert.keep") }}
            </span>
          </div>
        </div>
        <hr class="mt-3 mb-3" />
        <p class="h5 mb-4">
          <strong>{{ $t("subscriptions.cancel.revert.subHeadline") }}</strong>
        </p>
        <p class="mb-4">{{ $t("subscriptions.cancel.revert.text") }}</p>
        <p class="mb-0" v-if="cancelSubscription.type !== 'topbrand'">
          <strong>{{ $t("subscriptions.cancel.revert.question") }}</strong>
        </p>
        <p v-if="cancelSubscription.type !== 'topbrand'">
          <strong>{{ $t("subscriptions.cancel.revert.revertHint") }}</strong>
        </p>
        <div class="row" v-if="cancelSubscription.type !== 'topbrand'">
          <div class="col">
            <CustomCheckbox
              wrapper-class="mt-3"
              :text="
                $t('page.membership.externalallowed', {
                  company: currentCompany.Name,
                })
              "
              v-model="modalCheckboxes.ExternalAllowed"
            ></CustomCheckbox>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div
          class="row d-flex justify-content-center w-100"
          v-if="cancelSubscription.type === 'topbrand'"
        >
          <div class="col-6">
            <button
              class="btn btn-block w-100 upgrade-cta"
              @click="
                dismissModal('revertCancelationModal', 'cancelSubscription')
              "
            >
              {{ $t("subscriptions.cancel.revert.success.button") }}
            </button>
          </div>
        </div>
        <div v-else class="row d-flex justify-content-center w-100">
          <div class="col-6">
            <button
              class="btn btn-block w-100 btn-light"
              @click="
                dismissModal('revertCancelationModal', 'cancelSubscription')
              "
            >
              {{ $t("subscriptions.cancel.revert.success.dismiss") }}
            </button>
          </div>
          <div class="col-6">
            <button
              class="btn btn-block w-100 upgrade-cta"
              @click="revertCancelationMembership"
            >
              {{ $t("subscriptions.cancel.revert.button") }}
            </button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      centered
      size="750px"
      id="revertCancelationSuccessModal"
      modal-class="blue-modal"
    >
      <template #modal-title>
        {{ $t("subscriptions.cancel.revert.success.headline") }}
      </template>

      <div v-if="revertCancelSubscription !== null">
        <div class="row">
          <div class="col-12">
            <strong>Abo #{{ revertCancelSubscription.subCounterIndex }}</strong>
          </div>
        </div>
        <div
          class="row p-2"
          v-for="(countryCode, index) in revertCancelSubscription.countries"
          :key="index"
        >
          <div class="col-6">
            <img :src="computedFlagIcon(countryCode)" class="me-1 flag" />
            {{
              computedProductName(revertCancelSubscription.type, countryCode)
            }}
          </div>
          <div class="col-6">
            <span class="text-success">
              <i class="fa fa-check-circle"></i>
              {{ $t("subscriptions.cancel.revert.keep") }}
            </span>
          </div>
        </div>
        <hr class="mt-3 mb-3" />
        <p class="h5 mb-4">
          <strong>{{
            $t("subscriptions.cancel.revert.success.subHeadline")
          }}</strong>
        </p>
        <p class="mb-4">
          {{ $t("subscriptions.cancel.revert.success.text") }}
        </p>
        <p class="mb-0">
          <strong>{{
            $t("subscriptions.cancel.revert.success.greeting1")
          }}</strong>
        </p>
        <p class="mb-0">
          <strong>{{
            $t("subscriptions.cancel.revert.success.greeting2")
          }}</strong>
        </p>
      </div>
      <template #modal-footer>
        <div class="row d-flex justify-content-center w-100">
          <div class="col-6">
            <button
              class="btn btn-block w-100 upgrade-cta"
              @click="
                dismissModal(
                  'revertCancelationSuccessModal',
                  'revertCancelSubscription'
                )
              "
            >
              {{ $t("subscriptions.cancel.revert.success.button") }}
            </button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input'
import CustomCheckbox from '../components/CustomCheckboxContainer'
export default {
  components: {
    CurrencyInput,
    CustomCheckbox
  },
  name: 'subscriptionView',
  props: [],
  data: function () {
    return {
      isLoading: false,
      subscriptions: [],
      upgradeSubscription: null,
      modalCheckboxes: {
        AGB: false,
        ExternalAllowed: false
      },
      cancelSubscription: null,
      revertCancelSubscription: null,
      hasCanceledItemSelected: false,
      hasCanceledItemSelectedAll: false,
      test: {
        a: 0
      }
    }
  },
  computed: {
    computedCountryIsCanceled () {
      return (canceledItems, countryCode) => {
        return canceledItems.includes(countryCode)
      }
    },
    hasCurrentBrand () {
      return typeof this.currentBrand.BrandID !== 'undefined'
    },
    computedSubCounter () {
      return (mode, index) => {
        return mode === 1
          ? index + 1
          : this.upgradeableSubscriptions.length + index + 1
      }
    },
    currentBrand () {
      const current = this.app.currentBrand || {}
      return current
    },
    isActiveMember () {
      return this.app.isActiveMember
    },
    currentBrandSlug () {
      return (
        this.app.getSlug(this.currentBrand.Name, {
          lang: 'de',
          custom: {
            '+': ''
          }
        }) +
        '-m-' +
        this.currentBrand.BrandID
      )
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    currentMembership: function () {
      return this.app.currentMembership || {}
    },
    upgradeableSubscriptions: function () {
      return this.subscriptions.filter(function (subscription) {
        return subscription.upgrade !== null
      })
    },
    currentCompany () {
      return this.currentBrand.Company
    },
    nonUpgradeableSubscriptions () {
      return this.subscriptions.filter(function (subscription) {
        return subscription.upgrade === null
      })
    },
    computedFlagIcon: function () {
      return function (countryCode) {
        return require('../assets/images/flags/' +
          String(countryCode).toLowerCase() +
          '.svg')
      }
    },
    computedAmount: function () {
      return function (amount) {
        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR'
        }).format(amount / 100)
      }
    },
    computedInterval: function () {
      return function (subscription) {
        switch (subscription.interval) {
          case 'quarter':
            return this.$t('date.quarter')
          case 'month':
            return this.$t('date.month')
          case 'year':
            return this.$t('date.year')
        }
      }
    },
    computedPeriodName: function () {
      return function (period) {
        switch (period) {
          case 'month':
            return 'Monatlich'
          case 'quarter':
            return 'Quartalsweise'
          case 'year':
            return 'Jährlich'
          default:
            return 'Monatlich'
        }
      }
    },
    computedBadge: function () {
      return function (period) {
        switch (period) {
          case 'month':
            return 'badge badge-warning'
          case 'year':
            return 'badge badge-success'
          default:
            return 'badge badge-warning'
        }
      }
    },
    computedProductName: function () {
      return function (type, countryCode) {
        const translatedCountrySlug = this.$i18n.t(
          `country.${countryCode.toLowerCase()}`
        )
        switch (type) {
          case 'membership':
            return 'Mitgliedschaft in ' + translatedCountrySlug
          case 'topbrand':
            return 'TOP-Marke in ' + translatedCountrySlug
          case 'chatbot':
            return 'Chatbot in ' + translatedCountrySlug
          default:
            return translatedCountrySlug
        }
      }
    }
  },
  created: function () {
    if (
      typeof this.currentBrand.BrandID !== 'undefined' &&
      !this.hasCurrentBrand
    ) {
      this.$router.push('/markenauswahl')
    }
  },
  methods: {
    handleCancelItemChange (event, countryCode) {
      this.cancelSubscription.cancelItemsRadios[countryCode].value = event

      const cancelItems = Object.keys(
        this.cancelSubscription.cancelItemsRadios
      ).filter((countryKey) => {
        return (
          this.cancelSubscription.cancelItemsRadios[countryKey].value === 1
        )
      })

      this.hasCanceledItemSelected = cancelItems.length > 0
      this.hasCanceledItemSelectedAll =
        cancelItems.length ===
        Object.keys(this.cancelSubscription.cancelItemsRadios).length
    },
    async revertCancelationMembership () {
      if (!this.modalCheckboxes.ExternalAllowed) {
        return this.$swal(
          this.$t('popupmessage.warn.missingdata'),
          this.$t('popupmessage.warn.bookingpermission'),
          'warning'
        )
      }

      const loader = this.app.startLoader()

      const loginFromStorage = localStorage.getItem('FPMB_Login')
      const currentUser = loginFromStorage ? JSON.parse(loginFromStorage) : {}

      this.app.APIStripe._post('revertCancelSubscription', {
        SubscriptionID: this.cancelSubscription.id,
        BrandID: this.currentBrand.BrandID,
        Username: currentUser.user.Name,
        CancelAllItems: this.cancelSubscription.canceledAllItems ? 1 : 0
      })
        .then((response) => {
          // this.$swal(
          //   this.$t('popupmessage.success.title'),
          //   this.$t('subscriptions.cancel.success'),
          //   'success'
          // )
          this.parseSubscriptions(response.body.Subscriptions)
          this.revertCancelSubscription =
            this.subscriptions.find((subscription) => {
              return this.cancelSubscription.id === subscription.id
            }) || null
          // this.$bvModal.hide('revertCancelationModal')

          let subCounterIndex = null
          this.upgradeableSubscriptions.forEach((sub, index) => {
            if (sub.id === this.revertCancelSubscription.id) {
              subCounterIndex = this.computedSubCounter(1, index)
            }
          })
          this.nonUpgradeableSubscriptions.forEach((sub, index) => {
            if (sub.id === this.revertCancelSubscription.id) {
              subCounterIndex = this.computedSubCounter(2, index)
            }
          })

          this.revertCancelSubscription.subCounterIndex = subCounterIndex

          this.dismissModal('revertCancelationModal', 'cancelSubscription')
          this.modalCheckboxes.ExternalAllowed = false
          this.$bvModal.show('revertCancelationSuccessModal')
        })
        .catch(() => {
          this.$swal(
            this.$t('popupmessage.error.title'),
            this.$t('popupmessage.error.unknown'),
            'error'
          )
        })
        .finally(() => {
          this.app.stopLoader(loader)
        })
    },
    async cancelMemberships () {
      if (!this.modalCheckboxes.ExternalAllowed) {
        return this.$swal(
          this.$t('popupmessage.warn.missingdata'),
          this.$t('popupmessage.warn.bookingpermission'),
          'warning'
        )
      }

      const loader = this.app.startLoader()

      const cancelCountries = Object.keys(
        this.cancelSubscription.cancelItemsRadios
      ).filter((countryKey) => {
        return (
          this.cancelSubscription.cancelItemsRadios[countryKey].value === 1
        )
      })

      const loginFromStorage = localStorage.getItem('FPMB_Login')
      const currentUser = loginFromStorage ? JSON.parse(loginFromStorage) : {}

      this.app.APIStripe._post('cancelSubscription', {
        SubscriptionID: this.cancelSubscription.id,
        Countries: cancelCountries,
        BrandID: this.currentBrand.BrandID,
        Username: currentUser.user.Name,
        CancelAllItems: this.hasCanceledItemSelectedAll ? 1 : 0
      })
        .then((response) => {
          // this.$swal(
          //   this.$t('popupmessage.success.title'),
          //   this.$t('subscriptions.popups.success'),
          //   'success'
          // )
          this.parseSubscriptions(response.body.Subscriptions)
          this.cancelSubscription = this.subscriptions.find((subscription) => {
            return this.cancelSubscription.id === subscription.id
          })

          let subCounterIndex = null
          this.upgradeableSubscriptions.forEach((sub, index) => {
            if (sub.id === this.cancelSubscription.id) {
              subCounterIndex = this.computedSubCounter(1, index)
            }
          })
          this.nonUpgradeableSubscriptions.forEach((sub, index) => {
            if (sub.id === this.cancelSubscription.id) {
              subCounterIndex = this.computedSubCounter(2, index)
            }
          })

          this.cancelSubscription.subCounterIndex = subCounterIndex

          this.$bvModal.hide('cancelModal')
          this.modalCheckboxes.ExternalAllowed = false
          this.$bvModal.show('revertCancelationModal')
        })
        .catch(() => {
          this.$swal(
            this.$t('popupmessage.error.title'),
            this.$t('popupmessage.error.unknown'),
            'error'
          )
        })
        .finally(() => {
          this.app.stopLoader(loader)
        })
    },
    dismissModal (name, source) {
      this.$bvModal.hide(name)
      setTimeout(() => {
        this[source] = null
      }, 300)
    },
    handleChatbotNewLimit (subscription) {
      const oldLimit = parseInt(subscription.chatbot.limit)
      const newLimit = parseInt(subscription.chatbot.newLimit) * 100

      if (newLimit <= oldLimit) {
        return this.$swal({
          title: this.$t('popupmessage.warn.attentiontitle'),
          text: this.$t('page.chatbot.updateLimitModal.lowerError', {
            minLimit: this.computedAmount(oldLimit)
          }),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: this.$t('button.ok')
        })
      }

      this.$swal({
        title: this.$t('popupmessage.warn.areyousuretitle'),
        text: this.$t('page.chatbot.updateLimitModal.question', {
          newLimit: this.computedAmount(newLimit)
        }),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then((result) => {
        if (typeof result.value !== 'undefined') {
          if (result.isConfirmed === true) {
            const loader = this.app.startLoader()
            this.app.APIStripe._post('updateChatbotLimit', {
              BrandID: this.currentBrand.BrandID,
              NewLimit: newLimit,
              SubscriptionID: subscription.id
            })
              .then(() => {
                this.$swal({
                  title: this.$t('popupmessage.success.title'),
                  text: this.$t('page.chatbot.updateLimitModal.success', {
                    newLimit: this.computedAmount(newLimit)
                  }),
                  icon: 'success',
                  showCancelButton: false,
                  confirmButtonText: this.$t('button.ok')
                })
                subscription.chatbot.limit =
                  parseInt(subscription.chatbot.newLimit) * 100
                // subscription.newLimitEdit = false
                this.dismissChatbotEditModal(subscription)
                this.app.stopLoader(loader)
              })
              .catch(() => {
                this.$swal(
                  this.$t('popupmessage.error.title'),
                  this.$t('popupmessage.error.unknown'),
                  'error'
                )
                this.app.stopLoader(loader)
              })
          }
        }
      })
    },
    openChatbotEditModal (subscription) {
      this.subscriptions = this.subscriptions.map((loopedSubscription) => {
        if (loopedSubscription.id === subscription.id) {
          loopedSubscription.newLimitEdit = true
        }
        return loopedSubscription
      })
    },
    dismissChatbotEditModal (subscription) {
      this.subscriptions = this.subscriptions.map((loopedSubscription) => {
        if (loopedSubscription.id === subscription.id) {
          loopedSubscription.newLimitEdit = false
        }
        return loopedSubscription
      })
    },
    redirectToCustomerPortal () {
      const loader = this.app.startLoader()

      return this.app.APIStripe._post('renderCustomerPortal', {
        BrandID: this.currentBrand.BrandID,
        Origin: window.location.href
      })
        .then((response) => {
          this.app.stopLoader(loader)
          window.open(response.body.SessionUrl)
        })
        .catch((error) => {
          console.error(error)
          this.app.stopLoader(loader)
        })
    },
    parseSubscriptions (loadedSubscriptions) {
      this.subscriptions = loadedSubscriptions.map((subscription) => {
        if (
          typeof subscription.chatbot !== 'undefined' &&
          subscription.chatbot !== null
        ) {
          subscription.chatbot.newLimit =
            parseInt(subscription.chatbot.limit) / 100
          subscription.newLimitEdit = false
        }
        return subscription
      })
    },
    async loadSubscriptions () {
      this.subscriptions = []
      if (
        !this.hasCurrentBrand ||
        (typeof this.app.currentMembership === 'undefined' || typeof this.app.currentMembership.StripeSubscriptions === 'undefined')
      ) {
        return false
      }
      const loadedSubscriptions =
        this.app.currentMembership.StripeSubscriptions
      this.parseSubscriptions(loadedSubscriptions)
    },
    startRevertCancelation (subscription, index) {
      subscription.subCounterIndex = index

      this.cancelSubscription = subscription
      this.modalCheckboxes.ExternalAllowed = false
      this.$bvModal.show('revertCancelationModal')
    },
    startCancelSubscription (subscription, index) {
      subscription.subCounterIndex = index

      subscription.cancelItemsRadios = {}
      subscription.countries.forEach((countryCode) => {
        subscription.cancelItemsRadios[countryCode] = {
          countryCode,
          value: 0
        }
      })

      this.hasCanceledItemSelected = false
      this.hasCanceledItemSelectedAll = false

      this.cancelSubscription = subscription

      this.modalCheckboxes.ExternalAllowed = false
      this.$bvModal.show('cancelModal')
    },
    handleSubscriptionUpgrade (subscription, index) {
      subscription.subCounterIndex = index
      this.upgradeSubscription = subscription

      this.modalCheckboxes = {
        ExternalAllowed: false,
        AGB: false
      }

      this.modalCheckboxes.ExternalAllowed = false
      this.$bvModal.show('upgradeModal')
    },
    handleSubscriptionUpgradeConfirmation () {
      if (!this.modalCheckboxes.AGB) {
        return this.$swal(
          this.$t('popupmessage.warn.missingdata'),
          this.$t('popupmessage.warn.readterms'),
          'warning'
        )
      }

      if (!this.modalCheckboxes.ExternalAllowed) {
        return this.$swal(
          this.$t('popupmessage.warn.missingdata'),
          this.$t('popupmessage.warn.bookingpermission'),
          'warning'
        )
      }

      const loader = this.app.startLoader()

      this.app.APIStripe._post('completeMembershipUpgrade', {
        SubscriptionID: this.upgradeSubscription.id
      })
        .then((response) => {
          this.$bvModal.hide('upgradeModal')
          this.upgradeSubscription = null
          this.$swal(
            this.$t('popupmessage.success.title'),
            this.$t('subscriptions.popups.success'),
            'success'
          )
          this.parseSubscriptions(response.body.Subscriptions)
          this.app.stopLoader(loader)
        })
        .catch(() => {
          this.$swal(
            this.$t('popupmessage.error.title'),
            this.$t('popupmessage.error.unknown'),
            'error'
          )
          this.app.stopLoader(loader)
        })
    }
  },
  watch: {
    'app.currentMembership': function () {
      this.loadSubscriptions()
    }
  },
  mounted: function () {
    this.loadSubscriptions()
  }
}
</script>

<style lang="less" scoped>
.mb-main-content .content-wrapper {
  background: white;
}
.content-wrapper {
  padding-left: 2rem;
  padding-top: 2rem;
  padding-right: 2rem;
}
ul {
  margin-top: 0.66rem;
  padding: 0;
}
li {
  list-style: none;
  margin-bottom: 0.66rem;
}
li img {
  width: 24px;
  vertical-align: top;
}
.flag {
  width: 20px;
  border-radius: 3px;
}
h5 {
  font-weight: bold;
}
.product-upgradeable,
.subscription-col {
  background-color: #f7f6f6;
}
.subscription-main-col {
  padding: 15px;
}
.bg-row {
  color: white;
  text-align: center;
  background: #f85022;
  background: linear-gradient(45deg, #f85022 35%, #582c5b 100%);
}
.upgrade-btn {
  max-width: 420px;
  outline: 0;
  border: 3px solid white;
  background-color: rgba(255, 255, 255, 0.77);
  color: #606060;
  text-transform: uppercase;
  padding: 0.33rem 1.33rem;
  font-size: 14px;
  border-radius: 5px;
}
.new-limit-input,
.new-limit-buttons button {
  border-radius: 0 !important;
}
.new-limit-input {
  max-width: 120px;
}
.new-limit-input-group {
  width: auto;
}
.badge {
  padding: 7px 14px;
}
.badge-warning {
  background-color: #f4eb49;
}
.badge-success {
  color: black;
  background-color: #92d36e;
  padding: 10px 20px;
}
.badge-corner {
  position: absolute;
  right: -12px;
  top: 0;
  transform: rotate(20deg);
}
.upgrade-row {
  position: relative;
  h5 strong {
    color: white;
  }
}
small {
  color: #a0a0a0;
}
hr {
  background: black;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.upgrade-cta {
  background-color: #54ad32;
}
.upgrade-cta:hover {
  color: white;
  background-color: #005578;
}
.cancel-dots .fa-close {
  color: red;
}
#cancelModal .btn.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
</style>
