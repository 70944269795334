<template>
  <div
    class="section-bottom d-flex gap-5 align-items-center justify-content-start"
  >
    <div class="d-flex flex-column gap-2">
      <MatchScoreWheel :percentage="computedPercentage" />
    </div>
    <div class="d-flex flex-column gap-2 feedback-col">
      <span>Feedback zum MatchScore</span>
      <div class="stars">
        <inline-svg
          :key="index"
          v-for="(star, index) in 5"
          :src="require('@/assets/images/icons/3.0/star.svg')"
          :class="{ filled: isFilled(index), active: isActive(index) }"
          @mouseover="setHoverIndex(index)"
          @mouseleave="clearHoverIndex"
          @click="setActiveIndex(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import MatchScoreWheel from './MatchScoreWheel'

export default {
  name: 'MatchScoreRate',
  components: {
    InlineSvg,
    MatchScoreWheel
  },
  props: {
    contact: {
      type: Object,
      required: true
    },
    currentRating: {
      type: Number,
      required: true,
      default: -1
    }
  },
  data () {
    return {
      currentStars: -1,
      hoverIndex: null,
      activeIndex: this.currentRating
    }
  },
  methods: {
    setHoverIndex (index) {
      this.hoverIndex = index
    },
    clearHoverIndex () {
      this.hoverIndex = null
    },
    setActiveIndex (index) {
      if (this.activeIndex === index) {
        this.activeIndex = null
        return
      }
      this.activeIndex = index
    },
    isFilled (index) {
      return this.hoverIndex !== null && index <= this.hoverIndex
    },
    isActive (index) {
      return this.activeIndex !== null && index <= this.activeIndex
    }
  },
  computed: {
    computedPercentage () {
      return this.contact.MatchScore
    }
  }
}

</script>

<style lang="scss" scoped>

.feedback-col {
  span {
    color: #3F4957;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}

.match-score-value {
  color: #3F4957;
  text-align: center;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  & > span:last-child {
    color: #91C124;
  }
}

.stars {
  display: flex;
  gap: .25rem;
}

.stars svg {
  width: 24px;
  height: 24px;
  fill: none; /* Default state */
  transition: fill 0.3s;
  stroke: #00719F;
  cursor: pointer;
}

.stars svg.filled {
  stroke: #F59E0B; /* Color when hovered */
}

.stars svg.active {
  stroke: #F59E0B; /* Color when active */
}

</style>
