<template>
  <div class="gauge">
    <div class="slice-colors">
      <div class="st slice-item" v-for="(color, index) in sliceColors" :key="index" :style="{ borderBottomColor: color }"></div>
    </div>
    <div class="needle" :style="needleStyle"></div>
    <div class="gauge-center">
      <span class="value">{{ percentage }}%</span>
      <span class="title">MatchScore</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Gauge',
  props: {
    percentage: {
      type: Number,
      required: true,
      validator (value) {
        return value >= 0 && value <= 100
      }
    }
  },
  data () {
    return {
      sliceColors: [
        {},
        {},
        {},
        {},
        {}
      ],
      rotation: 0
    }
  },
  watch: {
    percentage: {
      immediate: true,
      handler (newValue) {
        this.rotation = newValue * 1.8 // 0% -> 0deg, 100% -> 180deg
      }
    }
  },
  computed: {
    needleStyle () {
      return {
        transform: `rotate(${this.rotation}deg)`,
        transition: 'transform 1s ease-out'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$wrapper-card-bg: rgba(21, 34, 46, .25);
$gauge-shadow: 0 0 0 6px rgba(255, 255, 255, .09), 0 0 35px 5px rgba(255, 255, 255, .29);
$gauge-bg: #e7e7e7;
$gauge-common-color: #15222E;
$gauge-slice-default-color: #000;
$gauge-first-slice-color: #c23748;
$gauge-second-slice-color: #eabe35;
$gauge-third-slice-color: #5db1fb;
$gauge-fourth-slice-color: #9bd639;
$gauge-fifth-slice-color: #87bc30;
$color-white: #fff;

.gauge {
  font-family: Arial, Helvetica, sans-serif;
  background: $gauge-bg;
  box-shadow: $gauge-shadow;
  width: 200px;
  height: 100px;
  border-radius: 100px 100px 0 0;
  position: relative;
  overflow: hidden;

  /* safari fix */
  -webkit-transform-style: flat;
  -webkit-transform: translateZ(0px);

  &.min-scaled {
    transform: scale(.5);
  }
}

.gauge-center {
  content: '';
  color: $color-white;
  width: 60%;
  height: 60%;
  background: $gauge-common-color;
  border-radius: 100px 100px 0 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 -13px 15px -10px rgba(0, 0, 0, .28);
  right: 21%;
  bottom: 0;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  padding-top: 10px;
  line-height: 22px;

  .value {
    font-size: 20px;
    font-weight: bold;
  }

  .title {
    font-size: 16px;
  }

}

.needle {
  width: 78px;
  height: 7px;
  background: $gauge-common-color;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 100%;
  border-top-right-radius: 5px;
  position: absolute;
  bottom: 4px;
  left: 20px;
  transform-origin: 100% 4px;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, .38);
}

.slice-colors .st {
  position: absolute;
  bottom: 0;
  width: 80px;
  height: 0;
  border-bottom: 80px solid $gauge-slice-default-color;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;

  &.slice-item {
    &:nth-child(1) {
      border-bottom-color: $gauge-first-slice-color;
      left: -2px;
    }

    &:nth-child(2) {
      border-bottom-color: $gauge-second-slice-color;
      transform: rotate(135deg);
      transform-origin: right;
      top: 12px;
      left: -48px;
    }

    &:nth-child(3) {
      border-bottom-color: $gauge-third-slice-color;
      transform: rotate(179deg);
      transform-origin: right;
      top: -20px;
      left: -36px;
      border-bottom-width: 90px;
      border-left-width: 45px;
      border-right-width: 45px;
      width: 18px;
    }

    &:nth-child(4) {
      border-bottom-color: $gauge-fourth-slice-color;
      transform: rotate(219deg);
      transform-origin: right;
      top: -23px;
      left: 36px;
    }

    &:nth-child(5) {
      border-bottom-color: $gauge-fifth-slice-color;
      transform: rotate(240deg);
      transform-origin: right;
      right: 52px;
    }
  }
}
</style>
