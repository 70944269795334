<template>
  <FairFieldEditor title="Unternehmer-Gesucht.com: Beschreibungstext" :index="19"
    buttonSaveText="Korrekturwunsch absenden" :showContentOnEdit="true" :loading="isLoading" @save="handleSave"
    :isEditing="isEditing" :saveDisabled="!computedIsValid" :is-modal-open.sync="isModalOpen" @toggleEdit="toggleEdit"
    :previewDisabled="true" @toggle="toggleContent" :showOpenButton="false" :usePageStore="true">
    <div v-html="rpsbrand.BusinessConcept" />
    <template v-slot:edit>
      <hr />
      <div class="d-flex flex-column w-100 gap-3">
        <input type="text" class="form-control w-100" :placeholder="formFields.placeholder"
          v-model="formFields.value" />
      </div>
    </template>
    <template v-slot:editButtonCaption>
      Stimmt etwas nicht?
    </template>
  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import { useBrandStore } from '@/stores/brandStore'

export default {
  name: 'FairFormUGDescription',
  components: {
    FairFieldEditor
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      formFields: {
        placeholder: 'Tragen Sie hier Ihren neuen Vorschlag ein.',
        value: ''
      }
    }
  },
  methods: {
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Korrekturwünsche wurden erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Korrekturwünsche ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async submitForm () {
      await this.app.APIService._post('mb/fair/update/19', {
        brandId: this.currentBrand.BrandID,
        value: this.formFields.value
      })
    }
  },
  computed: {
    computedIsValid () {
      return this.formFields.value.length > 0
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand || {}
    },
    computedExpansionFormCaption () {
      if (this.currentBrand) {
        if (this.currentBrand.ConceptExpansionFormFranchise) return 'Franchise'
        if (this.currentBrand.ConceptExpansionFormLicense) return 'Lizenz'
      }
      return ''
    },
    rpsbrand: function () {
      return this.$root.getApp().rpsBrand || {}
    }
  }

}
</script>
