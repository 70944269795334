<template>
  <div>
    <div class="row mb-4">
      <div class="col">
        <p>{{ label }}</p>
      </div>
      <div class="col-4">
        <div class="d-flex justify-content-end gap-3">
          <input
            v-model="newYearInputValue"
            type="text"
            :placeholder="lastOperationUnitYearPlusOne"
            pattern="[0-9]{4}"
            class="newYearInput"
          >
          <button
            type="button"
            class="btn btn-success d-inline"
            @click.prevent="addEmptyOperationUnit"
          >
            <i class="fa fa-plus" /> {{ $t('page.yourdata.intpresence.addyear') }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-for="(item, index) in sortedOperationUnits"
      :key="index"
      class="row bg-row"
    >
      <div class="col-2 text-center form-group">
        <big>{{ item.Year }}</big>
      </div>
      <div class="col form-group">
        <label for="fld_ArticleTypeID">
          {{ $t('page.yourdata.intpresence.totalcompanies') }}
          <span
            v-if="totalQualifier.indexOf('International')!==-1"
            v-b-tooltip
            class="badge badge-warning"
            title="Gesamtes internationales Netzwerk inklusive Datenland"
          >?</span>
        </label>
        <div class="input-group save-group" :ref="'numberChangeInput0'+index">
          <input
            v-model="item[totalQualifier]"
            type="number"
            class="form-control"
            @change="handleNumberChange(item, index, 0)"
          >
          <i class="fa fa-spinner state-saving rotate"></i>
          <i class="fa fa-check state-done"></i>
        </div>
      </div>
      <div class="col form-group">
        <label for="fld_ArticleTypeID" v-t="'page.yourdata.intpresence.partnercompanies'"></label>

        <div class="input-group save-group" :ref="'numberChangeInput1'+index">
        <input
          v-model="item[childQualifier]"
          type="number"
          class="form-control"
          @change="handleNumberChange(item, index, 1)"
        >
          <i class="fa fa-spinner state-saving rotate"></i>
          <i class="fa fa-check state-done"></i>
        </div>
      </div>
      <div class="col-1 form-group d-flex align-items-center">
        <button
          type="button"
          class="btn btn-danger"
          @click.prevent="removeUnit(item)"
        >
          <i class="fa fa-trash" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OperationUnitForm',
  components: {},
  props: ['label', 'value', 'totalQualifier', 'childQualifier'],
  data: function () {
    return {
      newYearInputValue: new Date().getFullYear() + 1
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    },
    preloads: function () {
      return this.app.preloads || {}
    },
    currentOperationUnits: function () {
      return this.value || []
    },
    currentYear: function () {
      const d = new Date()
      const n = d.getFullYear()
      return n
    },
    sortedOperationUnits: function () {
      if (this.currentOperationUnits.length) {
        const arrayToSort = this.currentOperationUnits.slice(0)
        arrayToSort.sort((a, b) => {
          if (a.Year > b.Year) {
            return -1
          } else if (a.Year < b.Year) {
            return 1
          }
          return 0
        })
        return arrayToSort
      }

      return this.currentOperationUnits
    },
    lastOperationUnitYearPlusOne: function () {
      const operationUnits = this.currentOperationUnits || []
      let highestYear = 0
      if (operationUnits.length) {
        operationUnits.forEach(unit => {
          if (unit.Year > highestYear) {
            highestYear = unit.Year
          }
        })
      } else {
        highestYear = new Date().getFullYear()
        return highestYear
      }
      return parseInt(highestYear) + 1
    }
  },
  watch: {},
  methods: {
    addEmptyOperationUnit () {
      const newYear = parseInt(this.newYearInputValue)

      if (newYear > 0) {
        if (this.currentOperationUnits.length > 0) {
          if (
            this.currentOperationUnits.filter(unit => {
              return newYear === parseInt(unit.Year)
            }).length
          ) {
            return this.$swal(
              this.$t('popupmessage.warn.attentiontitle'),
              this.$t('popupmessage.warn.yearexists'),
              'warning'
            )
          }
        }

        const newYearData = {
          BrandOperationUnitsID: -1,
          BrandID: this.currentBrand.BrandID,
          Year: newYear
        }

        this.app.API._put('brandoperationunit/', newYearData).then(response => {
          if (
            response.status === 200 &&
            response.body.Result.Result === 'Succeeded'
          ) {
            newYearData.BrandOperationUnitsID = response.body.Result.NewID
            const newInputData = this.currentOperationUnits.slice(0)
            newInputData.push(newYearData)
            this.$emit('input', newInputData)
            this.newYearInputValue = ''
          }
        })
      } else {
        this.$swal(this.$t('popupmessage.warn.attentiontitle'), this.$t('popupmessage.warn.yearnumber'), 'warning')
      }
    },
    handleNumberChange (item, index, pos) {
      const changedInput = this.$refs['numberChangeInput' + pos + '' + index][0]
      changedInput.classList.add('saving')
      this.app.API._put('brandoperationunit/', item).then(() => {
        setTimeout(function () {
          changedInput.classList.remove('saving')
        }, 1000)
        setTimeout(function () {
          changedInput.classList.add('saved')
        }, 1600)
        setTimeout(function () {
          changedInput.classList.remove('saved')
        }, 2900)
      })
    },
    removeUnit (unitToRemove) {
      this.$swal({
        title: this.$t('popupmessage.warn.areyousuretitle'),
        text: this.$t('popupmessage.warn.yeardelete', { year: unitToRemove.Year }),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('button.yesdelete'),
        cancelButtonText: this.$t('button.no')
      }).then(result => {
        if (typeof result.value !== 'undefined' && result.value === true) {
          const deleteString =
            'BrandOperationUnit/' + unitToRemove.BrandOperationUnitsID
          this.app.API._delete(deleteString).then(() => {
            const newResult = this.currentOperationUnits.filter(unit => {
              return (
                unit.BrandOperationUnitsID !==
                unitToRemove.BrandOperationUnitsID
              )
            })
            this.$emit('input', newResult)
          })
        }
      })
    }
  }
}
</script>

<style scoped>
big {
  font-size: 3em;
}
.newYearInput {
  width: 100px;
  margin-right: 0.33rem;
}
.save-group .fa {
  position: absolute;
  right: 2rem;
  z-index: 100;
  top: 11px;
  transition: all 1s ease-in-out;
}
.state-done {
  color: green;
  opacity: 0;
}
.state-saving {
  color: orange;
  opacity: 0;
}

.save-group.saving .state-saving {
  opacity: 1;
}
.save-group.saved .state-done {
  opacity: 1;
}

.rotate{
  animation: rotate 1.5s linear infinite;
}
@keyframes rotate{
  to{ transform: rotate(360deg); }
}

</style>
