<template>
  <FairFieldEditor title="Weitere Videos (Im Infopaket)" :index="18" buttonSaveText="Änderungen speichern"
    :showContentOnEdit="false" :loading="isLoading" @save="handleSave" :isEditing="isEditing"
    :saveDisabled="!computedIsValid" :is-modal-open.sync="isModalOpen" @toggleEdit="toggleEdit" @toggle="toggleContent"
    :previewDisabled="true" :showOpenButton="false" :usePageStore="true" @update:isModalOpen="updateFormFields">

    <div class="row w-100">

      <div v-if="computedVideos.length === 0" class="d-flex align-items-center gap-3">
        <div class="empty-video bg-white flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
            <path d="M30.6663 9.93701L21.333 16.6037L30.6663 23.2703V9.93701Z" stroke="#64748B" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M18.6663 7.27051H3.99967C2.52692 7.27051 1.33301 8.46441 1.33301 9.93717V23.2705C1.33301 24.7433 2.52692 25.9372 3.99967 25.9372H18.6663C20.1391 25.9372 21.333 24.7433 21.333 23.2705V9.93717C21.333 8.46441 20.1391 7.27051 18.6663 7.27051Z"
              stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div class="d-flex flex-column gap-3">
          <strong>Keine Videos vorhanden.</strong>
        </div>
      </div>

      <template v-else>
        <div class="col-6 mb-4" v-for="fairVideo of computedVideos" :key="fairVideo.FairVideoID">

          <div class="d-flex gap-3 align-items-center">
            <VideoPreviewContainer :video="fairVideo" />
            <strong>{{ fairVideo.Medium.Title }}</strong>
          </div>

        </div>
      </template>
    </div>

    <template v-slot:edit>

      <div class="d-flex flex-column gap-2">
        <small>Sie können die Reihenfolge der Videos durch Drag & Drop ändern.</small>
        <draggable v-model="formFields.FairVideos" item-key="FairVideoID" class="image-grid row align-items-start"
          group="images" @end="updateSortPos" :move="onMove">
          <div class="col-3 image-col d-flex flex-column gap-2" v-for="fairVideo in formFields.FairVideos"
            :key="fairVideo.FairVideoID">
            <div class="fair-image fair-image-draggable">
              <VideoPreviewContainer :video="fairVideo" />
            </div>
            <span class="d-flex align-items-center justify-content-center fw-bold">{{ fairVideo.Medium.Title }}</span>
            <button class="btn btn-secondary" @click="removeVideo(fairVideo)">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                <path d="M2.375 4.27173H3.70833H14.375" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M5.70898 4.27177V2.93844C5.70898 2.58481 5.84946 2.24568 6.09951 1.99563C6.34956 1.74558 6.6887 1.6051 7.04232 1.6051H9.70898C10.0626 1.6051 10.4017 1.74558 10.6518 1.99563C10.9018 2.24568 11.0423 2.58481 11.0423 2.93844V4.27177M13.0423 4.27177V13.6051C13.0423 13.9587 12.9018 14.2979 12.6518 14.5479C12.4017 14.798 12.0626 14.9384 11.709 14.9384H5.04232C4.6887 14.9384 4.34956 14.798 4.09951 14.5479C3.84946 14.2979 3.70898 13.9587 3.70898 13.6051V4.27177H13.0423Z"
                  stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Entfernen
            </button>
          </div>
          <div class="col-3 image-col d-flex flex-column gap-2">
            <div class="fair-image d-flex align-items-center justify-content-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                <path d="M31.5413 9.33301L22.208 15.9997L31.5413 22.6663V9.33301Z" stroke="#A0ABBB" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M19.5413 6.66699H4.87467C3.40192 6.66699 2.20801 7.8609 2.20801 9.33366V22.667C2.20801 24.1398 3.40192 25.3337 4.87467 25.3337H19.5413C21.0141 25.3337 22.208 24.1398 22.208 22.667V9.33366C22.208 7.8609 21.0141 6.66699 19.5413 6.66699Z"
                  stroke="#A0ABBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <VideoUploadButton @newVideoSaved="handleNewVideoUpload">
              <template #buttonCaption>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path
                    d="M14.625 10.2717V12.9384C14.625 13.292 14.4845 13.6312 14.2345 13.8812C13.9844 14.1313 13.6453 14.2717 13.2917 14.2717H3.95833C3.60471 14.2717 3.26557 14.1313 3.01552 13.8812C2.76548 13.6312 2.625 13.292 2.625 12.9384V10.2717"
                    stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.9587 5.60506L8.62533 2.27173L5.29199 5.60506" stroke="#00719F" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8.625 2.27173V10.2717" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                <span>Video hinzufügen</span>
              </template>
            </VideoUploadButton>
          </div>
        </draggable>
      </div>

      <b-modal v-model="isRemoveFairVideoModalOpen" hide-footer hide-header size="sm">
        <div class="d-flex flex-column gap-3 p-3">
          <span class="blue-headline">Video wirklich löschen?</span>
          <div class="d-flex justify-content-between align-items-center gap-3">
            <button class="btn btn-secondary flex-grow-1" @click="handleRemoveFairVideo">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M2 4H3.33333H14" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M5.33301 4.00016V2.66683C5.33301 2.31321 5.47348 1.97407 5.72353 1.72402C5.97358 1.47397 6.31272 1.3335 6.66634 1.3335H9.33301C9.68663 1.3335 10.0258 1.47397 10.2758 1.72402C10.5259 1.97407 10.6663 2.31321 10.6663 2.66683V4.00016M12.6663 4.00016V13.3335C12.6663 13.6871 12.5259 14.0263 12.2758 14.2763C12.0258 14.5264 11.6866 14.6668 11.333 14.6668H4.66634C4.31272 14.6668 3.97358 14.5264 3.72353 14.2763C3.47348 14.0263 3.33301 13.6871 3.33301 13.3335V4.00016H12.6663Z"
                  stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <span>Ja, Video löschen</span>
            </button>
            <button class="btn btn-secondary flex-grow-1" @click="isRemoveFairVideoModalOpen = false">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path d="M12.5 4L4.5 12" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M4.5 4L12.5 12" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <span>Abbrechen</span>
            </button>
          </div>
        </div>
      </b-modal>

      <hr />

      <div class="d-flex flex-column w-100 gap-3">
        <div class="d-flex flex-column gap-1">
          <span class="fw-bold">Anforderungen:</span>
          <ul class="special">
            <li>Laden Sie weitere Videos hoch, die ein:e Interessent:in im Infopaket sehen soll</li>
            <li>Video-Testimonials von Partner:innen laden Sie bitte nur unter dem Punkt <strong>“Erfahrungen”</strong>
              hoch
            </li>
          </ul>
        </div>
      </div>
    </template>

    <template v-slot:editButtonCaption>
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-pencil"></i>
        <span>Bearbeiten</span>
      </div>
    </template>
  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import { useBrandStore } from '@/stores/brandStore'
import VideoPreviewContainer from './VideoPreviewContainer.vue'
import VideoUploadButton from './VideoUploadButton.vue'
import draggable from 'vuedraggable'

export default {
  name: 'FairFormVideos',
  components: {
    FairFieldEditor,
    VideoPreviewContainer,
    draggable,
    VideoUploadButton
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      isRemoveFairVideoModalOpen: false,
      fairVideoToDelete: null,
      formFields: {
        FairVideos: [],
        NewFairVideo: {
          Title: '',
          File: null
        }
      }
    }
  },
  methods: {
    uploadNewVideo () {
      this.formFields.NewFairVideo.File = null
    },
    updateFormFields () {
      this.formFields.FairVideos = [...this.computedVideos] ?? []
    },
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    onMove (event) {
      return typeof event.draggedContext.element !== 'undefined'
    },
    updateSortPos () {
      this.formFields.FairVideos.forEach((video, index) => {
        video.SortPos = index + 1
      })
    },
    removeVideo (fairVideo) {
      this.fairVideoToDelete = fairVideo
      this.isRemoveFairVideoModalOpen = true
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Änderungen erfolgreich gespeichert.')
        this.isModalOpen = false
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Änderungen ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async handleNewVideoUpload (newUploadedVideo) {
      try {
        this.isLoading = true
        const brandStore = useBrandStore()
        await brandStore.addUploadedFairVideo(newUploadedVideo)
        this.updateFormFields()
      } catch (error) {
        console.error('Error at Adding', error)
        this.$toast.error('Beim Hinzufügen des Videos ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async handleRemoveFairVideo () {
      this.isLoading = true
      try {
        const brandStore = useBrandStore()
        if (this.fairVideoToDelete) {
          const index = this.formFields.FairVideos.findIndex(
            (video) => video.FairVideoID === this.fairVideoToDelete.FairVideoID
          )
          if (index !== -1) {
            await brandStore.deleteFairVideo(this.fairVideoToDelete)
            brandStore.setCurrentFairUpdate({
              FairVideos: [
                ...this.currentFair.FairVideos.filter((video) => video.Type !== 3),
                ...this.formFields.FairVideos.filter((video) => video.FairVideoID !== this.fairVideoToDelete.FairVideoID)
              ]
            })
            this.updateFormFields()
          }
        }
        this.isRemoveFairVideoModalOpen = false
      } catch (error) {
        console.error('Error at Removing', error)
        this.$toast.error('Beim Löschen des Videos ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async submitForm () {
      const brandStore = useBrandStore()
      await Promise.all(
        this.formFields.FairVideos
          .filter((video) => video.Type === 3)
          .map(async (FairVideo) => {
            return await brandStore.fpApi._put('FairVideo', {
              FairVideoID: FairVideo.FairVideoID,
              SortPos: FairVideo.SortPos
            })
          })
      )

      const currentFairVideos = [...this.currentFair.FairVideos] ?? []
      const formFairVideos = this.formFields.FairVideos
      const fairVideosToDelete = currentFairVideos
        .filter(currentFairVideo => {
          return currentFairVideo.Type === 3
        })
        .filter(
          (currentFairVideo) => {
            return !formFairVideos.some(
              (formFairVideo) =>
                formFairVideo.FairVideoID === currentFairVideo.FairVideoID
            )
          }
        )

      await Promise.all(
        fairVideosToDelete.map(async (FairVideo) => {
          return await brandStore.fpApi._delete(`FairVideo/${FairVideo.FairVideoID}`)
        })
      )

      brandStore.setCurrentFairUpdate({
        FairVideos: [
          ...this.currentFair.FairVideos.filter((video) => video.Type !== 3),
          ...this.formFields.FairVideos
        ]
      })
    }
  },
  computed: {
    computedIsValid () {
      return true
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand
    },
    currentFair: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentFair
    },
    computedExpansionFormCaption () {
      if (this.currentBrand) {
        if (this.currentBrand.ConceptExpansionFormFranchise) return 'Franchise'
        if (this.currentBrand.ConceptExpansionFormLicense) return 'Lizenz'
      }
      return ''
    },
    computedVideos () {
      const FairVideos = this.currentFair.FairVideos ?? []
      return FairVideos.filter((video) => video.Type === 3)
    }
  }

}
</script>

<style lang="scss" scoped>
.empty-video {
  display: flex;
  width: 360px;
  height: 203.272px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #F7F8F9;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  /* Adjust to balance out the margin on the individual columns */
  margin-right: -8px;

  .image-col {
    width: calc(25% - 16px);
    /* Ensure that four items fit in one row, minus the gap */
    margin: 8px;
    /* To create a gap of 16px both vertically and horizontally */
    border-radius: 8px;
  }
}

.fair-image {
  height: 120px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  &.fair-image-draggable {
    cursor: move;
  }

}

.image-col {
  padding: 8px;
  background-color: #EFF1F4;
}
</style>
