<template>
  <div class="mb-login">
    <div class="mb-login-top">
      <h1>FranchiseUNIVERSUM</h1>
      <h2 v-t="'page.passwordreset.memberarea'"></h2>
    </div>

    <form @submit.prevent="resetPassword">
      <p
        class="text-white text-center"
        v-t="'page.passwordreset.description'"
      ></p>
      <input
        v-model="formHash"
        type="hidden"
        :disabled="loading"
      >
      <div class="form-group">
        <div class="input-group mb-3">
          <span class="icon-kontakte" />
          <input
            v-model="newPassword"
            type="password"
            class="form-control ps-5"
            :placeholder="$t('page.passwordreset.newpwd')"
            required
          >
        </div>
        <div class="input-group">
          <span class="icon-kontakte" />
          <input
            v-model="newPasswordRepeat"
            type="password"
            class="form-control ps-5"
            :placeholder="$t('page.passwordreset.repeatpwd')"
            required
          >
        </div>
      </div>
      <input
        v-model="formHash"
        type="hidden"
        :disabled="loading"
      >
      <p
        v-show="errorMessage"
        class="error-message text-center"
      >
        {{ errorMessage }}
      </p>
      <button
        ref="doLoginButton"
        type="submit"
        class="btn btn-primary"
        data-style="zoom-in"
        v-t="'page.passwordreset.confirmpwd'"
      ></button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import Config from '../config'
import * as Ladda from 'ladda'
export default {
  name: 'PasswordReset',
  components: {},
  props: [],
  data: function () {
    return {
      formHash: false,
      newPassword: '',
      newPasswordRepeat: '',
      errorMessage: '',
      loading: false
    }
  },
  computed: {
    currentAppVersion: function () {
      return Config.CLIENT_VERSION
    },
    isLoggedIn: function () {
      return this.$parent.isLoggedIn
    },
    app: function () {
      return this.$parent || {}
    }
  },
  watch: {
    loading: function (newValue) {
      if (newValue === true) {
        this.startLoader()
      } else {
        this.stopLoader()
      }
    }
  },
  mounted: function () {
    this.$refs.doLoginButton.loader = Ladda.create(this.$refs.doLoginButton)
    this.formHash = this.$route.params.id
    console.info(this.formHash)
  },
  methods: {
    startLoader () {
      if (
        typeof this.$refs.doLoginButton !== 'undefined' &&
        typeof this.$refs.doLoginButton.loader !== 'undefined'
      ) {
        this.$refs.doLoginButton.loader.start()
      }
    },
    stopLoader () {
      if (
        typeof this.$refs.doLoginButton !== 'undefined' &&
        typeof this.$refs.doLoginButton.loader !== 'undefined'
      ) {
        this.$refs.doLoginButton.loader.stop()
      }
    },
    resetPassword () {
      this.loading = true
      this.errorMessage = ''

      if (this.newPassword.trim().length < 6) {
        this.loading = false
        this.errorMessage = this.$t('page.passwordreset.minlength')
        return
      }

      if (this.newPassword.trim() !== this.newPasswordRepeat.trim()) {
        this.loading = false
        this.errorMessage = this.$t('page.passwordreset.repeaterror')
        return
      }

      const newResetRequest = {
        userIdHash: this.formHash,
        newPassword: this.newPassword
      }

      axios({
        url: Config.PWRESET_FUNCTION + 'reset.php',
        method: 'post',
        data: newResetRequest
      })
        .then(() => {
          this.$swal(
            this.$t('popupmessage.success.title'),
            this.$t('popupmessage.success.passwordchanged'),
            'success'
          )
          this.newPassword = ''
          this.newPasswordRepeat = ''
          this.loading = false
          this.$router.push('/login').catch(() => {})
        })
        .catch(reason => {
          console.error(reason)
          this.loading = false
          this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.unknown'), 'error')
        })
    }
  }
}
</script>

<style scoped>
.error-message {
  color: red;
}

.btn {
  margin-bottom: 120px;
}
</style>
