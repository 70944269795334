<template>
  <div class="w-100">
    <button class="btn btn-secondary w-100" @click="handleUploadButtonClick">
      <slot name="buttonCaption"></slot>
    </button>
    <b-modal v-model="isModalOpen" no-close-on-backdrop>

      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false" />

      <template v-slot:modal-title>
        Video Upload
      </template>

      <template v-slot:modal-footer>
        <button class="btn btn-secondary" @click="handleCancel" :disabled="getCurrentUploadState !== 'idle'">
          Abbrechen
        </button>
        <button class="btn btn-primary" @click="uploadVideo" :disabled="!computedIsVideoUploadReady">Hochladen</button>
      </template>

      <div class="quote-box d-flex flex-row align-items-center p-2 gap-3">
        <div class="empty-video flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
            <path d="M30.6663 9.93701L21.333 16.6037L30.6663 23.2703V9.93701Z" stroke="#64748B" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M18.6663 7.27051H3.99967C2.52692 7.27051 1.33301 8.46441 1.33301 9.93717V23.2705C1.33301 24.7433 2.52692 25.9372 3.99967 25.9372H18.6663C20.1391 25.9372 21.333 24.7433 21.333 23.2705V9.93717C21.333 8.46441 20.1391 7.27051 18.6663 7.27051Z"
              stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>

        <div class="d-flex flex-column gap-3 flex-grow-1">

          <div class="d-flex flex-column gap-2">
            <span class="blue-headline">Titel des Videos:</span>
            <input type="text" class="form-control" v-model="newVideoTitle" />
          </div>

          <div v-if="getCurrentVideoIsUploading" class="upload-state d-flex flex-column gap-2">
            <div class="d-flex align-items-center justify-content-between gap-2">
              <span>{{ getCurrentUploadStateCaption }}</span>
            </div>
            <b-progress v-if="getCurrentUploadState === 'uploading'" :value="computedCurrentVideoUploadProgress" :max="100" :striped="true" height="2rem" show-progress animated></b-progress>
            <b-skeleton v-else animation="wave" width="100%" height="2rem"></b-skeleton>
          </div>
          <div v-else class="d-flex flex-column">
            <span class="blue-headline">Video-Datei auswählen:</span>
            <div class="d-flex align-items-center justify-content-between">
              <input ref="videoInputField" type="file" @change="handleFileSelect" />
            </div>
          </div>

        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { useBrandStore } from '@/stores/brandStore'
import { usePageStore } from '@/stores/pageStore'
import Loading from 'vue-loading-overlay'

export default {
  name: 'VideoUploadButton',
  components: {
    Loading
  },
  emits: ['newVideoSaved'],
  data: function () {
    return {
      isModalOpen: false,
      newVideoTitle: '',
      newVideoFile: null,
      isLoading: false,
      isUploading: false,
      progressInPercent: 0
    }
  },
  methods: {
    handleUploadButtonClick () {
      const pageStore = usePageStore()
      pageStore.endVideoUpload()
      this.isModalOpen = true
    },
    handleCancel () {
      this.isModalOpen = false
    },
    handleFileSelect (event) {
      this.newVideoFile = event.target.files[0]
    },
    async uploadVideo () {
      const brandStore = useBrandStore()
      const pageStore = usePageStore()
      pageStore.startVideoUpload(this.newVideoFile.size)

      if (!this.newVideoFile) {
        this.$toast.error('Bitte wählen Sie eine Datei aus, bevor Sie hochladen.')
        return
      }
      try {
        this.isLoading = true

        pageStore.startVideoUpload(this.newVideoFile.size)
        const uploadedFairVideo = await brandStore.uploadVideo({
          Title: this.newVideoTitle,
          File: this.newVideoFile
        }, 'SystemRoot/Fair/FairVideos')

        if (!uploadedFairVideo) {
          throw new Error('Error uploading Video')
        }

        this.$emit('newVideoSaved', uploadedFairVideo)
      } catch (error) {
        console.error('Error uploading video', error)
        this.$toast.error('Beim Hochladen des Videos ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
        this.isUploading = false
        this.isModalOpen = false
        this.newVideoTitle = ''
        this.newVideoFile = null
        if (this.$refs.videoInputField) {
          this.$refs.videoInputField.value = ''
        }
      }
    }
  },
  computed: {
    computedIsVideoUploadReady () {
      return this.newVideoTitle.length > 0 && this.newVideoFile !== null && this.getCurrentUploadState === 'idle'
    },
    getCurrentVideoIsUploading () {
      const pageStore = usePageStore()
      return pageStore.getCurrentVideoIsUploading
    },
    getCurrentVideoUploadState () {
      const pageStore = usePageStore()
      return pageStore.getCurrentVideoUploadState
    },
    getCurrentUploadState () {
      const pageStore = usePageStore()
      return pageStore.getCurrentUploadState
    },
    computedCurrentVideoUploadProgress () {
      const pageStore = usePageStore()
      return pageStore.getCurrentVideoUploadProgress
    },
    computedCurrentVideoUploadProgressCaption () {
      return `${this.computedCurrentVideoUploadProgress}%`
    },
    getCurrentUploadStateCaption () {
      switch (this.getCurrentUploadState) {
        case 'uploading':
          return 'Video wird in die Cloud geladen...'
        case 'transcoding':
          return 'Video wird auf Hochglanz poliert...'
        case 'generatingThumbnail':
          return 'Vorschaubild wird erstellt...'
        case 'idle':
          return 'Warte auf Upload...'
        default:
          return 'Warte auf Upload...'
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.video-preview-container {
  width: 360px;
  height: 203px;
  overflow: hidden;
  position: relative;
  border-radius: 12px;

  .btn {
    width: auto;
    position: absolute;
    bottom: 12px;
    right: 12px;
  }

  >svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}

.empty-video {
  display: flex;
  width: 360px;
  height: 203.272px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #F7F8F9;
}

.upload-state {
  background-color: #F7F8F9;
  padding: 8px;
}

.upload-action-cancel {
  width: 24px;
  height: 24px;
  background-color: #FCE1DB;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}
</style>
