<template>
  <div />
</template>

<script>
export default {
  name: 'Logout',
  props: [],
  data: function () {
    return {}
  },
  computed: {},
  watch: {
    $route (to) {
      if (to.path === '/logout') {
        this.logout()
      }
    }
  },
  mounted: function () {
    if (this.$route.path === '/logout') {
      this.logout()
    }
  },
  methods: {
    logout () {
      this.$parent.doLogout()
    }
  }
}
</script>
