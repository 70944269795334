<template>
  <div>
      <section class="mb-nl-pos">
          <div class="container">
            <div class="mb-nl-pos-item">
                <div class="row">
                    <div class="col-lg-4">
                        <h4 v-html="$t('page.newsletter.standalone')"></h4>
                        <ul class="mb-frst-lst">
                            <li><i class="fa fa-check-circle"></i><span v-html="$t('page.newsletter.exclusivyourcontent')"></span></li>
                            <li><i class="fa fa-check-circle"></i><span v-t="'page.newsletter.responsive'"></span></li>
                            <li><i class="fa fa-check-circle"></i><span v-t="'page.newsletter.manyinterested'"></span></li>
                        </ul>
                    </div>
                    <div class="col-lg-4">
                        <strong v-t="'page.newsletter.standaloneincludes'"></strong>
                        <ul>
                            <li><i class="fa fa-arrow-right"></i><span v-t="'page.newsletter.manypictures'"></span></li>
                            <li><i class="fa fa-arrow-right"></i><span v-t="'page.newsletter.yourtext'"></span></li>
                            <li><i class="fa fa-arrow-right"></i><span v-t="'page.newsletter.yourcolors'"></span></li>
                            <li><i class="fa fa-arrow-right"></i><span v-t="'page.newsletter.ctabutton'"></span></li>
                        </ul>
                    </div>
                    <div class="col-lg-4 text-center">
                        <img :src="require('../../assets/images/nl/STA-Beispiel.webp')">
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <ul>
                            <li style="padding-left: 40px;" class="d-flex align-items-center"><i style="color: #005578" class="fa fa-info-circle"></i><span v-t="'page.newsletter.standalonebookinginfo'"></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
          </div>
      </section>
      <section class="mb-nl-faq">
          <div class="container">
              <h2 v-t="'page.newsletter.faq.title'"></h2>

                    <b-card no-body class="mb-acc">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block href="javascript:" v-b-toggle.accordion-4 variant="info">{{ $t('page.newsletter.faq.standalonetitle') }}<i class="icon-pfeilrunter"></i></b-button>
                    </b-card-header>
                    <b-collapse id="accordion-4" accordion="nl-accordion" role="tabpanel">
                        <b-card-body>
                        <b-card-text>
                            <h4 v-t="'page.newsletter.faq.standalonetext1'"></h4>
                            <p v-html="$t('page.newsletter.faq.standalonetext2')"></p>
                            <h4 v-t="'page.newsletter.faq.standalonetext4'"></h4>
                            <p v-t="'page.newsletter.faq.standalonetext5'"></p>
                            <p><strong v-t="'page.newsletter.faq.texttitle'"></strong></p>
                            <ul v-html="$t('page.newsletter.faq.standalonetextinfo')"></ul>
                            <p><strong v-t="'page.newsletter.faq.pictureinftitle'"></strong></p>
                            <ul v-html="$t('page.newsletter.faq.standalonepictureinfo')"></ul>
                        </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
          </div>
      </section>
  </div>
</template>

<script>
export default {
  name: 'NewsletterShowcase',
  props: [],
  data: function () {
    return {}
  },
  computed: {
  },
  methods: {
  },
  mounted: function () {
  }
}
</script>

<style lang="less" scoped>
.mb-nl-pos {
    background-color: #f3f3f3;
    padding-bottom: 30px;

    h2 {
        text-align: center;
        font-weight: 800;
    }
    .mb-nl-pos-item {
        background-color: #fff;
        padding: 30px 20px 10px 20px;
        margin: 0;

        h4 {
            padding-bottom: 30px;
            border-bottom: 1px solid #000;
        }

        ul {
            padding-left: 0px;
            list-style: none;
            margin-top: 30px;

            li {
                position: relative;
                padding-left: 30px;
                font-size: 14px;
                margin-bottom: 20px;

                i {
                    position: absolute;
                    left: 0px;
                    font-size: 30px;
                    color: #72bb53;

                    &.fa-arrow-right {
                        font-size: 20px;
                        left: 0px;
                        color: #005578;
                    }
                }
            }
        }

        ul.mb-frst-lst {
            li {
                padding-left: 40px;
            }
        }

        img {
            max-height: 300px;
            max-width: 322px;
        }
    }
}

.mb-sk-stoerer {
    background: linear-gradient(180deg, #f3f3f3 calc(100% - 45px), rgba(255,255,255,1) 45px); ;

    .row {
        align-items: center;
    }

    img {
        width: 300px;
    }
}

.mb-nl-faq {
    background-color: #fff;
    padding-bottom: 40px;
    padding-top: 40px;

    h2 {
        color: #005578;
        font-size: 30px;
        text-align: center;
        font-weight: 800;
        padding-bottom: 30px;
    }

    .card {
        background-color: #454b51;
        border-radius: 0;
        color: #fff;
        margin-bottom: 20px;

        .card-text {
            h4 {
                font-weight: 700;
                font-size: 20px;
            }

            img {
                width: 100%;
                max-width: 650px;
                margin-bottom: 20px;
            }
        }
    }
    .btn {
        background-color: transparent;
        border: none;
        border-radius: 0;
        box-shadow: none;
        color: #58c1dc;
        text-align: left;
        display: flex;
        justify-content: space-between;
        font-weight: 400;

        &.not-collapsed {
            i::after {
                content: '\e625';
            }
        }
    }

    a {
        color: #58c1dc;
    }
}
</style>
