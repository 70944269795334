import { render, staticRenderFns } from "./ChatDrawer.vue?vue&type=template&id=7791a5c2&scoped=true"
import script from "./ChatDrawer.vue?vue&type=script&lang=js"
export * from "./ChatDrawer.vue?vue&type=script&lang=js"
import style0 from "./ChatDrawer.vue?vue&type=style&index=0&id=7791a5c2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7791a5c2",
  null
  
)

export default component.exports