import { defineStore } from 'pinia'
import Api from '../modules/Api'
import ApiStripe from '../modules/Api_Stripe'
import { useBrandStore } from './brandStore'
import { usePageStore } from './pageStore'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      currentContact: null,
      userReady: false,
      credits: {
        history: [],
        loaded: false
      },
      brandStore: useBrandStore(),
      pageStore: usePageStore(),
      fpApi: new Api(),
      fpApiStripe: new ApiStripe()
    }
  },
  getters: {
    getToken () {
      const loginFromStorage = localStorage.getItem('FPMB_Login')
      const login = loginFromStorage ? JSON.parse(loginFromStorage) : {}
      if (login && login.token) {
        return login.token
      } else {
        return ''
      }
    },
    getAuthHeader () {
      return {
        Authorization: `Bearer ${this.getToken}`
      }
    },
    getCurrentContactID () {
      if (this.currentContact === null) return null
      return this.currentContact.ContactID
    },
    getCurrentContact () {
      return this.currentContact
    },
    getIsUserReady () {
      return this.userReady === true
    },
    getCreditHistory () {
      return this.credits.history
    },
    getCurrentUsername () {
      return this.currentContact.Mail
    },
    getCreditAmount (state) {
      return state.credits.history.reduce((total, current) => {
        if (current.Direction === 'H') {
          return total + current.Amount
        }
        if (current.Direction === 'S') {
          return total - current.Amount
        }
        return total
      }, 0)
    }
  },
  actions: {
    setCurrentContact (contact) {
      this.currentContact = contact
      this.userReady = true
    },
    setIsUserReady (value) {
      this.userReady = value
    },
    logout () {
      this.setCurrentContact(null)
      this.setIsUserReady(false)
    },
    async purchaseCredits (amount) {
      const purchaseCreditRequest = {
        BrandID: this.brandStore.getCurrentBrandID,
        ContactID: this.getCurrentContactID,
        Amount: amount
      }
      const purchaseCreditResponse = await this.fpApiStripe._post('purchaseCredits', purchaseCreditRequest)
      const purchasedCredits = purchaseCreditResponse.body
      this.credits.history.push(purchasedCredits)
    },
    async loadCreditHistory () {
      if (this.credits.loaded) {
        return
      }

      try {
        this.pageStore.startLoading('loadCreditHistory')
        const creditHistoryResponse = await this.fpApi._post('CreditTransactions', {
          ResFrom: 1,
          ResTo: 1000,
          FullSubEntities: false,
          Filter: [
            {
              Field: 'BrandID',
              Operator: 'EQ',
              Value: this.brandStore.getCurrentBrandID
            }
          ]
        })

        if (creditHistoryResponse.body.Errors.length === 0 && creditHistoryResponse.body.Result.Error === 0) {
          this.credits.history = creditHistoryResponse.body.Result.Data
          this.credits.loaded = true
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.pageStore.stopLoading('loadCreditHistory')
      }
    },
    async handleLogin (username) {
    }
  }
})
