import Vue from 'vue'
import Config from '../config/index'
import axios from 'axios'

export default class ErrorReport extends Vue {
  sendReport (apiToken, error, currentBrand, contact, data) {
    const headers = {
      Authorization: apiToken
    }

    const postData = {
      app: 'MB',
      brand: { brandID: currentBrand.BrandID, name: currentBrand.Name },
      contact: { contactID: contact.ContactID, firstname: contact.Firstname, lastname: contact.Lastname, mail: contact.Mail },
      errorid: Vue.Utils.uuidv4(),
      error,
      data,
      url: window.location.href,
      browser: navigator.userAgent,
      callstack: Error().stack
    }

    console.info(postData, JSON.stringify(postData, null, 2))
    const route = Config.FPDE_API_URL + 'email/error'

    axios({
      headers,
      url: route,
      data: postData,
      method: 'POST'
    }).then(response => {
    }).catch()
    return postData.errorid
  }
}
