<template>
  <a :href="computedLeadItemLink" @click="openModal">
    <div class="card">
      <div class="card-title">
        <span v-if="0">Im Kontakt</span>
        <span v-if="0">Match: {{ computedMatchScore }}</span>
      </div>

      <div class="card-body">
        <div class="lead-details">
          <div class="person-details d-flex justify-content-start align-items-center mb-2">
            <div class="me-3 lead-portrait">
              <img :src="computedPortrait" />
            </div>
            <div class="d-flex flex-column">
              <span class="mb-1 person-name">
                {{ computedName }}
              </span>
              <div class="contact-details d-flex">
                <span class="me-3" v-if="computedIsMailValidated">
                  <inline-svg width="16" height="16" :src="require('@/assets/images/icons/3.0/verified.svg')" />
                  Email
                </span>
                <span v-if="computedIsPhoneValidated">
                  <inline-svg width="16" height="16" :src="require('@/assets/images/icons/3.0/verified.svg')" />
                  Telefon
                </span>
              </div>
            </div>
          </div>

          <div v-if="computedShowLocationInfo" class="location-details d-flex align-items-center">
            <span class="me-1">
              <inline-svg width="16" height="16" :src="require('@/assets/images/icons/3.0/map-marker.svg')" />
            </span>
            <span>
              {{ computedZip }}
              {{ currentLead.LocationCity }}
              <span v-if="currentLead.Upgrade && currentLead.Upgrade.Radius">+ {{ currentLead.Upgrade.Radius }}km</span>
            </span>
          </div>

          <hr />

          <div class="business-details">
            <div class="mb-2" v-if="computedHasCV">
              <strong>Lebenslauf:</strong>
              <span>
                Ja, zum Download
              </span>
            </div>
            <div class="mb-2 position-relative" v-if="computedHasSpecialGroups">
              <strong>Interesse:</strong>
              <span>
                {{ computedFirstSpecialGroup }}</span>
              <span v-if="computedHasMultipleSpecialGroups">
                (<a href="javascript:" v-b-tooltip.hover :title="computedRestSpecialGroups">+{{
                  computedMultipleSpecialGroupsAmount }} weitere</a>)
              </span>
            </div>
            <div class="mb-2" v-if="computedHasEquitySet">
              <strong>Kapital:</strong>
              <span v-if="computedEquityFrom && computedEquityTo">
                {{ computedEquityFrom }} € - {{ computedEquityTo }} €
              </span>
              <span v-if="computedEquityFrom && !computedEquityTo">
                ab {{ computedEquityFrom }} €
              </span>
              <span v-if="!computedEquityFrom && computedEquityTo">
                bis {{ computedEquityTo }} €
              </span>
            </div>
            <div class="mb-2" v-if="computedHasProfession">
              <strong>Gruppe:</strong>
              <span>
                {{ computedProfession }}
              </span>
            </div>
            <div class="mb-2" v-if="currentLead.Contact.IndependentProfession !== null">
              <strong>Führung:</strong>
              <span>
                {{ currentLead.Contact.IndependentProfession }}
              </span>
            </div>
            <div class="mb-2" v-if="hasFoundingWill">
              <strong>Gründungswille:</strong>
              <span> {{ computedFoundingWill }}/10 </span>
            </div>
            <div class="mb-2" v-if="computedHasProfession">
              <strong>Selbstständig:</strong>
              <span> {{ computedProfessionIsSelfEmployed }} </span>
            </div>
            <div class="mb-2" v-if="computedHasReachability">
              <strong>Erreichbarkeit:</strong>
              <span> {{ computedReachability }} </span>
            </div>
          </div>
        </div>
        <div class="lead-actions">
          <div class="d-flex justify-content-end">
            <span v-if="0" class="d-flex align-items-center">
              <inline-svg class="me-1" width="16" height="16"
                :src="require('@/assets/images/icons/3.0/chat-small-blue.svg')" />
              {{ chatStore.getMessageCountByContactID(currentLead.ContactID) }}
            </span>

            <span class="d-flex align-items-center">
              <inline-svg class="me-1" width="16" height="16"
                :src="require('@/assets/images/icons/3.0/profile-eye.svg')" />
              Profil
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="source-details">
      <span>Quelle: {{ computedSourceModule }}</span>
      <span>{{ computedCreatedDate }}</span>
    </div>

    <b-modal hide-footer :id="computedModalId" :ref="computedModalId" modal-class="lead-modal"
      :title="computedModalTitle" @hide="handleModalHide">
      <template #modal-header="{ close }">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <h5>{{ computedModalTitle }}</h5>
          <a href="javascript:" @click="close">
            <inline-svg class="stroke-black" width="24" height="24"
              :src="require('@/assets/images/icons/3.0/close.svg')" />
          </a>
        </div>
      </template>

      <div class="row section-bottom gap-5">
        <div class="col">
          <div class="d-flex justify-content-between section-bottom">
            <div class="lead-modal-contact-image">
              <img :src="computedPortrait" />
            </div>

            <div class="d-flex flex-column flex-grow-1">
              <span class="state-bar"></span>
              <h2>{{ computedName }}</h2>
              <div class="contact-details d-flex">
                <span class="me-3" v-if="computedIsMailValidated">
                  <inline-svg width="16" height="16" :src="require('@/assets/images/icons/3.0/verified.svg')" />
                  Email verifiziert
                </span>
                <span v-if="computedIsPhoneValidated">
                  <inline-svg width="16" height="16" :src="require('@/assets/images/icons/3.0/verified.svg')" />
                  Telefonnummer verifiziert
                </span>
              </div>
            </div>
          </div>

          <div class="section-bottom">
            <h4>Kontaktdaten</h4>

            <div v-if="hasValidMail" class="d-flex justify-content-between contact-detail-row mb-3">
              <span>Email</span>
              <span>{{ currentLead.Contact.Mail }}</span>
            </div>

            <div v-if="hasValidPhone" class="d-flex justify-content-between contact-detail-row mb-3">
              <span>Telefon</span>
              <span>{{ currentLead.Contact.Phone }}</span>
            </div>

            <div v-if="hasValidReachable" class="d-flex justify-content-between contact-detail-row mb-3">
              <span>Erreichbarkeit</span>
              <span>{{ currentLead.Contact.Reachable }}</span>
            </div>

            <div v-if="hasValidComputedAddress" class="d-flex justify-content-between contact-detail-row mb-3">
              <span>Anschrift</span>
              <span>{{ computedAddress }}</span>
            </div>
          </div>

          <contact-properties-view :lead="currentLead" :contactProperties="computedContactProperties" :contactPropertyRatings="aiFeatures.ratings" @rated="handleRatedProperty" @deleted="handleRatedPropertyDelete" />
        </div>
        <div v-if="computedShowLocationInfo" class="col">
          <div class="section-bottom">
            <h4>Meine Wunschregion</h4>

            <div class="row">
              <div class="col-12 mb-3">
                <div id="googleMap" class="google-map-container"></div>
              </div>
              <div class="col-12">
                <div class="d-flex align-items-center">
                  <inline-svg class="me-2 map-marker" width="16" height="16"
                    :src="require('@/assets/images/icons/3.0/map-marker.svg')" />
                  <span>
                    {{ computedZip }}
                    {{ currentLead.LocationCity }},
                    {{ currentLead.LocationState }} +
                    {{ currentLead.Upgrade.Radius }}km Umkreis
                  </span>
                </div>
              </div>
            </div>
          </div>

          <MatchScoreRate v-if="computedMatchScore" :contact="currentContact" :currentRating="aiFeatures.matchScoreRating" />

          <template v-if="false">
            <div v-if="!(aiFeatures.isLoading === false && !aiFeatures.generatedDescription)" class="section-bottom" v-show="false">
              <h4 class="d-flex align-items-center gap-2">
                <img src="@/assets/images/icons/3.0/ai.png" width="32" height="32" />
                KI-Analyse
              </h4>
              <div class="ai-description">
                <div class="loader" v-if="aiFeatures.isLoading">
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </div>
                <p v-if="aiFeatures.generatedDescription">{{ computedAiDescription }}</p>
                <p v-if="aiFeatures.isLoading === false && !aiFeatures.generatedDescription">KI-Analyse kann für diesen Lead
                  leider
                  nicht bereitgestellt werden.</p>
              </div>
            </div>
          </template>

          <lead-item-functions :lead="currentLead" :computedModalId="computedModalId" />

        </div>
        <div class="col" v-if="0">
          <ChatModule :lead="currentLead" />
        </div>
      </div>

      <contact-notes :lead="currentLead" />

      <TalentpoolSimilarContacts v-if="0" :contact="currentContact" />
    </b-modal>
  </a>
</template>

<script>
import Config from '@/config'
import InlineSvg from 'vue-inline-svg'
import moment from 'moment'
import ChatModule from './ChatModule.vue'
import $ from 'jquery'
import { useChatStore } from '@/stores/chatStore'
import { useBrandStore } from '@/stores/brandStore'
import ContactPropertiesView from './ContactPropertiesView.vue'
import LeadItemFunctions from './LeadItemFunctions.vue'
import TalentpoolSimilarContacts from './TalentpoolSimilarContacts.vue'
import MatchScoreRate from './MatchScoreRate.vue'
import ContactNotes from './ContactNotes.vue'

export default {
  components: {
    InlineSvg,
    ChatModule,
    ContactPropertiesView,
    LeadItemFunctions,
    ContactNotes,
    TalentpoolSimilarContacts,
    MatchScoreRate
  },
  name: 'LeadItem',
  props: ['currentLead'],
  inject: ['API', 'GoogleMap'],
  data: function () {
    return {
      chatStore: useChatStore(),
      brandStore: useBrandStore(),
      aiFeatures: {
        isLoading: true,
        generatedDescription: undefined,
        ratings: [],
        matchScoreRating: undefined
      },
      isModalOpen: false
    }
  },
  computed: {
    computedShowLocationInfo () {
      return this.currentLead.LocationZip !== null || this.currentLead.LocationCity !== null
    },
    computedLeadItemLink () {
      return `/leads/detail/${this.currentLead.LeadID}`
    },
    computedHasCV () {
      return typeof this.currentLead.Upgrade.CV !== 'undefined'
    },
    computedProfession () {
      switch (this.currentLead.Contact.Profession) {
        case 0:
          return 'Manager*in / Führungskraft'
        case 1:
          return 'Angestellt'
        case 2:
          return 'Studierend/ in Ausbildung'
        case 3:
          return 'Arbeitssuchend'
        case 4:
          return 'Unternehmer*in / Selbstständig'
        case -1:
          return 'Keine Angabe'
        default:
          return null
      }
    },
    computedHasProfession () {
      return this.currentLead.Contact.Profession !== null
    },
    computedProfessionIsSelfEmployed () {
      return this.currentLead.Contact.Profession === 4 ? 'Ja' : 'Nein'
    },
    computedHasReachability () {
      return this.currentLead.Contact.Reachable !== null
    },
    computedReachability () {
      return String(this.currentLead.Contact.Reachable)
    },
    computedFirstSpecialGroup () {
      return this.currentLead.Contact.SpecialGroupCaptions[0]
    },
    computedRestSpecialGroups () {
      return this.currentLead.Contact.SpecialGroupCaptions.slice(1).join(', ')
    },
    computedHasSpecialGroups () {
      return this.currentLead.Contact.SpecialGroupCaptions.length > 0
    },
    computedHasMultipleSpecialGroups () {
      return this.currentLead.Contact.SpecialGroupCaptions.length > 1
    },
    computedMultipleSpecialGroupsAmount () {
      return this.currentLead.Contact.SpecialGroupCaptions.length - 1
    },
    computedZip () {
      if (this.currentLead.LocationZip === null) {
        return ''
      }
      const zip = this.currentLead.LocationZip
      const hiddenZip = zip.substring(0, zip.length - 2) + '...'
      return `D-${hiddenZip}`
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentContact: function () {
      return this.currentLead.Contact
    },
    computedAiDescription: function () {
      if (this.aiFeatures.generatedDescription === undefined) {
        return ''
      }
      return this.aiFeatures.generatedDescription
    },
    computedIsMailValidated: function () {
      return this.currentLead.Contact.EmailValidated
    },
    computedIsPhoneValidated: function () {
      return this.currentLead.Contact.PhoneValidated
    },
    computedMatchScore () {
      return this.currentLead.Contact.MatchScore || 0
    },
    computedCreatedDate () {
      return moment(this.currentLead.Created).format('DD.MM.YYYY')
    },
    computedPortrait () {
      if (this.currentLead.Contact.PortraitID !== null) {
        return `${Config.MEDIA_API_URL}id/${this.currentLead.Contact.Portrait.FileID}`
      }

      return require('@/assets/images/user-none.png')
    },
    computedSourceModule () {
      /*
        SourceModule
        Chatbot.AT
        Chatbot.CH
        Chatbot.DE
        FranchiseKOMPARATOR.at
        FranchiseKOMPARATOR.ch
        FranchiseKOMPARATOR.de
        FranchiseKOMPARATOR.ug
        FranchisePORTAL.at
        FranchisePORTAL.ch
        FranchisePORTAL.de
        UG.comKomparator
        UnternehmerGesucht.com
      */

      switch (this.currentLead.SourceModule) {
        case 'Chatbot.AT':
          return 'Chatbot AT'
        case 'Chatbot.CH':
          return 'Chatbot CH'
        case 'Chatbot.DE':
          return 'Chatbot DE'
        case 'FranchiseKOMPARATOR.at':
          return 'KOMPARATOR.at'
        case 'FranchiseKOMPARATOR.ch':
          return 'KOMPARATOR.ch'
        case 'FranchiseKOMPARATOR.de':
          return 'KOMPARATOR.de'
        case 'FranchiseKOMPARATOR.ug':
          return 'KOMPARATOR.ug'
        case 'FranchisePORTAL.at':
          return 'FP.at'
        case 'FranchisePORTAL.ch':
          return 'FP.ch'
        case 'FranchisePORTAL.de':
          return 'FP.de'
        case 'UG.comKomparator':
          return 'UG.comKomparator'
        case 'UnternehmerGesucht.com':
          return 'UG.com'
        default:
          return 'Unbekannt'
      }
    },
    computedSourceModuleFull () {
      switch (this.currentLead.SourceModule) {
        case 'Chatbot.AT':
          return 'Chatbot Österreich'
        case 'Chatbot.CH':
          return 'Chatbot Schweiz'
        case 'Chatbot.DE':
          return 'Chatbot Deutschland'
        case 'FranchiseKOMPARATOR.at':
          return 'KOMPARATOR.at'
        case 'FranchiseKOMPARATOR.ch':
          return 'KOMPARATOR.ch'
        case 'FranchiseKOMPARATOR.de':
          return 'KOMPARATOR.de'
        case 'FranchiseKOMPARATOR.ug':
          return 'KOMPARATOR.ug'
        case 'FranchisePORTAL.at':
          return 'FranchisePORTAL.at'
        case 'FranchisePORTAL.ch':
          return 'FranchisePORTAL.ch'
        case 'FranchisePORTAL.de':
          return 'FranchisePORTAL.de'
        case 'UG.comKomparator':
          return 'UG.comKomparator'
        case 'UnternehmerGesucht.com':
          return 'UnternehmerGesucht.com'
        default:
          return 'Unbekannt'
      }
    },
    computedName () {
      return `${this.currentLead.Contact.Firstname} ${this.currentLead.Contact.Lastname}`
    },
    computedModalId () {
      return `lead-modal-${this.currentLead.LeadID}`
    },
    computedModalTitle () {
      // example: Quelle: FranchisePORTAL.de | 23.07.2022
      return `Quelle: ${this.computedSourceModuleFull} | ${this.computedCreatedDate}`
    },
    hasValidComputedAddress () {
      return (
        this.currentLead.Contact.Street !== null && this.currentLead.Contact.Zip !== null && this.currentLead.Contact.City !== null &&
        this.currentLead.Contact.Street !== '' && this.currentLead.Contact.Zip !== '' && this.currentLead.Contact.City !== ''
      )
    },
    hasValidReachable () {
      return this.currentLead.Contact.Reachable !== null && this.currentLead.Contact.Reachable !== ''
    },
    hasValidPhone () {
      return this.currentLead.Contact.Phone !== null && this.currentLead.Contact.Phone !== ''
    },
    hasValidMail () {
      return this.currentLead.Contact.Mail !== null && this.currentLead.Contact.Mail !== ''
    },
    computedAddress () {
      return `${this.currentLead.Contact.Street}, ${this.currentLead.Contact.Zip} ${this.currentLead.Contact.City}`
    },
    computedToastId () {
      return `toast-${this.currentLead.LeadID}`
    },
    computedHasEquitySet () {
      return (
        this.currentLead.Contact.EquityFrom !== null ||
        this.currentLead.Contact.EquityTo !== null
      )
    },
    computedEquityFrom () {
      if (this.currentLead.Contact.EquityFrom === null) {
        return null
      }
      return this.currentLead.Contact.EquityFrom.toLocaleString('de-DE')
    },
    computedEquityTo () {
      if (this.currentLead.Contact.EquityTo === null) {
        return null
      }
      return this.currentLead.Contact.EquityTo.toLocaleString('de-DE')
    },
    hasFoundingWill () {
      return typeof this.currentContact.ContactProperties.FoundingWill !== 'undefined'
    },
    computedFoundingWill () {
      return this.currentContact.ContactProperties.FoundingWill
    },
    computedContactProperties () {
      return this.currentContact.ContactProperties
    }
  },
  created: function () { },
  methods: {
    handleRatedPropertyDelete (ContactPropertyRatingID) {
      const index = this.aiFeatures.ratings.findIndex(rating => rating.ContactPropertyRatingID === ContactPropertyRatingID)
      if (index !== -1) {
        this.aiFeatures.ratings.splice(index, 1)
      }
    },
    handleRatedProperty (data) {
      if (typeof data.ContactPropertyRatingID !== 'undefined') {
        const alreadyExistentIndex = this.aiFeatures.ratings.findIndex(rating => rating.ContactPropertyRatingID === data.ContactPropertyRatingID)
        if (alreadyExistentIndex !== -1) {
          this.aiFeatures.ratings[alreadyExistentIndex].IsGood = data.IsGood
        } else {
          this.aiFeatures.ratings.push(data)
        }
      }
    },
    removePrintCss () {
      if (this.printCssLink) {
        document.head.removeChild(this.printCssLink)
        this.printCssLink = null
      }
    },
    loadAiFeatures: async function () {
      try {
        this.aiFeatures.generatedDescription = null
        const result = await this.app.APIService._post('talentpool/leads/ai/detail', {
          contactId: this.currentLead.ContactID,
          brandId: this.brandStore.getCurrentBrandID
        })
        this.aiFeatures.generatedDescription = result.body.text
      } catch (error) {
        console.error(error)
      } finally {
        this.aiFeatures.isLoading = false
      }
    },
    loadAiRatings: async function () {
      try {
        const result = await this.app.APIService._post('talentpool/leads/ai/ratings', {
          contactId: this.currentLead.ContactID,
          brandId: this.brandStore.getCurrentBrandID
        })
        this.aiFeatures.ratings = result.body.ratings
        this.aiFeatures.matchScoreRating = result.body.matchScoreRating
      } catch (error) {
        console.error(error)
      }
    },
    openModal: async function (event) {
      if (event) {
        event.preventDefault()
      }

      this.$bvModal.show(this.computedModalId)
      this.initMap(window.google)
      this.isModalOpen = true

      const body = document.querySelector('body')
      body.classList.add('printing-lead')

      // TODO when enable ChatModule
      setTimeout(() => {
        const chatMessages = $('.chat-messages')[0]
        if (chatMessages) {
          $('.chat-messages').scrollTop(chatMessages.scrollHeight + 100)
        }
      }, 100)

      const newPath = `/leads/detail/${this.currentLead.LeadID}`
      if (this.$route.path.startsWith(newPath)) {
        return
      }

      this.$router.push(newPath)

      this.loadAiFeatures()
      this.loadAiRatings()
    },
    handleModalHide () {
      if (this.$route.path.startsWith('/leads/detail/')) {
        this.$router.push('/leads')
      }
      this.isModalOpen = false

      const body = document.querySelector('body')
      if (body) {
        body.classList.remove('printing-lead')
      }

      const printSection = document.getElementById('printSection')
      if (printSection && typeof printSection.innerHTML !== 'undefined') {
        printSection.innerHTML = ''
      }
    },
    getZoomForRadius (radius) {
      const equatorLength = 40075004 // in meters
      const widthInPixels = document.getElementById('googleMap').offsetWidth
      let metersPerPixel = equatorLength / 256
      let zoomLevel = 1
      while (metersPerPixel * widthInPixels > radius * 2) {
        metersPerPixel /= 2
        ++zoomLevel
      }
      return zoomLevel
    },
    initMap (google) {
      this.GoogleMap.geocoder = new google.maps.Geocoder()
      const searchAddress = `${this.currentLead.LocationZip}, ${this.currentLead.LocationCity}, ${this.currentLead.LocationState}`
      this.geocodeAddress(searchAddress)
    },
    geocodeAddress (address) {
      this.GoogleMap.geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          const latLng = results[0].geometry.location

          const center = { lat: latLng.lat(), lng: latLng.lng() } // These are just placeholder values

          this.GoogleMap.map = new window.google.maps.Map(
            document.getElementById('googleMap'),
            {
              zoom: 9,
              center
            }
          )

          if (this.currentLead.Upgrade && this.currentLead.Upgrade.Radius) {
            const calculatedRadius = this.currentLead.Upgrade.Radius * 1000
            this.GoogleMap.circle = new window.google.maps.Circle({
              strokeColor: '#005578',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#005578',
              fillOpacity: 0.35,
              map: this.GoogleMap.map,
              center,
              radius: calculatedRadius // 10 km
            })

            if (calculatedRadius <= 20000) {
              this.GoogleMap.map.setZoom(10)
            }

            if (calculatedRadius >= 50000) {
              this.GoogleMap.map.setZoom(8)
            }

            if (calculatedRadius >= 75000) {
              this.GoogleMap.map.setZoom(7)
            }
            // this.googleMap.circle.setCenter(latLng)
            // this.googleMap.map.setZoom(this.getZoomForRadius(this.currentLead.Upgrade.Radius * 1000))
          }

          // this.googleMap.map.setCenter(latLng)
        } else {
          console.error(
            `Geocode was not successful for the following reason: ${status}`
          )
        }
      })
    }
  },
  mounted () {
    if (typeof this.$route.params.id !== 'undefined') {
      const leadId = parseInt(this.$route.params.id)
      if (leadId === this.currentLead.LeadID) {
        this.openModal()
      }
    }
  },
  watch: {
    $route (to, from) {
      if (typeof to.params.id !== 'undefined') {
        const leadId = parseInt(to.params.id)
        if (leadId === this.currentLead.LeadID) {
          this.openModal()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 296px;
  font-size: 14px;
  font-family: DM Sans;

  .card-title {
    display: flex;
    padding: 4px 16px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    background: linear-gradient(90deg, #00638b 0%, #00719f 100%);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    margin-bottom: 0;

    span {
      color: white;
      text-transform: none;

      font-style: normal;
      font-weight: 700;
      line-height: 20px;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .card-body {
    a, span.d-flex {
      color: #00719f;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration: none;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 16px;
    height: 372px;

    .person-details {
      .lead-portrait {
        width: 44px;
        height: 44px;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }

    .person-name {
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }

    .contact-details {
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    hr {
      color: #d0d5dd;
      opacity: 1;
    }

    .business-details {

      height: 200px;
      max-height: 200px;
      overflow: hidden;

      >div {
        strong {
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }

        span {
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
}

.source-details {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #64748b;

  margin-top: 8px;

  padding: 0 16px;
}

.lead-modal {
  .modal-header {
    padding: 0;
  }

  #googleMap {
    width: 100%;
    height: 360px;
  }
}

</style>
