<template>
  <div class="mb-login">
    <div class="mb-login-top">
      <h1 v-html="$t('page.login.title')"></h1>
    </div>

    <div class="mb-login-top" v-if="isMaintenance">
      <h2 v-t="'page.login.maintenance'"></h2>
      <p v-html="$t('page.login.maintenancetext')"></p>
    </div>
    <div class="mb-login-error" v-if="loginError">
      <div class="mb-login-err-msg" v-html="loginErrorMessage"></div>
      <div v-if="loginExists" class="mb-login-exists">
        <span class="icon-login"></span>
        <span v-t="'page.login.pleaselogin'"></span>
      </div>
    </div>
    <form v-if="!isMaintenance" @submit.prevent="onClickLoginButton">
      <div class="form-container">
        <div class="form-group">
          <label for="loginUsername" v-t="'page.login.mail'"></label>
          <input
            v-model="username"
            type="text"
            class="form-control"
            :class="username.trim() === '' || loginError ? usrErrorClass : ''"
            :placeholder="$t('page.login.mail')"
            id="loginUsername"
          />
        </div>
        <div v-if="!isPasswordResetForm" class="form-group">
          <label
            for="exampleInputPassword1"
            v-t="'page.login.password'"
          ></label>
          <input
            id="exampleInputPassword1"
            v-model="password"
            type="password"
            class="form-control"
            :class="password.trim() === '' || loginError ? pwdErrorClass : ''"
            :placeholder="$t('page.login.password')"
          />
        </div>
        <button
          type="submit"
          class="btn btn-primary"
          data-style="zoom-in"
          v-t="'page.login.login'"
        ></button>
      </div>
      <div class="text-center">
        <a href="javascript:" class="text-white" @click.prevent="startPasswordReset">
          <small>{{ $t("page.login.passwordforgot") }}</small>
        </a>
      </div>
    </form>
    <div class="mb-login-register">
      <label v-t="'page.login.noaccount'"></label>
      <router-link to="/register" class="btn btn-outline-light">
        {{ $t("page.login.register") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Config from '../config'
import isEmail from 'validator/lib/isEmail'
import { usePageStore } from '@/stores/pageStore'

export default {
  name: 'Login',
  components: {},
  props: [],
  data: function () {
    return {
      username: '',
      password: '',
      isPasswordResetForm: false,
      isMaintenance: false,
      loginError: false,
      loginErrorMessage: '',
      loginExists: false,
      fieldErrorClass: 'mb-field-error',
      pwdErrorClass: '',
      usrErrorClass: '',
      pageStore: usePageStore()
    }
  },
  computed: {
    currentAppVersion: function () {
      return Config.CLIENT_VERSION
    },
    isLoggedIn: function () {
      return this.$parent.isLoggedIn
    },
    app: function () {
      return this.$parent || {}
    }
  },
  watch: {
    $route (to, from) {
      if (this.$route.params.loginError != null) {
        this.loginError = this.$route.params.loginError
        this.loginExists = this.$route.params.loginExists
        this.username = this.$route.params.email
        if (this.loginExists) {
          this.loginErrorMessage = this.$t('page.login.erroruserexists', {
            username: this.username
          })
        }
      } else {
        if (to.path === '/login') this.resetLoginForm()
      }
    }
  },
  mounted: function () {
    const login = localStorage.getItem('FPMB_Login')
    if (login !== null) {
      this.$router.push('/markenauswahl')
    }
    this.needMoreTime()
  },
  methods: {
    needMoreTime () {
      if (this.$route.query.mrtm == null) return
      this.app.API.needMoreTime(this.$route.query.mrtm).then((result) => {
        if (
          result == null ||
          result.status == null ||
          result.status === 'Failed' ||
          result.status === 'Error'
        ) {
          let reason = result.message
          if (result.message === 'no data') { reason = this.$t('page.login.moretime.failedreasonnodata') }
          if (result.message === 'brand not found') { reason = this.$t('page.login.moretime.failedreasonnobrand') }
          if (result.message === 'basic data already filled') { reason = this.$t('page.login.moretime.failedreasonalreadyfilled') }
          if (result.message === 'email already scheduled') {
            reason = this.$t(
              'page.login.moretime.failedreasonalreadyscheduled'
            )
          }
          if (result.status === 'Error' || reason === '') { reason = this.$t('popupmessage.error.unknown') }

          this.$toast(this.$t('page.login.moretime.failed') + reason, {
            position: 'top-center',
            showCloseButtonOnHover: false,
            timeout: 10000,
            icon: false
          })
        } else if (result.status === 'OK') {
          this.$toast(this.$t('page.login.moretime.success'), {
            position: 'top-center',
            showCloseButtonOnHover: false,
            timeout: 20000,
            icon: false
          })
        }
      })
    },
    resetLoginForm () {
      this.username = ''
      this.password = ''
      this.loginErrorMessage = ''
      this.loginError = false
      this.loginExists = false
    },
    startPasswordReset () {
      this.$swal({
        input: 'email',
        title: this.$t('page.login.resetpassword.title'),
        html: this.$t('page.login.resetpassword.description'),
        icon: 'question',
        confirmButtonText: this.$t('page.login.resetpassword.confirm'),
        inputPlaceholder: this.$t('page.login.resetpassword.inputplaceholder'),
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
        validationMessage: this.$t('validation.novalidmail'),
        reverseButtons: true
      }).then((input) => {
        if (typeof input.value !== 'undefined' && input.isConfirmed === true) {
          this.pageStore.startLoading('startPasswordReset')

          const username = input.value
          if (username !== null && isEmail(username)) {
            const formData = new FormData()
            formData.append('username', username)

            axios({
              url: Config.PWRESET_FUNCTION + 'request.php',
              method: 'post',
              data: formData
            })
              .then(() => {
                const resetExplanation = [
                  this.$t('page.login.resetpassword.confirmmessage1', {
                    username
                  }),
                  this.$t('page.login.resetpassword.confirmmessage2'),
                  this.$t('page.login.resetpassword.confirmmessage3')
                ]
                  .map((line) => {
                    return `<p>${line}</p>`
                  })
                  .join('\r\n')

                this.$swal({
                  title: this.$t('page.login.resetpassword.title'),
                  html: resetExplanation,
                  icon: 'success'
                })
              })
              .finally(() => {
                this.resetLoginForm()
                this.pageStore.stopLoading('startPasswordReset')
              })
          } else {
            this.$swal(
              this.$t('popupmessage.error.title'),
              this.$t('popupmessage.error.pleaseentervalidmail'),
              'warning'
            )
            this.pageStore.stopLoading('startPasswordReset')
          }
        }
      })
    },
    onClickLoginButton: function () {
      const usr = this.username.trim()
      const pwd = this.password.trim()
      if (usr === '' || pwd === '') {
        if (usr === '') this.usrErrorClass = this.fieldErrorClass
        if (pwd === '') this.pwdErrorClass = this.fieldErrorClass
        return
      }

      this.pageStore.startLoading('onClickLogin')

      const loginData = {
        username: String(usr),
        password: String(pwd)
      }

      this.loginError = false
      this.loginErrorMessage = ''
      this.loginExists = false

      this.app
        .doLogin(loginData, this.$route.params.id)
        .then(() => {
          this.resetLoginForm()
          const encryptedBrandIdFromLocalStorage = localStorage.getItem('FPMB_NewFairScreenNext')
          if (encryptedBrandIdFromLocalStorage) {
            const newUrl = '/neuer-messestand-2024/' + encryptedBrandIdFromLocalStorage
            this.$router.push(newUrl)
          }
        })
        .catch(() => {
          this.loginError = true
          this.loginErrorMessage = this.$t('page.login.loginerror')
          if (this.loginError) {
            this.usrErrorClass = this.fieldErrorClass
            this.pwdErrorClass = this.fieldErrorClass
          }
          this.showLoginError()
        })
        .finally(() => {
          this.pageStore.stopLoading('onClickLogin')
        })
    },
    showLoginError () {
      this.password = ''
      this.pageStore.stopLoading()
    }
  }
}
</script>

<style lang="scss" scoped>
.mb-login {
  .mb-login-top {
    text-align: center;
    color: white;
    padding-top: 80px;
    margin-bottom: 80px;

    h1 {
      font-size: 30px;
      font-weight: 800;
    }

    h2 {
      font-size: 30px;
      font-weight: 300;
    }
  }

  > form {
    > .form-group {
      margin-bottom: 30px;
    }

    .btn {
      width: 100%;
      margin-bottom: 10px;
    }

    a {
      color: white;
      text-align: center;
    }
  }
}
.clientVersionHint {
  font-size: 10px;
  color: #fff;
}
</style>

<style lang="less">
body.mb-login {
  .Vue-Toastification__toast-body {
    text-align: center;
  }
}
</style>
