<template>
  <div>
    <div class="mb-main-content">
        <h1 v-t="'page.yourdata.title'"></h1>
        <a
          :href="websiteUrl + 'infopaket/preview/' + app.maskedBrandID()"
          class="btn btn-primary"
          target="_blank"
          ><i class="icon-lupe"></i> {{ $t("button.preview") }}</a
        >

      <div class="container mb-menue-content mb-menue-infopaket mt-5 p-0">
        <div id="list-example" class="list-group shadow-sm">
          <a
            v-scroll-to="'#konzept'"
            data-scrollto="#konzept"
            class="list-group-item list-group-item-action"
            href="javascript:"
            v-t="'page.yourdata.menue.concept'"
          ></a>
          <a
            v-scroll-to="'#konditionen'"
            data-scrollto="#konditionen"
            class="list-group-item list-group-item-action"
            href="javascript:"
            v-t="'page.yourdata.menue.conditions'"
          ></a>
          <a
            v-scroll-to="'#intpraesenz'"
            data-scrollto="#intpraesenz"
            class="list-group-item list-group-item-action"
            href="javascript:"
            v-t="'page.yourdata.menue.intpresence'"
          ></a>
          <a
            v-scroll-to="'#natpraesenz'"
            data-scrollto="#natpraesenz"
            class="list-group-item list-group-item-action"
            href="javascript:"
            v-t="'page.yourdata.menue.natpresence'"
          ></a>
          <a
            v-scroll-to="'#leistungen'"
            data-scrollto="#leistungen"
            class="list-group-item list-group-item-action"
            href="javascript:"
            v-t="'page.yourdata.menue.services'"
          ></a>
          <a
            v-scroll-to="'#taetigkeit'"
            data-scrollto="#taetigkeit"
            class="list-group-item list-group-item-action"
            href="javascript:"
            v-t="'page.yourdata.menue.partneractivity'"
          ></a>
          <a
            v-scroll-to="'#broschuere'"
            data-scrollto="#broschuere"
            class="list-group-item list-group-item-action"
            href="javascript:"
            v-t="'page.yourdata.menue.companybrochure'"
          ></a>
        </div>
      </div>

      <BrandProfileDataConecept />
      <BrandProfileDataConditions />
      <BrandProfileDataInternational />
      <BrandProfileDataNational />
      <BrandProfileDataServices />
      <BrandProfileDataApplications />
      <BrandProfileDataDocument />
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

import Vue from 'vue'
import BrandProfileDataConecept from './Infopaket/1_Concept'
import BrandProfileDataConditions from './Infopaket/2_Conditions'
import BrandProfileDataInternational from './Infopaket/3_International'
import BrandProfileDataNational from './Infopaket/4_National'
import BrandProfileDataServices from './Infopaket/5_Services'
import BrandProfileDataApplications from './Infopaket/6_Applications'
import BrandProfileDataDocument from './Infopaket/7_Document'
import Config from '@/config'

export default {
  name: 'Profile',
  components: {
    BrandProfileDataConecept,
    BrandProfileDataConditions,
    BrandProfileDataInternational,
    BrandProfileDataNational,
    BrandProfileDataServices,
    BrandProfileDataApplications,
    BrandProfileDataDocument
  },
  props: [],
  data: function () {
    return {
      scrollEventLoaded: false,
      websiteUrl: Vue.Utils.trim(Config.WEBSITE_URL, '/') + '/'
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      return this.$parent.currentBrand || {}
    },
    preloads: function () {
      return this.$parent.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    }
  },
  mounted: function () {
    this.loadScrollEvents()
  },
  methods: {
    loadScrollEvents () {
      if (this.scrollEventLoaded === true) return

      $(window).scroll(() => {
        if (this.$route) {
          if (this.$route.path === '/profil') {
            const elementInView = $('.mb-main-content div.container[id]').filter(
              (index, elem) => {
                const docViewTop = $(window).scrollTop()
                const elemTop = $(elem).offset().top
                return elemTop + 200 >= docViewTop
              }
            )

            if (elementInView.length) {
              $('[data-scrollto]').removeClass('active')
              const elementId = '#' + $(elementInView).attr('id')
              $('.list-group')
                .find("[data-scrollto='" + elementId + "']")
                .addClass('active')
            }
          }
        }
      })
      this.scrollEventLoaded = true
    }
  }
}
</script>
