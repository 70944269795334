<template>
  <div class="video-preview-container flex-shrink-0">
    <VideoPreviewImageContainer v-if="isReady" :video.sync="video.Medium" />
    <a href="javascript:" rel="noopener noreferrer" @click="openModal">
      <svg width="45" height="47" viewBox="0 0 45 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="45" height="47" rx="8" fill="#00719F" />
        <path d="M37 23.5L15.25 37.7894L15.25 9.21058L37 23.5Z" fill="white" />
      </svg>
    </a>
    <b-modal v-if="video" v-model="isModalOpen" title="Video" size="lg" dialog-class="modal-100w" hide-footer hide-header>
      <div class="responsive-video-container">
        <iframe
          class="sproutvideo-player"
          :src="computedVideoLink"
          frameborder="0"
          allowfullscreen
          referrerpolicy="no-referrer-when-downgrade"
          title="Video Player">
        </iframe>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { useBrandStore } from '@/stores/brandStore'
import VideoPreviewImageContainer from './VideoPreviewImageContainer.vue'

export default {
  name: 'VideoPreviewContainer',
  components: {
    VideoPreviewImageContainer
  },
  props: ['video'],
  data: function () {
    return {
      isModalOpen: false
    }
  },
  methods: {
    openModal () {
      this.isModalOpen = true
    },
    async loadMediumIfNeeded () {
      // Check if Medium needs to be loaded
      if (!this.video.Medium && this.video.MediumID) {
        try {
          const retrievedMedium = await this.API._post('Medium', {
            FullSubEntities: true,
            MediumID: this.video.MediumID
          })
          this.$set(this.video, 'Medium', retrievedMedium.body.Result.Data) // Ensure reactivity
        } catch (error) {
          console.error('Error loading Medium:', error)
        }
      }
    }
  },
  computed: {
    computedFrameStyles () {
      return {
        width: '100%',
        height: '100%',
        border: 'none'
      }
    },
    isReady () {
      return typeof this.video !== 'undefined' && (typeof this.video.Medium !== 'undefined' || typeof this.video.MediumID !== 'undefined')
    },
    computedVideoLink: function () {
      if (!this.isReady) {
        return ''
      }
      const brandStore = useBrandStore()
      return brandStore.getVideoLink(this.video.Medium)
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    API: function () {
      return this.app.API || {}
    }
  },
  watch: {
    video: {
      handler: function (newValue, oldValue) {
        // When `video` prop changes, check if data needs to be loaded
        if (newValue && newValue.MediumID && !newValue.Medium) {
          this.loadMediumIfNeeded()
        }
      },
      immediate: true, // Run the handler immediately when the component is mounted
      deep: true // Ensure changes within `video` are also watched
    }
  },
  async mounted () {
    if (!this.isReady) {
      await this.loadMediumIfNeeded()
    }
  }
}
</script>

<style lang="scss" scoped>
.video-preview-container {
  width: 200px;
  height: 122px;
  overflow: hidden;
  position: relative;
  border-radius: 12px;

  .btn {
    width: auto;
    position: absolute;
    bottom: 12px;
    right: 12px;
  }

  a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      transition: transform ease-in-out 0.2s;
      /* Transition for scaling in both directions */
    }

    &:hover {
      svg {
        transform: scale(1.1);
        /* Scale up on hover */
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}

.modal-100w {
  max-width: 100% !important;
}

.responsive-video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (height / width = 9 / 16 = 0.5625 or 56.25%) */
  height: 0;
  overflow: hidden;
  border-radius: 8px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
