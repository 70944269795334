<template>
  <div>
    <hr class="margin-64" />

    <div class="row">
      <div class="col-12">
        <h4>Ähnliche Profile im Talentpool</h4>
      </div>
      <div v-if="!isLoading" class="col-12">
        <div class="overview">
          <AnonymousContactCard v-for="currentContact in recommendedContacts" :key="currentContact.ContactID"
            :currentContact="currentContact" />
        </div>
      </div>
      <div v-else class="col-12">
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </div>
      <div v-if="!isLoading" class="col-12">

      </div>
    </div>
  </div>
</template>

<script>
import AnonymousContactCard from '@/components/3.0/AnonymousContactCard.vue'
export default {
  name: 'TalentpoolSimilarContacts',
  inject: ['currentBrand', 'GoogleMap', 'APIService'],
  components: {
    AnonymousContactCard
  },
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      recommendedContacts: [],
      isLoading: false
    }
  },
  methods: {},
  async mounted () {
    if (this.isLoading) return
    try {
      this.isLoading = true
      const recommendationsRequest = await this.APIService._post('talentpool/leads/ai/recommendations', {
        contactId: this.contact.ContactID
      })
      this.recommendedContacts = recommendationsRequest.body
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
hr {
  &.margin-64 {
    margin: 64px 0;
  }
}
.overview {
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
}

</style>
