<template>
  <FairFieldEditor :title="computedTitle" :index="13" buttonSaveText="Änderungen speichern"
    :showContentOnEdit="false" :loading="isLoading" @save="handleSave" :isEditing="isEditing"
    :saveDisabled="!computedIsValid" :is-modal-open.sync="isModalOpen" @toggleEdit="toggleEdit"
    @update:isModalOpen="updateFormFields" @toggle="toggleContent" :showOpenButton="false" :usePageStore="true">

    <div v-if="computedConecptVideo" class="d-flex align-items-center gap-3">
      <VideoPreviewLink :video="computedConecptVideo.Medium" />
      <div class="d-flex flex-column">
        <strong>Video:</strong>
        <span>{{ computedConecptVideo.Medium.Title }}</span>
      </div>
    </div>

    <div v-else class="d-flex align-items-center gap-3">
      <div class="empty-video bg-white flex-shrink-0">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
          <path d="M30.6663 9.93701L21.333 16.6037L30.6663 23.2703V9.93701Z" stroke="#64748B" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M18.6663 7.27051H3.99967C2.52692 7.27051 1.33301 8.46441 1.33301 9.93717V23.2705C1.33301 24.7433 2.52692 25.9372 3.99967 25.9372H18.6663C20.1391 25.9372 21.333 24.7433 21.333 23.2705V9.93717C21.333 8.46441 20.1391 7.27051 18.6663 7.27051Z"
            stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div class="d-flex flex-column gap-3">
        <strong>Noch kein Video ausgewählt!</strong>
      </div>
    </div>

    <template v-slot:edit>

      <div class="quote-box d-flex flex-row align-items-center p-2 gap-3">

        <template v-if="formState.isVideoForm">

          <template v-if="formState.isNew">

            <div class="empty-video flex-shrink-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                <path d="M30.6663 9.93701L21.333 16.6037L30.6663 23.2703V9.93701Z" stroke="#64748B" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M18.6663 7.27051H3.99967C2.52692 7.27051 1.33301 8.46441 1.33301 9.93717V23.2705C1.33301 24.7433 2.52692 25.9372 3.99967 25.9372H18.6663C20.1391 25.9372 21.333 24.7433 21.333 23.2705V9.93717C21.333 8.46441 20.1391 7.27051 18.6663 7.27051Z"
                  stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>

            <div class="d-flex flex-column gap-3 flex-grow-1">

              <div class="d-flex flex-column gap-2">
                <div class="d-flex justify-content-between align-items-center">
                  <span class="blue-headline">Titel des Videos:</span>
                  <a href="javascript:" @click="cancelNewVideoUploadForm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path d="M12.5 4.60376L4.5 12.6038" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M4.5 4.60376L12.5 12.6038" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </a>
                </div>
                <input type="text" class="form-control" v-model="formFields.newVideo.Title" />
              </div>

              <div v-if="formState.isUploading" class="upload-state d-flex flex-column gap-2">
                <div class="d-flex align-items-center justify-content-between gap-2">
                  <div>
                    <span>Video wird hochgeladen...</span>
                  </div>
                  <div class="d-flex gap-2">
                    <a v-if="0" href="javascript:" class="upload-action-cancel">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                        <path
                          d="M9.375 2.47876H7.6875C7.48039 2.47876 7.3125 2.64665 7.3125 2.85376V10.3538C7.3125 10.5609 7.48039 10.7288 7.6875 10.7288H9.375C9.58211 10.7288 9.75 10.5609 9.75 10.3538V2.85376C9.75 2.64665 9.58211 2.47876 9.375 2.47876Z"
                          stroke="#00719F" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M4.3125 2.47876H2.625C2.41789 2.47876 2.25 2.64665 2.25 2.85376V10.3538C2.25 10.5609 2.41789 10.7288 2.625 10.7288H4.3125C4.51961 10.7288 4.6875 10.5609 4.6875 10.3538V2.85376C4.6875 2.64665 4.51961 2.47876 4.3125 2.47876Z"
                          stroke="#00719F" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </a>
                    <a href="javascript:" class="upload-action-cancel">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                        <path d="M9.375 3.22876L2.625 9.97876" stroke="#E63A14" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M9.375 9.97876L2.625 3.22876" stroke="#E63A14" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </a>
                  </div>
                </div>
                <b-progress :value="formState.progressInPercent" :max="100" show-progress animated></b-progress>
              </div>
              <div v-else class="d-flex flex-column">
                <span class="blue-headline">Video-Datei auswählen:</span>
                <div class="d-flex align-items-center justify-content-between">
                  <input type="file" @change="handleFileSelect" />
                  <button class="btn btn-primary" @click="uploadVideo"
                    :disabled="!computedIsVideoUploadReady">Hochladen</button>
                </div>
              </div>

            </div>

          </template>

          <template v-else>

            <div class="video-preview-container flex-shrink-0" :style="{
              backgroundColor: '#F7F8F9'
            }">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                <path d="M30.6673 10.105L21.334 16.7716L30.6673 23.4383V10.105Z" stroke="#64748B" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M18.6673 7.43799H4.00065C2.52789 7.43799 1.33398 8.6319 1.33398 10.1047V23.438C1.33398 24.9107 2.52789 26.1047 4.00065 26.1047H18.6673C20.1401 26.1047 21.334 24.9107 21.334 23.438V10.1047C21.334 8.6319 20.1401 7.43799 18.6673 7.43799Z"
                  stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>

            <div class="d-flex flex-column gap-3 flex-grow-1">

              <span class="blue-headline">Neues Video:</span>
              <template v-if="computedSelectableVideos.length">
                <div class="d-flex justify-content-between">
                  <b-form-select v-model="formFields.FairVideoID" :options="computedSelectableVideos"
                    :disabled="formState.isUploading" style="max-width: 330px" class="flex-grow-1">
                  </b-form-select>
                  <button class="btn btn-secondary" @click="handleSelectVideo" :disabled="!formFields.FairVideoID">
                    Video auswählen
                  </button>
                </div>
              </template>

              <div class="d-flex flex-column align-items-start gap-3">
                <button class="btn btn-secondary d-flex gap-2" @click="startVideoUploadForm">
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <path
                      d="M14.5 10.6038V13.2704C14.5 13.624 14.3595 13.9632 14.1095 14.2132C13.8594 14.4633 13.5203 14.6038 13.1667 14.6038H3.83333C3.47971 14.6038 3.14057 14.4633 2.89052 14.2132C2.64048 13.9632 2.5 13.624 2.5 13.2704V10.6038"
                      stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.8337 5.93709L8.50033 2.60376L5.16699 5.93709" stroke="#00719F" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.5 2.60376V10.6038" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  <span>Neues Video hochladen</span>
                </button>
                <button class="btn btn-secondary d-flex gap-2" @click="closeVideoFormEdit">
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <path d="M12.5 4.60376L4.5 12.6038" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M4.5 4.60376L12.5 12.6038" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  <span>Abbrechen</span>
                </button>
              </div>

            </div>
          </template>

        </template>

        <template v-else>

          <template v-if="formFields.FairVideo">

            <VideoPreviewContainer :video="formFields.FairVideo" />
            <div class="d-flex flex-column gap-3">

              <div class="d-flex flex-column">
                <strong>Video:</strong>
                <span>{{ formFields.FairVideo.Medium.Title }}</span>
              </div>

              <button class="btn btn-secondary d-flex gap-2" @click="startVideoFormEdit">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path d="M4.66667 15.9371L2 13.2704L4.66667 10.6038" stroke="#00719F" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M14 9.27051V10.6038C14 11.3111 13.719 11.9894 13.219 12.4895C12.7189 12.9896 12.0406 13.2705 11.3333 13.2705H2"
                    stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.333 1.27051L13.9997 3.93717L11.333 6.60384" stroke="#00719F" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M2 7.93701V6.60368C2 5.89643 2.28095 5.21816 2.78105 4.71806C3.28115 4.21796 3.95942 3.93701 4.66667 3.93701H14"
                    stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span>Ersetzen</span>
              </button>
              <button class="btn btn-secondary d-flex gap-2" @click="removeCurrentConceptVideo">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path d="M2 4.60376H3.33333H14" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M5.33301 4.60368V3.27034C5.33301 2.91672 5.47348 2.57758 5.72353 2.32754C5.97358 2.07749 6.31272 1.93701 6.66634 1.93701H9.33301C9.68663 1.93701 10.0258 2.07749 10.2758 2.32754C10.5259 2.57758 10.6663 2.91672 10.6663 3.27034V4.60368M12.6663 4.60368V13.937C12.6663 14.2906 12.5259 14.6298 12.2758 14.8798C12.0258 15.1299 11.6866 15.2703 11.333 15.2703H4.66634C4.31272 15.2703 3.97358 15.1299 3.72353 14.8798C3.47348 14.6298 3.33301 14.2906 3.33301 13.937V4.60368H12.6663Z"
                    stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span>Entfernen</span>
              </button>

              <b-modal v-model="isRemoveVideoModalOpen" hide-footer hide-header size="sm">
                <div class="d-flex flex-column gap-3 p-3">
                  <span class="blue-headline">Video wirklich löschen?</span>
                  <div class="d-flex justify-content-between align-items-center gap-3">
                    <button class="btn btn-secondary flex-grow-1" @click="handleRemoveConceptVideo">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M2 4H3.33333H14" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path
                          d="M5.33301 4.00016V2.66683C5.33301 2.31321 5.47348 1.97407 5.72353 1.72402C5.97358 1.47397 6.31272 1.3335 6.66634 1.3335H9.33301C9.68663 1.3335 10.0258 1.47397 10.2758 1.72402C10.5259 1.97407 10.6663 2.31321 10.6663 2.66683V4.00016M12.6663 4.00016V13.3335C12.6663 13.6871 12.5259 14.0263 12.2758 14.2763C12.0258 14.5264 11.6866 14.6668 11.333 14.6668H4.66634C4.31272 14.6668 3.97358 14.5264 3.72353 14.2763C3.47348 14.0263 3.33301 13.6871 3.33301 13.3335V4.00016H12.6663Z"
                          stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <span>Ja, Video löschen</span>
                    </button>
                    <button class="btn btn-secondary flex-grow-1" @click="isRemoveVideoModalOpen = false">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M12.5 4L4.5 12" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M4.5 4L12.5 12" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                      <span>Abbrechen</span>
                    </button>
                  </div>
                </div>
              </b-modal>

            </div>

          </template>

          <div v-else class="d-flex align-items-center gap-3 w-100">
            <div class="empty-video flex-shrink-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                <path d="M30.6663 9.93701L21.333 16.6037L30.6663 23.2703V9.93701Z" stroke="#64748B" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M18.6663 7.27051H3.99967C2.52692 7.27051 1.33301 8.46441 1.33301 9.93717V23.2705C1.33301 24.7433 2.52692 25.9372 3.99967 25.9372H18.6663C20.1391 25.9372 21.333 24.7433 21.333 23.2705V9.93717C21.333 8.46441 20.1391 7.27051 18.6663 7.27051Z"
                  stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div class="d-flex flex-column gap-3 flex-grow-1">
              <strong>Noch kein Video ausgewählt!</strong>

              <template v-if="computedSelectableVideos.length">
                <div class="d-flex justify-content-between">
                  <b-form-select v-model="formFields.FairVideoID" :options="computedSelectableVideos"
                    :disabled="formState.isUploading" style="max-width: 330px" class="flex-grow-1">
                    <template #default>1233</template>
                    <template #first>1234</template>
                  </b-form-select>
                  <button class="btn btn-secondary" @click="handleSelectVideo" :disabled="!formFields.FairVideoID">
                    Video auswählen
                  </button>
                </div>
              </template>

              <button class="btn btn-secondary d-flex gap-2" @click="startVideoUploadForm">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path
                    d="M14.5 10.6038V13.2704C14.5 13.624 14.3595 13.9632 14.1095 14.2132C13.8594 14.4633 13.5203 14.6038 13.1667 14.6038H3.83333C3.47971 14.6038 3.14057 14.4633 2.89052 14.2132C2.64048 13.9632 2.5 13.624 2.5 13.2704V10.6038"
                    stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.8337 5.93709L8.50033 2.60376L5.16699 5.93709" stroke="#00719F" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8.5 2.60376V10.6038" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                <span>Neues Video hochladen</span>
              </button>

            </div>
          </div>

        </template>

      </div>

      <hr />
      <div class="d-flex flex-column w-100 gap-3">
        <div class="d-flex flex-column gap-1">
          <span class="fw-bold">Anforderungen:</span>
          <ul class="special">
            <li>Das Video sollte weitere Infos und Einblicke zu Geschäftskonzept, Historie oder Produkten enthalten</li>
            <li>KEINE Werbeclips, die sich an Endkunden richten</li>
            <li>Wenn Sie kein solches Video haben, lassen Sie es einfach leer</li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:editButtonCaption>
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-pencil"></i>
        <span>Bearbeiten</span>
      </div>
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/8_ConceptVideo.png" />
    </template>
  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import { useBrandStore } from '@/stores/brandStore'
import Config from '@/config'
import VideoPreviewContainer from '@/components/fair/VideoPreviewContainer.vue'
import VideoPreviewLink from '@/components/fair/VideoPreviewLink.vue'

export default {
  name: 'FairFormMoreVideo',
  components: {
    FairFieldEditor,
    VideoPreviewContainer,
    VideoPreviewLink
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      formState: {
        isVideoForm: false,
        isNew: false,
        isUploading: false,
        progressInPercent: 0
      },
      formFields: {
        FairVideo: null,
        newVideo: {
          Title: '',
          File: null
        }
      },
      isRemoveVideoModalOpen: false
    }
  },
  methods: {
    async handleRemoveConceptVideo () {
      await this.API._put('FairVideo', {
        FairVideoID: this.formFields.FairVideo.FairVideoID,
        Type: 3
      })
      this.isRemoveVideoModalOpen = false
      this.formFields.FairVideo = null
      const brandStore = useBrandStore()
      await brandStore.updateFairVideos()
    },
    removeCurrentConceptVideo () {
      this.isRemoveVideoModalOpen = true
    },
    cancelNewVideoUploadForm () {
      this.formState.isNew = false
      this.formState.isVideoForm = false
      this.formState.isUploading = false
      this.formState.progressInPercent = 0
    },
    updateFormFields () {
      this.formFields.FairVideo = this.computedConecptVideo
    },
    async handleSelectVideo () {
      this.isLoading = true
      try {
        this.formFields.FairVideo = this.currentFair.FairVideos.find(FairVideo => FairVideo.FairVideoID === this.formFields.FairVideoID)
        this.formFields.FairVideo.Type = 2
        await Promise.all(this.currentFair.FairVideos.map(async (FairVideo) => {
          if (FairVideo.Type === 2) {
            FairVideo.Type = 3
            await this.API._put('fairvideo', {
              FairVideoID: FairVideo.FairVideoID,
              Type: 3
            })
          }
        }))
        await this.API._put('fairvideo', {
          FairVideoID: this.formFields.FairVideo.FairVideoID,
          Type: 2
        })

        const brandStore = useBrandStore()
        await brandStore.setNewFairVideo(this.formFields.FairVideoID, 2)
        this.formState.isNew = false
        this.formState.isVideoForm = false
        this.formFields.FairVideoID = null
      } catch (error) {
        console.error('Error at selecting video', error)
        this.$toast.error('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.')
      } finally {
        this.isLoading = false
      }
    },
    async uploadVideo () {
      const brandStore = useBrandStore()

      if (!this.formFields.newVideo.File) {
        this.$toast.error('Bitte wählen Sie eine Datei aus, bevor Sie hochladen.')
        return
      }
      try {
        this.isLoading = true
        this.formState.isUploading = true

        const uploadedFairVideo = await brandStore.uploadFairVideo(this.formFields.newVideo, 2)

        if (!uploadedFairVideo) {
          throw new Error('Error uploading Video')
        }

        this.updateFormFields()

        this.formFields.newVideo.Title = ''
        this.formFields.newVideo.File = null
        this.formState.isNew = false
        this.formState.isVideoForm = false
        this.$toast.success('Video erfolgreich geändert.')
      } catch (error) {
        console.error('Error uploading video', error)
        this.$toast.error('Beim Hochladen des Videos ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
        this.formState.isUploading = false
      }
    },
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Änderungen erfolgreich gespeichert.')
        this.isModalOpen = false

        this.formFields.FairVideo = null
        this.formFields.newVideo.Title = ''
        this.formFields.newVideo.File = null
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.')
      } finally {
        this.isLoading = false
      }
    },
    async submitForm () {
      if (this.computedConecptVideo && this.formFields.FairVideo === null) {
        await this.API._put('fairvideo', {
          FairVideoID: this.computedConecptVideo.FairVideoID,
          Type: 3
        })
        const brandStore = useBrandStore()
        await brandStore.updateFairVideos()
      }
    },
    startVideoFormEdit () {
      this.formState.isVideoForm = true
    },
    closeVideoFormEdit () {
      this.formState.isVideoForm = false
    },
    startVideoUploadForm () {
      this.formState.isNew = true
      this.formState.isVideoForm = true
    },
    handleFileSelect (event) {
      this.formFields.newVideo.File = event.target.files[0]
    }
  },
  computed: {
    computedTitle () {
      return `Video: Mehr über ${this.currentBrand.Name}`
    },
    computedVideoLink () {
      if (this.computedConecptVideo.Medium.Filename === 'youtube') {
        // in Medium.URL is the YouTube ID
        return `https://www.youtube.com/watch?v=${this.computedConecptVideo.Medium.URL}`
      }

      if (this.computedConecptVideo.Medium.Filename === 'sproutvideo') {
        // in Medium.URL is the SproutVideo ID
        return `https://videos.sproutvideo.com/embed/${this.computedConecptVideo.Medium.URL}`
      }

      return `${Config.MEDIA_API_URL}${this.computedConecptVideo.Medium.URL}`
    },
    computedIsValid () {
      // return this.formFields.value.length > 0 && this.formFields.value.length <= 65
      return true
    },
    computedIsVideoUploadReady () {
      return String(this.formFields.newVideo.Title).length > 0 && this.formFields.newVideo.File !== null
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    API: function () {
      return this.app.API || {}
    },
    APIMedia: function () {
      return this.app.APIMedia || {}
    },
    currentBrand: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand
    },
    currentFair: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentFair
    },
    computedConecptVideo () {
      return this.currentFair.FairVideos.find(video => video.Type === 2)
    },
    computedSelectableVideos () {
      return this.currentFair.FairVideos
        .filter(video => video.Type === 3)
        .map(video => {
          return {
            value: video.FairVideoID,
            text: video.Medium.Title
          }
        })
    }
  }

}
</script>

<style lang="scss" scoped>
.video-preview-container {
  width: 360px;
  height: 203px;
  overflow: hidden;
  position: relative;
  border-radius: 12px;

  .btn {
    width: auto;
    position: absolute;
    bottom: 12px;
    right: 12px;
  }

  >svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}

.empty-video {
  display: flex;
  width: 360px;
  height: 203.272px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #F7F8F9;
}

.upload-state {
  background-color: #F7F8F9;
  padding: 8px;
}

.upload-action-cancel {
  width: 24px;
  height: 24px;
  background-color: #FCE1DB;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}
</style>
