<template>
  <div class="chat-module">
    <span class="headline">Nachrichten</span>

    <div class="chat-content">
      <loading
        :active.sync="chatStore.getIsLoading"
        :can-cancel="false"
        :is-full-page="false"
      />

      <vue-custom-scrollbar class="chat-messages">
        <ChatMessage
          v-for="message in computedMessages"
          :message="message"
          :key="message.ChatMessageID"
        />
      </vue-custom-scrollbar>

      <div class="new-message">
        <div class="top">
          <textarea v-model="newMessageContent" class="form-control" :disabled="isFormDisabled"></textarea>
          <button class="send-link" @click="sendMessage" :disabled="isFormDisabled">
            <inline-svg
              width="24"
              height="24"
              :src="require('@/assets/images/icons/3.0/send.svg')"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import ChatMessage from '@/components/3.0/ChatMessage.vue'
import Loading from 'vue-loading-overlay'
import InlineSvg from 'vue-inline-svg'
import $ from 'jquery'
import { useChatStore } from '@/stores/chatStore'
import { useBrandStore } from '@/stores/brandStore'
import { useUserStore } from '@/stores/userStore'

export default {
  components: {
    Loading,
    InlineSvg,
    vueCustomScrollbar,
    ChatMessage
  },
  props: ['lead'],
  name: 'ChatModule',
  data: function () {
    return {
      newMessageContent: '',
      isChatLoading: false,
      chatStore: useChatStore(),
      brandStore: useBrandStore(),
      userStore: useUserStore(),
      isFormDisabled: false
    }
  },
  methods: {
    async sendMessage () {
      if (this.newMessageContent.length === 0) {
        this.$swal(
          this.$t('popupmessage.warn.attentiontitle'),
          'Bitte geben Sie eine Nachricht ein.',
          'error'
        )
        return
      }

      this.chatStore.setIsLoading(true)
      this.isFormDisabled = true

      const newMessage = {
        FromContactID: this.userStore.getCurrentContactID,
        ToContactID: this.lead.ContactID,
        Content: this.newMessageContent,
        BrandID: this.brandStore.getCurrentBrandID
      }

      try {
        await this.chatStore.sendMessage(newMessage)
        this.newMessageContent = ''
        $('.chat-messages').scrollTop($('.chat-messages')[0].scrollHeight + 100)
      } catch (error) {
        this.$swal(
          this.$t('popupmessage.warn.attentiontitle'),
          'Nachricht konnte nicht gesendet werden.',
          'error'
        )
      }

      this.isFormDisabled = false
      this.chatStore.setIsLoading(false)
    }
  },
  computed: {
    computedMessages () {
      const ContactID = this.lead.ContactID
      return this.chatStore.getMessages.filter((message) => {
        return (
          message.FromContactID === ContactID ||
          message.ToContactID === ContactID
        )
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.chat-module {
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  .chat-content {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 64px;
    flex: 1 0 0;
    align-self: stretch;

    background-image: url("../../assets/images/chat-bg.png");

    position: relative;

    border-radius: 0px 0px 12px 12px;
    border: 2px solid #00719F;
  }

  .new-message {
    display: flex;
    // min-height: 124px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);

    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 16px;

      textarea {
        flex-grow: 1;
        border: 0;
        color: #a0abbb;
        min-height: 36px;
        max-height: 320px;
      }
    }
  }

  .chat-messages {
    max-height: 600px;
    overflow: auto;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .send-link {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
  }
}
</style>
