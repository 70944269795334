<template>
  <div class="row">
    <div class="col-12">
      <h4>Interne Notizen</h4>
    </div>
    <div class="col-12" v-if="isLoading">
      <div class="loader">
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </div>
    </div>
    <template v-else>
      <div class="col-12" v-if="currentNotes.length === 0">
        <p>Bisher keine Notizen</p>
      </div>

      <div class="col-12" v-else>
        <vue-custom-scrollbar class="credit-notes pb-3">
          <ContactNote v-for="note in computedCurrentNotes" :message="note" :key="note.ContactNoteID" />
        </vue-custom-scrollbar>

      </div>

      <div class="col-12">
        <div class="new-message">
          <div class="top">
            <textarea v-model="newNoteContent" class="form-control" placeholder="Neue Notiz" @keydown="checkCtrlEnter" :disabled="isFormDisabled"></textarea>
            <button class="send-link" @click="sendMessage">
              <inline-svg width="24" height="24" :src="require('@/assets/images/icons/3.0/send.svg')" />
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import ContactNote from '@/components/3.0/ContactNote.vue'
import { useUserStore } from '@/stores/userStore'
import { useBrandStore } from '@/stores/brandStore'

export default {
  name: 'ContactNotes',
  props: ['lead'],
  inject: ['API'],
  components: {
    vueCustomScrollbar,
    InlineSvg,
    ContactNote
  },
  data: function () {
    return {
      userStore: useUserStore(),
      brandStore: useBrandStore(),
      isLoading: false,
      isSaving: false,
      currentNotes: [],
      newNoteContent: ''
    }
  },
  computed: {
    computedCurrentNotes () {
      return this.currentNotes
    },
    isFormDisabled () {
      return this.isSaving
    }
  },
  methods: {
    checkCtrlEnter (event) {
      if (event.ctrlKey && event.key === 'Enter') {
        this.sendMessage()
      }
    },
    sendMessage: async function () {
      if (this.newNoteContent.length === 0) {
        return
      }

      if (this.isSaving) {
        return
      }

      try {
        this.isSaving = true
        const newContactNoteData = {
          ContactID: this.lead.ContactID,
          AuthorID: this.userStore.getCurrentContactID,
          BrandID: this.brandStore.getCurrentBrandID,
          Content: this.newNoteContent
        }
        const newContactNoteResponse = await this.API._put('ContactNote/', newContactNoteData)
        newContactNoteData.ContactNoteID = newContactNoteResponse.body.Result.NewID
        newContactNoteData.Created = new Date().toISOString()
        newContactNoteData.Author = this.userStore.getCurrentContact
        this.currentNotes.push(newContactNoteData)
        this.newNoteContent = ''
      } catch (error) {
        console.error(error)
      } finally {
        this.isSaving = false
      }
    }
  },
  async mounted () {
    if (this.isLoading) return
    try {
      this.isLoading = true
      const contactNotes = await this.API._post('ContactNotes/', {
        ResFrom: 1,
        ResTo: 1000,
        FullSubEntities: true,
        Filter: [
          {
            Field: 'ContactID',
            Operator: 'eq',
            Value: this.lead.ContactID
          }
        ]
      })
      this.currentNotes = contactNotes.body.Result.Data
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.credit-notes {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.new-message {
  display: flex;
  // min-height: 124px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);

  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;

    textarea {
      flex-grow: 1;
      border: 0;
      color: #3F4957;
      &::placeholder {
        color: #a0abbb;
      }
      min-height: 36px;
      max-height: 320px;
    }
  }
}

.send-link {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
}
</style>
