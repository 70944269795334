<template>
  <div>
    <div class="mb-main-content mb-booking-success">
      <div
        class="mb-top-container"
        >
        <div class="container">
          <div class="row mb-5">
            <div class="col-12">
                <h1 class="mb-title">
                  <i class="icon-topmarke-weiss"></i>
                  <span>{{ $t('page.membershipsuccess.congratulations', { firstname: currentContact.Firstname, lastname: currentContact.Lastname }) }}</span>
                  <div>{{ $t('page.topbrandsuccess.bookedsuccessfull', { brandname: currentBrand.Name }) }}</div>
                </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="mb-progress position-sticky">
                <h3 v-t="'page.membershipsuccess.howitcontinues'"></h3>
                <ul class="mb-info-lst">
                  <li><i class="fa fa-check"></i><span v-t="'page.topbrandsuccess.topbrandisbooked'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.topbrandsuccess.moreviews'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.topbrandsuccess.maxvisibility'"></span></li>
                  <li><i class="fa fa-check"></i><span v-t="'page.topbrandsuccess.contactsmenue'"></span></li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 d-flex justify-content-between flex-column">
              <div class="mt-5">
              <img
                class="img-fluid"
                :src="require('../../assets/images/kurzprofil.png')"
              />
              </div>
              <h4 v-t="'page.membershipsuccess.successwishes'"></h4>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-5 pb-5">
            <div class="mb-arrow-down">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-vid-cont">
        <div class="container pt-5 pb-5">
          <div class="row mt-5 mb-5">
            <div class="col-lg-5">
              <h2 class="mb-5" v-t="'page.topbrandsuccess.videotitle'"></h2>
              <div v-html="$t('page.topbrandsuccess.videodesc')"></div>
              <router-link to="/profil" class="btn mt-4 text-uppercase">{{ $t('button.tobrandprofile') }}</router-link>
            </div>
            <div class="col-lg-7">
              <iframe
                id="ytplayer"
                type="text/html"
                src="https://www.youtube-nocookie.com/embed/FYZ36FTDP2g"
                frameborder="0"
                allowfullscreen="allowfullscreen"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueConfetti from 'vue-confetti'

Vue.use(VueConfetti)

export default {
  name: 'MembershipSuccess',
  props: [],
  data: function () {
    return {
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    currentContact: function () {
      return this.app.currentContact || {}
    },
    currentType: function () {
      return this.$route.meta.type
    },
    isMembershipBooking: function () {
      return this.currentType === 'membership'
    },
    isTopBrandBooking: function () {
      return this.currentType === 'topbrand'
    },
    avvSigned: function () {
      return true
    },
    currentBrand () {
      const current = this.$parent.currentBrand || {}
      return current
    }
  },
  methods: {
  },
  mounted: function () {
    this.$confetti.start({
      particles: [
        {
          type: 'rect'
        }
      ]
    })
    setTimeout(() => {
      this.$confetti.stop()
    }, 5000)
  },
  watch: {
    '$route' (to, from) {
      if (from.path === '/topmarke-buchen-erfolgreich') {
        this.app.loadBrand(this.currentBrand.BrandID)
      }
    }
  }
}
</script>
