<template>
  <div class="chat-module talentpool-module">
    <span class="headline">Kontakt anfragen</span>

    <div class="chat-content" v-if="!nextStep">

      <div class="d-flex gap-3 justify-content-between info-box">
        <div class="d-flex flex-column gap-3">
          <strong>Aktivität von P**** ********</strong>
          <div class="d-flex flex-column gap-1">
            <span>Profil registriert: 23.07.2022</span>
            <span>Letzte Aktivität: Vor 1 Monat</span>
            <span>Erhaltene Kontaktanfragen: 4</span>
            <span>Akzeptierte Kontaktanfragen: 2</span>
          </div>
        </div>
        <img src="@/assets/images/talent.png" width="60" height="60" />
      </div>

      <div class="d-flex flex-column gap-3 buy-box">
        <h5>Sie möchten P**** kennenlernen?</h5>
        <span>Wenn Petra die Kontaktanfrage akzeptiert, erhalten Sie die freigeschalteten Kontaktdaten und Zugriff auf die Chat-Funktion.</span>
        <span>Sie erhalten Ihre Credits automatisch wieder gutgeschrieben, wenn P**** Ihre Anfrage ablehnt oder Ihre Anfrage aufgrund fehlender Rückmeldung nach 14 Tagen storniert wird.</span>
        <div class="d-flex align-items-center gap-1">
          <img class="coin" src="@/assets/images/icons/3.0/coin.png" width="16" height="16" />
          <span class="cost-tag">Kosten:</span>
          <span>1 Credit</span>
        </div>
        <button class="btn" @click="goToNextStep">Jetzt Kontakt anfragen</button>
      </div>

    </div>

    <div class="chat-content" v-else>

      <div class="last-step-head">
        <span>Nur noch ein letzter Schritt:</span>
        <span>Bearbeiten Sie jetzt den Textvorschlag nach Belieben und senden Sie Ihre Kontaktanfrage ab.</span>
        <span>Das Teilen von Kontaktdaten oder Links ist nicht erlaubt!</span>
      </div>

      <div class="lead-message">
        <loading
          :active.sync="isLoading"
          :can-cancel="false"
          :is-full-page="false"
        />

        <div v-if="!isLoading">
          {{ currentMessage }}
        </div>

      </div>

      <button class="btn" @click="purchaseContact">Kontaktanfrage absenden</button>

    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { useBrandStore } from '@/stores/brandStore'

export default {
  name: 'TalentpoolRequestForm',
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  components: {
    Loading
  },
  data () {
    return {
      brandStore: useBrandStore(),
      nextStep: false,
      isLoading: false,
      currentMessage: ''
    }
  },
  methods: {
    async goToNextStep () {
      this.nextStep = true
      this.isLoading = true
      const result = await this.app.APIService._post('talentpool/leads/ai/message', {
        contactId: this.contact.ContactID,
        brandId: this.brandStore.getCurrentBrandID
      })
      this.currentMessage = result.body.text
      this.isLoading = false
    },
    purchaseContact () {

    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    }
  }
}

</script>

<style lang="scss" scoped>

.last-step-head {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #3F4957;

  span:nth-child(1) {
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.38px;
  }

  span:nth-child(2) {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  span:nth-child(3) {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

}

.lead-message {
  position: relative;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Shades-White, #FFF);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.10);
  min-height: 240px;
}

.buy-box {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.10);

  .cost-tag {
    color: #3F4957;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  h5 {
    color: #3F4957;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.38px;
    margin-bottom: 0;
  }

  span {
    color: #3F4957;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

}

button {
  display: flex;
  outline: none;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: #00719F;

  color: #FFF;
  border: 0;

  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */

}

.info-box {
  width: 100%;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;

  border-radius: 12px;
  border: 1px solid #00719F;
  background: #FFF;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.10);
  color: #3F4957;

  img {
    border-radius: 100px;
    border: 3px solid var(--Shades-White, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
  }

  strong {
    color: #3F4957;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.38px;
  }

  span {
    color: #3F4957;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

}

.chat-module {
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  .chat-content {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;

    background-image: url("../../assets/images/chat-bg.png");

    position: relative;

    border-radius: 0px 0px 12px 12px;
    border: 2px solid #00719F;
  }

  .new-message {
    display: flex;
    // min-height: 124px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);

    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 16px;

      textarea {
        flex-grow: 1;
        border: 0;
        color: #a0abbb;
        min-height: 36px;
        max-height: 320px;
      }
    }
  }

  .chat-messages {
    max-height: 600px;
    overflow: auto;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .send-link {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
  }
}
</style>
