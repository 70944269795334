// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'jquery'
import '@popperjs/core'
import 'bootstrap'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'ladda/css/ladda.scss'
import './assets/less/styles.less'
import 'font-awesome/css/font-awesome.css'
import 'vue-datetime/dist/vue-datetime.css'
import 'shepherd.js/dist/css/shepherd.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'vue-toastification/dist/index.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

import { createPinia, PiniaVuePlugin } from 'pinia'
import VueScrollTo from 'vue-scrollto'
import VueScrollactive from 'vue-scrollactive'
import BootstrapVue from 'bootstrap-vue'
import Toast from 'vue-toastification'
import ToggleButton from 'vue-js-toggle-button'
import i18n from './i18n'
import Loading from 'vue-loading-overlay'

Vue.use(Toast, {
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.type === toast.type
    ).length !== 0) {
      // Returning false discards the toast
      return false
    }
    // You can modify the toast if you want
    return toast
  }
})
Vue.config.productionTip = false
Vue.use(VueScrollTo, {
  offset: -100
})
Vue.use(VueScrollactive)
Vue.use(BootstrapVue)
Vue.use(ToggleButton)

Vue.use(Loading)

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.filter('formatPrice', function (value) {
  if (!value) return '0,00€'
  value = (value / 100).toFixed(2)
  return value.toString().replace('.', ',') + '€'
})

export default new Vue({
  el: '#app',
  pinia,
  router,
  components: {
    App
  },
  methods: {
    getApp () {
      return this.$children[0]
    }
  },
  template: '<App />',
  i18n,
  render: h => h(App)
})
