<template>

  <div class="d-flex flex-column gap-2 experience-container">

    <div class="d-flex justify-content-between align-items-center">
      <div class="w-50">
        <input type="text" class="form-control" v-model="experience.AuthorData" placeholder="Name" />
      </div>
      <div class="d-flex gap-32px">
        <div class="d-flex gap-2 align-items-center">
          <span>Reihenfolge:</span>
          <button :disabled="computedIsDisabledUp" @click="sortOrderUp" class="btn btn-secondary">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path d="M8.1875 12.6666V3.33325" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M3.52051 7.99992L8.18717 3.33325L12.8538 7.99992" stroke="#00719F" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
          <button :disabled="computedIsDisabledDown" @click="sortOrderDown" class="btn btn-secondary">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path d="M8.0625 3.33325V12.6666" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M12.7288 8L8.06217 12.6667L3.39551 8" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </button>
        </div>
        <button class="btn btn-secondary" @click="removeFairExperience">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M2 4H3.33333H14" stroke="#00719F" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M5.33301 3.99992V2.66659C5.33301 2.31296 5.47348 1.97382 5.72353 1.72378C5.97358 1.47373 6.31272 1.33325 6.66634 1.33325H9.33301C9.68663 1.33325 10.0258 1.47373 10.2758 1.72378C10.5259 1.97382 10.6663 2.31296 10.6663 2.66659V3.99992M12.6663 3.99992V13.3333C12.6663 13.6869 12.5259 14.026 12.2758 14.2761C12.0258 14.5261 11.6866 14.6666 11.333 14.6666H4.66634C4.31272 14.6666 3.97358 14.5261 3.72353 14.2761C3.47348 14.026 3.33301 13.6869 3.33301 13.3333V3.99992H12.6663Z"
              stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span>Entfernen</span>
        </button>
      </div>
    </div>
    <input class="form-control" v-model="experience.Location"
      placeholder="Standort-Zusatz (“betreibt sein Geschäft in ...”)" />
    <div class="d-flex gap-3 align-items-center">
      <div class="experience-media-container d-flex flex-column" :class="{
        'empty-preview': experience.VideoID === null && experience.MediumID === null
      }">

        <template v-if="experience.VideoID">
          <div class="d-flex flex-column gap-2">
            <VideoPreviewContainer :video="experience.Video" />
            <span class="video-title">{{ experience.Video.Title }}</span>
          </div>
          <hr />
          <button class="btn btn-secondary" @click="removeFairExperienceVideo">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path d="M2.375 4.375H3.70833H14.375" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M5.70898 4.37516V3.04183C5.70898 2.68821 5.84946 2.34907 6.09951 2.09902C6.34956 1.84897 6.6887 1.7085 7.04232 1.7085H9.70898C10.0626 1.7085 10.4017 1.84897 10.6518 2.09902C10.9018 2.34907 11.0423 2.68821 11.0423 3.04183V4.37516M13.0423 4.37516V13.7085C13.0423 14.0621 12.9018 14.4013 12.6518 14.6513C12.4017 14.9014 12.0626 15.0418 11.709 15.0418H5.04232C4.6887 15.0418 4.34956 14.9014 4.09951 14.6513C3.84946 14.4013 3.70898 14.0621 3.70898 13.7085V4.37516H13.0423Z"
                stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>Entfernen</span>
          </button>
        </template>

        <template v-else-if="experience.MediumID">
          <img :src="computedThumbnailUrl" />
          <hr />
          <button class="btn btn-secondary" @click="removeFairExperienceImage">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path d="M2.375 4.375H3.70833H14.375" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M5.70898 4.37516V3.04183C5.70898 2.68821 5.84946 2.34907 6.09951 2.09902C6.34956 1.84897 6.6887 1.7085 7.04232 1.7085H9.70898C10.0626 1.7085 10.4017 1.84897 10.6518 2.09902C10.9018 2.34907 11.0423 2.68821 11.0423 3.04183V4.37516M13.0423 4.37516V13.7085C13.0423 14.0621 12.9018 14.4013 12.6518 14.6513C12.4017 14.9014 12.0626 15.0418 11.709 15.0418H5.04232C4.6887 15.0418 4.34956 14.9014 4.09951 14.6513C3.84946 14.4013 3.70898 14.0621 3.70898 13.7085V4.37516H13.0423Z"
                stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>Entfernen</span>
          </button>
        </template>

        <template v-else>
          <div class="empty-experience-container">
            <div class="d-flex flex-column gap-3">
              <ImageUploadButton @newImageSaved="handleNewImageSaved" folder="SystemRoot/Fair/FairExperiences"
                :aspectRatio="1" />
              <VideoUploadButton @newVideoSaved="handleNewVideoSaved">
                <template #buttonCaption>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <path d="M15.7077 5.5415L11.041 8.87484L15.7077 12.2082V5.5415Z" stroke="#00719F" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M9.70768 4.2085H2.37435C1.63797 4.2085 1.04102 4.80545 1.04102 5.54183V12.2085C1.04102 12.9449 1.63797 13.5418 2.37435 13.5418H9.70768C10.4441 13.5418 11.041 12.9449 11.041 12.2085V5.54183C11.041 4.80545 10.4441 4.2085 9.70768 4.2085Z"
                      stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span>Video hochladen</span>
                </template>
              </VideoUploadButton>
            </div>
          </div>
        </template>

      </div>
      <textarea class="form-control flex-grow-1" style="height: auto;" v-model="experience.Text"
        placeholder="Erfahrungsbericht"></textarea>
    </div>

    <b-modal v-model="isRemoveFairExperienceModalOpen" hide-footer hide-header size="sm">
      <div class="d-flex flex-column gap-3 p-3">
        <span class="blue-headline">Erfahrung wirklich löschen?</span>
        <div class="d-flex justify-content-between align-items-center gap-3">
          <button class="btn btn-secondary flex-grow-1" @click="handleRemoveFairExperience">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M2 4H3.33333H14" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M5.33301 4.00016V2.66683C5.33301 2.31321 5.47348 1.97407 5.72353 1.72402C5.97358 1.47397 6.31272 1.3335 6.66634 1.3335H9.33301C9.68663 1.3335 10.0258 1.47397 10.2758 1.72402C10.5259 1.97407 10.6663 2.31321 10.6663 2.66683V4.00016M12.6663 4.00016V13.3335C12.6663 13.6871 12.5259 14.0263 12.2758 14.2763C12.0258 14.5264 11.6866 14.6668 11.333 14.6668H4.66634C4.31272 14.6668 3.97358 14.5264 3.72353 14.2763C3.47348 14.0263 3.33301 13.6871 3.33301 13.3335V4.00016H12.6663Z"
                stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>Ja, Erfahrung löschen</span>
          </button>
          <button class="btn btn-secondary flex-grow-1" @click="isRemoveFairExperienceModalOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path d="M12.5 4L4.5 12" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M4.5 4L12.5 12" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <span>Abbrechen</span>
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="isRemoveFairExperienceImageModalOpen" hide-footer hide-header size="sm">
      <div class="d-flex flex-column gap-3 p-3">
        <span class="blue-headline">Foto wirklich löschen?</span>
        <div class="d-flex justify-content-between align-items-center gap-3">
          <button class="btn btn-secondary flex-grow-1" @click="handleRemoveFairExperienceImage">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M2 4H3.33333H14" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M5.33301 4.00016V2.66683C5.33301 2.31321 5.47348 1.97407 5.72353 1.72402C5.97358 1.47397 6.31272 1.3335 6.66634 1.3335H9.33301C9.68663 1.3335 10.0258 1.47397 10.2758 1.72402C10.5259 1.97407 10.6663 2.31321 10.6663 2.66683V4.00016M12.6663 4.00016V13.3335C12.6663 13.6871 12.5259 14.0263 12.2758 14.2763C12.0258 14.5264 11.6866 14.6668 11.333 14.6668H4.66634C4.31272 14.6668 3.97358 14.5264 3.72353 14.2763C3.47348 14.0263 3.33301 13.6871 3.33301 13.3335V4.00016H12.6663Z"
                stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>Ja, Foto löschen</span>
          </button>
          <button class="btn btn-secondary flex-grow-1" @click="isRemoveFairExperienceImageModalOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path d="M12.5 4L4.5 12" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M4.5 4L12.5 12" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <span>Abbrechen</span>
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="isRemoveFairExperienceVideoModalOpen" hide-footer hide-header size="sm">
      <div class="d-flex flex-column gap-3 p-3">
        <span class="blue-headline">Video wirklich löschen?</span>
        <div class="d-flex justify-content-between align-items-center gap-3">
          <button class="btn btn-secondary flex-grow-1" @click="handleRemoveFairExperienceVideo">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M2 4H3.33333H14" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M5.33301 4.00016V2.66683C5.33301 2.31321 5.47348 1.97407 5.72353 1.72402C5.97358 1.47397 6.31272 1.3335 6.66634 1.3335H9.33301C9.68663 1.3335 10.0258 1.47397 10.2758 1.72402C10.5259 1.97407 10.6663 2.31321 10.6663 2.66683V4.00016M12.6663 4.00016V13.3335C12.6663 13.6871 12.5259 14.0263 12.2758 14.2763C12.0258 14.5264 11.6866 14.6668 11.333 14.6668H4.66634C4.31272 14.6668 3.97358 14.5264 3.72353 14.2763C3.47348 14.0263 3.33301 13.6871 3.33301 13.3335V4.00016H12.6663Z"
                stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>Ja, Video löschen</span>
          </button>
          <button class="btn btn-secondary flex-grow-1" @click="isRemoveFairExperienceVideoModalOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path d="M12.5 4L4.5 12" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M4.5 4L12.5 12" stroke="#00719F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <span>Abbrechen</span>
          </button>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>

import Config from '@/config'
import VideoPreviewContainer from '@/components/fair/VideoPreviewContainer.vue'
import ImageUploadButton from '@/components/fair/ImageUploadButton.vue'
import VideoUploadButton from '@/components/fair/VideoUploadButton.vue'

export default {
  name: 'experienceContainer',
  props: ['experience', 'amountOfExperiences'],
  emits: ['updateSortOrderUp', 'updateSortOrderDown', 'removeExperience'],
  components: {
    VideoPreviewContainer,
    ImageUploadButton,
    VideoUploadButton
  },
  data: function () {
    return {
      isRemoveFairExperienceModalOpen: false,
      isRemoveFairExperienceImageModalOpen: false,
      isRemoveFairExperienceVideoModalOpen: false
    }
  },
  methods: {
    handleNewImageSaved (medium) {
      this.experience.MediumID = medium.MediumID
      this.experience.Medium = medium
      this.experience.VideoID = null
      this.experience.Video = null
    },
    handleNewVideoSaved (medium) {
      this.experience.VideoID = medium.MediumID
      this.experience.Video = medium
      this.experience.MediumID = null
      this.experience.Medium = null
    },
    sortOrderUp () {
      this.$emit('updateSortOrderUp', this.experience)
    },
    sortOrderDown () {
      this.$emit('updateSortOrderDown', this.experience)
    },
    removeFairExperience () {
      this.isRemoveFairExperienceModalOpen = true
    },
    removeFairExperienceImage () {
      this.isRemoveFairExperienceImageModalOpen = true
    },
    removeFairExperienceVideo () {
      this.isRemoveFairExperienceVideoModalOpen = true
    },
    handleRemoveFairExperience () {
      this.$emit('removeExperience', this.experience)
      this.isRemoveFairExperienceModalOpen = false
    },
    handleRemoveFairExperienceImage () {
      this.experience.MediumID = null
      this.experience.Medium = null
      this.isRemoveFairExperienceImageModalOpen = false
    },
    handleRemoveFairExperienceVideo () {
      this.experience.VideoID = null
      this.experience.Video = null
      this.isRemoveFairExperienceVideoModalOpen = false
    }
  },
  computed: {
    computedHasThumbnail () {
      return this.experience.MediumID !== null
    },
    computedIsVideoExperience () {
      return this.experience.Video !== null
    },
    plainText () {
      return this.stripHtmlTags(this.experience.Text)
    },
    computedThumbnailUrl () {
      if (this.computedHasThumbnail) {
        return `${Config.MEDIA_API_URL}${this.experience.Medium.URL}?size=md`
      } else {
        return ''
      }
    },
    computedIsDisabledUp () {
      return this.experience.SortOrder === 0
    },
    computedIsDisabledDown () {
      return this.experience.SortOrder === this.amountOfExperiences - 1
    }
  }
}

</script>

<style lang="scss" scoped>
.video-preview-container {
  width: 100%;
  height: auto;
  position: relative;

  svg {
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

}

.experience-container {
  textarea {
    padding: 8px 12px;
  }
}

.experience-media-container {
  width: 183px;
  background-color: #F7F8F9;
  padding: 8px;
  flex-shrink: 0;
  border-radius: 8px;

  &.empty-preview {
    border-radius: 20px;
  }

  img {
    border-radius: 8px;
  }

}

.video-title {
  color: #3F4957;
  text-align: center;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
}

.empty-experience-container {
  height: 219px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
</style>
