<template>
  <div ref="slider"></div>
</template>

<script>
import noUiSlider from 'nouislider'
import 'nouislider/dist/nouislider.css'

export default {
  name: 'NoUiSlider',
  props: {
    start: {
      type: Array,
      required: true
    },
    range: {
      type: Object,
      required: true
    },
    connect: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1
    }
  },
  data: function () {
    return {
      isWatchEventSet: false
    }
  },
  mounted () {
    this.createSlider()
  },
  beforeDestroy () {
    if (this.$refs.slider && this.$refs.slider.noUiSlider) {
      this.$refs.slider.noUiSlider.destroy()
    }
  },
  watch: {
    start (newValue) {
      this.isWatchEventSet = true

      if (newValue[0] < 0) {
        newValue[0] = 0
      }

      if (newValue[1] > this.range[1]) {
        newValue[1] = this.range[1]
      }

      this.$refs.slider.noUiSlider.set(newValue)
      this.isWatchEventSet = false
    }
  },
  methods: {
    createSlider () {
      noUiSlider.create(this.$refs.slider, {
        start: this.start,
        range: this.range,
        connect: this.connect,
        step: this.step || 1,
        tooltips: false
      })

      this.$refs.slider.noUiSlider.on('update', (values, handle) => {
        if (this.isWatchEventSet) return
        this.$emit('update:value', values)
      })
    }
  }
}
</script>
