<template>
  <div
    v-if="upgradeData !== null&&!isPanelHidden"
    class="mb-upgrade d-flex flex-column justify-content-center mb-4"
  >
    <a class="close" href="javascript:"
      ><i class="fa fa-close" @click="closeUpgradePanel()"></i
    ></a>

    <div class="row">
      <div class="col-lg-2 text-center">
        <img class="price-tag" src="../assets/images/icons/price-tag.png" width="106" />
      </div>
      <div class="col-lg-6 d-flex justify-content-center align-items-center">
        <div>
          <span class="headline"
            >{{$t('subscriptions.teaser.headline', {
              amount: computedAmount(upgradeData.amount)
            })}}</span
          >
          <span class="d-block" v-html="$t('subscriptions.teaser.subtext', {
            percentage: upgradeData.percentage,
          })"
            ></span
          >
        </div>
      </div>
      <div class="col-lg-4 d-flex justify-content-center align-items-center">
        <router-link to="/abos" class="upgrade-btn">
          Angebot ansehen
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['name', 'value'],
  data: function () {
    return {
      localStorageName: `FPMB_${this.name}PanelHide`
    }
  },
  computed: {
    upgradeData: function () {
      return this.value
    },
    isPanelHidden: function () {
      return localStorage.getItem(this.localStorageName) === 'true'
    },
    computedAmount: function () {
      return function (amount) {
        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR'
        }).format(amount / 100)
      }
    }
  },
  methods: {
    closeUpgradePanel: function () {
      localStorage.setItem(this.localStorageName, true)
      this.upgradeData = null
    }
  }
}
</script>

<style lang="scss" scoped>
.mb-upgrade {
  min-height: 115px;
  background: #f85022;
  background: linear-gradient(45deg, #f85022 35%, #582c5b 100%);
  color: white;
  position: relative;
  overflow: hidden;
}
.mb-upgrade a.close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  z-index: 100;
}

.headline {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 800;
}
a.upgrade-btn {
  color: #606060;
  padding: 7px 40px;
  text-decoration: none;
  border: 1px solid #fefefe;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.66);
}
a.upgrade-btn:hover {
  background-color: rgba(255, 255, 255, 0.88);
}
.price-tag {
  margin-top: -10px;
}
</style>
