<template>
  <div class="mb-markeanlegen">
    <div
      v-if="$parent.shortlyRegistered"
      class="mb-markeanlegen-top">
      <i class="fa fa-check"></i><span v-html="$t('page.newbrand.successfullyregistered', { name: app.login.user.Name })"></span>
    </div>

    <div class="mb-markeanlegen-top" v-if="isMaintenance">
      <h2 v-t="'page.login.maintenance'"></h2>
      <p v-html="$t('page.login.maintenancetext')"></p>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-4 d-none d-lg-block">
          <div class="mb-progress">
            <h3 v-t="'page.membershipsuccess.howitcontinues'"></h3>
            <ul class="mb-info-lst">
              <li class="mb-progress-active"><i class="fa fa-long-arrow-right"></i><span v-t="'page.newbrand.createnewbrand'"></span></li>
              <li><i class="fa fa-clock-o"></i><span v-t="'page.newbrand.fillimportantinformation'"></span></li>
              <li><i class="fa fa-clock-o"></i><span v-t="'page.newbrand.wecreateyourtext'"></span></li>
              <li><i class="fa fa-clock-o"></i><span v-t="'page.newbrand.bookmembership'"></span></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-8">
          <form v-if="!isMaintenance" @submit.prevent="doCreateBrand">
            <div class="form-container">
              <h3 v-t="'page.newbrand.title'"></h3>
              <div class="row">
                <div class="col-12">
                  <label for="brandName" v-t="'page.newbrand.brandname'"></label>
                  <input
                    v-model="currentBrand.Name"
                    type="text"
                    class="form-control"
                    required
                    id="brandName"
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-12 mb-system-type">
                  <div class="mb-title">
                    {{ $t('page.newbrand.systemtype') }}
                    <a
                      id="tt_yearOfFounding"
                      href="javascript:"
                      class="badge badge-warning"
                    >?</a>
                  <b-tooltip
                    target="tt_yearOfFounding"
                    placement="top"
                  >
                    <p v-t="'page.newbrand.systemtypehint'"></p>
                  </b-tooltip>

                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="systemType" name="systemType" id="type1" value="1" required>
                    <label class="form-check-label" for="type1" v-t="'systemform.franchise'"></label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="systemType" name="systemType" id="type2" value="2">
                    <label class="form-check-label" for="type2" v-t="'systemform.license'"></label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="systemType" name="systemType" id="type3" value="3">
                    <label class="form-check-label" for="type3" v-t="'systemform.else'"></label>
                  </div>
                </div>
              </div>
              <div v-if="systemType <= 2">
              <div class="row">
                <div class="col-sm-7">
                  <div class="form-group">
                    <label for="brandCompany" v-t="'page.newbrand.brandcompany'"></label>
                      <input
                        v-model="brandCompany.Name"
                        type="text"
                        class="form-control"
                        required
                        id="brandCompany"
                      >
                  </div>
                </div>
                <div class="col-sm-5">
                    <label for="brandStr" v-t="'address.street'"></label>*
                      <input
                        v-model="brandCompany.Street"
                        type="text"
                        class="form-control"
                        required
                        id="brandStr"
                      >
                </div>
              </div>
              <div class="row">
                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="brandZip" v-t="'address.zip'"></label>*
                      <input
                        v-model="brandCompany.Zip"
                        type="text"
                        class="form-control"
                        required
                        id="brandZip"
                      >
                  </div>
                </div>
                <div class="col-sm-5">
                  <div class="form-group">
                    <label for="brandCity" v-t="'address.city'"></label>*
                      <input
                        v-model="brandCompany.City"
                        type="text"
                        class="form-control"
                        required
                        id="brandCity"
                      >
                  </div>
                </div>
                <div class="col-sm-5">
                  <div class="form-group">
                    <label for="brandCountry" v-t="'address.country'"></label>*
                    <select
                      key="CountryCodeSelect"
                      v-model="brandCompany.CountryID"
                      class="form-control"
                      name="CountryCode"
                      id="brandCountry"
                      required
                    >
                      <option
                        v-for="(country) in app.getCountryListStartWithDeAtCh()"
                        :key="country.CountryID"
                        :value="country.CountryID"
                      >{{ country.Caption }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mb-user-type">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="userType" name="userType" id="user1" value="1" required>
                    <label class="form-check-label" for="user1" v-t="'page.newbrand.ibelongtocompany'"></label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="userType" name="userType" id="user2" value="2">
                    <label class="form-check-label" for="user2" v-t="'page.newbrand.imexternal'"></label>
                  </div>
                </div>
              </div>
              <div
                v-if="userType == 2"
              >
                <div>
                  <div class="row">
                    <div class="col-sm-7">
                      <div class="form-group">
                        <label for="userCompany" v-t="'page.newbrand.usercompany'"></label>
                          <input
                            v-model="userCompany.Name"
                            type="text"
                            class="form-control"
                            required
                            id="userCompany"
                          >
                      </div>
                    </div>
                    <div class="col-sm-5">
                        <label for="userStr" v-t="'address.street'"></label>*
                          <input
                            v-model="userCompany.Street"
                            type="text"
                            class="form-control"
                            required
                            id="userStr"
                          >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label for="userZip" v-t="'address.zip'"></label>*
                          <input
                            v-model="userCompany.Zip"
                            type="text"
                            class="form-control"
                            required
                            id="userZip"
                          >
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label for="userCity" v-t="'address.city'"></label>*
                          <input
                            v-model="userCompany.City"
                            type="text"
                            class="form-control"
                            required
                            id="userCity"
                          >
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label for="userCountry" v-t="'address.country'"></label>*
                        <select
                          key="CountryCodeSelect"
                          v-model="userCompany.CountryID"
                          class="form-control"
                          name="UserCountryCode"
                          id="userCountry"
                          required
                          @change="countryChange"
                        >
                          <option
                            v-for="(country) in app.getCountryListStartWithDeAtCh()"
                            :key="country.CountryID"
                            :value="country.CountryID"
                          >{{ country.Caption }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="mb-title" v-t="'page.newbrand.centralcontactperson'"></div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-2">
                    <div class="form-group">
                      <label for="centralSalutation" v-t="'contact.salutation'"></label>*
                      <select
                        v-model="centralContact.SalutationID"
                        type="text"
                        name="anrede"
                        class="form-control"
                        required
                        id="centralSalutation"
                      >
                        <option
                          v-for="salutation in $parent.preloads.salutations"
                          :key="salutation.SalutationID"
                          :value="salutation.SalutationID"
                        >
                          {{ salutation.Caption }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-5">
                    <div class="form-group">
                      <label for="centralFirstname" v-t="'contact.firstname'"></label>*
                        <input
                          v-model="centralContact.Firstname"
                          type="text"
                          class="form-control"
                          required
                          id="centralFirstname"
                        >
                    </div>
                  </div>
                  <div class="col-sm-5">
                    <div class="form-group">
                      <label for="centralLastname" v-t="'contact.lastname'"></label>*
                        <input
                          v-model="centralContact.Lastname"
                          type="text"
                          class="form-control"
                          required
                          id="centralLastname"
                        >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="centralPhone" v-t="'address.phone'"></label>*
                        <input
                          v-model="centralContact.Phone"
                          type="tel"
                          class="form-control"
                          required
                          id="centralPhone"
                        >
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="centralEmail" v-t="'address.mail'"></label>*
                        <input
                          v-model="centralContact.Mail"
                          type="email"
                          class="form-control"
                          :class="emailErrorClass"
                          required
                          id="centralEmail"
                        >
                        <div class="invalid-feedback" :class="emailErrorClass" v-t="'page.newbrand.mailerror'">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mb-3" v-t="'page.newbrand.centralcontactpersonhint'"></div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="1" id="userPerm" required v-model="aceptTerms">
                    <label class="form-check-label" for="userPerm" v-t="'page.newbrand.allowedtocreatebrand'"></label>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <button
                  ref="doCreateBrand"
                  type="submit"
                  class="btn btn-primary"
                  data-style="zoom-in"
                  v-t="'page.newbrand.createbrandbutton'"
                ></button>
              </div>
              <small v-t="'page.newbrand.mandantoryfields'"></small>
            </div>
            <div
              v-else
              class="mb-type-info"
              v-html="$t('page.newbrand.systemtypenotsupported')"
            ></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Ladda from 'ladda'
import Config from '../config'

export default {
  name: 'MarkeAnlegen',
  components: {
  },
  props: [],
  data: function () {
    return {
      systemType: 0,
      userType: 0,
      userCompany: {},
      centralContact: {},
      currentBrand: {},
      brandCompany: {},
      contactCompany: {},
      loader: null,
      isMaintenance: false,
      errorClass: 'mb-field-error',
      emailErrorClass: '',
      aceptTerms: 0
    }
  },
  computed: {
    currentAppVersion: function () {
      return Config.CLIENT_VERSION
    },
    isLoggedIn: function () {
      return this.$parent.isLoggedIn
    },
    app: function () {
      return this.$parent || {}
    }
  },
  watch: {
    'app.currentContact': function () {
      if (this.app.currentContact != null && this.app.currentContact.CompanyID != null) this.userCompany = this.app.currentContact.Company
    }
  },
  mounted: function () {
    this.$refs.doCreateBrand.loader = Ladda.create(this.$refs.doCreateBrand)
    this.app.showSaveSuccessPopup = false
    this.app.showSaveBar = false
    this.app.rewriteDefaultValuesOnLoadBrand = false
    this.app.resetBrand()
    this.resetValues()
    this.app.navIsOpen = false
    this.userCompany = this.app.currentContact.Company || {}
    this.creatingBrandInProcess = false
  },
  methods: {
    startLoader () {
      if (
        typeof this.$refs.doCreateBrand !== 'undefined' &&
        typeof this.$refs.doCreateBrand.loader !== 'undefined'
      ) {
        this.$refs.doCreateBrand.loader.start()
      }
    },
    stopLoader () {
      if (
        typeof this.$refs.doCreateBrand !== 'undefined' &&
        typeof this.$refs.doCreateBrand.loader !== 'undefined'
      ) {
        this.$refs.doCreateBrand.loader.stop()
      }
    },
    countryChange () {
      const country = this.app.getCountryByCountryCode(this.userCompany.CountryCode)
      return country.CountryID
    },
    doCreateBrand () {
      if (this.creatingBrandInProcess) return
      this.creatingBrandInProcess = true
      this.startLoader()
      return new Promise((resolve, reject) => {
        Promise.all([
          this.checkEmail()
        ])
          .then((values) => {
            return new Promise((resolve, reject) => {
              const loggedInContact = this.app.currentContact
              let brandCompanyID = null
              this.brandCompany.CorrespondenceLanguageID = (this.$i18n.locale === 'de' ? 2 : 5)
              this.createCompany(this.brandCompany)
                .then((result) => {
                  brandCompanyID = result

                  const newBrand = new Promise((resolve, reject) => {
                    if (this.systemType === '1') {
                      this.currentBrand.ConceptExpansionFormFranchise = 1
                      this.currentBrand.ConceptExpansionFormLicense = 0
                    } else if (this.systemType === '2') {
                      this.currentBrand.ConceptExpansionFormFranchise = 0
                      this.currentBrand.ConceptExpansionFormLicense = 1
                    }
                    this.currentBrand.InquiryMail = loggedInContact.Mail
                    this.currentBrand.InternalContactMail = loggedInContact.Mail
                    this.currentBrand.InterestedMail = loggedInContact.Mail
                    this.currentBrand.CompanyID = brandCompanyID
                    this.currentBrand.PflichfeldStatusID = 0 // Unvollständig
                    this.currentBrand.StatusID = 2
                    this.currentBrand.Membership = 0
                    this.currentBrand.DataQualityID = 2 // Adresse
                    this.currentBrand.SDStatus = 0
                    this.currentBrand.CompletedAVV = 0
                    this.currentBrand.TrailerPromotionAvailable = 0

                    this.createBrand()
                      .then(
                        (result) => {
                          const newBrandID = result.body.Result.NewID
                          Promise.all([
                            this.createBrandRole(loggedInContact.ContactID, newBrandID, 2), // Kontakt für Interessenten
                            this.createBrandRole(loggedInContact.ContactID, newBrandID, 5), // Primärkontakt
                            this.createBrandRole(loggedInContact.ContactID, newBrandID, 4), // Markenverwalter
                            this.createCompanyRoleOrBrandRole(brandCompanyID, loggedInContact.ContactID, newBrandID)
                          ])
                            .then((values) => {
                              if (this.userType === '1') { // Inhaber ect.
                                if (loggedInContact.CompanyID === null) {
                                  loggedInContact.CompanyID = brandCompanyID
                                  const contactUpdate = {
                                    ContactID: loggedInContact.ContactID,
                                    CompanyID: brandCompanyID
                                  }
                                  this.updateContact(contactUpdate)
                                    .then((result) => {
                                    },
                                    (error) => {
                                      console.info(error)
                                    })
                                }
                                this.app.loadAvailableBrands()
                                  .then(() => {
                                    this.app.loadBrand(newBrandID)
                                      .then(() => {
                                        this.$parent.storeNewBrandID(newBrandID)
                                        this.$parent.shortlyRegisteredBrand = true
                                        this.sendNewBrandEmail(newBrandID)
                                        this.stopLoader()
                                        this.resetValues()
                                        this.$router.push('/markeanlegendetails#' + newBrandID)
                                        resolve()
                                      })
                                  })
                              } else if (this.userType === '2') { // Externer Berater
                                if (loggedInContact.CompanyID === null) {
                                  this.createCompany(this.userCompany)
                                    .then((result) => {
                                      const contactUpdate = {
                                        ContactID: loggedInContact.ContactID,
                                        CompanyID: result
                                      }
                                      this.updateContact(contactUpdate)
                                    })
                                }
                                this.createBrandRole(loggedInContact.ContactID, newBrandID, 7) // Externer Partner
                                this.centralContact.CompanyID = brandCompanyID
                                this.createContact(this.centralContact)
                                  .then((result) => {
                                    this.createBrandRole(result, newBrandID, 6) // Sonstiges
                                    this.createCompanyRole(brandCompanyID, result, 9) // Sonstiges
                                      .then(() => {
                                        this.app.loadAvailableBrands()
                                          .then(() => {
                                            this.$parent.loadBrand(newBrandID)
                                              .then(() => {
                                                this.$parent.storeNewBrandID(newBrandID)
                                                this.$parent.shortlyRegisteredBrand = true
                                                this.sendNewBrandEmail(newBrandID)
                                                this.stopLoader()
                                                this.resetValues()
                                                this.$router.push('/markeanlegendetails#' + newBrandID)
                                                resolve()
                                              })
                                          })
                                      })
                                  })
                              }
                            },
                            (error) => {
                              console.info(error)
                              this.app.showSaveError()
                              this.stopLoader()
                            })
                        })
                  })
                  console.info(newBrand)
                })
            },
            (errors) => {
              console.info(errors)
              this.stopLoader()
            })

          // this.$parent.currentBrand = this.currentBrand
          // this.$parent.saveBrand()
          })
          .finally(() => {
            this.stopLoader()
            this.creatingBrandInProcess = false
          })
      }).catch((error) => {
        console.info(error)
        this.creatingBrandInProcess = false
      })
    },
    sendNewBrandEmail (newBrandID) {
      const postData = {
        BrandID: newBrandID,
        UserType: this.userType
      }

      return this.app.APIStripe._post(
        'registerBrand',
        postData
      )
    },
    updateContact (contact) {
      return new Promise((resolve, reject) => {
        this.app.API._put('contact/', contact)
          .then((result) => {
            resolve(result)
          })
      })
    },
    createBrand () {
      return new Promise((resolve, reject) => {
        this.currentBrand.BrandID = -1
        this.app.API
          ._put('brand/', this.currentBrand)
          .then((response) => {
            const resultString = response.body.Result.Result

            if (resultString === 'Succeeded') {
              resolve(response)
            } else {
              this.app.showSaveError()
            }
          })
          .catch((error) => {
            console.info(error)
          })
      })
    },
    createBrandRole (contactID, newBrandID, brandRoleID) {
      return new Promise((resolve, reject) => {
        if (!contactID) return reject(new Error('ContactID is empty'))
        const newContactBrandRole = {
          ContactBrandRoleID: -1,
          ContactID: contactID,
          BrandID: newBrandID,
          BrandRoleID: brandRoleID
        }

        this.app.API._put(
          'contactbrandrole/',
          newContactBrandRole
        ).then((result) => {
          resolve(result)
        },
        () => {
          reject(new Error('Fehler beim erstellen der Brandrole'))
        })
      })
    },
    createCompanyRoleOrBrandRole (companyID, contactID, brandID) {
      if (this.userType === '1') {
        return this.createCompanyRole(companyID, contactID, 9) // Sonstiges
      } else if (this.userType === '2') {
        return this.createBrandRole(contactID, brandID, 7) // Primärkontakt
      }
    },
    createCompanyRole (companyID, contactID, companyRoleID) {
      return new Promise((resolve, reject) => {
        const data = {
          ContactCompanyRoleID: -1,
          CompanyID: companyID,
          ContactID: contactID,
          CompanyRoleID: companyRoleID
        }
        this.app.API._put('contactCompanyRole/', data).then(response => {
          if (
            response.status === 200 &&
            response.body.Result.Result === 'Succeeded'
          ) {
            resolve(response)
          } else {
            reject(new Error('ContactCompanyRole error'))
          }
        })
      })
    },
    createCompany (company) {
      const countryList = this.$parent.preloads.countries.filter(function (country) {
        return country.CountryID === company.CountryID
      })

      if (countryList.length > 0) {
        const country = countryList.shift()
        company.CountryCode = country.Code
        // company.Country = country.Caption
        delete company.CountryID
      }
      if (company.CorrespondenceLanguageID == null) company.CorrespondenceLanguageID = 2 // Deutsch

      return new Promise((resolve, reject) => {
        this.app.API._put('company/', company)
          .then((response) => {
            const resultString = response.body.Result.Result

            if (resultString === 'Succeeded') {
              resolve(response.body.Result.NewID)
            } else {
              this.app.showSaveError()
              reject(new Error('Ein Fehler ist beim Speichern der company aufgetreten'))
            }
          })
          .catch((error) => {
            console.error(error)
            this.app.showSaveError()
          })
      })
    },
    createContact (contact) {
      return new Promise((resolve, reject) => {
        if (contact.ContactID === null) contact.ContactID = -1
        this.app.API._put('contact/', contact)
          .then((response) => {
            const resultString = response.body.Result.Result

            if (resultString === 'Succeeded') {
              resolve(response.body.Result.NewID)
            } else {
              this.app.showSaveError()
              reject(new Error('Ein Fehler ist beim Speichern des contact aufgetreten'))
            }
          })
          .catch((error) => {
            console.error(error)
            this.app.showSaveError()
          })
      })
    },
    checkEmail () {
      return new Promise((resolve, reject) => {
        if (this.userType === '1') return resolve(true)
        const res = this.$parent.checkEmail(this.centralContact.Mail)
        res.then(
          (result) => {
            this.emailErrorClass = ''
            resolve(result.state)
          },
          (error) => {
            this.loginError = true
            this.loginErrorMessage = 'nope'
            this.emailErrorClass = this.errorClass
            this.stopLoader()
            reject(error)
          }
        )
      })
    },
    showLoginError () {
      this.password = ''
      this.stopLoader()
    },
    resetValues () {
      this.currentBrand = {}
      this.firstname = ''
      this.lastname = ''
      this.phone = ''
      this.email = ''
      this.password = ''
      this.passwordrepeat = ''
      this.showPwdInfo = false
      this.loader = null
      this.loginError = false
      this.loginErrorMessage = ''
      this.loginExists = false
      this.emailErrorClass = ''
      this.pwdErrorClass = ''
      this.pwdStrengthClass = ''
      this.pwdRepErrorClass = ''
      this.userType = 0
      this.systemType = 0
      this.userCompany = {}
      this.centralContact = {}
      this.brandCompany = {}
      this.contactCompany = {}
      this.isMaintenance = false
      this.aceptTerms = 0
      this.creatingBrandInProcess = false
    }
  }
}
</script>

<style lang="scss" scoped>
.mb-markeanlegen {
  > form {
    > .form-group {
      margin-bottom: 30px;
    }

    .btn {
      width: 100%;
      margin-bottom: 10px;
    }

    a {
      color: white;
      text-align: center;
    }
  }
}
.clientVersionHint {
  font-size: 10px;
  color: #fff;
}
</style>
