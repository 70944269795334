<template>
  <FairFieldEditor :title="computedTitle" :index="15" buttonSaveText="Korrekturwunsch absenden"
    :showContentOnEdit="true" :loading="isLoading" @save="handleSave" :isEditing="isEditing"
    :saveDisabled="!computedIsValid" :is-modal-open.sync="isModalOpen" @toggleEdit="toggleEdit" @toggle="toggleContent"
    :showOpenButton="false" :usePageStore="true">
    <div class="d-flex flex-column justify-content-start align-items-start gap-3">
      <div>
        {{ currentBrand.Name }} expandiert weiter und strebt danach, in ganz Deutschland präsent zu sein.
      </div>
      <hr />
      <div>
        Aktuell bieten die folgenden Städte besonders gute Möglichkeiten für neue Franchise-Gründungen oder einen
        schnellen Einstieg durch Übernahme bestehender Standorte:
      </div>
      <div class="d-flex gap-3 w-100">
        <img class="flex-shrink-0" src="@/assets/images/new-fair/rps.png" />
        <div class="d-flex flex-column gap-2 w-100">
          <div class="rps-entry-row" v-for="(entry, index) of entries" :key="index">
            <span class="type">{{ entry.type }}</span>
            <span class="place">{{ entry.place }}</span>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:edit>
      <hr />
      <div class="d-flex flex-column w-100 gap-3">
        <input type="text" class="form-control w-100" :placeholder="formFields.placeholder"
          v-model="formFields.value" />
        <div class="d-flex flex-column gap-1">
          <span class="fw-bold">Anforderungen:</span>
        </div>
      </div>
    </template>
    <template v-slot:editButtonCaption>
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-pencil"></i>
        <span>Bearbeiten</span>
      </div>
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/15_RPS.png" />
    </template>
  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'

export default {
  name: 'FairFormRPS',
  components: {
    FairFieldEditor
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      formFields: {
        placeholder: 'Tragen Sie hier Ihren neuen Vorschlag ein.',
        value: ''
      },
      entries: [
        { type: 'Nachfolge', place: '25664 Jever' },
        { type: 'Neugründung', place: '28325 Rostock' },
        { type: 'Neugründung', place: '28325 Rostock' },
        { type: 'Nachfolge', place: '25664 Jever' },
        { type: 'Nachfolge', place: '25664 Jever' },
        { type: 'Neugründung', place: '28325 Rostock' },
        { type: 'Nachfolge', place: '25664 Jever' },
        { type: 'Neugründung', place: '28325 Rostock' },
        { type: 'Neugründung', place: '28325 Rostock' },
        { type: 'Neugründung', place: '28325 Rostock' }
      ]
    }
  },
  methods: {
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Änderungen erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Änderungen ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async submitForm () {
      // await this.app.APIService._post('mb/fair/new/2024/step/1', {
      //   brandId: this.currentBrand.BrandID,
      //   value: this.formFields.value
      // })
    }
  },
  computed: {
    computedTitle () {
      return `Regionale Suche: Deine Chance - ${this.currentBrand.Name} sucht dich!`
    },
    computedIsValid () {
      return this.formFields.value.length > 0 && this.formFields.value.length <= 65
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    },
    computedShortBrandName () {
      return this.currentBrand.ShortBrandName ?? this.currentBrand.Name ?? ''
    },
    computedExpansionFormCaption () {
      if (this.currentBrand) {
        if (this.currentBrand.ConceptExpansionFormFranchise) return 'Franchise'
        if (this.currentBrand.ConceptExpansionFormLicense) return 'Lizenz'
      }
      return ''
    }
  }

}
</script>

<style lang="scss" scoped>
.rps-entry-row {
  display: flex;
  background-color: #EFF1F4;
  width: 100%;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  .type {
    width: 150px;
  }

  .place {
    font-weight: 700;
  }

}
</style>
