<template>
  <div class="new-fair-form">
    <div class="new-fair-form-container container-fluid d-flex flex-column gap-5">
      <header class="row">
        <div class="col-12 col-lg-10 d-flex flex-column gap-3 order-1 order-lg-0" v-if="!isInitialLoadFinished">
          <span>Neuer Messestand</span>
          <b-skeleton width="80%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
          <small>Wir laden Ihre Inhalte. Bitte haben Sie einen Moment Geduld.</small>
        </div>
        <div class="col-12 col-lg-10 d-flex flex-column gap-3 order-1 order-lg-0" :style="{
          lineHeight: '56px'
        }" v-else>
          <span>Neuer Messestand für {{ computedBrand.Name }}:</span>
          <span>Bitte überprüfen Sie die neuen Inhalte</span>
        </div>
        <div class="col-12 col-lg-2 d-flex align-items-start justify-content-end order-0 order-lg-1">
          <a to="https://www.franchiseportal.de" target="_blank">
            <img src="@/assets/images/fp-logo.svg" class="img-fluid logo" />
          </a>
        </div>
      </header>

      <div class="d-flex flex-column gap-2 align-items-start"
        v-if="isInitialLoadFinished && availableBrands.length > 1">

        <div class="d-flex gap-2 align-items-center">
          <label for="selectBrandControl">
            <strong class="flex-grow-1">Inhalte für eine andere Marke prüfen:</strong>
          </label>

          <div class="d-flex position-relative">
            <b-form-select id="selectBrandControl" class="custom-select form-control pe-5" v-model="selectedBrandId"
              :options="availableBrands" @change="handleBrandChange">
            </b-form-select>
            <div class="position-absolute top-50 end-0 translate-middle-y pe-3 pe-none">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M4 6L8 10L12 6" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
          </div>

        </div>

        <div class="d-flex align-items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M7.99967 14.6666C11.6816 14.6666 14.6663 11.6819 14.6663 7.99998C14.6663 4.31808 11.6816 1.33331 7.99967 1.33331C4.31778 1.33331 1.33301 4.31808 1.33301 7.99998C1.33301 11.6819 4.31778 14.6666 7.99967 14.6666Z"
              stroke="#E63A14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 10.6667H8.00667" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M8 5.33331V7.99998" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <span>Prüfen Sie die Inhalte für alle Ihre Marken, mit denen Sie eine aktive Mitgliedschaft haben!</span>
        </div>

      </div>

      <section v-if="isInitialLoadFinished">

        <div class="card bg-white">
          <div class="card-body">
            <div class="d-flex flex-column gap-4 all-steps">

              <!-- STEP 1 -->
              <FairFieldEditor title="Überschrift" :index="1" :isOpen="computedCurrentIsOpen(1)"
                buttonSaveText="Korrekturwunsch absenden" @toggle="toggleContent" :showContentOnEdit="true"
                :loading="isStepLoading" @save="handleSaveStep1" :isEditing="computedCurrentIsEditing(1)"
                :saveDisabled="!computedIsStep1Valid" :is-modal-open.sync="modalOpened.step1" @toggleEdit="toggleEdit">
                <div class="d-flex flex-column fw-bold">
                  <span><strong class="color-primary">{{ computedBrand.Name }}</strong> {{
                    computedExpansionFormCaption }}:</span>
                  <span>{{ computedBrand.ConceptBusinessType }}</span>
                </div>
                <template v-slot:edit>
                  <hr />
                  <div class="d-flex flex-column w-100 gap-3">
                    {{ currentEditingTabIndex }}
                    <input type="text" class="form-control w-100" :placeholder="formFields.step1.placeholder"
                      v-model="formFields.step1.value" />
                    <div class="d-flex flex-column gap-1">
                      <span class="fw-bold">Anforderungen:</span>
                      <ul class="special">
                        <li>Die erste Zeile lautet immer “<em>{{ computedBrand.Name }} {{ computedExpansionFormCaption
                            }}</em>:”</li>
                        <li :class="{
                          success: formFields.step1.value.length <= 65,
                          error: formFields.step1.value.length > 65
                        }">Die zweite Zeile darf <strong>max. 65 Zeichen</strong> haben</li>
                        <li>Der Satz in der 2. Zeile sollte aktivierend / motivierend sein, wie zum Beispiel: Starte /
                          Eröffne / Kreiere / Verwirkliche / Biete / Verbreite / Erobere ...</li>
                        <li>Die Überschrift sollte idealerweise den User emotional ansprechen</li>
                        <li>Es muss erkennbar sein, was zukünftige Franchise-Partner machen oder anbieten werden</li>
                        <li class="d-flex align-items-start inner-list">
                          <div class="d-flex flex-column align-items-start gap-1">
                            <strong>Beispiel:</strong>
                            <span>“Kreiere Wohnwelten aus nachwachsenden Rohstoffen”</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
                <template v-slot:editButtonCaption>
                  Stimmt etwas nicht?
                </template>
                <template v-slot:preview>
                  <img class="img-fluid" src="@/assets/images/new-fair/1_Headline.png" />
                </template>
              </FairFieldEditor>

              <!-- STEP 2 -->
              <FairFieldEditor title="Begrüßung am Messestand" :index="2" :isOpen="computedCurrentIsOpen(2)"
                buttonSaveText="Änderungen speichern" @toggle="toggleContent" @save="handleSaveStep2"
                :is-modal-open.sync="modalOpened.step2" :loading="isStepLoading" :saveDisabled="!computedIsStep2Valid">

                <div class="d-flex flex-column flex-md-row gap-3 w-100">
                  <div class="portrait-container">
                    <img v-if="computedFair.FairContactPortrait" :src="computedFairContactPortrait" />
                  </div>
                  <div class="d-flex flex-column gap-2 flex-grow-1">

                    <span><strong>Ansprechpartner für Interessenten:</strong> {{ computedFair.FairContactName
                      }}</span>
                    <span><strong>Email des Ansprechpartners:</strong> {{ computedBrand.InterestedMail }}</span>

                    <div class="d-flex flex-column gap-2 p-3 quote-box">
                      <div v-html="computedFair.FairContactWelcome" />
                    </div>

                  </div>
                </div>

                <template v-slot:edit>

                  <div class="d-flex flex-column gap-3">

                    <div class="d-flex flex-column flex-md-row gap-4 w-100">
                      <div class="d-flex flex-column gap-2 flex-grow-1">
                        <span class="blue-headline">Name des Ansprechpartners für Interessenten</span>
                        <input type="text" class="form-control w-100" v-model="formFields.step2.FairContactName" />
                      </div>
                      <div class="d-flex flex-column gap-2 flex-grow-1">
                        <span class="blue-headline">Email des Ansprechpartners (Nur im Infopaket)</span>
                        <input type="text" class="form-control w-100" v-model="formFields.step2.FairContactMail" />
                      </div>
                    </div>

                    <div class="d-flex flex-column flex-md-row gap-4">
                      <div class="d-flex flex-column gap-2">
                        <span class="blue-headline">Ansprechpartner-Foto</span>
                        <image-connected-selector v-model="formFields.step2.FairContactPortraitID" class="mb-edit-img"
                          :default="defaultImage" :save="saveImageToContact" :ratio="1" editClass="silhouette-portrait"
                          :resolution-hint="$t('image.recoprofilepicture')" />
                      </div>
                      <div class="d-flex flex-column gap-2 flex-grow-1">
                        <span class="blue-headline">Begrüßungstext am Messestand</span>
                        <textarea class="form-control w-100 flex-grow-1 py-3" rows="10" style="min-height: 200px;"
                          v-model="formFields.step2.FairContactWelcome"></textarea>
                        <div class="d-flex flex-column flex-md-row gap-2">
                          <a href="javascript:" class="btn btn-secondary flex-grow-1"
                            @click="generateFairContactWelcome('collegial')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17"
                              fill="none">
                              <path d="M1.9165 14.1298V10.1298H5.9165" stroke="#00719F" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M16.5835 3.46301V7.46301H12.5835" stroke="#00719F" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path
                                d="M3.58984 6.7964C3.92795 5.84092 4.50259 4.98667 5.26015 4.31334C6.0177 3.64002 6.93348 3.16957 7.92203 2.9459C8.91058 2.72223 9.93967 2.75262 10.9133 3.03425C11.8869 3.31587 12.7733 3.83954 13.4898 4.5564L16.5832 7.46306M1.9165 10.1297L5.00984 13.0364C5.72634 13.7533 6.61275 14.2769 7.58638 14.5585C8.56 14.8402 9.5891 14.8706 10.5776 14.6469C11.5662 14.4232 12.482 13.9528 13.2395 13.2795C13.9971 12.6061 14.5717 11.7519 14.9098 10.7964"
                                stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span>Neuen Vorschlag generieren (Locker)</span>
                          </a>
                          <a href="javascript:" class="btn btn-secondary flex-grow-1"
                            @click="generateFairContactWelcome('factual')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17"
                              fill="none">
                              <path d="M1.9165 14.1298V10.1298H5.9165" stroke="#00719F" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M16.5835 3.46301V7.46301H12.5835" stroke="#00719F" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path
                                d="M3.58984 6.7964C3.92795 5.84092 4.50259 4.98667 5.26015 4.31334C6.0177 3.64002 6.93348 3.16957 7.92203 2.9459C8.91058 2.72223 9.93967 2.75262 10.9133 3.03425C11.8869 3.31587 12.7733 3.83954 13.4898 4.5564L16.5832 7.46306M1.9165 10.1297L5.00984 13.0364C5.72634 13.7533 6.61275 14.2769 7.58638 14.5585C8.56 14.8402 9.5891 14.8706 10.5776 14.6469C11.5662 14.4232 12.482 13.9528 13.2395 13.2795C13.9971 12.6061 14.5717 11.7519 14.9098 10.7964"
                                stroke="#00719F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span>Neuen Vorschlag generieren (Professionell)</span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex flex-column gap-3 new-text-area" v-if="computedHasGeneratedText">

                      <div class="d-flex flex-column gap-2">

                        <div class="d-flex justify-content-between align-items-center">
                          <span class="headline">Neuer Vorschlag <em>({{ computedGeneratedTextType }})</em></span>
                          <a href="javascript:" @click="resetGeneratedFairWelcome">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                              fill="none">
                              <path d="M12 4.79639L4 12.7964" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                              <path d="M4 4.79639L12 12.7964" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            </svg>
                          </a>
                        </div>

                        <div v-html="computedGeneratedText" />

                      </div>

                      <a href="javascript:" class="btn btn-secondary" @click="useGeneratedText">
                        Vorschlag übernehmen und anpassen
                      </a>

                    </div>

                  </div>

                  <hr />
                  <div class="d-flex flex-column w-100 gap-3">
                    <div class="d-flex flex-column gap-1">
                      <span class="fw-bold">Anforderungen:</span>
                      <ul class="special">
                        <li :class="{
                          success: String(formFields.step2.FairContactWelcome).includes(formFields.step2.FairContactName),
                          error: !String(formFields.step2.FairContactWelcome).includes(formFields.step2.FairContactName)
                        }">Der Text muss den Namen <strong>“{{ formFields.step2.FairContactName }}”</strong> enthalten
                        </li>
                        <li :class="{
                          success: String(formFields.step2.FairContactWelcome).includes(computedBrand.Name) || (computedBrand.ShortBrandName ? String(formFields.step2.FairContactWelcome).includes(computedBrand.ShortBrandName) : false),
                          error: !String(formFields.step2.FairContactWelcome).includes(computedBrand.Name) && !(computedBrand.ShortBrandName ? String(formFields.step2.FairContactWelcome).includes(computedBrand.ShortBrandName) : false)
                        }">Der Text muss den Markennamen <strong>“{{ computedBrand.Name }}”</strong>
                          <template v-if="computedBrand.ShortBrandName">oder
                            <strong>“{{ computedBrand.ShortBrandName }}”</strong>
                          </template>
                          enthalten
                        </li>
                        <li :class="{
                          success: formFields.step2.FairContactWelcome.length <= 400,
                          error: formFields.step2.FairContactWelcome.length > 400
                        }">Der Text darf <strong>max. 400 Zeichen</strong> haben</li>
                        <li>Der Text soll eine freundliche Begrüßung des Messestand-Besuchers enthalten</li>
                        <li>Der Text soll dem Interessenten zeigen, dass Sie ihn auf dem weiteren Weg beraten und
                          begleiten werden</li>
                        <li>Der Name sollte von einer echten Person sein (<strong>Kein</strong> “Franchise-Team” o.ä.)
                        </li>
                        <li>Das Foto sollte Sie freundlich lächelnd zeigen und eine gute Qualität haben</li>
                      </ul>
                    </div>
                  </div>
                </template>

                <template v-slot:editButtonCaption>
                  <div class="d-flex align-items-center gap-2">
                    <i class="fa fa-pencil"></i>
                    <span>Bearbeiten</span>
                  </div>
                </template>
                <template v-slot:preview>
                  <img class="img-fluid" src="@/assets/images/new-fair/2_WelcomeText.png" />
                </template>

              </FairFieldEditor>

              <!-- STEP 3 -->
              <FairFieldEditor title="Was wird gegründet?" :index="3" :isOpen="computedCurrentIsOpen(3)"
                :loading="isStepLoading" :showContentOnEdit="false" :isEditing="computedCurrentIsEditing(3)"
                @toggleEdit="toggleEdit" @save="handleSaveStep3" buttonSaveText="Korrekturwunsch absenden"
                :saveDisabled="!computedIsStep3Valid" :is-modal-open.sync="modalOpened.step3" @toggle="toggleContent">

                <div class="d-flex flex-column gap-3">
                  <span class="fw-bold">{{ computedBrand.FoundationPurpose }}</span>
                  <span>Bsp.: <em v-html="computedFoundationText" /></span>
                </div>

                <template v-slot:editButtonCaption>
                  Stimmt etwas nicht?
                </template>
                <template v-slot:preview>
                  <img class="img-fluid" src="@/assets/images/new-fair/3_FoundationPurpose.png" />
                </template>

                <template v-slot:edit>

                  <div class="d-flex flex-column gap-3">
                    <span class="fw-bold">{{ computedBrand.FoundationPurpose }}</span>
                    <div class="d-flex flex-column">
                      <span>Dieser Begriff muss in Sätze eingebettet werden können.</span>
                      <span>Bsp.: <em v-html="computedFoundationText" /></span>
                    </div>
                  </div>

                  <hr />
                  <div class="d-flex flex-column w-100 gap-3">
                    <input type="text" class="form-control w-100" :placeholder="formFields.step3.placeholder"
                      v-model="formFields.step3.value" />
                    <div class="d-flex flex-column gap-1">
                      <span class="fw-bold">Anforderungen:</span>
                      <ul class="special">
                        <li :class="{
                          success: formFields.step3.value.length <= 40,
                          error: formFields.step3.value.length > 40
                        }"
                        >Der Begriff soll möglichst kurz sein und darf max. 40 Zeichen haben</li>
                        <li>Der Begriff sollte das Partnergeschäft präzise und verständlich umschreiben</li>
                        <li>Der Begriff sollte aus sprachlichen Gründen keine Adjektive enthalten</li>
                        <li class="d-flex align-items-start inner-list">
                          <div class="d-flex flex-column align-items-start gap-1">
                            <strong>Beispiele:</strong>
                            <ul>
                              <li>Traditions-Bäckerei</li>
                              <li>Alltagshilfe für Pflegebedürftige</li>
                              <li>Handlauf-Installationsdienst</li>
                              <li>Leckortungs-Service</li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>

              </FairFieldEditor>

              <!-- STEP 4 -->
              <FairFieldEditor title="Konditionen: Was kostet es zu starten?" :index="4" :loading="isStepLoading"
                @save="handleSaveStep4" buttonSaveText="Änderungen speichern" :isOpen="computedCurrentIsOpen(4)"
                :is-modal-open.sync="modalOpened.step4" @toggle="toggleContent" :isEditing="computedCurrentIsEditing(4)"
                :saveDisabled="!computedIsStep4Valid">

                <div class="quote-box">

                  <div><strong>Gesamtinvestition:</strong> {{ computedCondCaptions.CondInvestSumCaption }}</div>

                  <div class="d-flex align-items-center gap-2 ps-4">
                    <i class="fa fa-arrow-right"></i>
                    <span><strong>Davon Eigenkapitalbedarf:</strong> {{
                      computedCondCaptions.CondRequiredCapitalCaption }}</span>
                  </div>

                  <div class="error d-flex align-items-center gap-2 ps-5" v-if="computedHasCondError.RequiredCapital">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    <span>darf das Minimum der Gesamtinvestition ({{
                      computedCondInvestSumMinimum }})
                      <strong>nicht
                        überschreiten</strong></span>
                  </div>

                  <div class="d-flex align-items-center gap-2 ps-4">
                    <i class="fa fa-arrow-right"></i>
                    <span><strong>Davon Eintrittsgebühr:</strong> {{ computedCondCaptions.CondEntryFeeCaption
                      }}</span>
                  </div>

                  <div class="error d-flex align-items-center gap-2 ps-5" v-if="computedHasCondError.EntryFee">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    <span>darf das Minimum der Gesamtinvestition ({{
                      computedCondInvestSumMinimum }})
                      <strong>nicht
                        überschreiten</strong></span>
                  </div>

                  <div class="d-flex align-items-center gap-2 ps-4">
                    <i class="fa fa-arrow-right"></i>
                    <span><strong>Davon Erstausstattung:</strong> {{ computedCondCaptions.CondInitialCapitalCaption
                      }}</span>
                  </div>

                  <div class="error d-flex align-items-center gap-2 ps-5" v-if="computedHasCondError.InitialCapital">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    <span>darf das Minimum der Gesamtinvestition ({{
                      computedCondInvestSumMinimum }})
                      <strong>nicht
                        überschreiten</strong></span>
                  </div>

                </div>

                <hr />

                <div v-if="!computedHasCondError.RequiredCapital">Starte mit <strong>{{
                  formatCurrency(currentBrand.CondRequiredCapitalFrom) }} Eigenkapital</strong> und sichere dir damit
                  weitere
                  <strong>{{ formatCurrency(currentBrand.CondInvestSumFrom - currentBrand.CondRequiredCapitalFrom) }}
                    Fremdkapital</strong>, um die <strong>Gesamtinvestition von mind. {{ computedCondInvestSumMinimum
                    }}</strong> zu
                  erreichen
                  und deinen Traum
                  zu verwirklichen.
                </div>
                <div v-else>
                  Starte mit <strong>{{ formatCurrency(currentBrand.CondRequiredCapitalFrom) }} Eigenkapital</strong>,
                  um deinen Traum
                  zu verwirklichen.
                </div>

                <div v-html="computedCondDescription"></div>

                <template v-slot:editButtonCaption>
                  <div class="d-flex align-items-center gap-2">
                    <i class="fa fa-pencil"></i>
                    <span>Bearbeiten</span>
                  </div>
                </template>
                <template v-slot:preview>
                  <img class="img-fluid" src="@/assets/images/new-fair/4_Conditions.png" />
                </template>

                <template v-slot:edit>

                  <div class="quote-box cond-box">

                    <div class="d-flex flex-column gap-2 w-100">

                      <span class="blue-headline">Wie hoch ist die Gesamtinvestitionssumme für eine Gründung mit {{
                        computedBrand.Name
                        }}?</span>

                      <div class="d-flex flex-sm-row flex-column justify-content-start align-items-center gap-3">

                        <!-- input dropdown (von/bis; fix; ab) -->

                        <b-form-select class="form-control flex-grow-0 w-100"
                          v-model="formFields.step4.CondInvestSumType"
                          :options="computedCondFinanceTypes"></b-form-select>
                        <hr class="vertical d-none d-sm-block" />

                        <currency-input class="form-control flex-grow-0" v-model="formFields.step4.CondInvestSumFrom"
                          currency="EUR" locale="de" :allow-negative=false required />
                        <template v-if="formFields.step4.CondInvestSumType === 1">
                          <span>bis</span>
                          <currency-input class="form-control flex-grow-0" v-model="formFields.step4.CondInvestSumTo"
                            currency="EUR" locale="de" :allow-negative=false required />
                        </template>

                      </div>

                      <hr />

                      <div class="d-flex flex-column gap-3 ps-3">

                        <div class="d-flex flex-column gap-2">

                          <div class="d-flex align-items-center justify-content-start gap-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                              fill="none">
                              <path d="M3.3335 8.79639H12.6668" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                              <path d="M8 4.12964L12.6667 8.79631L8 13.463" stroke="#3F4957" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <span class="blue-headline">Wievel davon muss als Eigenkapital vorhanden sein?</span>

                          </div>

                          <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center gap-3"
                            :style="{
                              paddingLeft: '24px'
                            }">

                            <b-form-select class="form-control flex-grow-0"
                              v-model="formFields.step4.CondRequiredCapitalType"
                              :options="computedCondFinanceTypes"></b-form-select>
                            <hr class="vertical d-none d-sm-block" />

                            <currency-input class="form-control flex-grow-0"
                              v-model="formFields.step4.CondRequiredCapitalFrom" currency="EUR" locale="de"
                              :allow-negative=false required />

                            <template v-if="formFields.step4.CondRequiredCapitalType === 1">
                              <span>bis</span>
                              <currency-input class="form-control flex-grow-0"
                                v-model="formFields.step4.CondRequiredCapitalTo" currency="EUR" locale="de"
                                :allow-negative=false required />
                            </template>

                          </div>

                          <div class="error d-flex align-items-center gap-2"
                            v-if="computedHasCondErrorInEditForm.RequiredCapital" :style="{
                              paddingLeft: '24px'
                            }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                              fill="none">
                              <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                              <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            </svg>
                            <span>darf das Minimum der Gesamtinvestition ({{
                              computedCondInvestSumMinimumInEditForm }})
                              <strong>nicht
                                überschreiten</strong></span>
                          </div>

                        </div>

                        <div class="d-flex flex-column gap-2">

                          <div class="d-flex align-items-center justify-content-start gap-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                              fill="none">
                              <path d="M3.3335 8.79639H12.6668" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                              <path d="M8 4.12964L12.6667 8.79631L8 13.463" stroke="#3F4957" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <span class="blue-headline">Wievel davon beträgt die Eintrittsgebühr?</span>

                          </div>

                          <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center gap-3"
                            :style="{
                              paddingLeft: '24px'
                            }">

                            <b-form-select class="form-control flex-grow-0" v-model="formFields.step4.CondEntryFeeType"
                              :options="computedCondFinanceTypes"></b-form-select>
                            <hr class="vertical d-none d-sm-block" />

                            <currency-input class="form-control flex-grow-0" v-model="formFields.step4.CondEntryFeeFrom"
                              currency="EUR" locale="de" :allow-negative=false required />

                            <template v-if="formFields.step4.CondEntryFeeType === 1">
                              <span>bis</span>
                              <currency-input class="form-control flex-grow-0" v-model="formFields.step4.CondEntryFeeTo"
                                currency="EUR" locale="de" :allow-negative=false required />
                            </template>

                          </div>

                          <div class="error d-flex align-items-center gap-2"
                            v-if="computedHasCondErrorInEditForm.EntryFee" :style="{
                              paddingLeft: '24px'
                            }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                              fill="none">
                              <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                              <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            </svg>
                            <span>darf das Minimum der Gesamtinvestition ({{
                              computedCondInvestSumMinimumInEditForm }})
                              <strong>nicht
                                überschreiten</strong></span>
                          </div>

                        </div>

                        <div class="d-flex flex-column gap-2">

                          <div class="d-flex align-items-center justify-content-start gap-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                              fill="none">
                              <path d="M3.3335 8.79639H12.6668" stroke="#3F4957" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                              <path d="M8 4.12964L12.6667 8.79631L8 13.463" stroke="#3F4957" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <span class="blue-headline">Wievel davon wird für die Erstausstattung benötigt?</span>

                          </div>

                          <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center gap-3"
                            :style="{
                              paddingLeft: '24px'
                            }">

                            <b-form-select class="form-control flex-grow-0"
                              v-model="formFields.step4.CondInitialCapitalType"
                              :options="computedCondFinanceTypesInitialCapital"></b-form-select>
                            <hr class="vertical d-none d-sm-block" v-if="computedShowSumFieldsForIniticalCapital" />

                            <currency-input class="form-control flex-grow-0"
                              v-if="computedShowSumFieldsForIniticalCapital"
                              v-model="formFields.step4.CondInitialCapitalFrom" currency="EUR" locale="de"
                              :allow-negative=false required />

                            <template v-if="formFields.step4.CondInitialCapitalType === 1">
                              <span>bis</span>
                              <currency-input class="form-control flex-grow-0"
                                v-model="formFields.step4.CondInitialCapitalTo" currency="EUR" locale="de"
                                :allow-negative=false required />
                            </template>

                          </div>

                          <div class="error d-flex align-items-center gap-2"
                            v-if="computedHasCondErrorInEditForm.InitialCapital" :style="{
                              paddingLeft: '24px'
                            }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                              fill="none">
                              <path d="M12 4L4 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                              <path d="M4 4L12 12" stroke="#E63A14" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            </svg>
                            <span>darf das Minimum der Gesamtinvestition ({{
                              computedCondInvestSumMinimumInEditForm }})
                              <strong>nicht
                                überschreiten</strong></span>
                          </div>

                        </div>

                      </div>

                    </div>

                  </div>

                  <hr />

                  <div class="d-flex flex-column gap-3">
                    <div class="d-flex flex-column gap-2">
                      <span class="blue-headline">Daraus generiert sich am Messestand der folgende Text:</span>

                      <div v-if="!computedHasCondErrorInEditForm.RequiredCapital">Starte mit <strong>{{
                        formatCurrency(formFields.step4.CondRequiredCapitalFrom) }} Eigenkapital</strong> und sichere
                        dir damit
                        weitere
                        <strong>{{ formatCurrency(formFields.step4.CondInvestSumFrom -
                          formFields.step4.CondRequiredCapitalFrom) }}
                          Fremdkapital</strong>, um die <strong>Gesamtinvestition von mind. {{
                            formatCurrency(formFields.step4.CondInvestSumFrom)
                          }}</strong> zu
                        erreichen
                        und deinen Traum
                        zu verwirklichen.
                      </div>
                      <div v-else>
                        Starte mit <strong>{{ formatCurrency(formFields.step4.CondRequiredCapitalFrom) }}
                          Eigenkapital</strong>,
                        um deinen Traum
                        zu verwirklichen.
                      </div>

                    </div>

                    <div class="d-flex flex-column gap-2">

                      <span class="blue-headline">Hier können Sie individuelle Details hinzufügen:</span>

                      <textarea class="form-control w-100" v-model="formFields.step4.CondDescription" :style="{
                        height: '140px',
                        minHeight: '200px'
                      }"></textarea>

                    </div>

                  </div>

                  <hr />

                  <div class="d-flex flex-column w-100 gap-3">
                    <div class="d-flex flex-column gap-1">
                      <span class="fw-bold">Anforderungen an die individuellen Details zu den Konditionen:</span>
                      <ul class="special">
                        <li :class="{
                          success: formFields.step4.CondDescription.length <= 500,
                          error: formFields.step4.CondDescription.length > 500
                        }">Die individuellen Details dürfen max. 500 Zeichen haben</li>
                        <li>Der Text soll etwaige Besonderheiten beschreiben, die nicht durch die Standard-Angaben
                          ersichtlich sind</li>
                        <li>Der Texte sollte hohe Erstinvestitionen begründen bzw. "rechtfertigen", finanzielle
                          Sicherheit bzw. ein
                          geringes Risiko vermitteln oder besonders positive finanzielle Anreize hervorheben, z. B.
                          “Keine
                          Lizenzgebühren” o.ä.</li>
                        <li>Der Text sollte positiv formuliert sein und darstellen, dass sich die Investition lohnen
                          wird</li>
                        <li class="d-flex align-items-start inner-list">
                          <div class="d-flex flex-column align-items-start gap-1">
                            <strong>Beispiel:</strong>
                            <span>“Dank der relativ geringen Erstinvestition kannst du meistens recht schnell deinen
                              ersten Gewinn
                              machen. Zudem verspricht dir eine Franchisepartnerschaft mit {{ computedBrand.Name }} eine
                              überdurchschnittlich hohe Umsatzrendite.”</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                </template>

              </FairFieldEditor>

              <!-- STEP 5 -->
              <FairFieldEditor v-if="computedShowFaqArea" title="Häufig gestellte Fragen (FAQ)" :index="5"
                :is-modal-open.sync="modalOpened.step5" :loading="isStepLoading" @save="handleSaveStep5"
                :isOpen="computedCurrentIsOpen(5)" :saveDisabled="!computedIsStep5Valid"
                buttonSaveText="Änderungen speichern" @toggle="toggleContent">

                <div class="d-flex flex-column gap-3">

                  <FaqQuestionContainer v-for="(faq, index) of computedPreSelectedFaqs" :key="faq.FaqID"
                    :faqId="faq.ExternalID" :title="rewrittenQuestion(faq)" :index="index + 1"
                    :isOpen="computedCurrentFaqIsOpen(index + 1)" @toggle="toggleFaq" :showSelect="false">
                    <div v-html="rewrittenAnswer(faq)" />
                  </FaqQuestionContainer>

                </div>

                <template v-slot:editButtonCaption>
                  <div class="d-flex align-items-center gap-2">
                    <i class="fa fa-pencil"></i>
                    <span>Auswahl ändern</span>
                  </div>
                </template>
                <template v-slot:preview>
                  <img class="img-fluid" src="@/assets/images/new-fair/5_FAQ.png" />
                </template>

                <template v-slot:edit>
                  <p>Die häufig gestellten Fragen werden ganz unten am Messestand angezeigt und sollen mögliche Einwände
                    von zögernden Interessenten entkräften.</p>
                  <p>Lesen Sie die Fragen / Antworten durch und entscheiden Sie, welche davon zu Ihrem Geschäftskonzept
                    passend sind und an Ihrem Messestand angezeigt werden sollen. </p>

                  <div class="d-flex flex-column gap-3">

                    <FaqQuestionContainer v-for="(faq, index) of computedAllFaqs" :key="faq.ExternalID"
                      :title="rewrittenQuestion(faq)" @select="handleFaqSelect" @deselect="handleFaqDeSelect"
                      :faqId="faq.ExternalID" :index="index + 1"
                      :isOpen="computedCurrentFaqIsOpenInsideModal(index + 1)" @toggle="toggleFaqInsideModal"
                      :selected="formFields.step5.selectedFaqs">
                      <div v-html="rewrittenAnswer(faq)" />
                    </FaqQuestionContainer>

                  </div>
                  <hr />

                  <div class="d-flex flex-column w-100 gap-3">
                    <div class="d-flex flex-column gap-1">
                      <span class="fw-bold">Anforderungen:</span>
                      <ul class="special">
                        <li :class="{
                          success: computedSelectedFaqIds.length >= 3,
                          error: computedSelectedFaqIds.length < 3
                        }">Mindestens 3 Fragen müssen ausgewählt sein</li>
                        <li :class="{
                          success: computedSelectedFaqIds.length <= 6,
                          error: computedSelectedFaqIds.length > 6
                        }">Maximal 6 Fragen dürfen ausgewählt werden</li>
                        <li>Die Fragen / Antworten müssen zu Ihrem Geschäftskonzept passen (Quereinstieg / Konditionen /
                          angebotene Unterstützungen etc.)</li>
                        <li>Eigene, individuelle Fragen / Antworten sind aktuell noch nicht möglich</li>
                      </ul>
                    </div>
                  </div>

                </template>

              </FairFieldEditor>

              <!-- STEP 6 -->
              <FairFieldEditor title="FAQ zu den nächsten Schritten" :index="6" :isOpen="computedCurrentIsOpen(6)"
                :is-modal-open.sync="modalOpened.step6" :loading="isStepLoading" @save="handleSaveStep6"
                buttonSaveText="Änderungen speichern" :saveDisabled="!computedIsStep6Valid" @toggle="toggleContent">

                <div class="quote-box">
                  <div class="next-steps-headline">Ich bin startklar, mein eigenes Unternehmen zu gründen. Wie
                    geht&apos;s
                    weiter?</div>
                  <div class="d-flex flex-column gap-2">
                    <div>Das ist großartig! Der erste Schritt ist einfach. du stellst eine Anfrage über das Formular
                      auf dieser Seite!
                    </div>
                    <strong>So geht´s weiter:</strong>
                    <div>Du registrierst dich und bekommst Zugriff auf ein ausführliches Infopaket mit detaillierten
                      Informationen zum
                      Konzept. </div>
                    <div v-html="computedNextStepsHtml" />
                    <div>Sende jetzt das Formular ab und lass uns gemeinsam ins Gespräch kommen!</div>
                  </div>
                </div>

                <template v-slot:editButtonCaption>
                  <div class="d-flex align-items-center gap-2">
                    <i class="fa fa-pencil"></i>
                    <span>Bearbeiten</span>
                  </div>
                </template>
                <template v-slot:preview>
                  <img class="img-fluid" src="@/assets/images/new-fair/6_NextSteps.png" />
                </template>

                <template v-slot:edit>

                  <div class="quote-box">
                    <div class="next-steps-headline">Ich bin startklar, mein eigenes Unternehmen zu gründen. Wie
                      geht&apos;s
                      weiter?</div>
                    <div class="d-flex flex-column gap-2">
                      <div>Das ist großartig! Der erste Schritt ist einfach. du stellst eine Anfrage über das Formular
                        auf dieser Seite!
                      </div>
                      <strong>So geht´s weiter:</strong>
                      <div>Du registrierst dich und bekommst Zugriff auf ein ausführliches Infopaket mit detaillierten
                        Informationen zum
                        Konzept. </div>

                      <!-- textarea-->
                      <textarea class="form-control w-100 flex-grow-1 py-3" style="min-height: 200px;"
                        v-model="formFields.step6.value"></textarea>

                      <div>Sende jetzt das Formular ab und lass uns gemeinsam ins Gespräch kommen!</div>
                    </div>
                  </div>
                  <hr />

                  <div class="d-flex flex-column w-100 gap-3">
                    <div class="d-flex flex-column gap-1">
                      <span class="fw-bold">Anforderungen:</span>
                      <ul class="special">
                        <li :class="{
                          success: formFields.step6.value.length <= 500,
                          error: formFields.step6.value.length > 500
                        }">Der individualisierbare Teile darf max. 500 Zeichen haben</li>
                        <li>Der Anfang und das Ende der Antwort ist fest vorgegeben. Passen Sie den mittleren Teil auf
                          Ihren Rekrutierungsprozess an!</li>
                        <li>Im Text soll dem Interessenten gezeigt werden, was nach seiner Infopaket-Anfrage passiert
                        </li>
                        <li>Der Text sollte positiv, freundlich und persönlich geschrieben sein</li>
                        <li>Der Text soll zeigen, dass der Interessent nach der Anfrage durch den weiteren Prozess
                          begleitet wird</li>
                      </ul>
                    </div>
                  </div>

                </template>

              </FairFieldEditor>

              <a href="/dashboard" class="btn btn-secondary btn-extended d-inline" :style="{
                maxWidth: '370px'
              }">
                <strong>Fertig?</strong> Zurück zum Mitgliederbereich
              </a>

            </div>
          </div>
        </div>

      </section>
    </div>

    <Footer />

  </div>
</template>

<script>
import Footer from '../../components/Footer.vue'
import FairFieldEditor from '../../components/FairFieldEditor.vue'
import FaqQuestionContainer from '../../components/FaqQuestionContainer.vue'
import ImageConnectedSelector from '../../components/ImageConnectedSelector.vue'
import { CurrencyInput } from 'vue-currency-input'
import AppConfig from '../../config/index'

export default {
  name: 'NewFairForm',
  components: {
    Footer,
    FairFieldEditor,
    FaqQuestionContainer,
    ImageConnectedSelector,
    CurrencyInput
  },
  data: function () {
    return {
      initialLoad: {
        started: false,
        finished: false
      },
      isStepLoading: false,
      brandId: null,
      currentBrand: null,
      currentFair: null,
      currentOpenedTabIndex: 1,
      currentEditingTabIndex: null,
      openedFaqIndexes: [1],
      openedFaqIndexesInsideModal: [],
      selectedBrandId: null,
      availableBrands: [],
      faq: {
        default: [],
        brand: [],
        selected: [],
        nextStepsText: ''
      },
      modalOpened: {
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        step6: false
      },
      formFields: {
        step1: {
          placeholder: 'Tragen Sie hier Ihren neuen Vorschlag ein.',
          value: ''
        },
        step2: {
          FairContactName: '',
          FairContactMail: '',
          FairContactPortrait: {},
          FairContactPortraitID: null,
          FairContactWelcome: '',
          GeneratedTextType: '',
          GeneratedText: '',
          GeneratedTextIsLoading: false
        },
        step3: {
          placeholder: 'Tragen Sie hier Ihren neuen Vorschlag ein.',
          value: ''
        },
        step4: {

          CondInvestSumType: '',
          CondInvestSumFrom: '',
          CondInvestSumTo: '',

          CondEntryFeeType: '',
          CondEntryFeeFrom: '',
          CondEntryFeeTo: '',

          CondInitialCapitalType: '',
          CondInitialCapitalFrom: '',
          CondInitialCapitalTo: '',

          CondRequiredCapitalType: '',
          CondRequiredCapitalFrom: '',
          CondRequiredCapitalTo: '',

          CondDescription: ''

        },
        step5: {
          selectedFaqs: []
        },
        step6: {
          value: ''
        }
      }
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    isInitialLoadFinished () {
      return this.initialLoad.finished
    },
    computedBrand () {
      return this.currentBrand
    },
    computedFair () {
      return this.currentFair
    },
    computedCurrentIsOpen () {
      return function (tab) {
        return this.currentOpenedTabIndex === tab
      }
    },
    defaultImage () {
      return require('../../assets/images/platzhalter-portrait-weiss.jpg')
    },
    computedCurrentFaqIsOpen () {
      return function (tab) {
        return this.openedFaqIndexes.includes(tab)
      }
    },
    computedCurrentFaqIsOpenInsideModal () {
      return function (tab) {
        return this.openedFaqIndexesInsideModal.includes(tab)
      }
    },
    computedCondDescription () {
      return String(this.formFields.step4.CondDescription ?? '')
        .replaceAll('\r\n', '<br />')
        .replaceAll('\n\n', '<br />')
        .replaceAll('\r', '<br />')
        .replaceAll('\n', '<br />')
    },
    computedCurrentSelectedFaqIds () {
      return function (tab) {
        return this.faq.selected.map(tab => {
          return Number(tab)
        }).includes(tab)
      }
    },
    computedExpansionFormCaption () {
      if (this.currentBrand) {
        if (this.currentBrand.ConceptExpansionFormFranchise) return 'Franchise'
        if (this.currentBrand.ConceptExpansionFormLicense) return 'Lizenz'
      }
      return ''
    },
    computedFoundationText () {
      // Assuming `computedBrand.GenderArticle` can be 1, 2, or 3
      const genderArticle = this.computedBrand.GenderArticle
      const foundationPurpose = String(this.computedBrand.FoundationPurpose)

      // Map numeric genderArticle values to actual articles
      const articleText =
        genderArticle === 1
          ? 'einen ' // der
          : genderArticle === 2
            ? 'eine ' // die
            : genderArticle === 3
              ? 'ein ' // das
              : ' '

      // Map numeric genderArticle values to the appropriate ending for "eigen"
      const eigenText =
        genderArticle === 1
          ? 'en ' // der
          : genderArticle === 2
            ? 'e ' // die
            : genderArticle === 3
              ? 'es ' // das
              : ' '

      // Return the final string with concatenation
      return `Was kostet es, ${articleText}eigen${eigenText}<strong>&gt;${foundationPurpose}&lt;</strong> zu starten?`
    },

    formatCurrency () {
      return (value) => {
        return new Intl.NumberFormat('de-DE', {
          minimumFractionDigits: 0, // No decimal places for whole numbers
          maximumFractionDigits: 0 // No decimal places for whole numbers
        }).format(value) + ' €'
      }
    },

    computedFairContactPortrait () {
      return `${AppConfig.MEDIA_API_URL}id/${this.computedFair.FairContactPortrait.FileID}`
    },

    computedSelectedFaqIds () {
      return this.formFields.step5.selectedFaqs
    },

    computedNextStepsHtml () {
      return String(this.faq.nextStepsText ?? '')
        .replaceAll('\r\n', '<br />')
        .replaceAll('\n\n', '<br />')
        .replaceAll('\r', '<br />')
        .replaceAll('\n', '<br />')
    },

    computedIsFaqSelected () {
      return (faqId) => {
        return this.formFields.step5.selectedFaqs.includes(faqId)
      }
    },

    computedIsStep1Valid () {
      return this.formFields.step1.value.length > 0 && this.formFields.step1.value.length <= 65
    },
    computedIsStep3Valid () {
      return this.formFields.step3.value.length > 0 && this.formFields.step3.value.length <= 40
    },

    computedIsStep2Valid () {
      return (
        this.formFields.step2.FairContactName.length > 0 &&
        this.formFields.step2.FairContactMail.length > 0 &&
        // this.formFields.step2.FairContactPortraitID !== null &&
        this.formFields.step2.FairContactWelcome.length > 0 &&
        this.formFields.step2.FairContactWelcome.length <= 400 &&
        this.formFields.step2.FairContactWelcome.includes(this.formFields.step2.FairContactName) &&
        (this.currentBrand.ShortBrandName
          ? this.formFields.step2.FairContactWelcome.includes(this.currentBrand.ShortBrandName) ||
          this.formFields.step2.FairContactWelcome.includes(this.currentBrand.Name)
          : this.formFields.step2.FairContactWelcome.includes(this.currentBrand.Name))
      )
    },

    computedIsStep4Valid () {
      return (
        this.formFields.step4.CondInvestSumType !== '' &&
        this.formFields.step4.CondInvestSumFrom > 0 &&
        (this.formFields.step4.CondInvestSumType === 1
          ? (this.formFields.step4.CondInvestSumFrom > 0 && this.formFields.step4.CondInvestSumTo > 0 && this.formFields.step4.CondInvestSumFrom < this.formFields.step4.CondInvestSumTo)
          : true) &&
        this.formFields.step4.CondRequiredCapitalType !== '' &&
        this.formFields.step4.CondRequiredCapitalFrom > 0 &&
        (this.formFields.step4.CondRequiredCapitalType === 1
          ? (this.formFields.step4.CondRequiredCapitalFrom > 0 && this.formFields.step4.CondRequiredCapitalTo > 0 && this.formFields.step4.CondRequiredCapitalFrom < this.formFields.step4.CondRequiredCapitalTo)
          : true) &&
        this.formFields.step4.CondEntryFeeType !== '' &&
        this.formFields.step4.CondEntryFeeFrom > 0 &&
        (this.formFields.step4.CondEntryFeeType === 1
          ? (this.formFields.step4.CondEntryFeeFrom > 0 && this.formFields.step4.CondEntryFeeTo > 0 && this.formFields.step4.CondEntryFeeFrom < this.formFields.step4.CondEntryFeeTo)
          : true) &&
        this.formFields.step4.CondInitialCapitalType !== '' &&
        (this.formFields.step4.CondInitialCapitalType === -1
          ? true
          : (this.formFields.step4.CondInitialCapitalFrom > 0 &&
            (this.formFields.step4.CondInitialCapitalType === 1
              ? (this.formFields.step4.CondInitialCapitalTo > 0 && this.formFields.step4.CondInitialCapitalFrom < this.formFields.step4.CondInitialCapitalTo)
              : true)
            )) &&
        this.computedHasCondErrorInEditForm.RequiredCapital === false &&
        this.computedHasCondErrorInEditForm.EntryFee === false &&
        this.computedHasCondErrorInEditForm.InitialCapital === false &&
        String(this.formFields.step4.CondDescription ?? '').length <= 500
      )
    },

    computedIsStep5Valid () {
      return this.formFields.step5.selectedFaqs.length >= 3 && this.formFields.step5.selectedFaqs.length <= 6
    },

    computedIsStep6Valid () {
      return this.formFields.step6.value.length <= 500 && this.formFields.step6.value.length > 0
    },

    computedPreSelectedFaqs () {
      return this.faq.selected.map((faqId) => {
        return this.faq.default.find((faq) => Number(faq.ExternalID) === Number(faqId))
      })
    },

    computedAllFaqs () {
      return this.faq.default.filter(faq => {
        return faq.ExternalID !== 13
      })
    },

    computedCondFinanceTypes () {
      return [
        { value: 1, text: 'von / bis' },
        { value: 2, text: 'fix' },
        { value: 3, text: 'ab' }
      ]
    },

    computedShowSumFieldsForIniticalCapital () {
      return this.formFields.step4.CondInitialCapitalType !== -1
    },

    computedCondFinanceTypesInitialCapital () {
      return [
        ...this.computedCondFinanceTypes,
        { value: -1, text: 'Keine Kosten für Erstausstattung' }
      ]
    },

    computedTextIsLoading () {
      return this.formFields.step2.GeneratedTextIsLoading
    },

    computedCondInvestSumMinimum () {
      return this.formatCurrency(this.currentBrand.CondInvestSumFrom)
    },
    computedCondInvestSumMinimumInEditForm () {
      return this.formatCurrency(this.formFields.step4.CondInvestSumFrom)
    },
    computedCondCaptions () {
      return {
        CondInvestSumCaption: this.formatCondCaption(
          this.currentBrand.CondInvestSumType,
          this.currentBrand.CondInvestSumFrom,
          this.currentBrand.CondInvestSumTo
        ),
        CondEntryFeeCaption: this.formatCondCaption(
          this.currentBrand.CondEntryFeeType,
          this.currentBrand.CondEntryFeeFrom,
          this.currentBrand.CondEntryFeeTo
        ),
        CondInitialCapitalCaption: this.formatCondCaption(
          this.currentBrand.CondInitialCapitalType,
          this.currentBrand.CondInitialCapitalFrom,
          this.currentBrand.CondInitialCapitalTo
        ),
        CondRequiredCapitalCaption: this.formatCondCaption(
          this.currentBrand.CondRequiredCapitalType,
          this.currentBrand.CondRequiredCapitalFrom,
          this.currentBrand.CondRequiredCapitalTo
        )
      }
    },
    computedHasCondError () {
      return {
        RequiredCapital: this.currentBrand.CondInvestSumFrom < this.currentBrand.CondRequiredCapitalFrom,
        EntryFee: this.currentBrand.CondInvestSumFrom < this.currentBrand.CondEntryFeeFrom,
        InitialCapital: this.currentBrand.CondInvestSumFrom < this.currentBrand.CondInitialCapitalFrom
      }
    },
    computedHasCondErrorInEditForm () {
      return {
        RequiredCapital: this.formFields.step4.CondInvestSumFrom < this.formFields.step4.CondRequiredCapitalFrom,
        EntryFee: this.formFields.step4.CondInvestSumFrom < this.formFields.step4.CondEntryFeeFrom,
        InitialCapital: this.formFields.step4.CondInvestSumFrom < this.formFields.step4.CondInitialCapitalFrom
      }
    },
    computedCondInvestSumCaption () {
      switch (this.currentBrand.CondInvestSumType) {
        case 0: {
          return 'Keine Angabe'
        }
        case 1: {
          const from = this.formatCurrency(this.currentBrand.CondInvestSumFrom)
          const to = this.formatCurrency(this.currentBrand.CondInvestSumTo)
          return `${from} bis ${to}`
        }
        case 2: {
          return this.formatCurrency(this.currentBrand.CondInvestSumFrom)
        }
        case 3: {
          return `ab ${this.formatCurrency(this.currentBrand.CondInvestSumFrom)}`
        }
        default: {
          return 'Keine Angabe'
        }
      }
    },
    computedCurrentIsEditing () {
      return function (tab) {
        return this.currentEditingTabIndex === tab
      }
    },
    computedShowFaqArea () {
      return this.faq.brand.length === 0
    },
    computedHasGeneratedText () {
      return String(this.formFields.step2.GeneratedText).length > 0
    },
    computedGeneratedText () {
      return String(this.formFields.step2.GeneratedText)
    },
    computedGeneratedTextType () {
      return this.formFields.step2.GeneratedTextType === 'collegial' ? 'Locker' : 'Professionell'
    }
  },
  methods: {
    async loadBrand () {
      this.initialLoad.started = true
      try {
        const loadBrandRequest = await this.app.APIService._post('mb/fair/new/2024', {
          brandId: this.brandId
        })

        this.selectedBrandId = this.brandId

        this.currentBrand = loadBrandRequest.body.Brand
        this.currentFair = loadBrandRequest.body.Fair

        this.faq.default = loadBrandRequest.body.AllFaqs

        this.faq.brand = loadBrandRequest.body.BrandFaqs
        this.faq.selected = loadBrandRequest.body.PreSelectedFaqs.map(faqId => Number(faqId))
        // this.openedFaqIndexesInsideModal = this.faq.selected.map(faqId => Number(faqId))[0]

        this.formFields.step2.FairContactName = this.currentFair.FairContactName
        this.formFields.step2.FairContactMail = this.currentBrand.InterestedMail

        this.formFields.step2.FairContactPortrait = this.currentFair.FairContactPortrait
        this.formFields.step2.FairContactPortraitID = this.currentFair.FairContactPortraitID
        this.formFields.step2.FairContactWelcome = this.currentFair.FairContactWelcome

        this.formFields.step4.CondInvestSumType = this.currentBrand.CondInvestSumType
        this.formFields.step4.CondInvestSumFrom = this.currentBrand.CondInvestSumFrom
        this.formFields.step4.CondInvestSumTo = this.currentBrand.CondInvestSumTo

        this.formFields.step4.CondEntryFeeType = this.currentBrand.CondEntryFeeType
        this.formFields.step4.CondEntryFeeFrom = this.currentBrand.CondEntryFeeFrom
        this.formFields.step4.CondEntryFeeTo = this.currentBrand.CondEntryFeeTo

        this.formFields.step4.CondInitialCapitalType = this.currentBrand.CondInitialCapitalType
        this.formFields.step4.CondInitialCapitalFrom = this.currentBrand.CondInitialCapitalFrom
        this.formFields.step4.CondInitialCapitalTo = this.currentBrand.CondInitialCapitalTo

        this.formFields.step4.CondRequiredCapitalType = this.currentBrand.CondRequiredCapitalType
        this.formFields.step4.CondRequiredCapitalFrom = this.currentBrand.CondRequiredCapitalFrom
        this.formFields.step4.CondRequiredCapitalTo = this.currentBrand.CondRequiredCapitalTo

        this.formFields.step4.CondDescription = this.currentBrand.CondDescription ?? ''

        this.formFields.step5.selectedFaqs = [...this.faq.selected]

        this.faq.nextStepsText = loadBrandRequest.body.NextStepsText
        this.formFields.step6.value = loadBrandRequest.body.NextStepsText

        this.availableBrands = loadBrandRequest.body.AvailableBrands.map((brand) => {
          return {
            value: brand.BrandID,
            text: brand.Name
          }
        })
      } catch (error) {
        console.error('Error at Initial Brand Load', error)
        this.$router.push({ name: 'Dashboard' })
      } finally {
        this.initialLoad.finished = true
      }
    },
    handleBrandChange () {
      window.location.href = `/neuer-messestand-2024/${this.selectedBrandId}`
    },
    async submitStep1 () {
      await this.app.APIService._post('mb/fair/new/2024/step/1', {
        brandId: this.brandId,
        value: this.formFields.step1.value
      })
    },
    async submitStep2 () {
      await this.app.APIService._post('mb/fair/new/2024/step/2', {
        brandId: this.brandId,
        value: {
          FairContactName: this.formFields.step2.FairContactName,
          FairContactMail: this.formFields.step2.FairContactMail,
          FairContactPortraitID: this.formFields.step2.FairContactPortraitID,
          FairContactWelcome: this.formFields.step2.FairContactWelcome
        }
      })
    },
    async submitStep3 () {
      await this.app.APIService._post('mb/fair/new/2024/step/3', {
        brandId: this.brandId,
        value: this.formFields.step3.value
      })
    },
    async submitStep4 () {
      await this.app.APIService._post('mb/fair/new/2024/step/4', {
        brandId: this.brandId,
        value: {
          CondInvestSumType: this.formFields.step4.CondInvestSumType,
          CondInvestSumFrom: this.formFields.step4.CondInvestSumFrom,
          CondInvestSumTo: this.formFields.step4.CondInvestSumTo,
          CondEntryFeeType: this.formFields.step4.CondEntryFeeType,
          CondEntryFeeFrom: this.formFields.step4.CondEntryFeeFrom,
          CondEntryFeeTo: this.formFields.step4.CondEntryFeeTo,
          CondInitialCapitalType: this.formFields.step4.CondInitialCapitalType,
          CondInitialCapitalFrom: this.formFields.step4.CondInitialCapitalFrom,
          CondInitialCapitalTo: this.formFields.step4.CondInitialCapitalTo,
          CondRequiredCapitalType: this.formFields.step4.CondRequiredCapitalType,
          CondRequiredCapitalFrom: this.formFields.step4.CondRequiredCapitalFrom,
          CondRequiredCapitalTo: this.formFields.step4.CondRequiredCapitalTo,
          CondDescription: this.formFields.step4.CondDescription ?? ''
        }
      })
    },
    async submitStep5 () {
      await this.app.APIService._post('mb/fair/new/2024/step/5', {
        brandId: this.brandId,
        value: this.formFields.step5.selectedFaqs
      })
    },
    async submitStep6 () {
      await this.app.APIService._post('mb/fair/new/2024/step/6', {
        brandId: this.brandId,
        value: this.formFields.step6.value
      })
    },
    saveImageToContact (data) {
      this.formFields.step2.FairContactPortraitID = data.MediumID
      this.formFields.step2.FairContactPortrait = {
        FileID: data.FileID,
        MediumID: data.MediumID
      }
    },
    resetGeneratedFairWelcome () {
      this.formFields.step2.GeneratedText = ''
      this.formFields.step2.GeneratedTextIsLoading = false
      this.formFields.step2.GeneratedTextType = ''
    },
    useGeneratedText () {
      this.formFields.step2.FairContactWelcome = String(this.formFields.step2.GeneratedText).replace('</p><p>', '\n\n').replace('<p>', '').replace('</p>', '')
      this.resetGeneratedFairWelcome()
    },
    async handleSaveStep1 () {
      this.isStepLoading = true
      try {
        await this.submitStep1()
        this.$toast.success('Korrekturwünsche wurden erfolgreich gespeichert.')
        this.currentEditingTabIndex = null
        this.modalOpened.step1 = false
        this.formFields.step1.value = ''
      } catch (error) {
        console.error('Error at Saving Step 1', error)
        this.$toast.error('Beim Speichern deiner Korrekturwünsche ist ein Fehler aufgetreten.')
      } finally {
        this.isStepLoading = false
      }
    },
    async handleSaveStep2 () {
      this.isStepLoading = true
      try {
        await this.submitStep2()
        this.$toast.success('Korrekturwünsche wurden erfolgreich gespeichert.')
        this.currentEditingTabIndex = null
        this.modalOpened.step2 = false

        this.currentFair.FairContactName = this.formFields.step2.FairContactName
        this.currentBrand.InterestedMail = this.formFields.step2.FairContactMail
        this.currentFair.FairContactPortraitID = this.formFields.step2.FairContactPortraitID
        this.currentFair.FairContactPortrait = this.formFields.step2.FairContactPortrait
        this.currentFair.FairContactWelcome = this.formFields.step2.FairContactWelcome
      } catch (error) {
        console.error('Error at Saving Step 2', error)
        this.$toast.error('Beim Speichern deiner Begrüßung am Messestand ist ein Fehler aufgetreten.')
      } finally {
        this.isStepLoading = false
      }
    },
    async handleSaveStep3 () {
      this.isStepLoading = true
      try {
        await this.submitStep3()
        this.$toast.success('Korrekturwünsche wurden erfolgreich gespeichert.')
        this.currentEditingTabIndex = null
        this.modalOpened.step3 = false
        this.formFields.step3.value = ''
      } catch (error) {
        console.error('Error at Saving Step 3', error)
        this.$toast.error('Beim Speichern deiner Korrekturwünsche ist ein Fehler aufgetreten.')
      } finally {
        this.isStepLoading = false
      }
    },
    async handleSaveStep4 () {
      this.isStepLoading = true
      try {
        await this.submitStep4()
        this.$toast.success('Korrekturwünsche wurden erfolgreich gespeichert.')
        this.currentEditingTabIndex = null
        this.modalOpened.step4 = false

        this.currentBrand.CondInvestSumType = this.formFields.step4.CondInvestSumType
        this.currentBrand.CondInvestSumFrom = this.formFields.step4.CondInvestSumFrom
        this.currentBrand.CondInvestSumTo = this.formFields.step4.CondInvestSumTo

        this.currentBrand.CondEntryFeeType = this.formFields.step4.CondEntryFeeType
        this.currentBrand.CondEntryFeeFrom = this.formFields.step4.CondEntryFeeFrom
        this.currentBrand.CondEntryFeeTo = this.formFields.step4.CondEntryFeeTo

        this.currentBrand.CondInitialCapitalType = this.formFields.step4.CondInitialCapitalType
        this.currentBrand.CondInitialCapitalFrom = this.formFields.step4.CondInitialCapitalFrom
        this.currentBrand.CondInitialCapitalTo = this.formFields.step4.CondInitialCapitalTo

        this.currentBrand.CondRequiredCapitalType = this.formFields.step4.CondRequiredCapitalType
        this.currentBrand.CondRequiredCapitalFrom = this.formFields.step4.CondRequiredCapitalFrom
        this.currentBrand.CondRequiredCapitalTo = this.formFields.step4.CondRequiredCapitalTo

        this.currentBrand.CondDescription = this.formFields.step4.CondDescription ?? ''
      } catch (error) {
        console.error('Error at Saving Step 4', error)
        this.$toast.error('Beim Speichern deiner Konditionen ist ein Fehler aufgetreten.')
      } finally {
        this.isStepLoading = false
      }
    },
    async handleSaveStep5 () {
      this.isStepLoading = true
      try {
        await this.submitStep5()
        this.$toast.success('Korrekturwünsche wurden erfolgreich gespeichert.')
        this.currentEditingTabIndex = null
        this.modalOpened.step5 = false
        this.faq.selected = [...this.formFields.step5.selectedFaqs]
      } catch (error) {
        console.error('Error at Saving Step 5', error)
        this.$toast.error('Beim Speichern deiner FAQs ist ein Fehler aufgetreten.')
      } finally {
        this.isStepLoading = false
      }
    },
    async handleSaveStep6 () {
      this.isStepLoading = true
      try {
        await this.submitStep6()
        this.$toast.success('Korrekturwünsche wurden erfolgreich gespeichert.')
        this.currentEditingTabIndex = null
        this.modalOpened.step6 = false
        this.faq.nextStepsText = this.formFields.step6.value
      } catch (error) {
        console.error('Error at Saving Step 6', error)
        this.$toast.error('Beim Speichern deiner nächsten Schritte ist ein Fehler aufgetreten.')
      } finally {
        this.isStepLoading = false
      }
    },

    rewrittenQuestion (question) {
      return String(question.Question ?? '')
        .replaceAll('{{EXPANSIONSFORM}}', this.computedExpansionFormCaption)
        .replaceAll('{{MARKE}}', this.currentBrand.Name)
    },
    rewrittenAnswer (answer) {
      return String(answer.Answer ?? '')
        .replaceAll('{{EXPANSIONSFORM}}', this.computedExpansionFormCaption)
        .replaceAll('{{MARKE}}', this.currentBrand.Name)
    },
    async generateFairContactWelcome (type) {
      this.isStepLoading = true
      this.formFields.step2.GeneratedText = ''
      this.formFields.step2.GeneratedTextType = type
      try {
        const generatedTextRequest = await this.app.APIService._get(`mb/fair/new/2024/generate-welcome-text/${this.brandId}/${type}`)
        this.formFields.step2.GeneratedTextType = type === 'collegial' ? 'Locker' : 'Professionell'
        this.formFields.step2.GeneratedText = generatedTextRequest.body.NewText
      } catch (error) {
        console.error('Error at Generating Welcome Text', error)
      } finally {
        this.isStepLoading = false
      }
    },
    toggleContent (index) {
      this.isStepLoading = false
      if (this.currentOpenedTabIndex === index) {
        this.currentOpenedTabIndex = null
      } else {
        this.currentOpenedTabIndex = index
      }
      this.currentEditingTabIndex = null
    },
    toggleFaq (index) {
      if (this.openedFaqIndexes.includes(index)) {
        this.openedFaqIndexes = this.openedFaqIndexes.filter(i => i !== index)
      } else {
        this.openedFaqIndexes.push(index)
      }
    },
    toggleFaqInsideModal (index) {
      if (this.openedFaqIndexesInsideModal.includes(index)) {
        this.openedFaqIndexesInsideModal = this.openedFaqIndexesInsideModal.filter(i => i !== index)
      } else {
        this.openedFaqIndexesInsideModal.push(index)
      }
    },
    handleFaqSelect (faqId) {
      if (this.formFields.step5.selectedFaqs.includes(faqId)) {
        return
      }
      this.formFields.step5.selectedFaqs.push(faqId)
      this.formFields.step5.selectedFaqs.sort((a, b) => a - b)
    },
    handleFaqDeSelect (faqId) {
      this.formFields.step5.selectedFaqs = this.formFields.step5.selectedFaqs.filter(faqIdInArray => faqIdInArray !== faqId)
    },
    toggleEdit (index) {
      if (this.currentEditingTabIndex === index) {
        this.currentEditingTabIndex = null
      } else {
        this.currentEditingTabIndex = index
      }
    },
    formatCondCaption (type, fromValue, toValue) {
      switch (type) {
        case 0:
          return 'Keine Angabe'
        case 1:
          return `${this.formatCurrency(fromValue)} bis ${this.formatCurrency(toValue)}`
        case 2:
          return this.formatCurrency(fromValue)
        case 3:
          return `ab ${this.formatCurrency(fromValue)}`
        default:
          return 'Keine Angabe'
      }
    }
  },
  mounted () {
    this.brandId = this.$route.params.brandId
    if (!this.brandId) {
      this.$router.push({ name: 'Home' })
    } else {
      localStorage.removeItem('FPMB_NewFairScreenNext')
      this.loadBrand()
    }
  }
}

</script>

<style lang="scss" scoped>
body,
html {
  overflow: hidden;
}

.custom-select-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.custom-dropdown-icon {
  position: absolute;
  right: 10px;
  pointer-events: none;
  /* Ensure the icon doesn't block user interaction with the select */
}

.custom-dropdown-icon svg {
  display: block;
}

.new-fair-form {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

header {
  span {
    color: #3F4957;
    font-family: "Alegreya Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px;
    /* 116.667% */
    letter-spacing: 0.156px;

    // mobile smaller font size
    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 40px;
    }

  }
}

.new-fair-form-container {
  padding: 64px 32px;

  background-color: #f7f8f9;

  @media (max-width: 768px) {

    .card-body {
      padding: 0;
    }

    section>.card {
      background-color: transparent !important;
      box-shadow: none;

      .card-body {
        >.all-steps {
          >section {
            background-color: white;
          }
        }
      }

    }

  }

}

.logo {
  height: 56px;
}

section {
  max-width: 939px;
}

.portrait-container {
  img {
    width: 166px;
    border-radius: 8px;
    object-fit: cover;
  }
}

.quote-box {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #EFF1F4;

  &.cond-box {
    .form-control {
      /* flex: 0 1 auto; Prevent flexbox growth, shrink if necessary */
      width: auto;
      /* Ensure it doesn’t expand to fill the container */
      max-width: 140px;

      @media (max-width: 768px) {
        max-width: 100%;
      }

    }
  }

}

.color-primary {
  color: #00719F;
}

.next-steps-headline {
  font-size: 19px;
  font-weight: 600;
}

.error {
  span {
    color: var(--Error-500, #E53A14);
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

hr {
  background: #D0D5DD;
  width: 100%;
  height: 1px;
  margin: 0;
}
</style>
