<template>
  <div>
    <div class="header">
      <div class="memberships">
        <strong>{{ $t('menue.mitgliedschaftbuchen') }}:</strong>
        <header-membership-link countryCode="de" />
        <header-membership-link countryCode="at" />
        <header-membership-link countryCode="ch" />
      </div>
      <div class="actions">

        <template v-if="0">
          <div class="credits d-flex align-items-center justify-content-start">
            <img class="coin credits-header-location" src="@/assets/images/icons/3.0/coin.png" />
            <span>Credits:</span>
            <span>{{ userStore.getCreditAmount }}</span>
            <a href="javascript:" class="d-flex" @click="openCreditModal">
              <inline-svg
                :src="require('@/assets/images/icons/3.0/add.svg')"
              />
            </a>
          </div>

          <a v-if=0 href="javascript:" @click="chatStore.openChat">
            <inline-svg
              :src="require('@/assets/images/icons/3.0/chat.svg')"
            />
          </a>
          <a href="javascript:" @click="chatStore.openChat">
            <inline-svg
              :src="require('@/assets/images/icons/3.0/notifications.svg')"
            />
          </a>
        </template>

        <LocaleSwitch />

      </div>
    </div>
    <credit-modal ref="creditModalComponent" />
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

import LocaleSwitch from '@/components/3.0/LocaleSwitch'
import HeaderMembershipLink from '@/components/3.0/HeaderMembershipLink'
import CreditModal from '@/components/3.0/CreditModal'

import { useBrandStore } from '@/stores/brandStore'
import { useChatStore } from '@/stores/chatStore'
import { useUserStore } from '@/stores/userStore'

export default {
  name: 'NewHeader',
  components: {
    InlineSvg,
    LocaleSwitch,
    HeaderMembershipLink,
    CreditModal
  },
  data: function () {
    return {
      loading: true,
      memberships: {
        de: false,
        ch: false,
        at: false
      },
      brandStore: useBrandStore(),
      chatStore: useChatStore(),
      userStore: useUserStore()
    }
  },
  mounted: function () {
    this.userStore.loadCreditHistory()
  },
  methods: {
    openCreditModal () {
      this.$refs.creditModalComponent.show()
    }
  }
}
</script>
