<template>
  <div class="mb-main-content mb-booking-success">
    <div
      class="mb-top-container"
      >
      <div class="container">
        <div class="row mb-5">
          <div class="col-12">
              <h1 class="mb-title">
                <i class="icon-star-check"></i>
                {{ $t('page.membershipsuccess.congratulations', { firstname: currentContact.Firstname, lastname: currentContact.Lastname }) }}
                <div>{{ $t('page.membershipsuccess.bookingsuccessfull', { brandname: currentBrand.Name }) }}</div>
              </h1>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-progress position-sticky">
              <h3 v-t="'page.membershipsuccess.howitcontinues'"></h3>
              <ul class="mb-info-lst">
                <li><i class="fa fa-check"></i><span v-t="'page.membershipsuccess.membershipbooked'"></span></li>
                <li><i class="fa fa-check"></i><span>{{ $t('page.membershipsuccess.addonaccess', { brandname: currentBrand.Name }) }}</span></li>
                <li><i class="fa fa-check"></i><span v-t="'page.membershipsuccess.leadsmenue'"></span></li>
                <li
                  v-if="!avvSigned"
                >
                  <i class="fa fa-clock-o"></i><span v-t="'page.membershipsuccess.avvnotsigned'"></span>
                </li>
                <li
                  v-if="avvSigned"
                >
                  <i class="fa fa-check"></i><span v-t="'page.membershipsuccess.avvsigned'"></span>
                </li>
                <li><i class="fa fa-clock-o"></i><span v-t="'page.membershipsuccess.nextstep'"></span></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 d-flex justify-content-between flex-column">
            <div class="mt-5">
            <img
              class="img-fluid"
              :src="require('../../assets/images/mitgliedschaft-devices.png')"
            />
            </div>
            <h4 v-t="'page.membershipsuccess.successwishes'"></h4>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5 pb-5">
          <div class="mb-arrow-down">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mb-vid-cont"
      v-if="!avvSigned"
    >
      <div class="container pt-5">
        <div class="row mt-5">
          <div class="col-lg-5">
            <h2 class="mb-5" v-t="'page.membershipsuccess.avv.title'"></h2>
            <p v-t="'page.membershipsuccess.avv.description'"></p>
            <p v-html="$t('page.membershipsuccess.avv.steps')"></p>
            <p v-html="$t('page.membershipsuccess.avv.why')"></p>
          </div>
          <div class="col-lg-7 d-flex align-items-center justify-content-center">
            <button class="btn" @click="signAVVContract" v-t="'page.membershipsuccess.avv.buttonsign'"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-vid-cont">
      <div class="container pt-5 pb-5">
        <div
          class="row mt-5 mb-5"
        >
          <div class="col-lg-5">
            <h2 class="mb-5" v-t="'page.membershipsuccess.getthebestoutofit'"></h2>
            <div v-html="$t('page.membershipsuccess.videotip')"></div>
            <router-link to="/dashboard" class="btn mt-4 text-uppercase">{{ $t('page.membershipsuccess.buttondashboard') }}</router-link>
          </div>
          <div class="col-lg-7">
            <iframe
              id="ytplayer"
              type="text/html"
              src="https://www.youtube-nocookie.com/embed/u7-963opC9I"
              frameborder="0"
              allowfullscreen="allowfullscreen"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueConfetti from 'vue-confetti'

Vue.use(VueConfetti)

export default {
  name: 'MembershipSuccess',
  props: [],
  data: function () {
    return {
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    currentContact: function () {
      return this.app.currentContact
    },
    currentType: function () {
      return this.$route.meta.type
    },
    isMembershipBooking: function () {
      return this.currentType === 'membership'
    },
    isTopBrandBooking: function () {
      return this.currentType === 'topbrand'
    },
    currentBrand () {
      const current = this.$parent.currentBrand || {}
      return current
    },
    avvSigned: function () {
      return this.app.currentBrand.CompletedAVV === 1
    }
  },
  methods: {
    signAVVContract () {
      localStorage.setItem('returnLocationAfterAVVSetup', 'mitgliedschaft-buchen-erfolgreich')
      this.$router.push('/avv-unterzeichnen')
    }
  },
  mounted: function () {
    this.$confetti.start({
      particles: [
        {
          type: 'rect'
        }
      ]
    })
    setTimeout(() => {
      this.$confetti.stop()
    }, 5000)
  },
  watch: {
    '$route' (to, from) {
      if (from.path === '/mitgliedschaft-buchen-erfolgreich') {
        this.app.loadBrand(this.app.currentBrand.BrandID)
      }
    }
  }
}
</script>
